import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import LoadingState from './loading.reducer';

export const getLoadingState = (state: State) => state.loading;

export const selectIsLoading = createSelector(getLoadingState, (state: LoadingState) => state.loading);

export const selectUploadProgress = createSelector(getLoadingState, (state: LoadingState) => state.progress);

export const selectIsUploadInProgress = createSelector(getLoadingState, (state: LoadingState) => typeof state.progress === 'number');
