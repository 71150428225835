<div id="fab-dismiss" *ngIf="fabTogglerState === 'active'" (click)="toggle()"></div>

<div class="fab-container" [ngClass]="{'hidden' : hidden}">
  <button mat-fab class="fab-toggler button-red" [disabled]="disabled" (click)="toggle()">
    <i class="material-icons" [@fabToggler]="{ value: fabTogglerState }">add</i>
  </button>

  <div [@speedDialStagger]="buttons.length">
    <mat-chip-list *transloco="let t" class="mat-chip-list-stacked">
      <mat-chip
        class="button-secondary chip-button"
        *ngFor="let btn of buttons" (click)="!btn.disabled && btn.action(); toggle()"
        [matTooltip]="btn.tooltip | sibatTranslate:t"
        [class.disabled]="btn.disabled"
        [class.has-icon]="!!btn.icon">
        <mat-icon *ngIf="btn.icon" matChipAvatar>{{btn.icon}}</mat-icon>
        <span>{{ btn.label | sibatTranslate:t }}</span>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
