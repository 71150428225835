import { ControlDashboardComponent } from './cockpit/control-dashboard/control-dashboard.component';
import { UserResolver } from './cockpit/resolver/user.resolver';
import { Routes } from '@angular/router';
import { BuildingPageComponent } from './building/building-page/building-page.component';
import { BuildingsComponent } from './building/buildings/buildings.component';
import { AuthenticationGuard } from './authentication/authentication.guard';
import { PasswordModificationComponent } from './authentication/password-modification/password-modification.component';
import { CockpitComponent } from './cockpit/cockpit.component';
import { PasswordReinitializationComponent } from './authentication/password-reinitialization/password-reinitialization.component';
import { NewPasswordComponent } from './authentication/new-password/new-password.component';
import { NewUserComponent } from './cockpit/account-management/new-user/new-user.component';
import { ListUsersComponent } from './cockpit/account-management/list-users/list-users.component';
import { AdminGuard } from './authentication/admin.guard';
import { EditUserComponent } from './cockpit/account-management/edit-user/edit-user.component';
import { OfflineDefectsGuard } from './control/control-report/offline-defects.guard';
import { SignInPageComponent } from './authentication/sign-in-page/sign-in-page.component';
import { MunicipalityListComponent } from './cockpit/municipality-list/municipality-list.component';
import { BuildingOverviewComponent } from './building/building-overview/building-overview.component';
import { HistoryComponent } from './building/history/history.component';
import { BuildingDocumentsComponent } from './building/documents/building-documents.component';
import { InstallationListComponent } from './building/technical-installations/installation-list/installation-list.component';
import { BuildingResolver } from './building/building.resolver';
import { CasePreviewComponent } from './shared/components/case-preview/case-preview.component';
import { BuildingRemoveComponent } from './building/building-remove/building-remove.component';

export const routes: Routes = [
  {
    path: 'signin',
    component: SignInPageComponent,
    data: {
      title: 'signin',
    },
  },
  {
    path: 'forgotPassword',
    component: PasswordReinitializationComponent,
    data: {
      title: 'forgotPassword',
    },
  },
  {
    path: 'setNewPassword',
    component: NewPasswordComponent,
    data: {
      title: 'Nouveau mot de passe',
    },
  },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'building/:buildingId',
        canDeactivate: [OfflineDefectsGuard],
        component: BuildingPageComponent,
        resolve: {
          fetchTriggered: BuildingResolver,
        },
        data: {
          title: 'detail',
          top: '/',
          nav: 'map',
        },
        children: [
          {
            path: '',
            component: BuildingOverviewComponent,
            data: {
              title: 'detail',
              top: '/',
              nav: 'map',
            },
          },
          {
            path: 'documents',
            component: BuildingDocumentsComponent,
            data: {
              title: 'docs',
              top: '/',
              nav: 'map',
            },
          },
          {
            path: 'history',
            component: HistoryComponent,
            data: {
              title: 'history',
              top: '/',
              nav: 'map',
            },
          },
          {
            path: 'itech',
            component: InstallationListComponent,
            data: {
              title: 'itechs',
              top: '/',
              nav: 'map',
            },
          },
          {
            path: 'cases',
            component: CasePreviewComponent,
            data: {
              title: 'cases',
              top: '/',
              nav: 'map',
            },
          },
        ],
      },
      {
        path: 'building/:buildingId/delete',
        component: BuildingRemoveComponent,
        // data: {
        //   title: 'remove',
        //   top: '/',
        //    nav: 'map',
        // },
      },
      {
        path: 'changePassword',
        component: PasswordModificationComponent,
        data: {
          title: 'changePassword',
          top: '/cockpit',
          nav: 'cockpit',
        },
      },
      {
        path: 'cockpit',
        component: CockpitComponent,
        data: {
          title: 'cockpit',
          nav: 'cockpit',
        },
      },
      {
        path: 'cockpit/control-dashboard',
        component: ControlDashboardComponent,
      },
      {
        path: 'cockpit/admin',
        canActivate: [AdminGuard],
        children: [
          {
            path: 'users',
            component: ListUsersComponent,
            data: {
              title: 'showUsers',
              top: '/cockpit',
              nav: 'cockpit',
            },
          },
          {
            path: 'users/create',
            component: NewUserComponent,
            data: {
              title: 'createUser',
              top: '/cockpit',
              nav: 'cockpit',
            },
          },
          {
            path: 'users/edit/:userId',
            component: EditUserComponent,
            data: {
              title: 'editUser',
              top: '/cockpit',
              nav: 'cockpit',
            },
            resolve: {
              user: UserResolver,
            },
          },
          {
            path: 'municipalities',
            component: MunicipalityListComponent,
            data: {
              title: 'showMunicipalities',
              top: '/cockpit',
              nav: 'cockpit',
            },
          },
        ],
      },
      {
        path: 'case',
        loadChildren: () => import('./case/case.module').then(m => m.CaseModule),
      },
      {
        path: 'migration/gdossiers',
        loadChildren: () => import('./migration/migration.module').then(m => m.MigrationModule),
      },
      {
        path: 'area',
        loadChildren: () => import('./area/area.module').then(m => m.AreaModule),
      },
      {
        path: '',
        component: BuildingsComponent,
        data: {
          title: 'map',
          nav: 'map',
        },
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
