import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/authentication/model/user.model';
import { Store } from '@ngrx/store';
import { selectUserById } from 'src/app/admin/store/admin.selector';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<User | undefined> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User | undefined> {
    const userId = Number(route.paramMap.get('userId'));
    return this.store.select(selectUserById, userId).pipe(take(1));
  }
}
