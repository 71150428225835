import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectSigninUrlIfUnauthenticated } from './store/authentication.selector';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin().pipe(
      map(redirectUrl => {
        if (redirectUrl) {
          return this.router.parseUrl(redirectUrl);
        } else {
          return true;
        }
      })
    );
  }

  checkLogin(): Observable<string | undefined> {
    return this.store.select(selectSigninUrlIfUnauthenticated);
  }
}
