<div [ngClass]="isReadOnly ? 'readonly-mode' : 'edit-mode'" *transloco="let t; read: 'building.assessment.protectiveMeasuresForm'">
  <form class="measure-card" [formGroup]="measureForm">
    <span class="title" *ngIf="isEditionMode && !readOnly" data-testid="modification-text">
      <mat-icon>mode_edit</mat-icon>
      <p>{{ t('modification') }}</p>
    </span>
    <mat-form-field>
      <mat-label>{{t('choseType')}}</mat-label>
      <mat-chip-list>
        <mat-chip *ngFor="let measureType of measureTypes$ | async"
          selectable
          (click)="onMeasureTypeSelected(measureType)"
          [class.selected]="isMeasureTypeSelected(measureType.id)"
          [disabled]="isMeasureTypeSelected(measureType.id) || readOnly"
        >
          <mat-icon matChipAvatar *ngIf="isMeasureTypeSelected(measureType.id)">check</mat-icon>
          {{t('ref_measure.' + measureType.text)}}
        </mat-chip>
      </mat-chip-list>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ t('description') }}</mat-label>
      <input matInput formControlName="description"/>
    </mat-form-field>
    <mat-form-field class="proposition-improve">
      <mat-label>{{ t('proposition') }}</mat-label>
      <textarea
          data-testid="propositionImprove-field"
          matInput formControlName="proposition"
          (keyup)="extendTextArea($event.target)">
      </textarea>
    </mat-form-field>
  </form>
  <sibat-validate-button
      data-testid="validate-changes-button"
      *ngIf="!isReadOnly"
      (clicked)="saveMeasure()"
      [label]="t('save')"
      [disabled]="measureForm.pristine"
  >
  </sibat-validate-button>
</div>
