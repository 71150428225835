import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BuildingSummary } from '../model/building.model';
import { Store } from '@ngrx/store';
import { downloadCurrentBuildings, downloadSelectedBuildings, findBuildings } from '../store/buildings.action';
import { combineLatest, firstValueFrom, Observable, Subscription } from 'rxjs';
import { selectBuildingsCount, selectBuildings, selectSelectedBuildings } from '../store/buildings.selector';
import { take, map } from 'rxjs/operators';
import { selectBuildingClassesFilter, selectConstructionPeriodsFilter, selectMunicipalitiesFilter } from '../store/reference-data.selector';
import { selectControlPlanningEnabled } from 'src/app/features/store/feature-flags.selector';

@Component({
  selector: 'sibat-lists',
  template: `
    <mat-tab-group *ngIf="!detailedSelectionDisplay"
                   class="mat-tab"
                   [selectedIndex]="selectedTab"
                   (selectedIndexChange)="changeSelectedTab($event)">
      <mat-tab label="{{ 'building.list.filteredCount' | transloco: { buildingsCount: buildingsCount$ | async } }}">
        <sibat-building-list
          [buildingsList]="buildings$ | async"
          [buildingsCount]="buildingsCount$ | async"
          [highlightSelectedBuildings]="false"
          [displayAddButton]="true"
          [attributesDisplay]="attributesDisplay"
          (centerOnBuilding)="centerOnSpecifiedBuilding($event)"
          (centerOnAllBuildings)="centerOnFilteredBuildings()"
          (downloadList)="downloadBuildingsList($event)"
        ></sibat-building-list>
      </mat-tab>

      <mat-tab
        label="{{ 'building.list.selectedCount' | transloco: { buildingsCount: selectedBuildingsCount$ | async } }}">
        <sibat-building-list
          [buildingsList]="selectedBuildings$ | async"
          [buildingsCount]="selectedBuildingsCount$ | async"
          [highlightSelectedBuildings]="true"
          [displayAddButton]="false"
          [attributesDisplay]="attributesDisplay"
          (centerOnBuilding)="centerOnSpecifiedBuilding($event)"
          (centerOnAllBuildings)="centerOnSelectedBuildings()"
          (downloadList)="downloadBuildingsList($event)"
        ></sibat-building-list>
      </mat-tab>
    </mat-tab-group>
    <div class="expand-button mat-elevation-z8" *ngIf="(controlPlanningEnabled$ | async) && !detailedSelectionDisplay"
         (click)="displayDetailedListSelection()">
      <mat-icon svgIcon="arrow-left"></mat-icon>
    </div>
  `,
  styleUrls: ['building-list-group.component.scss'],
})
export class BuildingListGroupComponent implements OnInit, OnDestroy {
  @Input() detailedSelectionDisplay = false;
  @Input() selectedTab = 0;
  @Output() selectedTabChange = new EventEmitter<number>();
  @Output() centerOnBuildings = new EventEmitter<number[] | 'all'>();
  @Output() showDetailedSelection = new EventEmitter();

  selectedBuildings$: Observable<BuildingSummary[]>;
  controlPlanningEnabled$: Observable<boolean>;
  selectedBuildingsCount$: Observable<number>;
  buildings$ = this.store.select(selectBuildings);
  buildingsCount$ = this.store.select(selectBuildingsCount);
  filtersSubscriptions = new Subscription();
  attributesDisplay: Map<string, boolean> = new Map<string, boolean>();

  constructor(private store: Store) {
    this.selectedBuildings$ = this.store.select(selectSelectedBuildings);
    this.selectedBuildingsCount$ = this.selectedBuildings$.pipe(map(x => x.length));
    this.controlPlanningEnabled$ = combineLatest([this.store.select(selectControlPlanningEnabled), this.selectedBuildings$]).pipe(
      map(([controlPlanningEnabled, selectedBuildings]) => controlPlanningEnabled && selectedBuildings.length > 0)
    );
  }

  ngOnInit(): void {
    this.filtersSubscriptions.add(
      this.store.select(selectMunicipalitiesFilter).subscribe(municipalities => {
        this.attributesDisplay['municipality'] = municipalities.length > 0;
      })
    );
    this.filtersSubscriptions.add(
      this.store
        .select(selectBuildingClassesFilter)
        .subscribe(buildingClasses => (this.attributesDisplay['buildingClass'] = buildingClasses.length > 0))
    );
    this.filtersSubscriptions.add(
      this.store
        .select(selectConstructionPeriodsFilter)
        .subscribe(constructionPeriods => (this.attributesDisplay['constructionPeriod'] = constructionPeriods.length > 0))
    );
    this.store.dispatch(findBuildings());
  }

  ngOnDestroy(): void {
    this.filtersSubscriptions.unsubscribe();
  }

  downloadBuildingsList(isSelectedBuildings: boolean): void {
    if (isSelectedBuildings) {
      this.store.dispatch(downloadSelectedBuildings());
    } else {
      this.store.dispatch(downloadCurrentBuildings());
    }
  }

  centerOnSpecifiedBuilding(buildingId: number) {
    this.centerOnSpecifiedBuildings([buildingId]);
  }

  centerOnSpecifiedBuildings(buildingIds: number[]) {
    this.centerOnBuildings.emit(buildingIds);
  }

  async centerOnFilteredBuildings() {
    this.buildings$.pipe(take(1)).subscribe(() => this.centerOnBuildings.emit('all'));
  }

  async centerOnSelectedBuildings() {
    this.centerOnSpecifiedBuildings(await this.getSelectedBuildings().then(buildings => buildings.map(b => b.id)));
  }

  displayDetailedListSelection() {
    this.detailedSelectionDisplay = true;
    this.showDetailedSelection.emit();
  }

  changeSelectedTab = (tabIndex: number) => {
    this.selectedTabChange.emit(tabIndex);
  };

  private async getSelectedBuildings(): Promise<BuildingSummary[]> {
    return firstValueFrom(this.selectedBuildings$);
  }
}
