export interface FeatureFlag {
  key: string;
  enabled: boolean;
}

export const FEATURE = {
  welcomeMessage: 'welcomeMessage',
  buildingDocumentBrowsing: 'buildingDocumentBrowsing',
  documentUpload: 'documentUpload',
  startBuildingControl: 'startBuildingControl',
  orphanControls: 'orphanControls',
  itech: 'itech',
  controlPlanning: 'controlPlanningFlag',
} as const;
export type Feature = typeof FEATURE[keyof typeof FEATURE];
