import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BuildingDocument } from '../model/building-document.model';
import { BUILDINGS_BASE_URI } from './building.service';
import { buildNestedPath } from '../../file-browser/service/file-browser.service';

@Injectable({
  providedIn: 'root',
})
export class BuildingDocumentsService {
  constructor(private readonly http: HttpClient) {}

  getBuildingDocuments(buildingId: number, relativePath: string): Observable<BuildingDocument[]> {
    const params = new HttpParams().append('path', relativePath);
    return this.http.get<BuildingDocument[]>(`${BUILDINGS_BASE_URI}/${buildingId}/filestore`, { params });
  }

  uploadBuildingDocument(
    buildingId: number,
    path: string,
    nestedPath: string,
    file: File,
    overwrite: boolean = false
  ): Observable<HttpResponse<void>> {
    const multipartFile = new FormData();
    multipartFile.append('file', file);
    const params = new HttpParams()
      .append('path', buildNestedPath(path, nestedPath))
      .append('overwrite', overwrite);
    return this.http.post<void>(`${BUILDINGS_BASE_URI}/${buildingId}/filestore`, multipartFile, { observe: 'response', params });
  }

  downloadBuildingDocument(buildingId: number, path: string, filename: string): Observable<Blob> {
    const params = new HttpParams().append('filePath', buildNestedPath(path, filename));
    return this.http.get(`${BUILDINGS_BASE_URI}/${buildingId}/filestore/download`, {
      headers: {},
      responseType: 'blob',
      params,
    });
  }

  deleteBuildingDocument(buildingId: number, path: string, fileName: string): Observable<void> {
    const params = new HttpParams().append('filePath', buildNestedPath(path, fileName));
    return this.http.delete<void>(`${BUILDINGS_BASE_URI}/${buildingId}/filestore`, { params });
  }

  moveBuildingDocument(buildingId: number, from: string, to: string, isFolder: boolean): Observable<HttpResponse<void>> {
    return this.http.post<void>(
      `${BUILDINGS_BASE_URI}/${buildingId}/filestore:move`,
      {
        from,
        to,
        isFolder,
      },
      { observe: 'response' }
    );
  }

  createBuildingSubFolder(buildingId: number, path: string, folderName: string): Observable<HttpResponse<void>> {
    const params = new HttpParams().append('path', buildNestedPath(path, folderName));
    return this.http.post<void>(`${BUILDINGS_BASE_URI}/${buildingId}/filestore/subfolder`, null, { observe: 'response', params });
  }

}
