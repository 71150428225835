import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { mergeMap, tap } from 'rxjs/operators';
import { getHistory } from '../store/building.action';
import { selectHistoryTimeline } from '../store/building.selector';

@Component({
  selector: 'sibat-history',
  template: ` <sibat-timeline [timelineData]="historyRecord$ | async"></sibat-timeline> `,
  styleUrls: ['history.component.scss'],
})
export class HistoryComponent {
  historyRecord$ = (this.route.parent ?? this.route).params.pipe(
    tap(({ buildingId }) => this.store.dispatch(getHistory({ buildingId }))),
    mergeMap(() => this.store.select(selectHistoryTimeline))
  );

  constructor(private store: Store, private route: ActivatedRoute) {}
}
