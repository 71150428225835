import { COLOR_TYPOLOGY, ColorTypology, Typology } from './typology.model';
import { Expert } from './expert.model';
import { Control } from '../../control/model/control.model';
import { AssessmentDto } from '../../assessment/model/assessment.model';

export const BUILDING_STATUS = {
  constructed: 'CONSTRUCTED',
  project: 'PROJECT',
  underConstruction: 'UNDER_CONSTRUCTION',
  exploitation: 'EXPLOITATION',
  demolished: 'DEMOLISHED'
} as const;
export type BuildingStatus = typeof BUILDING_STATUS[keyof typeof BUILDING_STATUS];

export interface Building {
  id: number;
  fidBat: string;
  egid: number;
  buildingClass: string;
  localizedAffectations: string[];
  typology?: Typology;
  description: string;
  addresses: string[];
  postcode: number;
  municipality: string;
  period: string;
  expert: Expert | null;
  urlIcare: string;
  urlSharePoint?: string;
  urlDangersFR?: string;
  urlDangersDE?: string;
  urlCartoECAB?: string;
  tags?: string[];
  ongoingControl?: Control;
  ongoingAssessment?: AssessmentDto;
  status: BuildingStatus;
  nextControlDate?: string;
}

export interface BuildingSummary {
  id: number;
  fidBat: string;
  buildingClass: string;
  localizedAffectations: string[];
  currentColor?: ColorTypology;
  description: string;
  addresses: string[];
  municipality: string;
  period: string;
  selected: boolean;
  tags?: string[];
  lastControlDate?: string;
  nextControlDate?: string;
}

export interface BuildingAddress {
  id: number;
  addresses: string[];
  urlSharePoint?: string;
}

export interface DeleteInfos {
  msg: string;
  success: boolean;
}

/**
 * Control interval in years
 */
const CONTROL_INTERVAL = {
  default: 5,
  forGreenTypologies: 10,
} as const;

export const nextControlInterval = (typology?: Typology): number | undefined => {
  switch (typology?.color) {
    case COLOR_TYPOLOGY.green:
      return CONTROL_INTERVAL.forGreenTypologies;
    case COLOR_TYPOLOGY.white:
      return undefined;
    default:
      return CONTROL_INTERVAL.default;
  }
};
