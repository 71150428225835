import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DefectCompliance } from 'src/app/control/model/control.model';
import { downloadComplianceProof } from 'src/app/control/store/control.action';

@Component({
  selector: 'sibat-control-defect-compliance-details',
  templateUrl: './control-defect-compliance-details.component.html',
  styleUrls: ['./control-defect-compliance-details.component.scss'],
})
export class ControlDefectComplianceDetailsComponent {
  @Input() compliance: DefectCompliance;
  @Input() controlId: number;

  isOpen = false;

  constructor(private store: Store) {}

  onToggleDetailsClick() {
    this.isOpen = !this.isOpen;
  }

  downloadComplianceProof(proofName: string) {
    this.store.dispatch(downloadComplianceProof({ controlId: this.controlId, defectId: this.compliance.defectId, proofName }));
  }
}
