import { UntypedFormControl, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { uncompletedValidator } from 'src/app/building/model/technical-installations-fields.model';


@Pipe({
  name: 'requiredIndicator',
})
export class RequiredIndicatorPipe implements PipeTransform {
  transform(control: UntypedFormControl | UntypedFormGroup, formControlName?: string): string {
    let formControl: AbstractControl | null = control;

    if (control instanceof UntypedFormGroup && formControlName) {
      formControl = control.get(formControlName);
    }

    if (formControl) {
      return this.isRequired(formControl) || this.isNeededForCompletion(formControl) ? ' *' : '';
    }

    return '';
  }

  private isRequired(control: AbstractControl): boolean {
    return control.hasValidator(Validators.required);
  }

  private isNeededForCompletion(control: AbstractControl): boolean {
    return control.hasValidator(uncompletedValidator);
  }
}
