import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { findMunicipalities } from '../../building/store/buildings.action';
import { selectSortedMunicipalities } from '../../building/store/reference-data.selector';
import { Municipality } from '../../building/model/municipality.model';

@Component({
  selector: 'sibat-municipality-list',
  templateUrl: './municipality-list.component.html',
  styleUrls: ['./municipality-list.component.scss'],
})
export class MunicipalityListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['nr', 'name', 'expert'];
  dataSource: MatTableDataSource<Municipality>;
  municipalitySubscription: Subscription;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(findMunicipalities());
    this.municipalitySubscription = this.store.select(selectSortedMunicipalities).subscribe(municipalities => {
      this.dataSource = new MatTableDataSource<Municipality>(municipalities);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'expert':
            return `${item.expert.firstName} ${item.expert.lastName}`.trim();
          default:
            return item[property];
        }
      };
      this.dataSource.sort = this.sort;
    });
  }

  ngOnDestroy(): void {
    if (this.municipalitySubscription) {
      this.municipalitySubscription.unsubscribe();
    }
  }
}
