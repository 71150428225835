import { Action, createReducer, on } from '@ngrx/store';
import { getEnvironmentConfigSuccess } from './environment-config.action';
export default interface EnvironmentConfigState {
  environment?: string;
}

export const initialState: EnvironmentConfigState = {
  environment: undefined,
};

const configReducer = createReducer(
  initialState,
  on(
    getEnvironmentConfigSuccess,
    (state, { config }): EnvironmentConfigState => ({
      ...state,
      environment: config.environment,
    } as EnvironmentConfigState)
  )
);

export const reducer = (state: EnvironmentConfigState | undefined, action: Action): EnvironmentConfigState => configReducer(state, action);
