import { notifyWarn } from 'src/app/model/notification.model';
import { NotificationService } from 'src/app/notification/notification.service';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '../service/user.service';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  checkUserAccessToBuilding, checkUserAccessToBuildingFailure, checkUserAccessToBuildingSuccess,
  clearUser,
  getConfigSuccess,
  getUserAfterLoginSuccess,
  getUserAfterLoginSuccessFailure,
  getUserAfterTokenLoaded,
  getUserAfterTokenLoadedFailure,
  getUserSuccess,
  getUserSuccessWithRedirect, loadUser, loadUserFailure, loadUserSuccess,
  markMotdAsRead,
  registerGisToken,
  registerGisTokenFailure,
  registerGisTokenSuccess,
  showMotd, updateActiveRole,
  updateUserLanguage,
  updateUserLanguageFailure,
  updateUserLanguageSuccess,
} from './user.action';
import { logoutSuccess, redirectToCockpit, redirectToHome, redirectToLogin } from './authentication.action';
import { of } from 'rxjs';
import { NavigationService } from '../../navigation-rail/service/navigation.service';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { selectHasAccess, selectMotd, selectUser, selectUserId } from './user.selector';
import {
  findBuildingClasses,
  findConstructionPeriods,
  findMunicipalities,
  findTypologyAffectations,
  reloadLocalizedData,
} from '../../building/store/buildings.action';
import { getTags } from '../../building/store/tag.action';
import { selectAuthenticationStatus } from './authentication.selector';
import { noopAction } from 'src/app/shared/app.action';
import { ConfigurationService } from '../service/configuration.service';
import { getFeatureFlags } from '../../features/store/feature-flags.action';
import { User } from '../model/user.model';
import { MatDialog } from '@angular/material/dialog';
import { MotdComponent } from '../../notification/motd/motd.component';
import { reportMapError } from '../../map/store/map.action';
import { selectIsWelcomeMessageEnabled } from 'src/app/features/store/feature-flags.selector';
import { getMotdAndCheck, getMotdAndCheckSuccess } from './motd.action';
import { Motd } from '../model/motd.model';
import { selectCurrentBuildingId } from '../../building/store/building.selector';
import { PopupChangingRoleComponent } from '../../navigation-rail/popup-changing-role/popup-changing-role.component';

export const MOTD_READ = 'motd_read';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  getUserAfterLoginSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserAfterLoginSuccess),
      mergeMap(() =>
        this.userService.findUser().pipe(
          map(user => getUserSuccessWithRedirect({ user })),
          catchError(() => of(getUserAfterLoginSuccessFailure()))
        )
      )
    )
  );

  updateUserLanguageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserLanguage),
      concatLatestFrom(() => this.store.select(selectUser)),
      mergeMap(([{ language }, user]) => {
        if (user) {
          return this.userService.updateLanguage(language).pipe(
            map(() => updateUserLanguageSuccess({ language })),
            catchError(() => of(updateUserLanguageFailure()))
          );
        } else {
          return of(updateUserLanguageSuccess({ language }));
        }
      })
    )
  );

  updateUserLanguageSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserLanguageSuccess),
      tap(({ language }) => this.navigationService.setActiveLang(language)),
      concatLatestFrom(() => this.store.select(selectAuthenticationStatus)),
      map(([_, connected]) => (connected ? reloadLocalizedData() : noopAction()))
    )
  );

  getUserAfterTokenLoadedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserAfterTokenLoaded),
      mergeMap(() =>
        this.userService.findUser().pipe(
          map(user => getUserSuccess({ user })),
          catchError(() => of(getUserAfterTokenLoadedFailure()))
        )
      )
    )
  );

  getMunicipalitiesDataAfterUserRetrievedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      map(() => findMunicipalities())
    )
  );

  getAffectationsDataAfterUserRetrievedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      map(() => findTypologyAffectations())
    )
  );

  getBuildingClassesDataAfterUserRetrievedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      map(() => findBuildingClasses())
    )
  );

  getConstructionPeriodDataAfterUserRetrievedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      map(() => findConstructionPeriods())
    )
  );

  getTagsAfterUserRetrievedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      map(() => getTags())
    )
  );

  setLanguageAfterUserRetrievedEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserSuccess, getUserSuccessWithRedirect),
        tap(({ user }: { user: User }) => this.navigationService.ensureActiveLang(user.language))
      ),
    { dispatch: false }
  );

  getFeatureFlagsAfterUserRetrievedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      map(() => getFeatureFlags())
    )
  );

  getConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      mergeMap(({ user }) =>
        this.configurationService.getConfiguration().pipe(
          map(configuration =>
            getConfigSuccess({
              user,
              configuration,
            })
          )
        )
      )
    )
  );

  getMotdAndCheckEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccess, getUserSuccessWithRedirect),
      map(() => getMotdAndCheck())
    )
  );

  registerGisToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConfigSuccess),
      map((action: ReturnType<typeof getConfigSuccess>) =>
        registerGisToken({
          user: action.user,
          arcGisUrl: action.configuration.portalUrl,
        })
      )
    )
  );

  registerGisTokenEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerGisToken),
      mergeMap(action =>
        this.userService.registerGisToken(action.user, action.arcGisUrl).pipe(
          map(() => registerGisTokenSuccess()),
          catchError(error => of(registerGisTokenFailure({ error, ...notifyWarn('authentication.error.serviceNotAvailable', true) })))
        )
      )
    )
  );

  registerGisTokenFailureReportError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerGisTokenFailure),
      map(({ error }) => reportMapError({ error }))
    )
  );

  redirectAfterUserRetrievedEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccessWithRedirect),
      map(() => (this.navigationService.isSmallScreen() ? redirectToCockpit() : redirectToHome()))
    )
  );

  redirectToSigninEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserAfterTokenLoadedFailure),
      map(() => redirectToLogin())
    )
  );

  logoutSuccessCleanupEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSuccess),
      map(() => {
        this.notificationService.dismissVisible();
        return clearUser();
      })
    )
  );

  setMotdReadCookieEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(markMotdAsRead),
        tap(({ uuid }) => this.cookieService.set(MOTD_READ, uuid))
      ),
    { dispatch: false }
  );

  checkMotdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMotdAndCheckSuccess),
      concatLatestFrom(() => [
        this.store.select(selectMotd),
        this.store.select(selectAuthenticationStatus),
        this.store.select(selectIsWelcomeMessageEnabled),
      ]),
      filter(([, motd, connected, motdEnabled]) => {
        if (!!motd && connected && motdEnabled) {
          return this.cookieService.get(MOTD_READ) !== motd.uuid;
        }
        return false;
      }),
      mergeMap(([, motd, ,]: [any, Motd, boolean, boolean]) => of(showMotd({ motd })))
    )
  );

  showMotdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showMotd),
      concatLatestFrom(() => [this.store.select(selectUser)]),
      mergeMap(([{ motd }, user]) => {
        const message = 'de' === user?.language ? motd.messageDe : motd.messageFr;
        this.dialog.open(MotdComponent, {
          data: message,
        });
        return of(markMotdAsRead({ uuid: motd.uuid }));
      })
    )
  );
  // Effet pour mettre à jour le rôle actif
  updateActiveRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateActiveRole),
      mergeMap(({ newRole }) =>
        this.userService.updateActiveRole(newRole).pipe(
          map(() => loadUser()),
          catchError((error) => of(loadUserFailure({ error })))
        )
      )
    )
  );
  // Effet pour charger l'utilisateur
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      concatLatestFrom(() => this.store.select(selectUser)),
      mergeMap(([_, currentUser]) => this.userService.findUser().pipe(
          map((user) => loadUserSuccess({user, previousUser: currentUser})),
          catchError((error) => of(loadUserFailure({error})))
        ))
    )
  );

  // Effet pour écouter le succès de loadUser et ensuite vérifier l'accès au bâtiment
  loadUserSuccessCheckAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserSuccess),
      concatLatestFrom(()=>[
        this.store.select((selectCurrentBuildingId)),
        this.store.select((selectUserId))]),
      mergeMap(([_, idBuilding, idUser]) => {
        if (idBuilding && idUser) {
          return of(checkUserAccessToBuilding({ idBuilding, idUser }));
        }
        return of();
      })
    )
  );

  checkUserAccessToBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkUserAccessToBuilding),
      mergeMap(({ idBuilding, idUser }) =>
        this.userService.checkUserAccessToBuilding(idBuilding, idUser).pipe(
          map((hasAccessToBuilding: boolean) => checkUserAccessToBuildingSuccess({ hasAccessToBuilding })),
          catchError(error => of(checkUserAccessToBuildingFailure({ error })))
        )
      )
    )
  );

  checkAccessEffect$ = createEffect(() =>
      this.actions$.pipe(
        ofType(checkUserAccessToBuildingSuccess),
        concatLatestFrom(() => this.store.select(selectHasAccess)),
        map(([_, hasAccess]) => {
          if (!hasAccess) {
            this.dialog.open(PopupChangingRoleComponent, {
              width: '600px',
            });
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private configurationService: ConfigurationService,
    private navigationService: NavigationService,
    private cookieService: CookieService,
    private store: Store,
    private dialog: MatDialog,
    private notificationService: NotificationService,
  ) {}
}
