import {
  announceInstallation,
  endTemporaryShutdownInstallation,
  temporaryShutdownInstallation,
  updateAnnouncement,
  updateNextControlDate,
} from '../../store/technical-installation.action';
import {
  AnnouncementForm,
  ItechUpdateAction,
} from '../../model/technical-installations.model';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'src/app/shared/service/dialog.service';
import {
  ITECH_UPDATE_ACTION,
  TechnicalInstallation,
  UpdateMessage
} from '../../model/technical-installations.model';
import {
  addAssessmentReport,
  addComplianceReport,
  addInstallationCertificate,
  addMaintenanceContract,
  addPeriodicControlReport,
  addReceptionControlReport,
  cancelMaintenanceContract,
  shutdownInstallation,
} from '../../store/technical-installation.action';
import { UpdateFormDialogComponent } from '../update-form-dialog/update-form-dialog.component';
import {
  assessmentReportFormFields,
  periodicControlFormFields,
  shutdownFormFields,
  nextControlFormFields,
  installationCertificateFormFields,
  maintenanceContractFormFields,
  receptionControlReportFormFields,
  complianceReportFormFields,
  createDefaultFileName,
  FieldGroup,
  temporaryShutdownFormFields,
} from '../../model/technical-installations-fields.model';
import { AnnouncementFormDialogComponent } from '../announcement-form-dialog/announcement-form-dialog.component';
import { selectIsExpertOrAbove } from '../../../authentication/store/user.selector';
import { Observable } from 'rxjs';

const baseHelp = 'https://ecab.atlassian.net/wiki/spaces/SS/pages/994607133/Installations+Techniques+ITECH';
const announceHelp = `${baseHelp}#Ajouter-une-attestation-d%E2%80%99installation`;
const maintenanceContractHelp = `${baseHelp}#Ajouter-un-contrat-de-maintenance`;
const receptionControlHelp = `${baseHelp}#Ajouter-le-rapport-de-contr%C3%B4le-de-r%C3%A9ception`;
const complianceReportHelp = `${baseHelp}#Mise-en-conformit%C3%A9`;
const periodicControlHelp = `${baseHelp}#Contr%C3%B4le-p%C3%A9riodique-%2F-Evaluation`;
const assessmentHelp = `${baseHelp}#Contr%C3%B4le-p%C3%A9riodique-%2F-Evaluation`;
const shutdownHelp = `${baseHelp}#Annoncer-l%E2%80%99arr%C3%AAt-d%C3%A9finitif-ou-le-d%C3%A9montage-d%E2%80%99une-ITECH`;

@Component({
  selector: 'sibat-installation-detail',
  templateUrl: './installation-detail.component.html',
  styleUrls: ['./installation-detail.component.scss'],
})
export class InstallationDetailComponent implements OnChanges {
  @Input() installation: TechnicalInstallation;
  @Input() buildingId: number;

  isExpert$: Observable<boolean> = this.store.select(selectIsExpertOrAbove);

  pastNextControlDate = false;
  readonly updateActions = ITECH_UPDATE_ACTION;

  constructor(private dialogService: DialogService, private store: Store) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.installation) {
      this.pastNextControlDate = !!this.installation.nextControlDate && new Date(this.installation.nextControlDate) < new Date();
    }
  }

  async requestUpdate(documentAction: ItechUpdateAction, isNew: boolean = true) {
    let message: UpdateMessage | undefined;
    const currentValue = isNew ? undefined : this.installation;

    switch (documentAction) {
      case ITECH_UPDATE_ACTION.announcement:
        const result = await this.dialogService.openDialogComponentAsync<AnnouncementForm>(AnnouncementFormDialogComponent, {
          defaultValue: currentValue,
        });

        if (result) {
          if (isNew) {
            this.store.dispatch(announceInstallation({ form: result, buildingId: this.buildingId }));
          } else {
            this.store.dispatch(updateAnnouncement({
              installationId: this.installation.id,
              form: result,
              buildingId: this.buildingId
            }));
          }
        }
        break;
      case ITECH_UPDATE_ACTION.installationCertificate:
        message = await this.fillInUpdateForm(
          isNew ? 'addInstallationCertificate' : 'editInstallationCertificate',
          installationCertificateFormFields(
            currentValue,
            createDefaultFileName('attestation', this.installation.installationType)
          ),
          announceHelp
        );
        if (message) {
          this.store.dispatch(addInstallationCertificate({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.maintenanceContract:
        message = await this.fillInUpdateForm(
          isNew ? 'addMaintenanceContract' : 'editMaintenanceContract',
          maintenanceContractFormFields(currentValue, createDefaultFileName('maintenance', this.installation.installationType)),
          maintenanceContractHelp
        );
        if (message) {
          this.store.dispatch(addMaintenanceContract({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.cancelMaintenanceContract:
        const confirmed = await this.dialogService.requestConfirmation({
          translationKey: 'building.technicalInstallation.confirmMaintenanceContractDeletion',
        });
        if (confirmed) {
          this.store.dispatch(
            cancelMaintenanceContract({
              installationId: this.installation.id,
              buildingId: this.buildingId,
            })
          );
        }
        break;
      case ITECH_UPDATE_ACTION.receptionControlReport:
        message = await this.fillInUpdateForm(
          isNew ? 'addReceptionControlReport' : 'editReceptionControlDate',
          receptionControlReportFormFields(currentValue, createDefaultFileName('controle-reception', this.installation.installationType)),
          receptionControlHelp
        );
        if (message) {
          this.store.dispatch(addReceptionControlReport({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.complianceReport:
        message = await this.fillInUpdateForm(
          'addComplianceReport',
          complianceReportFormFields(currentValue, createDefaultFileName('defauts', this.installation.installationType)),
          complianceReportHelp
        );
        if (message) {
          this.store.dispatch(addComplianceReport(message));
        }
        break;
      case ITECH_UPDATE_ACTION.periodicControlReport:
        message = await this.fillInUpdateForm(
          isNew ? 'addPeriodicControlReport' : 'editPeriodicControlDate',
          periodicControlFormFields(currentValue, createDefaultFileName('controle-periodique', this.installation.installationType)),
          periodicControlHelp
        );
        if (message) {
          this.store.dispatch(addPeriodicControlReport({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.assessmentReport:
        message = await this.fillInUpdateForm(
          isNew ? 'addAssessmentReport' : 'editAssessmentReport',
          assessmentReportFormFields(
            currentValue,
            createDefaultFileName('formulaire-approche-preliminaire', this.installation.installationType),
          ),
          assessmentHelp
        );
        if (message) {
          this.store.dispatch(addAssessmentReport({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.shutdown:
        message = await this.fillInUpdateForm('shutdownOrDismantling', shutdownFormFields(currentValue), shutdownHelp);
        if (message) {
          this.store.dispatch(shutdownInstallation({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.temporaryShutdown:
        message = await this.fillInUpdateForm(
          'temporaryShutdown',
          temporaryShutdownFormFields(currentValue, createDefaultFileName('arrettemporaire', this.installation.installationType)),
          shutdownHelp
        );
        if (message) {
          this.store.dispatch(temporaryShutdownInstallation({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.endTemporaryShutdown:
        message = {
          form: {},
          installationId: this.installation.id,
          buildingId: this.buildingId,
        };
        if (message) {
          this.store.dispatch(endTemporaryShutdownInstallation({ ...message, isNew }));
        }
        break;
      case ITECH_UPDATE_ACTION.nextControlDate:
        const fields = nextControlFormFields(this.installation);
        message = await this.fillInUpdateForm('nextControlDate', fields);
        if (message) {
          this.store.dispatch(updateNextControlDate(message));
        }
        break;
    }
  }

  private async fillInUpdateForm(
    titleKey: string,
    fieldGroup: FieldGroup,
    helpLink?: string
  ): Promise<UpdateMessage | undefined> {
    const result = await this.dialogService.openDialogComponentAsync(
      UpdateFormDialogComponent, { fieldGroup, titleKey, helpLink }
    );

    if (result) {
      return {
        form: result,
        installationId: this.installation.id,
        buildingId: this.buildingId,
      };
    }
  }
}
