import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

@Component({
  selector: 'sibat-rename-file',
  templateUrl: './rename-file-dialog.component.html',
  styleUrls: [
    './rename-file-dialog.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameFileDialogComponent {

  fileName: string;
  extension = '';

  constructor(
    public dialogRef: MatDialogRef<RenameFileDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: { fileName: string }
  ) {
    const nameParts = data.fileName.split('.');
    if (nameParts.length > 1 && nameParts[0].length > 0) {
      this.extension = `.${nameParts[nameParts.length - 1]}`;
      this.fileName = nameParts.slice(0, nameParts.length - 1).join('.');
    } else {
      this.fileName = data.fileName;
    }
  }

  validate() {
    this.dialogRef.close(this.fileName.trim() + this.extension);
  }

  cancel() {
    this.dialogRef.close();
  }

}
