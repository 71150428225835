<ng-container *transloco="let t; read: 'building.technicalInstallation'">
  <div mat-dialog-title class="title-container">
    <h2>{{ t(data.titleKey) }}</h2>
    <sibat-icon-button *ngIf="data.helpLink" [href]="data.helpLink" icon="help" target="_blank">
    </sibat-icon-button>
  </div>

  <mat-dialog-content sibatDragNDrop (fileDropped)="fileDropped($event)">
    <div #dialogContent [formGroup]="documentForm" [class.accepts-one-file]="acceptsOneFile">
      <div *ngFor="let field of data.fieldGroup.fields">
        <label *ngIf="field.type !== 'file'">{{ t(field.labelKey) }} {{ field.formControl | requiredIndicator }}</label>
        <p *ngIf="field.descriptionKey" class="description">{{ t(field.descriptionKey) }}</p>
        <p *ngIf="field.type === 'radio'" class="radio-field">
          <mat-radio-group [formControlName]="field.name" [attr.aria-label]="t(field.labelKey)">
            <mat-radio-button *ngFor="let choice of field.choices" [value]="choice.value">
              {{ t(choice.labelKey, choice.labelKeyOption) }}
            </mat-radio-button>
          </mat-radio-group>
        </p>
        <p *ngIf="field.type === 'select'">
          <mat-form-field>
            <mat-select [formControlName]="field.name">
              <mat-option *ngFor="let choice of field.choices" [value]="choice.value">
                {{ t(choice.labelKey, choice.labelKeyOption) }}
              </mat-option>
            </mat-select>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
        <p *ngIf="field.type === 'textarea'">
          <mat-form-field>
            <textarea matInput [formControlName]="field.name"
              [placeholder]="field.placeholderKey && t(field.placeholderKey)">
            </textarea>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
        <p *ngIf="field.type === 'text'">
          <mat-form-field>
            <input matInput [formControlName]="field.name"
              [placeholder]="field.placeholderKey && t(field.placeholderKey)" />
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
        <p *ngIf="field.type === 'date'">
          <mat-form-field class="small">
            <mat-label>{{ t(field.labelKey) }}</mat-label>
            <input matInput [formControlName]="field.name" [matDatepicker]="datePicker" [min]="field.minDate" [max]="field.maxDate"
              (focus)="datePicker.open()" />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #datePicker></mat-datepicker>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
        <p *ngIf="field.type === 'file'">
          <mat-form-field appearance="standard">
            <input [formControlName]="field.name" #fileField matInput
              (focus)="uploadFileIfNecessary(fileField, fileInput, field.name)" [placeholder]="t(field.labelKey)" />
            <input aria-hidden="true" type="file" class="hidden" [multiple]="false" #fileInput
              (change)="updateFileField(field.name, $event.target.files[0])" />
            <mat-icon matPrefix class="validation-icon" *ngIf="fileField.value && field.formControl.valid">check_circle
            </mat-icon>
            <button matSuffix mat-icon-button (click)="fileInput.click()">
              <mat-icon>file_download</mat-icon>
            </button>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <sibat-update-button *ngIf="isUncompleted$ | async; else validation" class="mat-button-base" (clicked)="validate()"
      [disabled]="hasRequiredError$ | async" [label]="'building.documents.validate' | transloco">
    </sibat-update-button>
    <ng-template #validation>
      <sibat-validate-button [class.uncompleted]="isUncompleted$ | async" class="mat-button-base" (clicked)="validate()"
        [disabled]="hasRequiredError$ | async" [label]="'building.documents.validate' | transloco">
      </sibat-validate-button>
    </ng-template>

    <sibat-secondary-button class="mat-button-base" (clicked)="cancel()"
      [label]="'building.documents.cancel' | transloco">
    </sibat-secondary-button>
  </mat-dialog-actions>
</ng-container>
