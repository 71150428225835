import {
  AddDefect,
  AddDefectCompliance,
  Control,
  DefectDto,
  DefectPicture,
  DefectSummary,
  DefectThumbnail,
  EditDefect,
  FileWithPreviewBase64,
} from '../model/control.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page } from 'src/app/model/page.model';
import { CONTROLS_BASE_URI } from './control.service';
import { filterResponseBody } from 'src/app/shared/http-event';

export const DEFECTS_BASE_URI = `${environment.apiUrl}/defects`;
export const DEFECTS_PAGE_SIZE = 5;

@Injectable({
  providedIn: 'root',
})
export class ControlDefectService {
  constructor(private readonly http: HttpClient) {}

  findNonCompliantDefectSummary(page: number = 0, pageSize: number = DEFECTS_PAGE_SIZE): Observable<Page<DefectSummary>> {
    const params = new HttpParams().append('page', page.toString()).append('size', pageSize.toString());
    return this.http.get<Page<DefectSummary>>(`${DEFECTS_BASE_URI}/summary`, { params });
  }

  removePreviewDataFromPictures = (pictures: FileWithPreviewBase64[]): FileWithPreviewBase64[] =>
    pictures.map(
      picture =>
        ({
          ...picture,
          preview: undefined,
        } as FileWithPreviewBase64)
    );

  sendDefects(controlId: number, defects: EditDefect[]): Observable<Control> {
    const formattedDefects = defects.map(
      defect =>
        ({
          pictures: this.removePreviewDataFromPictures(defect.picturesToAdd),
          description: defect.description,
          deadline: defect.deadline,
          type: defect.type,
          location: defect.location,
        } as AddDefect)
    );

    const request = this.http.post<Control>(`${CONTROLS_BASE_URI}/${controlId}/defects`, formattedDefects, {
      reportProgress: true,
      observe: 'events',
    });
    return filterResponseBody(request);
  }

  removeDefect(controlId: number, defectId: number): Observable<Control> {
    return this.http.delete<Control>(`${CONTROLS_BASE_URI}/${controlId}/defects/${defectId}`);
  }

  updateDefect(controlId: number, editDefect: EditDefect): Observable<Control> {
    const formattedDefect = {
      ...editDefect,
      picturesToAdd: this.removePreviewDataFromPictures(editDefect.picturesToAdd),
    };
    const request = this.http.put<Control>(`${CONTROLS_BASE_URI}/${controlId}/defects/${editDefect.id}`, formattedDefect, {
      reportProgress: true,
      observe: 'events',
    });
    return filterResponseBody(request);
  }

  fetchDefectsThumbnails(controlId: number): Observable<DefectThumbnail[]> {
    return this.http.get<DefectThumbnail[]>(`${CONTROLS_BASE_URI}/${controlId}/defects/thumbnails`);
  }

  getDefectPicture(controlId: number, pictureName: string): Observable<DefectPicture> {
    return this.http.post<DefectPicture>(`${CONTROLS_BASE_URI}/${controlId}/defects/picture`, pictureName);
  }

  updateDefectsOrder(controlId: number, defects: DefectDto[]): Observable<void> {
    return this.http.post<void>(`${CONTROLS_BASE_URI}/${controlId}/update-defects-order`, defects);
  }

  addDefectCompliance(form: AddDefectCompliance): Observable<Control> {
    const formData = new FormData();
    formData.append('defectIds', form.defectIds.join(','));
    formData.append('description', form.description);
    formData.append('date', form.date?.toISOString());
    form.files.forEach(file => formData.append('files', file));

    const request = this.http.post<Control>(`${CONTROLS_BASE_URI}/${form.controlId}/defects/compliance`, formData, {
      reportProgress: true,
      observe: 'events',
    });
    return filterResponseBody(request);
  }

  downloadComplianceProof(controlId: number, defectId: number, proofName: string): Observable<Blob> {
    const params = new HttpParams().append('defectId', defectId).append('proofName', proofName);
    return this.http.get(`${CONTROLS_BASE_URI}/${controlId}/defects/compliance/download`, {
      headers: {},
      responseType: 'blob',
      params,
    });
  }
}
