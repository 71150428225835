export const MAX_SIZE_DOC = 262144000;

export const BUILDING_DOCUMENT_ERROR_CODE = {
  maxSizeExceeded: 0,
} as const;
export type BuildingDocumentErrorCode = typeof BUILDING_DOCUMENT_ERROR_CODE[keyof typeof BUILDING_DOCUMENT_ERROR_CODE];

export interface BuildingDocument {
  name: string;
  isFolder: boolean;
  isEmpty: boolean;
}

export interface BuildingDocumentError {
  code: BuildingDocumentErrorCode;
  fileName: string;
}

export interface BuildingDocumentBrowsingData {
  buildingId: number;
  documentsPath: string;
}
