const translationKey = 'translationKey';
const translationOptions = 'translationOptions';
export interface TextTranslation {
  [translationKey]: string;
  [translationOptions]?: any;
}

export type TextLabel = string | TextTranslation;

export const isTextLiteral = (text: any): text is string => typeof text === 'string';
export const isTextTranslation = (text: any): text is TextTranslation => text?.hasOwnProperty(translationKey);
export const createTextTranslation = (key: string, options?: any): TextTranslation => ({
  translationKey: key,
  translationOptions: options,
});
