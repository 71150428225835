import { ROLE, Role } from './model/role.model';
import { Building } from '../building/model/building.model';
import { User } from './model/user.model';

export const role = (r: Role) => {
  const isReadOnly = () => r === ROLE.readOnly;
  const isCECB = () => r === ROLE.cecb;
  const isSCPI = () => r === ROLE.scpi;
  const isSecretary = () => r === ROLE.secretary;
  const isExpert = () => r === ROLE.expert;
  const isAdmin = () => r === ROLE.admin;

  const isExpertOrAbove = () => isAdmin() || isExpert();
  const isEcabOrAbove = () => isExpertOrAbove() || isSecretary();
  const isSecretaryOrAbove = () => isExpertOrAbove() || isSecretary();
  const isSpecialistOrAbove = () => isExpertOrAbove() || isSCPI();
  const isSCPIOrAbove = () => isSecretaryOrAbove() || isSCPI();
  const isAccreditationBased = () => isSCPI() || isReadOnly();
  const isCECBOrAbove = () => isSpecialistOrAbove() || isCECB();

  return {
    /**
     * Asserts if the role can edit building typologies
     */
    isReadOnly,
    isCECB,
    isSCPI,
    isSecretary,
    isExpert,
    isAdmin,
    isExpertOrAbove,
    isEcabOrAbove,
    isSecretaryOrAbove,
    isSpecialistOrAbove,
    isSCPIOrAbove,
    isAccreditationBased,
    isCECBOrAbove
  };
};
export const authorization = (user?: User) => ({
  authorizedForBuilding: (building?: Building): boolean =>
    (!!building && !!user && role(user.role).isEcabOrAbove()) ||
    (user?.role === ROLE.scpi && user?.accreditations.some(x => x.name === building?.municipality)) ||
    user?.role === ROLE.cecb,
});
