import { BrowserModule, Title } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuildingModule } from './building/building.module';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { BuildingEffects } from './building/store/building.effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingComponent } from './loading/loading.component';
import { LoadingInterceptor } from './loading/loading.interceptor';
import { LoadingEffects } from './loading/store/loading.effects';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { StringPipeModule } from './shared/string.module';
import { AuthenticationEffects } from './authentication/store/authentication.effects';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthenticationInterceptor } from './authentication/authentication.interceptor';
import { UserEffects } from './authentication/store/user.effects';
import { NavigationRailModule } from './navigation-rail/navigation-rail.module';
import { CookieService } from 'ngx-cookie-service';
import { CockpitModule } from './cockpit/cockpit.module';
import { AdminEffects } from './admin/store/admin.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { SibatRouteSerializer } from './router/sibat-route-serializer';
import { FeatureFlagsEffects } from './features/store/feature-flags.effects';
import { FlexLayoutModule } from '@angular/flex-layout';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ErrorReportingEffects } from './error-reporting/store/error-reporting.effects';
import { RouterEffects } from './router/store/router.effects';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MotdComponent } from './notification/motd/motd.component';
import { NetworkModule } from './network/network.module';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { ControlEffects } from './control/store/control.effects';
import { TagEffects } from './building/store/tag.effects';
import { DocumentEffects } from './building/store/document.effects';
import { BuildingsEffects } from './building/store/buildings.effects';
import { ReferenceDataEffects } from './building/store/reference-data.effects';
import { NotificationEffects } from './notification/store/notification.effects';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MapEffects } from './map/store/map.effects';
import { ControlDefectEffects } from './control/store/control-defect.effects';
import { SharedModule } from './shared/shared.module';
import { MotdEffects } from './authentication/store/motd.effects';
import { BuildingCasesEffects } from './building/store/building-cases.effects';
import { BuildingTechnicalInstallationsEffects } from './building/store/building-technical-installations.effects';
import { EnvironmentConfigEffects } from './environment-config/store/environment-config.effects';
import { TechnicalInstallationEffects } from './building/store/technical-installation.effects';
import { HtmlNotificationComponent } from './notification/html-notification/html-notification.component';
import { AssessmentEffects } from './assessment/store/assessment.effects';

Bugsnag.start({
  apiKey: environment.bugsnag.apiKey,
  appVersion: environment.version,
});
export const errorHandlerFactory = () => new BugsnagErrorHandler();

const dbConfig: DBConfig = {
  name: 'control',
  version: 1,
  objectStoresMeta: [
    {
      store: 'defects',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [],
    },
  ],
};

@NgModule({
  declarations: [AppComponent, LoadingComponent, MotdComponent, HtmlNotificationComponent],
  imports: [
    SharedModule,
    AppRoutingModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    BrowserModule,
    BuildingModule,
    CockpitModule,
    EffectsModule.forRoot([
      AdminEffects,
      AuthenticationEffects,
      BuildingEffects,
      BuildingsEffects,
      ControlEffects,
      MapEffects,
      DocumentEffects,
      LoadingEffects,
      ErrorReportingEffects,
      FeatureFlagsEffects,
      ReferenceDataEffects,
      RouterEffects,
      TagEffects,
      UserEffects,
      NotificationEffects,
      ControlDefectEffects,
      MotdEffects,
      BuildingCasesEffects,
      BuildingTechnicalInstallationsEffects,
      EnvironmentConfigEffects,
      TechnicalInstallationEffects,
      AssessmentEffects,
    ]),
    HttpClientModule,
    NavigationRailModule,
    NetworkModule,
    NgScrollbarModule.withConfig({
      visibility: 'hover',
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot({
      serializer: SibatRouteSerializer,
    }),
    StringPipeModule,
    FlexLayoutModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-CH' },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    CookieService,
    Title,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
