import { Component, Input, OnInit } from '@angular/core';
import { AlertType, ALERT_TYPE } from './alert.model';

@Component({
  selector: 'sibat-alert',
  template: `
    <div class="alert-container {{ type }}">
      <div *ngIf="type !== alertWithoutIcon" class="alert-icon">
        <mat-icon>{{ type }}</mat-icon>
      </div>
      <div class="alert-content"><ng-content></ng-content></div>
    </div>
  `,
  styleUrls: ['alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() type: AlertType;

  alertWithoutIcon: AlertType = ALERT_TYPE.alarm;

  constructor() {}

  ngOnInit(): void {}
}
