import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectSortedMunicipalities } from './../../building/store/reference-data.selector';
import { getControlsStats } from './../../control/store/control.action';
import { selectCompletedControlsCount, selectControlsStats, selectOngoingControlsCount } from './../../control/store/control.selector';

@Component({
  selector: 'sibat-control-dashboard',
  templateUrl: './control-dashboard.component.html',
  styleUrls: ['./control-dashboard.component.scss'],
})
export class ControlDashboardComponent implements OnInit, OnDestroy {
  displayedColumns = ['color', 'status', 'count'];
  ongoingControlsCount$ = this.store.select(selectOngoingControlsCount);
  completedControlsCount$ = this.store.select(selectCompletedControlsCount);
  controlsStats$ = this.store.select(selectControlsStats);
  municipalities$ = this.store.select(selectSortedMunicipalities);
  valueChangesSubscription?: Subscription;

  dataSample = new UntypedFormGroup({
    start: new UntypedFormControl(null, [Validators.required]),
    end: new UntypedFormControl(null, [Validators.required]),
    municipality: new UntypedFormControl(null),
  });

  constructor(private store: Store) {}

  ngOnDestroy(): void {
    this.valueChangesSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.valueChangesSubscription = this.dataSample.valueChanges.subscribe(({ start, end, municipality }) => {
      if (start && end) {
        this.store.dispatch(
          getControlsStats({
            periodStart: this.toISODate(start),
            periodEnd: this.toISODate(end),
            municipality,
          })
        );
      }
    });
  }

  private toISODate(date: Date): string {
    return date.toLocaleDateString('fr-CA');
  }

}
