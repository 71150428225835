import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export type UploadOption = 'overwrite' | 'keepBoth' | 'cancel';

interface FileInfo {
  fileName: string;
}

@Component({
  selector: 'sibat-file-already-exists-dialog',
  templateUrl: './file-already-exists-dialog.component.html',
  styleUrls: ['./file-already-exists-dialog.component.scss'],
})
export class FileAlreadyExistsDialogComponent {
  uploadAction = new UntypedFormControl('overwrite', [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<FileAlreadyExistsDialogComponent, UploadOption>,
    @Inject(MAT_DIALOG_DATA) readonly fileInfo: FileInfo
  ) {}

  cancel() {
    this.dialogRef.close('cancel');
  }

  validate() {
    this.dialogRef.close(this.uploadAction.value);
  }
}
