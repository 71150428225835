import { createAction, props } from '@ngrx/store';
import { Motd, SetMotd } from '../model/motd.model';

export const updateMotd = createAction('[MOTD] update MOTD', props<{ motd: SetMotd }>());

export const updateMotdSuccess = createAction('[MOTD] update MOTD success', props<{ motd: Motd }>());

export const updateMotdFailure = createAction('[MOTD] update MOTD failure', props<{ error: Error }>());

export const getMotd = createAction('[MOTD] get current MOTD');

export const getMotdSuccess = createAction('[MOTD] get current MOTD success', props<{ motd: Motd }>());

export const getMotdAndCheck = createAction('[MOTD] get current MOTD and check display');

export const getMotdAndCheckSuccess = createAction('[MOTD] get current MOTD and check display success', props<{ motd: Motd }>());

export const getMotdFailure = createAction('[MOTD] get current MOTD failure', props<{ error: Error }>());
