import { notifyWarn } from 'src/app/model/notification.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MunicipalityService } from '../service/municipality.service';
import { AffectationService } from '../service/affectation.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  findBuildingClasses,
  findBuildingClassesSuccess,
  findConstructionPeriods,
  findConstructionPeriodsSuccess,
  findMunicipalities,
  findMunicipalitiesSuccess,
  findTypologyAffectations,
  findTypologyAffectationsSuccess,
  reloadLocalizedData,
  reloadLocalizedDataFailure,
  reloadLocalizedDataSuccess,
} from './buildings.action';
import { of, zip } from 'rxjs';
import { Affectation } from '../model/affectation.model';
import { Municipality, MunicipalitySummary } from '../model/municipality.model';
import { BuildingClass } from '../model/building-class.model';
import { BuildingClassService } from '../service/building-class.service';
import { ConstructionPeriod } from '../model/construction-period.model';
import { ConstructionPeriodService } from '../service/construction-period.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataEffects {
  findMunicipalitiesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findMunicipalities),
      mergeMap(() => this.municipalityService.findAll()),
      map((municipalities: Municipality[]) => findMunicipalitiesSuccess({ municipalities }))
    )
  );

  findTypologyAffectationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findTypologyAffectations),
      mergeMap(() => this.affectationService.findAll()),
      map((affectations: Affectation[]) => findTypologyAffectationsSuccess({ affectations }))
    )
  );

  findBuildingClassesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findBuildingClasses),
      mergeMap(() => this.buildingClassService.findAll()),
      map((buildingClasses: BuildingClass[]) => findBuildingClassesSuccess({ buildingClasses }))
    )
  );

  findConstructionPeriodEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findConstructionPeriods),
      mergeMap(() => this.constructionPeriodService.findAll()),
      map((constructionPeriods: ConstructionPeriod[]) => findConstructionPeriodsSuccess({ constructionPeriods }))
    )
  );

  reloadLocalizedData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reloadLocalizedData),
      mergeMap(() =>
        zip(
          this.municipalityService.findAll(),
          this.affectationService.findAll(),
          this.buildingClassService.findAll(),
          this.constructionPeriodService.findAll()
        ).pipe(
          map(([municipalities, affectations, buildingClasses, constructionPeriods]) =>
            reloadLocalizedDataSuccess({
              municipalities,
              affectations,
              buildingClasses,
              constructionPeriods,
            })
          ),
          catchError(error => of(reloadLocalizedDataFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private municipalityService: MunicipalityService,
    private affectationService: AffectationService,
    private buildingClassService: BuildingClassService,
    private constructionPeriodService: ConstructionPeriodService
  ) {}
}
