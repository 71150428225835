import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../service/authentication.service';
import {
  checkTokenValidity,
  forgotPassword,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  loadExistingToken,
  loadExistingTokenFailure,
  loadExistingTokenSuccess,
  logout,
  logoutSuccess,
  redirectToCockpit,
  redirectToHome,
  redirectToLogin,
  sendChangePassword,
  sendChangePasswordFailure,
  sendChangePasswordSuccess,
  setNewPassword,
  setNewPasswordFailure,
  setNewPasswordSuccess,
  signIn,
  signInFailure,
  signInSuccess,
} from './authentication.action';
import { HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { JWTService } from '../service/jwt.service';
import { getUserAfterLoginSuccess, getUserAfterTokenLoaded } from './user.action';
import { notifyWarn } from 'src/app/model/notification.model';
import { noopAction } from 'src/app/shared/app.action';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationEffects {
  signInEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signIn),
      mergeMap(({ email, password }) =>
        this.authenticationService.signIn(email, password).pipe(
          map((response: HttpResponse<void>) => {
            const token = response.headers.get('Authorization');
            if (!token) {
              throw Error('invalid token');
            }
            return signInSuccess({ token });
          }),
          catchError(error => of(signInFailure({ error })))
        )
      )
    )
  );

  signInSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signInSuccess),
      map(action => {
        this.jwtService.setToken(action.token);
      }),
      map(() => getUserAfterLoginSuccess())
    )
  );

  redirectToHomeEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToHome),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  redirectToCockpitEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToCockpit),
        tap(() => this.router.navigate(['/cockpit']))
      ),
    { dispatch: false }
  );

  loadExistingTokenEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadExistingToken),
      map(() => {
        const token = this.jwtService.loadToken();
        if (token && this.jwtService.isTokenValid()) {
          return loadExistingTokenSuccess({ token });
        }
        return loadExistingTokenFailure();
      })
    )
  );

  loadExistingTokenSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadExistingTokenSuccess),
      map(() => getUserAfterTokenLoaded())
    )
  );

  checkTokenValidityEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkTokenValidity),
      map(() => {
        if (!this.jwtService.isTokenValid()) {
          return logout();
        }
        return noopAction();
      })
    )
  );

  logoutEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      map(() => {
        this.jwtService.clearToken();
        return logoutSuccess();
      })
    )
  );

  logoutSuccessRedirectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSuccess),
      map(() => redirectToLogin())
    )
  );

  redirectToLoginEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToLogin),
        tap(() => this.router.navigate(['/signin']))
      ),
    { dispatch: false }
  );

  sendChangePasswordEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendChangePassword),
      mergeMap((action: ReturnType<typeof sendChangePassword>) =>
        this.authenticationService.changePassword(action.password).pipe(
          map(() => sendChangePasswordSuccess()),
          catchError(error => of(sendChangePasswordFailure({ error })))
        )
      )
    )
  );

  sendChangePasswordSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendChangePasswordSuccess),
      map(() => redirectToHome())
    )
  );

  forgotPasswordEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword),
      mergeMap(({ email }) =>
        this.authenticationService.forgotPassword(email).pipe(
          map(() => forgotPasswordSuccess()),
          catchError(error => of(forgotPasswordFailure({ error })))
        )
      )
    )
  );

  setNewPasswordEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewPassword),
      mergeMap((action: ReturnType<typeof setNewPassword>) =>
        this.authenticationService.resetPassword(action.password, action.token).pipe(
          map(() => setNewPasswordSuccess()),
          catchError(error => of(setNewPasswordFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  setNewPasswordErrorRedirectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewPasswordFailure),
      map(() => redirectToLogin())
    )
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private authenticationService: AuthenticationService,
    private jwtService: JWTService,
  ) {}
}
