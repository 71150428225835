import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { getBuilding } from './store/building.action';

@Injectable({
    providedIn: 'root',
})
export class BuildingResolver implements Resolve<boolean> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      const buildingId = Number(route.paramMap.get('buildingId'));
      if (buildingId) {
        this.store.dispatch(getBuilding({ buildingId }));
      }
      return of(true);
    }
}
