import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { sendChangePassword } from '../store/authentication.action';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'sibat-password-modification',
  template: `
    <form>
      <mat-form-field [formGroup]="changePasswordForm">
        <mat-label>{{ 'authentication.newPassword' | transloco }}</mat-label>
        <input matInput type="password" formControlName="newPassword" />
      </mat-form-field>
      <mat-form-field [formGroup]="changePasswordForm">
        <mat-label>{{ 'authentication.confirmNewPassword' | transloco }}</mat-label>
        <input matInput type="password" formControlName="confirmPassword" [errorStateMatcher]="matcher" />
        <mat-error>{{ 'authentication.passwordsMismatch' | transloco }}</mat-error>
      </mat-form-field>
      <sibat-primary-button
        (clicked)="changePassword()"
        [label]="'authentication.setNewPassword' | transloco"
        [disabled]="changePasswordForm.invalid"
      >
      </sibat-primary-button>
    </form>
  `,
  styleUrls: ['../css/authentication.scss'],
})
export class PasswordModificationComponent {
  changePasswordForm: UntypedFormGroup;

  matcher = new FormErrorStateMatcher();

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) {
    this.changePasswordForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.checkPasswordsValidator }
    );
  }

  checkPasswordsValidator(group: UntypedFormGroup) {
    const password = group.controls.newPassword.value;
    const confirmPassword = group.controls.confirmPassword.value;
    return password === confirmPassword ? null : { different: true };
  }

  changePassword() {
    this.store.dispatch(
      sendChangePassword({
        password: this.changePasswordForm.value.newPassword,
      })
    );
  }
}

export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return invalidCtrl || invalidParent;
  }
}
