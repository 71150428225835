import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogService } from '../shared/service/dialog.service';
import { FileBrowserComponent } from './file-browser/file-browser.component';
import { FolderNameInputComponent } from './folder-name-input/folder-name-input.component';
import { RenameFileDialogComponent } from './rename-file-dialog/rename-file-dialog.component';
import { ButtonsModule } from '../shared/buttons/buttons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { FileAlreadyExistsDialogComponent } from './duplicate-error-dialog/file-already-exists-dialog.component';

const declarations = [
  FileBrowserComponent,
  FolderNameInputComponent,
  RenameFileDialogComponent,
  FileAlreadyExistsDialogComponent,
];

const imports = [
  TranslocoRootModule,
  DragDropModule,
  ButtonsModule,
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  SharedModule,
];

@NgModule({
  declarations,
  imports: [...imports ],
  exports: [...declarations, ...imports],
  providers: [DialogService],
})
export class FileBrowserModule {}
