import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorTypology, mapByColor, Typology } from '../model/typology.model';
import { Affectation } from '../model/affectation.model';

@Component({
  selector: 'sibat-typology-affectations',
  templateUrl: 'typology-affectations.component.html',
  styleUrls: ['typology-affectations.component.scss'],
})
export class TypologyAffectationsComponent {
  @Input() typologyColor: ColorTypology;
  @Input() typology?: Typology;
  @Input() disabled: boolean;
  @Output() affectationClicked = new EventEmitter<Affectation>();

  affectationMap: [ColorTypology, Affectation];

  constructor() {}

  @Input() set affectations(affectations: Affectation[]) {
    this.affectationMap = mapByColor(affectations);
  }

  affectationClick(affectation: Affectation) {
    this.affectationClicked.emit(affectation);
  }
}
