import {
  clearCurrentAssessment,
  extractOngoingAssessment,
  getMeasureTypesSuccess,
  refreshOngoingAssessmentSuccess,
  startNewAssessmentSuccess,
  loadRefAwningOptionsSuccess,
  loadRefAffectationOptionsSuccess,
  loadRefBasegroundOptionsSuccess,
  updateAssessmentDetailsSuccess,
  addProtectiveMeasureSuccess,
  updateProtectiveMeasureSuccess,
  removeProtectiveMeasureSuccess,
  cancelOngoingAssessmentSuccess,
  requestSecretaryApprovalSuccess,
  cancelRequestSecretaryApprovalSuccess,
  approveAssessmentSuccess,
} from './assessment.action';
import { Action, createReducer, on } from '@ngrx/store';
import {
  AssessmentDto,
  RefAwning,
  RefBaseground,
  RefAffectation,
  MeasureType
} from '../model/assessment.model';
import { downloadFullScreenDefectPictureSuccess } from '../../control/store/control.action';

export default interface AssessmentState {
  assessment?: AssessmentDto;
  refAwningOptions?: RefAwning[];
  refBasegroundOptions?: RefBaseground[];
  refAffectationOptions?: RefAffectation[];
  measureTypes: MeasureType[];
  fullScreenPicture: string;
}

export const initialState: AssessmentState = {
  assessment: undefined,
  refAwningOptions: [],
  refBasegroundOptions: [],
  refAffectationOptions: [],
  measureTypes: [],
  fullScreenPicture: '',
};

const assessmentReducer = createReducer(
  initialState,
  on(
    clearCurrentAssessment,
    (state): AssessmentState => ({
      ...state,
      assessment: undefined,
      measureTypes: [],
    })
  ),
  on(
    extractOngoingAssessment,
    (state, action): AssessmentState => ({
      ...state,
      assessment: action.assessment,
    })
  ),
  on(
    startNewAssessmentSuccess,
    (state, action): AssessmentState => ({
      ...state,
      assessment: action.ongoingAssessment,
    })
  ),
  on(
    addProtectiveMeasureSuccess,
    updateProtectiveMeasureSuccess,
    removeProtectiveMeasureSuccess,
    updateAssessmentDetailsSuccess,
    (state, action): AssessmentState => ({
      ...state,
      assessment: action.assessment,
    })
  ),
  on(
    refreshOngoingAssessmentSuccess,
    (state, action): AssessmentState => ({
      ...state,
      assessment: {
        ...action.ongoingAssessment,

      } as AssessmentDto,

    })
  ),
  on(
    cancelOngoingAssessmentSuccess,
    (state): AssessmentState => ({
      ...state,
      assessment: undefined,
      measureTypes: [],
      refAwningOptions: [],
      refBasegroundOptions: [],
      refAffectationOptions: []
    })
  ),
  on(
    requestSecretaryApprovalSuccess,
    (state, action): AssessmentState => ({
      ...state,
      assessment: action.assessment,
    })
  ),
  on(
    cancelRequestSecretaryApprovalSuccess,
    (state, action): AssessmentState => ({
      ...state,
      assessment: action.assessment,
    })
  ),
  on(
    approveAssessmentSuccess,
    (state, action): AssessmentState => ({
      ...state,
      assessment: action.assessment,
    })
  ),
  on(
    loadRefAffectationOptionsSuccess,
    (state, { refsAffectation }): AssessmentState => ({
      ...state,
      refAffectationOptions: refsAffectation,
    })
  ),
  on(
    loadRefBasegroundOptionsSuccess,
    (state, { refsBaseground }): AssessmentState => ({
      ...state,
      refBasegroundOptions: refsBaseground,
    })
  ),
  on(
    loadRefAwningOptionsSuccess,
    (state, { refsAwning }): AssessmentState => ({
      ...state,
      refAwningOptions: refsAwning,
    })
  ),
  on(
    getMeasureTypesSuccess,
    (state, action): AssessmentState => ({
      ...state,
      measureTypes: action.measureTypes,
    })
  ),
  on(
    downloadFullScreenDefectPictureSuccess,
    (state, action): AssessmentState => ({
      ...state,
      fullScreenPicture: action.pictureData,
    })
  )
);

export const reducer = (state: AssessmentState | undefined, action: Action): AssessmentState => assessmentReducer(state, action);
