import { Action, createReducer, on } from '@ngrx/store';
import { clearLoading, hideLoading, showLoading, setProgress } from './loading.action';

export default interface LoadingState {
  loading?: boolean;
  progress?: number;
}

export const initialState: LoadingState = {
  loading: undefined,
  progress: undefined,
};

const loadingReducer = createReducer(
  initialState,
  on(showLoading, (state): LoadingState => ({ ...state, loading: true })),
  on(hideLoading, (state): LoadingState => ({ ...state, loading: false, progress: undefined })),
  on(clearLoading, (): LoadingState => ({ ...initialState })),
  on(setProgress, (state, action): LoadingState => ({ ...state, loading: true, progress: action.progress }))
);

export const reducer = (state: LoadingState | undefined, action: Action): LoadingState => loadingReducer(state, action);
