import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UpdateTypologyDto } from '../model/typology.dto';
import { selectAffectations } from '../store/reference-data.selector';
import { Building } from '../model/building.model';

@Component({
  selector: 'sibat-batch-typology-edit-dialog',
  templateUrl: './batch-typology-edit-dialog.component.html',
})
export class BatchTypologyEditDialogComponent {
  affectations$ = this.store.select(selectAffectations);
  canSave = false;
  readonly building?: Building;
  readonly count: number;

  private currentValue: UpdateTypologyDto;

  constructor(
    private dialogRef: MatDialogRef<BatchTypologyEditDialogComponent, UpdateTypologyDto>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private data: { count: number; building?: Building }
  ) {
    this.count = this.data.count ?? 1;
    this.building = this.data.building;
  }

  updateCanSave(canSave: boolean, currentValue: UpdateTypologyDto) {
    this.canSave = canSave;
    this.currentValue = currentValue;
  }

  save() {
    if (this.canSave) {
      this.dialogRef.close(this.currentValue);
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
