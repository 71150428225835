import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { FeatureFlagsService } from '../service/feature-flags.service';
import {
  changeFeatureFlagState,
  changeFeatureFlagStateFailure,
  changeFeatureFlagStateSuccess,
  getFeatureFlags,
  getFeatureFlagsFailure,
  getFeatureFlagsSuccess,
} from './feature-flags.action';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsEffects {
  getFeatureFlagsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFeatureFlags),
      mergeMap(() =>
        this.featureFlagsService.getFeatureFlags().pipe(
          map(featureFlags => getFeatureFlagsSuccess({ featureFlags })),
          catchError(error => of(getFeatureFlagsFailure({ error })))
        )
      )
    )
  );

  changeFeatureFlagStateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeFeatureFlagState),
      mergeMap(({ key, enabled }) =>
        this.featureFlagsService.setFeatureFlagState(key, enabled).pipe(
          map(() => changeFeatureFlagStateSuccess({ key, enabled })),
          catchError(error => of(changeFeatureFlagStateFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private featureFlagsService: FeatureFlagsService) {}
}
