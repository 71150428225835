import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  approvalAssessmentStatus,
  AssessmentBase,
  AssessmentBuildingDetailsDto,
  AssessmentDto,
  canAssessmentBeValidated,
  isAssessmentEmpty,
  MeasuresInfo
} from '../model/assessment.model';
import { Building } from '../../building/model/building.model';
import { StatusAlert } from './assessment-status-alerts/assessment-status-alert.model';
import {
  approveAssessment,
  cancelOngoingAssessment,
  updateAssessmentDetails,
  cancelRequestSecretaryApproval,
  previewAssessmentReport,
  requestSecretaryApproval
} from 'src/app/assessment/store/assessment.action';
import { Store } from '@ngrx/store';
import { DialogService } from '../../shared/service/dialog.service';
import { Observable } from 'rxjs';
import {
  forceOffline,
  releaseForcedOfflineAndUploadAssessment,
} from '../../network/store/network.action';
import { ALERT_TYPE } from '../../alert/alert.model';
import { APPROVAL_STATE } from '../model/approval-state.model';
import { selectIsForcedOffline } from '../../network/store/network.selector';
import { ASSESSMENT_STATUS } from '../../building/model/history.model';
import { selectIsAdmin } from '../../authentication/store/user.selector';

@Component({
  selector: 'sibat-assessment-report',
  templateUrl: './assessment-report.component.html',
  styleUrls: ['assessment-report.component.scss'],
  animations: [
    trigger('fadeSlide', [
      state('void', style({ opacity: 0, transform: 'translateX(50px)' })),
      transition('void => *', [animate(400)]),
      transition('* => void', [animate(400)]),
    ]),
    trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate(400)])]),
    trigger('progress', [
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateX(-200px)',
        }),
        animate(400),
      ]),
    ]),
  ],
})

export class AssessmentReportComponent implements OnInit, OnDestroy, OnChanges {
  @Output() closeModal = new EventEmitter<void>();
  @Input() assessment: AssessmentDto;
  @Input() building?: Building;
  @Input() isCECB: boolean;
  @Input() offline: boolean;
  isAdmin$ = this.store.select(selectIsAdmin);
  ongoingAssessmentCanBeValidated = false;
  buildingId: number;
  unsavedDetails = false;
  readOnly: boolean;
  isAssessmentOnApproval: boolean;
  statusAlerts: StatusAlert[] = [];
  forcedOffline$: Observable<boolean>;

  constructor(private store: Store, private dialogService: DialogService) {}

  static isReadOnly(isCECB: boolean, assessment: AssessmentDto): boolean {
    if (!isCECB) {
      return true;
    }
    return !!assessment.approvalRequestDate
      && assessment.status === ASSESSMENT_STATUS.approval;
  }
  private static isNumber(val: unknown): val is number {
    return typeof val === 'number';
  }

  ngOnInit(): void {
    this.forcedOffline$ = this.store.select(selectIsForcedOffline);
  }

  ngOnDestroy(): void {
    // this.store.dispatch(clearCurrentAssessment());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.building) {
      if (this.building) {
        this.buildingId = this.building.id;
      }
    }
    if (changes.offline) {
      this.updateStatusAlerts();
    }
    if (changes.assessment && this.assessment) {
      this.ongoingAssessmentCanBeValidated = canAssessmentBeValidated(this.assessment);
      this.isAssessmentOnApproval = !!this.assessment.approvalRequestDate;
      this.readOnly = AssessmentReportComponent.isReadOnly(this.isCECB, this.assessment);
      this.updateStatusAlerts();
    }
  }

  hasUnsavedDetails(unsaved: boolean) {
    setTimeout(() => (this.unsavedDetails = unsaved));
  }


  // setOfflineMode(enabled: boolean) {
  //   if (enabled) {
  //     this.store.dispatch(forceOffline());
  //   } else {
  //     this.store.dispatch(releaseForcedOfflineAndUploadAssessment({
  //       assessmentId: this.assessment.id
  //     }));
  //   }
  // }


  saveAssessmentDetails(assessmentDetails: AssessmentBase | AssessmentBuildingDetailsDto | MeasuresInfo ) {
    const assessment = {
      ...this.assessment,
      ...assessmentDetails,
      id: this.assessment.id,
      status: this.assessment.status,
      user: this.assessment.user
    };
    this.store.dispatch(
      updateAssessmentDetails({
        assessmentId: this.assessment.id,
        assessment
      })
    );
  }

  async requestOngoingAssessmentCancellation(): Promise<void> {
    if (isAssessmentEmpty(this.assessment)) {
      this.cancelOngoingAssessment();
    } else {
      const result = await this.dialogService.requestConfirmation({ translationKey: 'building.assessment.sureToCancel' });
      if (result) {
        this.cancelOngoingAssessment();
      }
    }
  }

  async approveAssessment() {
    if (AssessmentReportComponent.isNumber(this.assessment.id)) {
      const result = await this.dialogService.requestConfirmation({ translationKey: 'building.assessment.sureToApproveRequest' });
      if (result) {
        this.store.dispatch(approveAssessment({ buildingId: this.buildingId }));
        this.close();
      }
    }
  }

  async requestApproval() {
    if (AssessmentReportComponent.isNumber(this.assessment.id)) {
      const result = await this.dialogService.requestConfirmation({ translationKey: 'building.assessment.sureToValidate' });
      if (result) {
        this.store.dispatch(requestSecretaryApproval({buildingId: this.buildingId}));
        this.close();
      }
    }
  }

  async cancelRequestApproval() {
    if (AssessmentReportComponent.isNumber(this.assessment.id)) {
      const result = await this.dialogService.requestConfirmation({ translationKey: 'building.assessment.sureToCancelRequest' });
      if (result) {
        this.store.dispatch(cancelRequestSecretaryApproval({buildingId: this.buildingId}));
        this.close();
      }
    }
  }

  previewReport(): void {
    if (AssessmentReportComponent.isNumber(this.assessment.id)) {
      this.store.dispatch(
        previewAssessmentReport({
          source: this.assessment.id,
        })
      );
    }
  }

  close() {
    this.closeModal.emit();
  }

  private cancelOngoingAssessment() {
    this.store.dispatch(cancelOngoingAssessment({ assessmentId: this.assessment.id }));
    this.close();
  }

  private updateStatusAlerts() {
      const alerts: StatusAlert[] = [];

      if (this.offline) {
        this.statusAlerts = [{ type: ALERT_TYPE.warn, messageKey: 'building.assessment.statusHint.offlineActivated' }];
        return;
      }

      const approvalState = approvalAssessmentStatus(this.assessment, this.isCECB);
      const needsExpertApproval = this.isCECB && !this.ongoingAssessmentCanBeValidated;

      const userShouldFillInFields =
        this.building &&
        !this.ongoingAssessmentCanBeValidated;

      if (approvalState === APPROVAL_STATE.cecbAwaitingExpertApproval) {
        alerts.push({ type: ALERT_TYPE.locked, messageKey: 'building.assessment.waitingForApproval' });
      }

      if (userShouldFillInFields && needsExpertApproval) {
        alerts.push({ type: ALERT_TYPE.warn, messageKey: 'building.assessment.statusHint.userMustFillInRequiredFields' });
      }

      this.statusAlerts = alerts;
    }

}
