<sibat-widget title="{{ 'cockpit.controlsDashboard' | transloco }}">
  <mat-icon widget-icon>monitoring</mat-icon>
  <div widget-content class="content">
    <div *transloco="let t; read: 'cockpit'">
      <sibat-primary-button
        [label]="t('goToControlDashboard')"
        [submit]="false"
        [disabled]="false"
        [stretch]="true"
        (clicked)="goToControlDashboard()"
        class="start-button"
      ></sibat-primary-button>
    </div>
  </div>
</sibat-widget>