import { Component, Inject } from '@angular/core';
import { AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface BuildingReportUpload {
  report: File;
  controlDate?: Date;
}

@Component({
  selector: 'sibat-upload-report-dialog',
  templateUrl: './upload-report-dialog.component.html',
  styleUrls: ['./upload-report-dialog.component.scss'],
})
export class UploadReportDialogComponent {
  readonly oldReport = this.formBuilder.control('');
  readonly date = this.formBuilder.control(null);
  readonly uploadForm = this.formBuilder.group(
    {
      isOldReport: this.oldReport,
      controlDate: this.date,
    },
    {
      validators: this.oldControlValidator,
    } as AbstractControlOptions
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UploadReportDialogComponent, BuildingReportUpload>,
    @Inject(MAT_DIALOG_DATA) public data: { file: File }
  ) {}

  oldControlValidator(group: UntypedFormGroup): ValidationErrors | null {
    const oldReport = group.controls.isOldReport.value;
    const date = group.controls.controlDate.value;
    if (oldReport === 'false' || (oldReport === 'true' && date)) {
      return null;
    }
    return { error: 'invalid form' };
  }

  onValidateForm() {
    const result: BuildingReportUpload = {
      report: this.data.file,
      controlDate: this.oldReport.value === 'true' ? this.date.value : undefined,
    };
    this.dialogRef.close(result);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
