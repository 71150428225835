import { NotificationService } from 'src/app/notification/notification.service';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  addDefect,
  addDefectCompliance,
  addDefectComplianceFailure,
  addDefectComplianceSuccess,
  addOfflineDefect,
  addOfflineDefectFailure,
  addOfflineDefectSuccess,
  downloadComplianceProof,
  downloadComplianceProofFailure,
  downloadComplianceProofSuccess,
  fetchThumbnails,
  fetchThumbnailsSuccess,
  findNonCompliantDefectSummary,
  findNonCompliantDefectSummaryFailure,
  findNonCompliantDefectSummarySuccess,
  downloadFullScreenDefectPicture,
  downloadFullScreenDefectPictureFailure,
  downloadFullScreenDefectPictureSuccess,
  refreshOfflineDefectsInStore,
  refreshOfflineDefectsInStoreFailure,
  refreshOfflineDefectsInStoreSuccess,
  removeDefect,
  removeDefectFailure,
  removeDefectSuccess,
  removeOfflineDefect,
  removeOfflineDefectFailure,
  removeOfflineDefectsFailure,
  removeOfflineDefectsSuccess,
  removeOfflineDefectSuccess,
  reportControlError,
  sendDefect,
  sendDefectFailure,
  sendDefectSuccess,
  sendOfflineDefects,
  sendOfflineDefectsFailure,
  sendOfflineDefectsSuccess,
  setFullScreenDefectPicture,
  storeOfflineDefectSuccess,
  updateDefect,
  updateDefectFailure,
  updateDefectsOrder,
  updateDefectsOrderFailure,
  updateDefectsOrderSuccess,
  updateDefectSuccess,
  updateOfflineDefect,
  updateOfflineDefectFailure,
  updateOfflineDefectSuccess,
  uploadOfflineDefects,
} from './control.action';
import { asyncScheduler, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { FullScreenPictureComponent } from '../full-screen-picture/full-screen-picture.component';
import { OfflineDefectService } from '../service/offline-defect.service';
import { selectIsOffline } from 'src/app/network/store/network.selector';
import { releaseForcedOfflineAndUploadDefects } from '../../network/store/network.action';
import { Page } from 'src/app/model/page.model';
import { Control, DefectSummary } from '../model/control.model';
import { ControlDefectService } from '../service/control-defect.service';
import { saveAs } from 'file-saver';
import { notifyInfo, notifyWarn } from 'src/app/model/notification.model';

const updateOrderTimeout = 2000;

@Injectable({
  providedIn: 'root',
})
export class ControlDefectEffects {
  // HANDLE ERRORS

  reportControlError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        sendDefectFailure,
        removeDefectFailure,
        updateDefectFailure,
        updateDefectsOrderFailure,
        downloadFullScreenDefectPictureFailure,
        updateOfflineDefectFailure,
        removeOfflineDefectFailure,
        refreshOfflineDefectsInStoreFailure
      ),
      map(failedAction =>
        reportControlError({
          error: failedAction.error,
          sourceActionType: failedAction.type,
          payload: failedAction.payload,
        })
      )
    )
  );

  // DEFECTS

  addDefectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDefect),
      concatLatestFrom(() => this.store.select(selectIsOffline)),
      mergeMap(([{ controlId, defect }, offline]) => {
        if (offline) {
          return of(addOfflineDefect({ defect }));
        } else {
          return of(sendDefect({ controlId, defect }));
        }
      })
    )
  );

  sendDefectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendDefect),
      mergeMap(({ controlId, defect }) =>
        this.controlDefectService.sendDefects(controlId, [defect]).pipe(
          map(control => sendDefectSuccess({ control, ...notifyInfo('notification.info.defect.sentDefect', true) })),
          catchError(error =>
            of(
              sendDefectFailure({
                error,
                defect,
                payload: { controlId },
                ...notifyWarn('notification.warning.defect.uploadDefectError', true),
              })
            )
          )
        )
      )
    )
  );

  removeDefectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDefect),
      mergeMap(({ controlId, defectId }) =>
        this.controlDefectService.removeDefect(controlId, defectId).pipe(
          map(control => removeDefectSuccess({ control, ...notifyInfo('notification.info.defect.removeDefectSuccess', true) })),
          catchError(error =>
            of(
              removeDefectFailure({
                error,
                defectId,
                payload: { defectId, controlId },
                ...notifyWarn('notification.warning.defect.removeDefectFailure', true),
              })
            )
          )
        )
      )
    )
  );

  updateDefectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDefect),
      mergeMap(({ controlId, editDefect }) =>
        this.controlDefectService.updateDefect(controlId, editDefect).pipe(
          map(control => updateDefectSuccess({ control, ...notifyInfo('notification.info.defect.updateDefectSuccess', true) })),
          catchError(error =>
            of(
              updateDefectFailure({
                error,
                payload: { controlId, editDefect },
                ...notifyWarn('notification.warning.defect.updateDefectFailure', true),
              })
            )
          )
        )
      )
    )
  );

  updateDefectsOrderEffect$ = createEffect(
    () =>
      ({ debounce = updateOrderTimeout, scheduler = asyncScheduler } = {}) =>
        this.actions$.pipe(
          ofType(updateDefectsOrder),
          debounceTime(debounce, scheduler),
          mergeMap(({ controlId, defects }) =>
            this.controlDefectService.updateDefectsOrder(controlId, defects).pipe(
              map(() => updateDefectsOrderSuccess({ defects })),
              catchError(error =>
                of(
                  updateDefectsOrderFailure({
                    error,
                    payload: { controlId },
                    ...notifyWarn('building.control.updateDefectsOrderError', true),
                  })
                )
              )
            )
          )
        )
  );

  fetchDefectsThumbnailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchThumbnails),
      debounceTime(200),
      mergeMap(({ controlId }) =>
        this.controlDefectService.fetchDefectsThumbnails(controlId).pipe(map(thumbnails => fetchThumbnailsSuccess({ thumbnails })))
      )
    )
  );

  downloadFullScreenDefectPictureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadFullScreenDefectPicture),
      mergeMap(({ controlId, pictureName }) =>
        this.controlDefectService.getDefectPicture(controlId, pictureName).pipe(
          map(({ pictureData }) => downloadFullScreenDefectPictureSuccess({ pictureData })),
          catchError(error =>
            of(
              downloadFullScreenDefectPictureFailure({
                error,
                payload: { controlId, pictureName },
                ...notifyWarn('building.control.getDefectPictureError', true),
              })
            )
          )
        )
      )
    )
  );

  setFullScreenDefectPictureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFullScreenDefectPicture),
      mergeMap(({ pictureData }) => of(downloadFullScreenDefectPictureSuccess({ pictureData })))
    )
  );

  openFullScreenDefectPictureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadFullScreenDefectPictureSuccess),
        map(({ pictureData }) => {
          const dialogRef = this.dialog.open(FullScreenPictureComponent, {
            data: pictureData,
            height: '100%',
            width: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
          });
          dialogRef.afterClosed().subscribe(result => {
            of(setFullScreenDefectPicture({ pictureData: '' }));
          });
        })
      ),
    { dispatch: false }
  );

  // OFFLINE

  addOfflineDefectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addOfflineDefect, sendDefectFailure),
      mergeMap(({ defect }) =>
        this.defectService.storeOfflineDefect(defect).pipe(
          map(() => addOfflineDefectSuccess()),
          catchError(error => of(addOfflineDefectFailure({ error, defect, payload: { defect } })))
        )
      )
    )
  );

  triggerUploadOfflineDefectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(releaseForcedOfflineAndUploadDefects),
      map(({ controlId }) => uploadOfflineDefects({ controlId }))
    )
  );

  uploadOfflineDefectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadOfflineDefects),
      switchMap(({ controlId }) =>
        this.defectService.getOfflineDefects().pipe(map(offlineDefects => sendOfflineDefects({ offlineDefects, controlId })))
      )
    )
  );

  sendOfflineDefectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendOfflineDefects),
      switchMap(({ controlId, offlineDefects }) =>
        this.controlDefectService.sendDefects(controlId, offlineDefects).pipe(
          map(control => sendOfflineDefectsSuccess({ control, defectOfflineIds: offlineDefects.map(defect => defect.id) })),
          catchError(error => of(sendOfflineDefectsFailure({ error, payload: {} })))
        )
      )
    )
  );

  removeOfflineDefectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendOfflineDefectsSuccess),
      switchMap(({ defectOfflineIds }) =>
        this.defectService.removeOfflineDefectsFromStore(defectOfflineIds).pipe(
          map(() => removeOfflineDefectsSuccess()),
          catchError(error => of(removeOfflineDefectsFailure({ error, payload: {} })))
        )
      )
    )
  );

  removeOfflineDefectEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOfflineDefect),
      mergeMap(({ defectOfflineId }) =>
        this.defectService.removeOfflineDefectFromStore(defectOfflineId).pipe(
          map(() => removeOfflineDefectSuccess(notifyInfo('notification.info.defect.removeDefectSuccess', true))),
          catchError(error =>
            of(
              removeOfflineDefectFailure({
                error,
                payload: { defectOfflineId },
                ...notifyWarn('notification.warning.defect.removeDefectFailure', true),
              })
            )
          )
        )
      )
    )
  );

  updateOfflineDefect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOfflineDefect),
      mergeMap(({ defect }) =>
        this.defectService.updateOfflineDefect(defect).pipe(
          map(() => updateOfflineDefectSuccess(notifyInfo('notification.info.defect.updateDefectSuccess', true))),
          catchError(error =>
            of(
              updateOfflineDefectFailure({
                error,
                payload: { defect },
                ...notifyWarn('notification.warning.defect.updateDefectFailure', true),
              })
            )
          )
        )
      )
    )
  );

  refreshOfflineDefectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshOfflineDefectsInStore),
      switchMap(() =>
        this.defectService.getOfflineDefects().pipe(
          map(defects => refreshOfflineDefectsInStoreSuccess({ defects })),
          catchError(error => of(refreshOfflineDefectsInStoreFailure({ error, payload: {} })))
        )
      )
    )
  );

  refreshOfflineDefectsInStoreEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOfflineDefectSuccess, removeOfflineDefectSuccess, storeOfflineDefectSuccess, addOfflineDefectSuccess),
      map(() => refreshOfflineDefectsInStore())
    )
  );

  findNonCompliantDefectSummaryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findNonCompliantDefectSummary),
      switchMap(({ page }) =>
        this.controlDefectService.findNonCompliantDefectSummary(page).pipe(
          map((summaryPage: Page<DefectSummary>) =>
            findNonCompliantDefectSummarySuccess({
              defects: summaryPage.content,
              count: summaryPage.totalElements,
              page: summaryPage.number,
            })
          ),
          catchError(error => of(findNonCompliantDefectSummaryFailure({ error, payload: {} })))
        )
      )
    )
  );

  addDefectComplianceEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addDefectCompliance),
      switchMap(({ form }) =>
        this.controlDefectService.addDefectCompliance(form).pipe(
          map((control: Control) => addDefectComplianceSuccess({ control, ...notifyInfo('common.notification.saveSuccess', true) })),
          catchError(error =>
            of(addDefectComplianceFailure({ error, payload: {}, ...notifyWarn('common.notification.saveFailure', true) }))
          )
        )
      )
    )
  );

  downloadComplianceProofEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadComplianceProof),
      switchMap(({ controlId, defectId, proofName }) =>
        this.controlDefectService.downloadComplianceProof(controlId, defectId, proofName).pipe(
          map(content => {
            const blob = new Blob([content], { type: 'application/octet-stream' });
            saveAs(blob, proofName);
            return downloadComplianceProofSuccess();
          }),
          catchError(error => of(downloadComplianceProofFailure({ error, payload: {} })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private controlDefectService: ControlDefectService,
    private dialog: MatDialog,
    private defectService: OfflineDefectService,
  ) {}
}
