<div>
  <mat-tab-group *transloco="let t; read: 'building'">
    <mat-tab *ngIf="canHaveDocuments$ | async" class="mat-tab" label="{{ t('title') }}">
      <sibat-building-documents-detail [building]="building$ | async"></sibat-building-documents-detail>
    </mat-tab>
    <ng-container *ngFor="let casePreview of (casesPreview$ | async)">
      <mat-tab class="mat-tab" [label]="getCaseTabLabel(casePreview)" *ngIf="isSCPIOrAbove$ | async" data-test="case-tabs">
        <sibat-file-browser [root]="casePreview.caseId" [sharePointUrl]="casePreview.url" [caseType]="casePreview.type">
        </sibat-file-browser>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
