import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { hideLoading, setProgress, showLoading } from './store/loading.action';

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(showLoading());

    if (!req.reportProgress) {
      return next.handle(req).pipe(finalize(() => this.store.dispatch(hideLoading())));
    } else {
      return next.handle(req).pipe(tap(event => this.setLoadingProgress(event)));
    }
  }

  private setLoadingProgress(event: HttpEvent<any>) {
    if (event.type === HttpEventType.UploadProgress) {
      const total = event.total ?? 2 * event.loaded;
      const percentDone = Math.round((100 * event.loaded) / total);
      this.store.dispatch(setProgress({ progress: percentDone }));
    }

    if (event.type === HttpEventType.Response || event.type === HttpEventType.Sent) {
      this.store.dispatch(hideLoading());
    }
  }
}
