import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HtmlNotificationComponent } from './html-notification/html-notification.component';

interface NotificationConfiguration {
  panelClass?: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly notificationInfoClass = 'notification-info' as const;
  readonly infoDurationInSeconds = 15 as const;
  readonly notificationWarningClass = 'notification-warning' as const;

  constructor(private snackBar: MatSnackBar) {}

  flash = (configuration: NotificationConfiguration) => {
    this.snackBar.openFromComponent(HtmlNotificationComponent, configuration);
  };

  flashInfo = (message: string) => {
    const configuration = {
      panelClass: this.notificationInfoClass,
      duration: this.infoDurationInSeconds * 1000,
      data: { htmlMessage: message },
    };
    this.flash(configuration);
  };

  flashWarning = (message: string) => {
    const configuration = {
      panelClass: this.notificationWarningClass,
      data: { htmlMessage: message },
    };
    this.flash(configuration);
  };

  dismissVisible() {
    this.snackBar.dismiss();
  }
}
