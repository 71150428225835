
<ng-container *ngFor="let casePreview of casePreviews$ | async">
  <section *ngIf="casePreview.friacFolderNumber" class="mat-elevation-z2 case-card">
  <h2>
    Affaire
    <sibat-link-button
      *ngIf="casePreview.url"
      [label]="casePreview.friacFolderNumber"
      [external]="true"
      [href]="casePreview.url"
      target="_blank"
    ></sibat-link-button>
    <span *ngIf="!casePreview.url">{{casePreview.friacFolderNumber}}</span>
  </h2>

  <h3>Adresses concernées</h3>
  <ul>
    <li *ngFor="let address of casePreview.addresses">
      {{address}}
    </li>
  </ul>

  <h3>Bâtiments concernés</h3>
  <ul>
    <li *ngFor="let building of casePreview.buildings">
      <a [routerLink]="['/building', building.id]">{{ building.addresses[0] || 'Adresse à vérifier' }}</a>
      <sibat-link-button
        *ngIf="building.urlSharePoint"
        label="SharePoint"
        [external]="true"
        [href]="building.urlSharePoint"
        target="_blank"
      ></sibat-link-button>
    </li>
  </ul>

  <p>Circulation en cours: {{ casePreview.recirculationIteration }}</p>

  <ng-container *ngIf="casePreview.linkedCases.length">
    <p>Affaires liées :</p>
    <ul>
      <li *ngFor="let linkedCase of casePreview.linkedCases">
        <sibat-link-button
          [label]="linkedCase.friacFolderNumber"
          [href]="'case/' + linkedCase.friacFolderNumber"
          data-testid="linked-case-number">
        </sibat-link-button>&nbsp;
        <sibat-link-button
          *ngIf="linkedCase.url"
          label="SharePoint"
          [external]="true"
          [href]="linkedCase.url"
          target="_blank"
          data-testid="linked-case-link"
        ></sibat-link-button>
      </li>
    </ul>
  </ng-container>
</section>
</ng-container>
