import MapView from '@arcgis/core/views/MapView';

export interface UsesMapView {
  view?: MapView;
  /**
   * An utility that will cleanup the MapView
   * in attempt to free up memory.
   *
   * You must explicitly call `this.mapViewCleaner.cleanup(this)`
   * in the `ngOnDestroy` method
   */
  mapViewCleaner: MapViewCleaner;
}

export interface MapViewCleaner {
  cleanup: (viewUser: UsesMapView) => void;
}

export const createMapViewCleaner = (): MapViewCleaner => ({
  cleanup: (viewUser: UsesMapView) => {
    if (viewUser.view) {
      viewUser.view.map.removeAll();
      viewUser.view.map.destroy();
      viewUser.view.map = null as any;
      viewUser.view.container = null as any;
      viewUser.view.destroy();
      viewUser.view = undefined;
    }
  },
});
