import { Component, Input, OnInit } from '@angular/core';
import { StatusAlert } from './status-alert.model';

@Component({
  selector: 'sibat-status-alerts',
  template: `
    <section>
      <sibat-alert class="sibat-alert" *ngFor="let alert of alerts" [type]="alert.type">
        {{ alert.messageKey | transloco }}
      </sibat-alert>
    </section>
  `,
  styleUrls: ['status-alert.scss'],
})
export class StatusAlertsComponent implements OnInit {
  @Input() alerts: StatusAlert[];

  constructor() {}

  ngOnInit(): void {}
}
