import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../authentication/model/user.model';
import {
  changeUserStatusSuccess,
  fetchAllRolesSuccess,
  fetchAllUsersFailure,
  fetchAllUsersSuccess
} from './admin.action';
import { RoleFullDto } from '../../authentication/model/role.model';

export default interface AdminState {
  users: User[];
  roles: RoleFullDto[];
}
export const initialState: AdminState = {
  users: [],
  roles: []
};

const adminReducer = createReducer(
  initialState,
  on(fetchAllUsersSuccess, (state, action): AdminState => ({ ...state, users: action.users })),
  on(fetchAllUsersFailure, (state, action): AdminState => ({ ...state, users: [] })),
  on(changeUserStatusSuccess, (state, action) => {
    const users = state.users.map(user => (user.id === action.userId ? { ...user, enabled: action.enabled } : user));
    return { ...state, users };
  }),
  on(fetchAllRolesSuccess, (state, action): AdminState => ({
    ...state,
    roles : action.roles
  })),
  on(fetchAllUsersFailure, (state): AdminState => ({
    ...state,
    roles : []
  }))
);
export const reducer = (state: AdminState | undefined, action: Action): AdminState => adminReducer(state, action);
