import { Component, Input } from '@angular/core';
import MenuOption from '../model/menu-option';

@Component({
  selector: 'sibat-widget',
  template: `
    <mat-card [ngClass]="[width, borderColor]">
      <mat-card-header *ngIf="title || subtitle || menu">
        <div mat-card-avatar>
          <ng-content select="[widget-icon]"></ng-content>
        </div>
        <div class="card-header-text">
          <mat-card-title>{{ title }}</mat-card-title>
          <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
        </div>
        <mat-icon class="align-right" [matMenuTriggerFor]="menuTrigger" *ngIf="menu?.length > 0">more_vert</mat-icon>
        <mat-menu #menuTrigger="matMenu">
          <ng-container *ngFor="let option of menu">
            <button [routerLink]="option.route" mat-menu-item>{{ option.text }}</button>
          </ng-container>
        </mat-menu>
      </mat-card-header>
      <mat-card-content>
        <ng-content select="[widget-content]"></ng-content>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['widget.component.scss'],
})
export class WidgetComponent {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() menu: MenuOption[];
  @Input() width: 'small-widget' | 'large-widget' = 'small-widget';
  @Input() borderColor: 'border-none' | 'border-red' = 'border-none';

  constructor() {}
}
