import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sibat-account-management-widget',
  template: `
    <sibat-widget title="{{ 'cockpit.accountManagement' | transloco }}">
      <mat-icon widget-icon>supervisor_account</mat-icon>
      <div class="button-container" widget-content>
        <sibat-dark-button
          (clicked)="router.navigate(['/cockpit/admin/users/create'])"
          [label]="'cockpit.createUser' | transloco"
          icon="add"
        >
        </sibat-dark-button>
        <sibat-dark-button (clicked)="router.navigate(['/cockpit/admin/users'])" [label]="'cockpit.showUsers' | transloco">
        </sibat-dark-button>
      </div>
    </sibat-widget>
  `,
  styles: [
    `
      .button-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-top: 12px;
        margin-top: -0.5em;
      }

      sibat-dark-button {
        margin-top: 0.5em;
      }
    `,
  ],
})
export class AccountManagementComponent {
  constructor(public router: Router) {}
}
