import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectTechnicalInstallation } from '../../store/building.selector';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'sibat-technical-installation-summary',
  templateUrl: './technical-installation-summary.component.html',
  styleUrls: ['./technical-installation-summary.component.scss'],
})
export class TechnicalInstallationSummaryComponent {
  buildingId: number;
  installation$ = this.store.select(selectTechnicalInstallation);

  constructor(
    private dialogRef: MatDialogRef<TechnicalInstallationSummaryComponent>,
    private router: Router,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private data: { buildingId: number }
  ) {
    this.buildingId = data.buildingId;
  }

  close() {
    this.dialogRef.close();
  }
}
