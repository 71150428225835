import { notifyInfo, notifyWarn } from '../../model/notification.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';
import { AssessmentService } from '../service/assessment.service';
import { BuildingDocumentsService } from '../../building/service/building-documents.service';
import { FullScreenPictureComponent } from '../../control/full-screen-picture/full-screen-picture.component';
import { MatDialog } from '@angular/material/dialog';
import {
  getMeasureTypes,
  getMeasureTypesSuccess,
  refreshOngoingAssessment,
  refreshOngoingAssessmentFailure,
  refreshOngoingAssessmentSuccess,
  reportAssessmentError,
  startNewAssessment,
  startNewAssessmentFailure,
  startNewAssessmentSuccess,
  updateAssessmentDetails,
  updateAssessmentDetailsFailure,
  updateAssessmentDetailsSuccess,
  loadRefAwningOptionsSuccess,
  loadRefAwningOptionsFailure,
  loadRefAffectationOptionsFailure,
  loadRefAffectationOptionsSuccess,
  loadRefBasegroundOptionsFailure,
  loadRefBasegroundOptionsSuccess,
  loadReferenceOptions,
  cancelOngoingAssessment,
  cancelOngoingAssessmentFailure,
  cancelOngoingAssessmentSuccess,
  addProtectiveMeasure,
  addProtectiveMeasureSuccess,
  addProtectiveMeasureFailure,
  updateProtectiveMeasure,
  updateProtectiveMeasureSuccess,
  updateProtectiveMeasureFailure,
  removeProtectiveMeasure,
  removeProtectiveMeasureSuccess,
  removeProtectiveMeasureFailure,
  requestSecretaryApproval,
  requestSecretaryApprovalSuccess,
  requestSecretaryApprovalFailure,
  cancelRequestSecretaryApproval,
  cancelRequestSecretaryApprovalSuccess,
  cancelRequestSecretaryApprovalFailure,
  approveAssessment,
  approveAssessmentSuccess,
  approveAssessmentFailure,
  downloadFullScreenAssessmentPictureSuccess,
  setFullScreenAssessmentPicture,
  downloadFullScreenAssessmentPicture, downloadFullScreenAssessmentPictureFailure
} from './assessment.action';
import { Store } from '@ngrx/store';
import { DialogService } from '../../shared/service/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentEffects {
  // HANDLE ERRORS

  reportAssessmentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNewAssessmentFailure, refreshOngoingAssessmentFailure),
      map(failedAction =>
        reportAssessmentError({
          error: failedAction.error,
          sourceActionType: failedAction.type,
          payload: failedAction.payload,
        })
      )
    )
  );

  // ASSESSMENT

  startNewAssessmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNewAssessment),
      mergeMap(({ buildingId }) =>
        this.assessmentService.startAssessment(buildingId).pipe(
          map(ongoingAssessment => startNewAssessmentSuccess({ ongoingAssessment })),
          catchError(error =>
            of(startNewAssessmentFailure({ error, payload: { buildingId }, ...notifyWarn('building.error.unexpected', true) }))
          )
        )
      )
    )
  );

  refreshOngoingAssessmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshOngoingAssessment),
      mergeMap(({ buildingId }) =>
        this.assessmentService.refreshOngoingAssessment(buildingId).pipe(
          map(ongoingAssessment => refreshOngoingAssessmentSuccess({ ongoingAssessment })),
          catchError(error => of(refreshOngoingAssessmentFailure({ error, payload: { buildingId } })))
        )
      )
    )
  );

  updateAssessmentDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAssessmentDetails),
      mergeMap(({ assessmentId, assessment }) =>
        this.assessmentService.updateAssessmentWithPictures(
          assessmentId, assessment).pipe(
          map(updatedAssessment => updateAssessmentDetailsSuccess({ assessment: updatedAssessment })),
          catchError(error =>
            of(updateAssessmentDetailsFailure({
              error,
              payload: { assessmentId, assessment },
              ...notifyWarn('notification.warning.assessment.updateAssessmentDetailsFailure', true),
            }))
          )
        )
      )
    )
  );


  loadReferenceOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReferenceOptions),
      mergeMap(() =>
        forkJoin([
          this.assessmentService.getRefAffectationOptions(),
          this.assessmentService.getRefAwningOptions(),
          this.assessmentService.getRefBasegroundOptions(),
        ]).pipe(
          map(([affectationOptions, awningOptions, basegroundOptions]) => ({
            affectationOptions,
            awningOptions,
            basegroundOptions,
          })),
          mergeMap(({ affectationOptions, awningOptions, basegroundOptions }) => [
            loadRefAffectationOptionsSuccess({ refsAffectation: affectationOptions }),
            loadRefAwningOptionsSuccess({ refsAwning: awningOptions }),
            loadRefBasegroundOptionsSuccess({ refsBaseground: basegroundOptions }),
          ]),
          catchError(error =>
            of(
              loadRefAffectationOptionsFailure({ error, ...notifyWarn('building.error.unexpected', true) }),
              loadRefAwningOptionsFailure({ error, ...notifyWarn('building.error.unexpected', true) }),
              loadRefBasegroundOptionsFailure({ error, ...notifyWarn('building.error.unexpected', true) })
            )
          )
        )
      )
    )
  );

  cancelOngoingAssessmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelOngoingAssessment),
      mergeMap(({ assessmentId }) =>
        this.assessmentService.cancelOngoingAssessment(assessmentId).pipe(
          map(_ => cancelOngoingAssessmentSuccess(notifyInfo('notification.info.assessment.assessmentCancelled', true))),
          catchError(error =>
            of(cancelOngoingAssessmentFailure(
              { error, payload: { assessmentId }, ...notifyWarn('notification.warning.assessment.assessmentCancelFailure') }))
          )
        )
      )
    )
  );

  //PICTURES

  //VALIDATION
  requestSecretaryApprovalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestSecretaryApproval),
      mergeMap(({ buildingId }) =>
        this.assessmentService.requestSecretaryApproval(buildingId).pipe(
          map(
            assessment => requestSecretaryApprovalSuccess({
              assessment, ...notifyInfo('notification.info.assessment.awaitingForApproval', true)
            })),
          catchError(error =>
            of(requestSecretaryApprovalFailure({
              error,
              payload: { buildingId },
              ...notifyWarn('building.error.unexpected', true)
            }))
          )
        )
      )
    )
  );

  cancelRequestSecretaryApprovalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelRequestSecretaryApproval),
      mergeMap(({ buildingId }) =>
        this.assessmentService.cancelRequestSecretaryApproval(buildingId).pipe(
          map(assessment =>
            cancelRequestSecretaryApprovalSuccess({
              assessment,
              ...notifyInfo('notification.info.assessment.awaitingForApprovalCancelled', true)
            })
          ),
          catchError(error =>
            of(cancelRequestSecretaryApprovalFailure({
              error,
              payload: { buildingId },
              ...notifyWarn('building.error.unexpected', true)
            }))
          )
        )
      )
    )
  );

  approveAssessmentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveAssessment),
      mergeMap(({ buildingId }) =>
        this.assessmentService.approveAssessment(buildingId).pipe(
          map(assessment => approveAssessmentSuccess({
            assessment,
            ...notifyInfo('notification.info.assessment.assessmentApproved', true)
          })),
          catchError(error =>
            of(approveAssessmentFailure({ error, payload: { buildingId }, ...notifyWarn('building.error.unexpected', true) }))
          )
        )
      )
    )
  );

  downloadFullScreenAssessmentPictureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadFullScreenAssessmentPicture),
      mergeMap(({ assessmentId, pictureName, subject }) =>
        this.assessmentService.getAssessmentPicture(assessmentId, pictureName, subject).pipe(
          map(({ pictureData }) => downloadFullScreenAssessmentPictureSuccess({ pictureData })),
          catchError(error =>
            of(
              downloadFullScreenAssessmentPictureFailure({
                error,
                payload: { assessmentId, pictureName, subject },
                ...notifyWarn('building.assessment.getAssessmentPictureError', true),
              })
            )
          )
        )
      )
    )
  );


  setFullScreenAssessmentPictureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setFullScreenAssessmentPicture),
      mergeMap(({ pictureData }) => of(downloadFullScreenAssessmentPictureSuccess({ pictureData })))
    )
  );

  openFullScreenAssessmentPictureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadFullScreenAssessmentPictureSuccess),
        map(({ pictureData }) => {
          const dialogRef = this.dialog.open(FullScreenPictureComponent, {
            data: pictureData,
            height: '100%',
            width: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
          });
          dialogRef.afterClosed().subscribe(result => {
            of(setFullScreenAssessmentPicture({ pictureData: '' }));
          });
        })
      ),
    { dispatch: false }
  );

  //Measures
  getAssessmentMeasureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMeasureTypes),
      mergeMap(() => this.assessmentService.getMeasureTypes().pipe(map(measureTypes => getMeasureTypesSuccess({ measureTypes }))))
    )
  );

  saveProtectiveMeasureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProtectiveMeasure),
      mergeMap(({assessmentId, protectiveMeasure}) =>
        this.assessmentService.addProtectiveMeasure(assessmentId, protectiveMeasure).pipe(
          map(assessment => addProtectiveMeasureSuccess({ assessment, ...notifyInfo('notification.info.measure.sentMeasure', true)})),
          catchError(error =>
            of(
              addProtectiveMeasureFailure({
                error,
                protectiveMeasure,
                payload: { assessmentId },
                ...notifyWarn('notification.warning.measure.uploadMeasureError', true),
              })
            )
          )
        )
      )
    )


  );

  updateProtectiveMeasureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProtectiveMeasure),
      mergeMap(({assessmentId, protectiveMeasure}) =>
        this.assessmentService.updateProtectiveMeasure(assessmentId, protectiveMeasure).pipe(
          map(assessment => updateProtectiveMeasureSuccess({
            assessment,
            ...notifyInfo('notification.info.measure.updateMeasureSuccess', true)
          })),
          catchError(error =>
            of(
              updateProtectiveMeasureFailure({
                error,
                protectiveMeasure,
                payload: { assessmentId },
                ...notifyWarn('notification.warning.measure.updateMeasureFailure', true),
              })
            )
          )
        )
      )
    )

  );

  removeProtectiveMeasureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProtectiveMeasure),
      mergeMap(({assessmentId, measureId}) =>
        this.assessmentService.removeProtectiveMeasure(assessmentId, measureId).pipe(
          map(assessment =>
            removeProtectiveMeasureSuccess({
              assessment,
              ...notifyInfo('notification.info.measure.removeMeasureSuccess', true)})),
          catchError(error =>
            of(
              removeProtectiveMeasureFailure({
                error,
                payload: { assessmentId, measureId},
                ...notifyWarn('notification.warning.measure.removeMeasureFailure', true)
              })

            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private assessmentService: AssessmentService,
    private buildingDocumentsService: BuildingDocumentsService,
    private readonly dialogService: DialogService,
    private dialog: MatDialog
  ) {}
}
