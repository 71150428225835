<form *transloco="let t; read: 'authentication'">
  <p *ngIf="error$ | async as errorMessage" class="error-msg">{{ errorMessage }}</p>
  <div *ngIf="(completed | async) === false">
    <mat-form-field [formGroup]="signInForm" [class.margin-bottom]="email.hasError('email')">
      <mat-label>{{ t('email') }}</mat-label>
      <input matInput type="email" formControlName="email" />
      <mat-error *ngIf="email.hasError('email')">{{ t('error.requiredValidEmail') }}</mat-error>
    </mat-form-field>
    <sibat-primary-button (clicked)="sendPasswordReset()" [label]="t('accessRetrieval')" [disabled]="signInForm.invalid">
    </sibat-primary-button>
  </div>
  <div *ngIf="completed | async" class="completed">
    {{ t('resetLinkSent') }}
  </div>
  <p><sibat-tertiary-button (clicked)="signin()" [label]="t('signin')"> </sibat-tertiary-button></p>
  <hr />
  <p>
    {{ t('noAccount') }}&nbsp;
    <sibat-link-button [href]="supportEmail" [label]="t('contactUs')" [external]="true"> </sibat-link-button>
  </p>
</form>
