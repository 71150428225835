<div mat-dialog-title class="title-container">
  <h2>{{ 'building.control.title' | transloco }} ({{ 'building.buildingsCount' | transloco:{count} }})</h2>
</div>

<mat-dialog-content *ngIf="control$ | async as control; else loadingPreview">
  <sibat-control-report
    [building]="building$ | async"
    [control]="control"
    [isSCPI]="isSCPI$ | async"
    [offline]="offline$ | async"
    (closeModal)="close()">
  </sibat-control-report>
</mat-dialog-content>

<ng-template #loadingPreview> </ng-template>

<mat-dialog-actions align="end">
  <sibat-secondary-button
    class="mat-button-base"
    (clicked)="close()"
    [label]="'layout.closeDialog' | transloco">
  </sibat-secondary-button>
</mat-dialog-actions>
