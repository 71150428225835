<div mat-dialog-title class="title-container">
  <h2>{{ 'building.typology.edition' | transloco }} ({{ 'building.buildingsCount' | transloco:{ count } }})</h2>
</div>

<mat-dialog-content>
  <sibat-typology-editor
    [affectations]="affectations$ | async"
    [isEditDenied]="false"
    [hideLastUpdate]="!building"
    [typology]="building?.typology"
    (canSave)="updateCanSave($event.canSave, $event.currentValue)">
  </sibat-typology-editor>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <sibat-validate-button
    class="mat-button-base"
    (clicked)="save()"
    [label]="'building.documents.validate' | transloco"
    [disabled]="!canSave">
  </sibat-validate-button>

  <sibat-secondary-button
    class="mat-button-base"
    (click)="cancel()"
    [label]="'building.documents.cancel' | transloco">
  </sibat-secondary-button>
</mat-dialog-actions>
