import { compress } from 'jpegasus';
import { environment } from 'src/environments/environment';
import { FileWithPreviewBase64 } from '../control/model/control.model';

const UID_TIMESTAMP_MODULO = 100000000;

export const compressImage = async (imageFile: File): Promise<File> =>
  (await compress(imageFile, {
    fixImageOrientation: false,
    maxHeight: environment.defectImageMaxSize,
    maxWidth: environment.defectImageMaxSize,
    quality: environment.defectImageCompressionQuality, // to test the rendering of the image: https://tonybrobston.github.io/jpegasus-demo/
  })) as File;

export const extractPreview = (file: File): Promise<FileWithPreviewBase64> =>
  new Promise<FileWithPreviewBase64>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(createBase64JpgImage(file, reader.result as string));
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const createBase64JpgImage = (file: File, htmlBase64Data: string): FileWithPreviewBase64 => {
  const data = htmlBase64Data.split('base64,').pop();
  let suffixDot = file.name.lastIndexOf('.');
  if (suffixDot < 0) {
    suffixDot = file.name.length;
  }
  const fileName = `${file.name.substring(0, suffixDot)}.jpg`;
  return {
    data,
    fileName,
    preview: htmlBase64Data,
  } as FileWithPreviewBase64;
};

export const addUniqueIdPrefix = (file: FileWithPreviewBase64): FileWithPreviewBase64 => ({
  ...file,
  fileName: `${Date.now() % UID_TIMESTAMP_MODULO}-${file.fileName}`,
});
