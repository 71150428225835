import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getEnvironmentConfig, getEnvironmentConfigSuccess } from './environment-config.action';
import { EnvironmentConfigurationService } from '../service/environment-config.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigEffects {

  getEnvironmentConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEnvironmentConfig),
      switchMap(() =>
        this.environmentConfigService.getEnvironmentConfiguration().pipe(
          map(config =>
            getEnvironmentConfigSuccess({
              config,
            })
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private environmentConfigService: EnvironmentConfigurationService,
  ) {}
}
