import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { reportBuildingError, updateTypologyFailure } from '../../building/store/building.action';
import { uploadBuildingDocumentFailure } from '../../building/store/document.action';
import { addTagsFailure } from '../../building/store/tag.action';
import { Store } from '@ngrx/store';
import { selectBuilding } from '../../building/store/building.selector';
import { ErrorReportingService } from '../service/error-reporting.service';
import { selectUser } from '../../authentication/store/user.selector';
import { reportMapError, updateMapQueryFailure } from '../../map/store/map.action';
import { getUserSuccess, getUserSuccessWithRedirect } from '../../authentication/store/user.action';
import { logoutSuccess } from '../../authentication/store/authentication.action';
import { reportControlError } from '../../control/store/control.action';
import {
  approveAssessmentFailure,
  requestSecretaryApprovalFailure,
  updateAssessmentDetailsFailure
} from '../../assessment/store/assessment.action';
import { selectOngoingAssessment } from '../../assessment/store/assessment.selector';
import { selectMapQuery } from '../../map/store/map.selector';

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingEffects {
  addUserToBugsnagEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserSuccess, getUserSuccessWithRedirect),
        tap(({ user }) => this.errorReportingService.addUserToBugsnag(user))
      ),
    { dispatch: false }
  );

  removeUserFromBugsnagEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccess),
        tap(() => this.errorReportingService.clearUserData())
      ),
    { dispatch: false }
  );

  reportBuildingError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reportBuildingError, reportControlError),
        concatLatestFrom(() => [this.store.select(selectUser), this.store.select(selectBuilding)]),
        tap(([action, user, building]) =>
          this.errorReportingService.reportBuildingError(action.error, user, building, action.sourceActionType, action.payload)
        )
      ),
    { dispatch: false }
  );

  reportUpdateTypologyFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTypologyFailure),
        concatLatestFrom(() => [this.store.select(selectUser), this.store.select(selectBuilding)]),
        tap(([action, user, building]) => this.errorReportingService.reportTypologyError(action.error, user, building, action.typology))
      ),
    { dispatch: false }
  );

  reportAddTagFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addTagsFailure),
        concatLatestFrom(() => this.store.select(selectUser)),
        tap(([action, user]) => this.errorReportingService.reportAddTagError(action.error, user, action.tagName, action.buildingIds))
      ),
    { dispatch: false }
  );

  buildingServiceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadBuildingDocumentFailure),
        concatLatestFrom(() => [this.store.select(selectUser), this.store.select(selectBuilding)]),
        tap(([action, user, building]) => this.errorReportingService.reportBuildingError(action.error, user, building, action.type))
      ),
    { dispatch: false }
  );

  reportMapFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reportMapError),
        concatLatestFrom(() => this.store.select(selectUser)),
        tap(([action, user]) => this.errorReportingService.reportMapError(action.error, user))
      ),
    { dispatch: false }
  );

  reportUpdateAssessmentFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAssessmentDetailsFailure),
        concatLatestFrom(() => this.store.select(selectOngoingAssessment)),
        tap(([action, assessment]) => this.errorReportingService.reportAssessmentError(action.error, assessment))
      ),
    { dispatch: false }
  );

  reportRequestAssessmentFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(requestSecretaryApprovalFailure),
        concatLatestFrom(() => this.store.select(selectOngoingAssessment)),
        tap(([action, assessment]) => this.errorReportingService.reportAssessmentError(action.error, assessment))
      ),
    { dispatch: false }
  );

  reportApproveAssessmentFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(approveAssessmentFailure),
        concatLatestFrom(() => this.store.select(selectOngoingAssessment)),
        tap(([action, assessment]) => this.errorReportingService.reportAssessmentError(action.error, assessment))
      ),
    { dispatch: false }
  );

  reportMapQueryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMapQueryFailure),
        concatLatestFrom(() => this.store.select(selectMapQuery)),
        tap(([action, query]) => this.errorReportingService.reportMapQueryError(action.error, query))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store, private errorReportingService: ErrorReportingService) {}
}
