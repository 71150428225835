import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../model/user.model';
import {
  checkUserAccessToBuildingFailure,
  checkUserAccessToBuildingSuccess,
  clearUser,
  getConfigSuccess,
  getUserSuccess,
  getUserSuccessWithRedirect, loadUserFailure, loadUserSuccess,
  registerGisTokenSuccess, UNDO_LOAD_USER_SUCCESS, undoLoadUserSuccess, updateUserInState,
  updateUserLanguageSuccess,
} from './user.action';
import { Motd } from '../model/motd.model';
import { getMotdAndCheckSuccess, getMotdSuccess } from './motd.action';
import { Configuration } from '../model/configuration.model';

export default interface UserState {
  user?: User;
  registeredGisToken: boolean;
  configuration?: Configuration;
  motd?: Motd;
  error: any;
  hasAccessToBuilding: boolean | null;
  previousUser: User | null;
}

export const initialState: UserState = {
  user: undefined,
  registeredGisToken: false,
  configuration: undefined,
  motd: undefined,
  error: null,
  hasAccessToBuilding: null,
  previousUser:null,
};

const userReducer = createReducer(
  initialState,
  on(getUserSuccess, getUserSuccessWithRedirect, (state, action): UserState => ({ ...state, user: action.user })),
  on(
    clearUser,
    (state): UserState => ({
      ...state,
      user: undefined,
      registeredGisToken: false,
    })
  ),
  on(
    registerGisTokenSuccess,
    (state): UserState => ({
      ...state,
      registeredGisToken: true,
    })
  ),
  on(
    updateUserLanguageSuccess,
    (state, action): UserState => (state.user ? { ...state, user: { ...state.user, language: action.language } } : state)
  ),
  on(
    getConfigSuccess,
    (state, { configuration }): UserState => ({
      ...state,
      configuration
    })
  ),
  on(
    getMotdSuccess,
    getMotdAndCheckSuccess,
    (state, { motd }): UserState => ({
      ...state,
      motd,
    })
  ),
  on(updateUserInState, (state: UserState, { updatedUser }) => ({
      ...state,
     user: state.user ? {
        ...state.user,
        roles: updatedUser.roles
      } : state.user
    })
  ),
  on(undoLoadUserSuccess, (state) => ({
    ...state,
    user: state.previousUser || undefined, // Rétablissez l'utilisateur précédent
       })
  ),
  on(loadUserSuccess, (state, { user, previousUser }) => ({
      ...state,
      user,
      previousUser: previousUser ?? null,
      error: null,
    })
  ),
  on(loadUserFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(checkUserAccessToBuildingSuccess, (state, { hasAccessToBuilding }) => ({
    ...state, hasAccessToBuilding,
    error: null,
  })),
  on(checkUserAccessToBuildingFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);


export const reducer = (state: UserState | undefined, action: Action): UserState => userReducer(state, action);
