import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AbstractAssessmentDetail, AssessmentDto } from '../model/assessment.model';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { extendTextArea } from 'src/app/shared/style';
import { replaceNullValueWithUndefined } from 'src/app/shared/form-control';

@Component({
  selector: 'sibat-edit-assessment-details',
  templateUrl: 'edit-assessment-details.component.html',
  styleUrls: ['edit-assessment-details.component.scss'],
})
export class EditAssessmentDetailsComponent implements OnChanges, OnDestroy {
  @Input() assessment: AssessmentDto | undefined;
  @Input() readOnly: boolean;
  @Input() offline: boolean;
  @Output() assessmentDetailsSaved = new EventEmitter<AssessmentDto>();
  @Output() unsavedDetails = new EventEmitter<boolean>();
  formSubscription: Subscription;

  readonly assessmentDate = this.formBuilder.control(undefined, Validators.required);
  readonly peoplePresent = this.formBuilder.control(undefined, Validators.required);
  readonly landlordOrOperator = this.formBuilder.control(undefined, Validators.required);
  readonly remarks = this.formBuilder.control(undefined);
  readonly designation = this.formBuilder.control(undefined);
  readonly assessmentDetailsForm = this.formBuilder.group(
    {
      designation: this.designation,
      landlordOrOperator: this.landlordOrOperator,
      date: this.assessmentDate,
      remarks: this.remarks,
      peoplePresent: this.peoplePresent,
    } as AbstractAssessmentDetail,
    { validators: Validators.required }
  );

  constructor(private formBuilder: UntypedFormBuilder) {
    this.formSubscription = this.assessmentDetailsForm.statusChanges
      .pipe(
        map(() => this.assessmentDetailsForm.pristine),
        distinctUntilChanged()
      )
      .subscribe(pristine => {
        this.unsavedDetails.emit(!pristine);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assessment) {
      this.setValuesForOngoingAssessment(this.assessment);
    }
    if (changes.offline) {
      if (changes.offline.currentValue) {
        this.assessmentDetailsForm.disable();
      } else {
        this.assessmentDetailsForm.enable();
      }
    }
  }

  extendTextArea(element) {
    extendTextArea(element);
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
  }

  setValuesForOngoingAssessment(assessment: AssessmentDto | undefined) {
    if (assessment) {
      if (assessment.date) {
        const currentAssessmentDate = new Date(assessment.date);
        this.assessmentDate.setValue(currentAssessmentDate);
      }
      this.landlordOrOperator.setValue(assessment.landlordOrOperator);
      this.remarks.setValue(assessment.remarks);
      this.peoplePresent.setValue(assessment.peoplePresent);
      this.designation.setValue(assessment.designation);
    } else {
      this.assessmentDate.reset();
      this.landlordOrOperator.reset();
      this.remarks.reset();
      this.peoplePresent.reset();
      this.designation.reset();
    }
  }

  saveData(): void {
    this.assessmentDetailsSaved.emit(this.assessmentDetailsForm.value);
    this.assessmentDetailsForm.markAsPristine();
  }

  preventRequiredDate(formControl: UntypedFormControl) {
    replaceNullValueWithUndefined(formControl);
  }
}
