import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SetMotd } from '../../authentication/model/motd.model';
import { Store } from '@ngrx/store';
import { selectMotd } from '../../authentication/store/user.selector';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { extendTextArea } from 'src/app/shared/style';
import { Subscription } from 'rxjs';
import { getMotd, updateMotd } from '../../authentication/store/motd.action';

@Component({
  selector: 'sibat-motd-edit-dialog',
  template: `
    <div class="edit-dialog" *transloco="let t; read: 'cockpit'">
      <div class="title">{{ t('welcomeMessage') }}</div>
      <form class="motd-edit" [formGroup]="defectForm">
        <mat-form-field class="description">
          <mat-label>{{ t('welcomeMessageDe') }}</mat-label>
          <textarea matInput formControlName="messageDe" (keyup)="extendTextArea($event.target)"></textarea>
        </mat-form-field>
        <mat-form-field class="description">
          <mat-label>{{ t('welcomeMessageFr') }}</mat-label>
          <textarea matInput formControlName="messageFr" (keyup)="extendTextArea($event.target)"></textarea>
        </mat-form-field>
      </form>
      <div mat-dialog-actions>
        <sibat-secondary-button [label]="t('cancel')" (clicked)="close()" class="dialog-btn"></sibat-secondary-button>
        <sibat-primary-button [label]="t('submit')" (clicked)="validateMotd()" class="dialog-btn"></sibat-primary-button>
      </div>
    </div>
  `,
  styleUrls: ['./motd-edit-dialog.component.scss'],
})
export class MotdEditDialogComponent implements OnInit, OnDestroy {
  motd$ = this.store.select(selectMotd);
  motdSubscription = new Subscription();
  readonly messageDe = this.formBuilder.control(undefined);
  readonly messageFr = this.formBuilder.control(undefined);
  readonly defectForm = this.formBuilder.group(
    {
      messageDe: this.messageDe,
      messageFr: this.messageFr,
    },
    { validators: Validators.required }
  );

  constructor(
    public store: Store,
    public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MotdEditDialogComponent, SetMotd>
  ) {}

  ngOnInit(): void {
    this.motdSubscription.add(
      this.motd$.subscribe(motd => {
        this.messageDe.setValue(motd?.messageDe);
        this.messageFr.setValue(motd?.messageFr);
      })
    );
    this.store.dispatch(getMotd());
  }

  ngOnDestroy() {
    this.motdSubscription.unsubscribe();
  }

  validateMotd(): void {
    const motd: SetMotd = {
      messageDe: this.messageDe.value,
      messageFr: this.messageFr.value,
    };
    this.store.dispatch(updateMotd({ motd }));
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  extendTextArea(element) {
    extendTextArea(element);
  }
}
