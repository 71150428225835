import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import AdminState from './admin.reducer';
import { User } from '../../authentication/model/user.model';

export const getAdminState = (state: State) => state.admin;

export const selectAllUsers = createSelector(getAdminState, (state: AdminState) => state.users);

export const selectAllRoles = createSelector(getAdminState, (state: AdminState) => state.roles);

export const selectUserById = createSelector(selectAllUsers, (users: User[], userId: number) => users.find(user => user.id === userId));
