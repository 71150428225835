import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { BuildingService } from '../service/building.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getBuilding,
  getBuildingFailure,
  getBuildingSuccess,
  getHistory,
  getHistorySuccess, deleteBuilding,
  updateNextControlDates,
  updateNextControlDatesError,
  updateNextControlDatesSuccess,
  updateTypologies,
  updateTypologiesSuccess,
  updateTypologyFailure,
  deleteBuildingSuccess,
  deleteBuildingFailure,
} from './building.action';
import { of } from 'rxjs';
import { EditHistory } from '../model/history.model';
import { extractOngoingControl } from '../../control/store/control.action';
import { extractOngoingAssessment } from '../../assessment/store/assessment.action';
import { notifyInfo, notifyWarn } from 'src/app/model/notification.model';
import { TranslocoService } from '@ngneat/transloco';
import { selectBuilding } from './building.selector';
import { Store } from '@ngrx/store';
import { noopAction } from '../../shared/app.action';

@Injectable({
  providedIn: 'root',
})
export class BuildingEffects {
  getBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuilding),
      mergeMap(({ buildingId }) =>
        this.buildingService.get(buildingId).pipe(
          map(building => getBuildingSuccess({ building })),
          catchError(error => of(getBuildingFailure({ error })))
        )
      )
    )
  );

  extractControlFromBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuildingSuccess),
      map(({ building }) => extractOngoingControl({ control: building.ongoingControl }))
    )
  );

  extractAssessmentFromBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuildingSuccess),
      map(({ building }) => extractOngoingAssessment({ assessment: building.ongoingAssessment }))
    )
  );

  updateTypologyBuildingRefreshHistoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTypologiesSuccess),
      concatLatestFrom(() => [this.store.select(selectBuilding)]),
      map(([{ buildingIds }, building]) => {
        if (building && buildingIds.includes(building.id)) {
          return getHistory({buildingId: building.id});
        } else {
          return noopAction();
        }
      }),
    )
  );

  updateTypologiesBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTypologies),
      mergeMap(({ buildingIds, typology }) =>
        this.buildingService.updateTypologies(buildingIds, typology).pipe(
          map(response => {
            const failedBuildingIds = buildingIds.filter(buildingId => !response.buildingIds.includes(buildingId));
            const successCount = response.buildingIds.length;
            const failureCount = failedBuildingIds.length;
            const messageTitle = this.translocoService.translate('building.typology.edition');
            const successMessage = this.translocoService.translate('building.successBuildingsCount', { successCount });
            const failureMessage = this.translocoService.translate('building.failureBuildingsCount', { failureCount });
            const message = `${messageTitle} - ${successMessage}${failureCount > 0 ? ' - ' + failureMessage : ''}`;
            return updateTypologiesSuccess({
              buildingIds: response.buildingIds,
              failedBuildingIds,
              typology: response.typology,
              ...notifyInfo(message, false),
            });
          }),
          catchError(error => of(updateTypologyFailure({ error, typology, ...notifyWarn('common.notification.saveFailure', true) })))
        )
      )
    )
  );

  updateNextControlDatesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNextControlDates),
      mergeMap(({ buildingIds, date }) =>
        this.buildingService.updateNextControlDates(buildingIds, date).pipe(
          map(successBuildingIds => {
            const failedBuildingIds = buildingIds.filter(buildingId => !successBuildingIds.includes(buildingId));
            const successCount = successBuildingIds.length;
            const failureCount = failedBuildingIds.length;
            const messageTitle = this.translocoService.translate('building.control.editionNextDate');
            const successMessage = this.translocoService.translate('building.successBuildingsCount', { successCount });
            const failureMessage = this.translocoService.translate('building.failureBuildingsCount', { failureCount });
            const message = `${messageTitle} - ${successMessage}${failureCount > 0 ? ' - ' + failureMessage : ''}`;
            return updateNextControlDatesSuccess({
              buildingIds: buildingIds.filter(buildingId => !failedBuildingIds.includes(buildingId)),
              failedBuildingIds,
              date,
              ...notifyInfo(message, false),
            });
          }),
          catchError(error => of(updateNextControlDatesError({ error, ...notifyWarn('common.notification.saveFailure', true) })))
        )
      )
    )
  );

  getHistoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHistory),
      mergeMap(({ buildingId }) => this.buildingService.getHistory(buildingId)),
      map((history: EditHistory) => getHistorySuccess({ history }))
    )
  );

  removeBuildingEffect$  = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBuilding),
      mergeMap(({ buildingId }) => this.buildingService.deleteBuilding(buildingId)),
      map((deleteInfos) => deleteBuildingSuccess({ deleteInfos })),
      catchError(error => of(deleteBuildingFailure({ error }))),
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private buildingService: BuildingService,
    private translocoService: TranslocoService
  ) {}
}
