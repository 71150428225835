import { Message } from './../../model/notification.model';
import { createAction, props } from '@ngrx/store';

export const signIn = createAction('[Auth] sign in', props<{ email: string; password: string }>());

export const signInSuccess = createAction('[Auth] sign in success', props<{ token: string }>());

export const signInFailure = createAction('[Auth] sign in failed', props<{ error: Error }>());

export const loadExistingToken = createAction('[Auth] load existing token');

export const loadExistingTokenSuccess = createAction('[Auth] load existing token success', props<{ token: string }>());

export const loadExistingTokenFailure = createAction('[Auth] load existing token failure');

export const checkTokenValidity = createAction('[Auth] check token validity');

export const redirectToHome = createAction('[Auth] redirect to home');

export const redirectToCockpit = createAction('[Auth] redirect to cockpit');

export const logout = createAction('[Auth] logout');

export const logoutSuccess = createAction('[Auth] logout success');

export const redirectToLogin = createAction('[Auth] redirect to login');

export const sendChangePassword = createAction('[Auth] change password', props<{ password: string }>());

export const sendChangePasswordSuccess = createAction('[Auth] change password success');

export const sendChangePasswordFailure = createAction('[Auth] change password failed', props<{ error: Error }>());

export const forgotPassword = createAction('[Auth] forgot password', props<{ email: string }>());

export const forgotPasswordSuccess = createAction('[Auth] forgot password success');

export const forgotPasswordFailure = createAction('[Auth] forgot password failed', props<{ error: Error }>());

export const clearPasswordResetState = createAction('[Auth] clear password reset state');

export const setNewPassword = createAction('[Auth] set new password', props<{ password: string; token: string }>());

export const setNewPasswordSuccess = createAction('[Auth] set new password success');

export const setNewPasswordFailure = createAction('[Auth] set new password failed', props<{ error: Error; notification: Message }>());

export const clearNewPasswordSetState = createAction('[Auth] clear new password set state');

export const redirectToSetNewPassword = createAction('[Auth] redirect to set new password');

export const sendNewPasswordError = createAction('[Auth] send new password failed');

export const clearAuthenticationError = createAction('[Auth] reset authentication error');
