import { createSelector } from '@ngrx/store';
import { State } from '../../reducers';
import EnvironmentConfigState from './environment-config.reducer';

export const getEnvironmentConfigState = (state: State) => state.environmentConfig;

export const selectShortEnvName = createSelector(getEnvironmentConfigState, (state: EnvironmentConfigState) =>
  state.environment?.substring(0, 3)
);

export const selectIsNotProdEnv = createSelector(getEnvironmentConfigState, (state: EnvironmentConfigState) =>
  state.environment !== 'production'
);
