import { Component, Input } from '@angular/core';
import { ControlSummary } from '../../control/model/control.model';

@Component({
  selector: 'sibat-orphan-control-item',
  template: `
    <div class="orphan-control-item" [title]="control.designation ? control.designation : ''">
      <div class="icon">
        <mat-icon>list_alt</mat-icon>
      </div>
      <div class="label">
        <span class="designation">{{ control.designation ? control.designation : control.id }}</span>
        <span class="date">{{ control.date | date }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./orphan-control-item.component.scss'],
})
export class OrphanControlItemComponent {
  @Input() control: ControlSummary;
}
