<div mat-dialog-title class="title-container">
  <h2>{{ 'building.control.editionNextDate' | transloco:{ count } }} ({{ 'building.buildingsCount' | transloco:{ count } }})</h2>
</div>

<mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ 'building.control.controlDetailsForm.controlDate' | transloco }}</mat-label>
    <input #dateInput
           matInput
           [matDatepicker]="controlDatePicker"
           (focus)="controlDatePicker.open()"
           (dateChange)="dateChanged($event.value)"
    />
    <mat-datepicker-toggle matSuffix [for]="controlDatePicker"></mat-datepicker-toggle>
    <mat-datepicker disabled="false" #controlDatePicker></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <sibat-validate-button
    class="mat-button-base"
    (clicked)="save()"
    [label]="'building.documents.validate' | transloco">
  </sibat-validate-button>

  <sibat-secondary-button
    class="mat-button-base"
    (click)="cancel()"
    [label]="'building.documents.cancel' | transloco">
  </sibat-secondary-button>
</mat-dialog-actions>
