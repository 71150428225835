import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../authentication/model/user.model';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(text: string, search: string | RegExp, replace: string): string {
    return text?.replace(search, replace);
  }
}

@Pipe({
  name: 'startsWith',
})
export class StartsWithPipe implements PipeTransform {
  transform(text: string, searchString: string): boolean {
    return text?.startsWith(searchString);
  }
}

@Pipe({
  name: 'substring',
})
export class SubstringPipe implements PipeTransform {
  transform(text: string, start: number): string {
    return text?.substring(start);
  }
}

@Pipe({
  name: 'displayName',
})
export class DisplayNamePipe implements PipeTransform {
  transform(user: User): string {
    return user ? `${user.firstName} ${user.lastName}` : '';
  }
}

@Pipe({
  name: 'breakAddress',
})
export class BreakAddressPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(', ', ',\n');
  }
}

@Pipe({
  name: 'replaceLineBreaks'
})
export class ReplaceLineBreaksPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/<>/g, '<br>');
  }
}
