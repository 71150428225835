import { Message } from '../../model/notification.model';
import { createAction, props } from '@ngrx/store';

export const notifyGenericError = createAction(
  '[Error] generic error',
  props<{ error: any; notification: Message }>()
);

export const notifyMessageError = createAction(
  '[Error] message error',
  props<{ notification: Message }>()
);
