import { Component, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectConfiguration,
  selectIsECABOrAbove,
  selectIsGisTokenRegistered
} from '../../authentication/store/user.selector';
import { Configuration } from '../../authentication/model/configuration.model';
import { FabService } from '../../shared/service/fab.service';

@Component({
  selector: 'sibat-buildings',
  template: `
    <sibat-filter></sibat-filter>
    <section flex fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="stretch">
      <sibat-web-map
        [configuration]="configuration$ | async"
        [registeredGisToken]="gisTokenIsRegistered$ | async"
        [centeredOnBuildings]="centeredOnBuildings"
        [isEcabOrAbove]="isECABOrAbove$ | async"
        (selectionChange)="showSelectedBuildingsTab()"
        fxFlex="none"
        fxFlex.gt-sm="auto"
      ></sibat-web-map>
      <span class="separator"></span>
      <sibat-lists fxFlex="none" fxFlex.gt-sm="384px"
                   [detailedSelectionDisplay]="displayDetailedSelection"
                   (centerOnBuildings)="centerOnSelectedBuildings($event)"
                   (showDetailedSelection)="showDetailedSelection(true)"
                   [(selectedTab)]="selectedBuildingListTab"
      ></sibat-lists>
      <sibat-detailed-list *ngIf="displayDetailedSelection"
                           (hide)="showDetailedSelection(false)"></sibat-detailed-list>
    </section>
  `,
  styleUrls: [
    './buildings.component.scss'
  ],
})
export class BuildingsComponent implements OnDestroy {
  configuration$: Observable<Configuration | undefined> = this.store.select(selectConfiguration);
  gisTokenIsRegistered$: Observable<boolean> = this.store.select(selectIsGisTokenRegistered);
  isECABOrAbove$ = this.store.select(selectIsECABOrAbove);
  centeredOnBuildings: number[] = [];
  displayDetailedSelection = false;
  selectedBuildingListTab = 0;

  constructor(private store: Store, private fabService: FabService) {
  }

  ngOnDestroy(): void {
    this.fabService.clearFabOptions();
  }

  centerOnSelectedBuildings(buildingIds: number[]) {
    this.centeredOnBuildings = buildingIds;
  }

  showDetailedSelection(displayDetailedSelection: boolean) {
    this.displayDetailedSelection = displayDetailedSelection;
  }

  showSelectedBuildingsTab = () => {
    this.selectedBuildingListTab = 1;
  };
}
