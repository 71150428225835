import { User } from '../../authentication/model/user.model';
import { Affectation } from './affectation.model';

export interface Typology {
  color: ColorTypology;
  source: string;
  date: string;
  affectationKeys: string[];
  justification: string;
  user: User;
}

export const COLOR_TYPOLOGY = {
  white: 'blanc',
  green: 'vert',
  red: 'rouge',
  toDefine: 'a_definir',
  notInspected: 'non_inspecte',
} as const;
export type ColorTypology = typeof COLOR_TYPOLOGY[keyof typeof COLOR_TYPOLOGY];

export const emptyTypology: Typology = {
  color: COLOR_TYPOLOGY.notInspected,
  affectationKeys: [],
} as unknown as Typology;

export const mapByColor = (affectations: Affectation[]): [ColorTypology, Affectation] =>
  affectations.reduce((prev, curr) => {
    (prev[curr.color] = prev[curr.color] || []).push(curr);
    prev[curr.color].sort((a, b) => a.title.localeCompare(b.title));
    return prev;
  }, {} as [ColorTypology, Affectation]);

export const typologyTranslations: Array<[ColorTypology, string]> = [
  [COLOR_TYPOLOGY.red, 'building.typology.red'],
  [COLOR_TYPOLOGY.green, 'building.typology.green'],
  [COLOR_TYPOLOGY.white, 'building.typology.white'],
];

export interface TypologyUpdateResult {
  buildingIds: number[];
  typology: Typology;
}
