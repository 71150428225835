<section class="root-container" *transloco="let t; read: 'building.technicalInstallation'">
  <header>
    <h2>
      <mat-icon class="text-middle">content_paste</mat-icon>
      {{ t('installationList') }}
    </h2>
  </header>

  <div class="table-container">
    <table mat-table [dataSource]="installations$ | async" class="installations-table single-action-list">

      <ng-container matColumnDef="installationType">
        <th mat-header-cell *matHeaderCellDef> {{ t('type') }} </th>
        <td mat-cell *matCellDef="let element">{{ t('installationType', {type: element.installationType}) }}</td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef> {{ t('designation') }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.designation ?? '-' }} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{ t('date') }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'dd.MM.yyyy' }} </td>
      </ng-container>

      <ng-container matColumnDef="temporaryShutdownEndDate">
        <th mat-header-cell *matHeaderCellDef> {{ t('temporaryShutdownEndDate') }} </th>
        <td mat-cell *matCellDef="let element"
          [ngClass]="{'orange': element.temporaryShutdownEndDate !== null, 'red': isBeforeToday(element.temporaryShutdownEndDate)}"
        >
          {{ (element.temporaryShutdownEndDate | date: 'dd.MM.yyyy') ?? '-' }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectInstallation(row)"></tr>
    </table>
  </div>
</section>
