import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Motd, SetMotd } from '../model/motd.model';

export const MOTD_BASE_URI = `${environment.apiUrl}/motd`;

@Injectable({
  providedIn: 'root',
})
export class MotdService {
  constructor(private readonly http: HttpClient) {}

  getMotd(): Observable<Motd> {
    return this.http.get<Motd>(`${MOTD_BASE_URI}`);
  }

  updateMotd(motd: SetMotd): Observable<string> {
    return this.http.post<string>(`${MOTD_BASE_URI}`, motd);
  }
}
