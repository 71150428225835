<div class="control-defect-item" [class.compliant]="defectDto.compliance" data-testid="control-defect-item">
  <div class="defect-select-control flex-center">
    <mat-checkbox
      [disabled]="defectDto.compliance"
      [checked]="defectDto.compliance || selected"
      class="example-margin"
      (change)="onCheckboxChange()"
    ></mat-checkbox>
  </div>
  <div class="defect-image flex-center">
    <mat-icon>description</mat-icon>
  </div>
  <div class="defect-details">
    <p class="defect-description">{{ defectDto.description }}</p>
    <div
      class="flex-items-center defect-deadline"
      *ngIf="defectDto.compliance?.date as complianceDate; else noCompliance"
      data-testid="compliance-date">
      <mat-icon>task_alt</mat-icon>{{ complianceDate | date: 'dd.MM.yyyy' }}
    </div>
    <ng-template #noCompliance>
      <div
        class="flex-items-center defect-deadline"
        *ngIf="defectDto.deadline as deadline"
        data-testid="compliance-deadline">
        <mat-icon>schedule</mat-icon>{{ deadline | date: 'dd.MM.yyyy' }}
      </div>
    </ng-template>
  </div>
  <button (click)="toggleDetails()" class="defect-display-control flex-center">
    <mat-icon>visibility</mat-icon>
  </button>
</div>
<ng-container *ngIf="defectDto.compliance">
  <sibat-control-defect-compliance-details
    data-testid="control-defect-compliance-details"
    [compliance]="defectDto.compliance"
    [controlId]="controlId"
  ></sibat-control-defect-compliance-details>
</ng-container>

<sibat-edit-defect
  data-testid="edit-defect"
  *ngIf="isOpen"
  [defect]="editDefect"
  [thumbnails]="thumbnails"
  [isReadOnly]="true"
  [controlId]="controlId"
></sibat-edit-defect>
