import { Message } from './../../model/notification.model';
import { createAction, props } from '@ngrx/store';
import { Tag } from '../model/tag.model';
import { HttpErrorResponse } from '@angular/common/http';

export const addTag = createAction('[Buildings] add tag', props<{ tagName: string; buildingIds: number[] }>());

export const addTagSuccess = createAction('[Buildings] add tag success', props<{ tagName: string; buildingIds: number[] }>());

export const addTagsFailure = createAction(
  '[Buildings] add tag failure',
  props<{ error: HttpErrorResponse; tagName: string; buildingIds: number[] }>()
);

export const removeTagFromBuilding = createAction('[Buildings] remove tag from building', props<{ tagName: string; buildingId: number }>());

export const removeTagFromBuildingSuccess = createAction(
  '[Buildings] remove tag from building success',
  props<{ tagName: string; buildingId: number }>()
);

export const removeTagFromBuildingFailure = createAction('[Buildings] remove tag from building failure', props<{ error: any }>());

export const getTags = createAction('[Buildings] get tags');

export const getTagsSuccess = createAction('[Buildings] get tags success', props<{ tags: Tag[] }>());

export const getTagsFailure = createAction('[Buildings] get tags failure', props<{ error: any }>());

export const deleteTag = createAction('[Buildings] delete tag', props<{ tag: Tag }>());

export const deleteTagSuccess = createAction('[Buildings] delete tag success', props<{ tag: Tag; notification: Message }>());

export const deleteTagFailure = createAction('[Buildings] delete tag failure', props<{ error: any }>());
