import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sibat-chip-proof',
  template: `<sibat-chip-base
    [name]="proofName"
    (iconClicked)="emitDownload()"
    [icon]="'cloud_download'"
    (selected)="emitDownload()"
  ></sibat-chip-base>`,
  styles: [],
})
export class ChipProofComponent {
  @Input() proofName;
  @Output() downloaded = new EventEmitter();

  constructor() {}

  emitDownload() {
    this.downloaded.emit();
  }
}
