import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[sibatDragNDrop]',
})
export class DragNDropDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<FileList>();
  @Input() applyHover = true;
  @Input() active = true;

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.active && this.applyHover) {
      this.fileOver = true;
    }
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    if (this.active) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
    }
  }

  @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
    if (this.active) {
      evt.preventDefault();
      evt.stopPropagation();
      this.fileOver = false;
      const files = evt.dataTransfer?.files;
      if (files !== undefined && files.length > 0) {
        this.fileDropped.emit(files);
      }
    }
  }
}
