import { User } from 'src/app/authentication/model/user.model';
import { ColorTypology } from './typology.model';
import { ControlSummary } from '../../control/model/control.model';

export const today = 'today';

export const HISTORY_TYPE = {
  typology: 'typology',
  control: 'control',
} as const;
export type HistoryType = typeof HISTORY_TYPE[keyof typeof HISTORY_TYPE];

export const CONTROL_STATUS = {
  completed: 'COMPLETED',
  ongoing: 'ONGOING',
  orphan: 'ORPHAN',
} as const;
export type ControlStatus = typeof CONTROL_STATUS[keyof typeof CONTROL_STATUS];

export const ASSESSMENT_STATUS = {
  approved: 'APPROVED',
  ongoing: 'ONGOING',
  canceled: 'CANCELED',
  approval: 'APPROVAL',
} as const;
export type AssessmentStatus = typeof ASSESSMENT_STATUS[keyof typeof ASSESSMENT_STATUS];

export interface ControlEntry {
  id: number;
  date: string;
  user: User;
  status: ControlStatus;
  isCompliant: boolean;
  defectCount: number;
  reportFileName?: string;
}

export interface TypologyEntry {
  previousColor?: ColorTypology;
  color: ColorTypology;
  source: string;
  date: string;
  affectationKeys: string[];
  justification: string;
  user: User;
}

export interface EditHistory {
  control: ControlEntry[];
  typology: TypologyEntry[];
}

export interface EditHistoryWithValidatedControl {
  control: ControlEntry[];
  typology: TypologyEntry[];
  validatedControl: ControlSummary;
}

export interface HistoryEntry {
  data: ControlEntry | TypologyEntry;
  date: string;
  type: HistoryType;
}

export const typologyData = (entry: TypologyEntry, previousColor?: ColorTypology): HistoryEntry => ({
  data: { ...entry, previousColor },
  date: entry.date,
  type: HISTORY_TYPE.typology,
});

export const controlData = (entry: ControlEntry): HistoryEntry => ({
  data: entry,
  date: entry.date,
  type: HISTORY_TYPE.control,
});
