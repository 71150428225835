<sibat-modal-header [title]="'building.documents.addFile' | transloco" (clicked)="closeDialog()"></sibat-modal-header>

<form [formGroup]="uploadForm">
  <div class="section file">
    <mat-label>{{ 'building.documents.yourFile' | transloco }}</mat-label>
    <span>{{ data.file.name }}</span>
  </div>

  <div class="section radio">
    <mat-label>{{ 'building.documents.oldReportFileQuestion' | transloco }}</mat-label>
    <mat-radio-group formControlName="isOldReport" name="fileTypeGroup">
      <mat-radio-button name="fileType" value="true">{{ 'common.yes' | transloco }}</mat-radio-button>
      <mat-radio-button name="fileType" value="false">{{ 'common.no' | transloco }}</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="section date" *ngIf="oldReport.value === 'true'">
    <mat-label>{{ 'building.documents.oldReportDate' | transloco }}</mat-label>
    <mat-form-field>
      <mat-label>{{ 'building.control.controlDetailsForm.controlDate' | transloco }}</mat-label>
      <input
          formControlName="controlDate"
          data-testid="control-date-picker"
          matInput
          [matDatepicker]="controlDatePicker"
          (focus)="controlDatePicker.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="controlDatePicker"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #controlDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <sibat-validate-button
      [disabled]="!uploadForm.valid"
      [label]="'common.validate' | transloco"
      [submit]="true"
      (clicked)="onValidateForm()"
  ></sibat-validate-button>
</form>
