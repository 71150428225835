import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MotdEditDialogComponent } from '../motd-edit-dialog/motd-edit-dialog.component';

@Component({
  selector: 'sibat-motd-widget',
  template: `
    <sibat-widget *transloco="let t; read: 'cockpit'" [title]="t('welcomeMessage')">
      <mat-icon widget-icon>announcement</mat-icon>
      <div widget-content class="content">
        <sibat-primary-button
          [label]="t('edit')"
          [submit]="false"
          [disabled]="false"
          [stretch]="true"
          (clicked)="editMotd()"
          class="start-button"
        ></sibat-primary-button>
      </div>
    </sibat-widget>
  `,
  styleUrls: ['motd-widget.component.scss'],
})
export class MotdWidgetComponent {
  constructor(private dialog: MatDialog) {}

  editMotd() {
    this.dialog.open(MotdEditDialogComponent);
  }
}
