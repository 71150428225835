import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BuildingClass } from '../model/building-class.model';

@Injectable({
  providedIn: 'root',
})
export class BuildingClassService {
  baseUrl = `${environment.apiUrl}/building-classes`;

  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<BuildingClass[]> {
    return this.http.get<BuildingClass[]>(this.baseUrl);
  }
}
