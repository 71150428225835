import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ColorTypology, COLOR_TYPOLOGY } from '../model/typology.model';

@Component({
  selector: 'sibat-typology-indicator',
  template: ``,
  styleUrls: ['./typology-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypologyIndicatorComponent implements OnChanges {
  @Input() color: ColorTypology;

  @HostBinding('class.white') isWhite = false;
  @HostBinding('class.red') isRed = false;
  @HostBinding('class.green') isGreen = false;
  @HostBinding('class.to-be-defined') isToBeDefined = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.isWhite = this.color === COLOR_TYPOLOGY.white;
      this.isRed = this.color === COLOR_TYPOLOGY.red;
      this.isGreen = this.color === COLOR_TYPOLOGY.green;
      // none of the above
      this.isToBeDefined = !this.isWhite && !this.isRed && !this.isGreen;
    }
  }
}
