import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sibat-tag',
  template: `
    <sibat-chip-base (iconClicked)="emitDelete()" (selected)="emitSelected()" [icon]="'cancel'" [name]="name"> </sibat-chip-base>
  `,
  styles: [],
})
export class TagComponent {
  @Input() name: string;
  @Output() selected = new EventEmitter();
  @Output() delete = new EventEmitter();

  emitSelected() {
    this.selected.emit();
  }

  emitDelete() {
    this.delete.emit();
  }
}
