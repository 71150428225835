import {
  getInstallationsSuccess,
  installationUpdateSuccess,
  resetActiveTechnicalInstallation,
  setActiveTechnicalInstallation,
} from './technical-installation.action';
import { CasePreview } from '../../case/model/case.model';
import {
  clearCurrentBuilding,
  deleteBuildingFailure,
  deleteBuildingSuccess,
  getBuilding,
  getBuildingFailure,
  getBuildingSuccess,
  getHistorySuccess,
  updateNextControlDatesSuccess,
  updateTypologiesSuccess,
  updateTypologyFailure,
} from './building.action';
import { changeDirectorySuccess, deleteBuildingDocumentSuccess, getBuildingDocumentsSuccess } from './document.action';
import { EditHistory } from '../model/history.model';
import { Action, createReducer, on } from '@ngrx/store';
import { Building, DeleteInfos } from '../model/building.model';
import { BuildingDocument } from '../model/building-document.model';
import { addTagSuccess, deleteTagSuccess, removeTagFromBuildingSuccess } from './tag.action';
import {
  cancelOngoingControlSuccess,
  linkControlToBuildingSuccess,
  validateControlSuccess
} from '../../control/store/control.action';
import { cancelOngoingAssessmentSuccess } from '../../assessment/store/assessment.action';
import { fetchBuildingCaseSuccess } from './building-case.action';
import { TechnicalInstallation } from '../model/technical-installations.model';
import { Typology } from '../model/typology.model';

export default interface BuildingState {
  building?: Building;
  buildingLoading: boolean;
  history?: EditHistory;
  error: any;
  buildingDocumentsPath: string;
  buildingDocuments: BuildingDocument[];
  casePreviews?: CasePreview[];
  technicalInstallationDetail?: TechnicalInstallation;
  technicalInstallationList: TechnicalInstallation[];
  deleteInfos: DeleteInfos;
}

export const initialState: BuildingState = {
  building: undefined,
  history: undefined,
  error: undefined,
  buildingLoading: false,
  buildingDocumentsPath: '',
  buildingDocuments: [],
  casePreviews: [],
  technicalInstallationDetail: undefined,
  technicalInstallationList: [],
  deleteInfos: { msg: '', success: false },
};

const buildingReducer = createReducer(
  initialState,
  on(getBuilding, (state): BuildingState => ({...state, building: undefined, buildingLoading: true})),
  on(getBuildingSuccess, (state, action): BuildingState => ({
    ...state,
    building: action.building,
    buildingLoading: false
  })),
  on(clearCurrentBuilding, (): BuildingState => ({...initialState, buildingLoading: false})),
  on(getBuildingFailure, (state, action): BuildingState => ({...state, error: action.error, buildingLoading: false})),
  on(updateTypologiesSuccess, (state, action): BuildingState => ({
    ...state,
    building: {...(state.building as Building), typology: action.typology},
  })),

  on(updateTypologiesSuccess, (state, action): BuildingState => ({
      ...state,
      building: (state.building ? (action.buildingIds.includes(state.building?.id ?? 0) ?
        updateBuildingWithTypology(state.building, action.typology) : state.building) : undefined)
  })),
  on(updateTypologyFailure, (state, action): BuildingState => ({...state, error: action.error})),
  on(getHistorySuccess, (state, {history}): BuildingState => ({
    ...state,
    history,
  })),
  on(getBuildingDocumentsSuccess, (state, action): BuildingState => ({
    ...state,
    buildingDocuments: action.buildingDocuments,
  })),
  on(changeDirectorySuccess, (state, action): BuildingState => ({
    ...state,
    buildingDocuments: action.documents,
    buildingDocumentsPath: action.path,
  })),
  on(deleteBuildingDocumentSuccess, (state, action): BuildingState => ({
    ...state,
    buildingDocuments: state.buildingDocuments.filter(file => file.name !== action.fileName),
  })),
  on(deleteTagSuccess, (state, action): BuildingState => {
    const updatedBuilding = state.building && {
      ...state.building,
      tags: state.building.tags?.filter(tagName => tagName !== action.tag.name),
    };
    return {
      ...state,
      building: updatedBuilding,
    };
  }),
  on(addTagSuccess, (state, action) => {
    const updatedBuilding = state.building?.id && action.buildingIds.includes(state.building.id)
      ? ({
        ...state.building,
        tags: Array.from(new Set(state.building?.tags?.concat(action.tagName))),
      } as Building)
      : state.building;
    return {
      ...state,
      building: updatedBuilding,
    };
  }),
  on(removeTagFromBuildingSuccess, (state, action): BuildingState => {
    const updatedBuilding =
      action.buildingId === state.building?.id
        ? {
          ...state.building,
          tags: state.building.tags?.filter(tag => tag !== action.tagName),
        }
        : undefined;
    return {
      ...state,
      building: updatedBuilding,
    };
  }),
  on(updateNextControlDatesSuccess, (state, action): BuildingState => {
    const updatedBuilding =
      action.buildingIds.includes(state.building?.id ?? 0)
        ? {
          ...state.building,
          nextControlDate : action.date,
        } as Building
        : undefined;
    return {
      ...state,
      building: updatedBuilding,
    };
  }),
  on(cancelOngoingControlSuccess, (state): BuildingState => {
    if (state.building) {
      return {
        ...state,
        building: {
          ...state.building,
          ongoingControl: undefined,
        } as Building,
      };
    } else {  // when cancelling orphan control
      return state;
    }
  }),
  on(cancelOngoingAssessmentSuccess, (state): BuildingState => {
    if (state.building) {
      return {
        ...state,
        building: {
          ...state.building,
          ongoingAssessment: undefined,
        } as Building,
      };
    } else {
      return state;
    }
  }),
  on(validateControlSuccess, (state): BuildingState => ({
      ...state,
      building: {
        ...state.building,
        ongoingControl: undefined,
      } as Building,
    })
  ),
  on(linkControlToBuildingSuccess, (state, action): BuildingState => ({
      ...state,
      building: {
        ...state.building,
        ongoingControl: action.control,
      } as Building,
    })
  ),
  on(fetchBuildingCaseSuccess, (state, {casePreviews}): BuildingState => ({
    ...state,
    casePreviews,
  })),
  on(deleteBuildingSuccess, (state, action): BuildingState => ({
    ...state,
    deleteInfos: action.deleteInfos,
  })),
  on(deleteBuildingFailure, (state, {error}): BuildingState  => ({
    ...state,
    deleteInfos: {
      success: false,
      msg: error.message
    },
  })),
  on(installationUpdateSuccess, (state, {installation}): BuildingState => ({
    ...state,
    technicalInstallationDetail: installation,
  })),
  on(resetActiveTechnicalInstallation, (state): BuildingState => ({
    ...state,
    technicalInstallationDetail: undefined,
  })),
  on(getInstallationsSuccess, (state, {installations}): BuildingState => ({
    ...state,
    technicalInstallationList: installations,
  })),
  on(setActiveTechnicalInstallation, (state, {installation}): BuildingState => ({
    ...state,
    technicalInstallationDetail: installation,
  }))
);

export const reducer = (state: BuildingState | undefined, action: Action): BuildingState => buildingReducer(state, action);

export const updateBuildingWithTypology = (building: Building, typology: Typology): Building => ({
  ...building,
  typology, // TODO update localized affectations
});
