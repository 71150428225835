import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sibat-base-button',
  template: `
    <button
      mat-button
      class="button"
      [type]="submit ? 'submit' : 'button'"
      [class.button-disabled]="disabled"
      [class.button-stretch]="stretch"
      [disabled]="disabled"
      (click)="clicked.emit($event)"
    >
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      <span>{{ label }}</span>
    </button>
  `,
  styleUrls: ['buttons.scss'],
  host: { class: 'sibat-base-button' },
})
export class BaseButtonComponent {
  @Input() label: string;
  @Input() icon?: string;
  @Input() disabled = false;
  @Input() stretch = false;
  @Input() submit = false;
  @Output() clicked = new EventEmitter();

  constructor() {}
}
