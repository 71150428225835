import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { checkTokenValidity, loadExistingToken } from './authentication/store/authentication.action';
import { selectUser } from './authentication/store/user.selector';
import { NetworkService } from './network/service/network.service';
import { Subscription } from 'rxjs';
import { goOffline, goOnline } from './network/store/network.action';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { FabService } from './shared/service/fab.service';
import { SpeedDialFabComponent } from './shared/components/speed-dial-fab/speed-dial-fab.component';

@Component({
  selector: 'sibat-root',
  template: `
    <sibat-navigation-rail [user]="user$ | async">
      <sibat-network-status></sibat-network-status>
      <sibat-loading></sibat-loading>
      <router-outlet></router-outlet>
    </sibat-navigation-rail>
    <sibat-speed-dial-fab
      #sdFab
      *ngIf="fabOptions$ | async as fabOptions"
      [options]="fabOptions"
      [hidden]="fabHidden$ | async"
      [disabled]="fabDisabled$ | async"></sibat-speed-dial-fab>
  `,
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(SpeedDialFabComponent) private sdFab: SpeedDialFabComponent;

  user$ = this.store.select(selectUser);
  networkSubscription: Subscription;
  readonly fabOptions$ = this.fabService.options$;
  readonly fabDisabled$ = this.fabService.disabled$;
  readonly fabHidden$ = this.fabService.hidden$;

  private closeFabMenuSubscription: Subscription;

  constructor(
    private store: Store,
    private networkService: NetworkService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private fabService: FabService,
  ) {
    this.networkSubscription = this.networkService.isOnline$.subscribe(online => this.store.dispatch(online ? goOnline() : goOffline()));
    [
      'folder-no-files',
      'arrow-left',
      'arrow-right',
    ].forEach(icon =>
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/svg-icons/${icon}.svg`)
      )
    );
    this.closeFabMenuSubscription = this.fabService.closeMenu$.subscribe(() => this.sdFab.hideItems());
  }

  @HostListener('window:focus')
  onFocusChange() {
    this.store.dispatch(checkTokenValidity());
  }

  ngOnInit() {
    this.store.dispatch(loadExistingToken());
  }

  ngOnDestroy(): void {
    this.networkSubscription.unsubscribe();
    this.closeFabMenuSubscription.unsubscribe();
  }

}
