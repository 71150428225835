import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FeatureFlag } from '../model/feature-flags.model';

export const FEATURE_FLAGS_BASE_URI = `${environment.apiUrl}/feature-flags`;

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(private readonly http: HttpClient) {}

  getFeatureFlags(): Observable<FeatureFlag[]> {
    return this.http.get<FeatureFlag[]>(`${FEATURE_FLAGS_BASE_URI}`);
  }

  setFeatureFlagState(key: string, enabled: boolean): Observable<void> {
    const featureFlagToUpdate = {
      key,
      enabled,
    } as FeatureFlag;
    return this.http.post<void>(`${FEATURE_FLAGS_BASE_URI}`, featureFlagToUpdate);
  }
}
