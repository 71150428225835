import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) {}

  signIn(email: string, password: string): Observable<HttpResponse<void>> {
    return this.http.post<void>(`${this.baseUrl}/login`, { email, password }, { observe: 'response' });
  }

  changePassword(password: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/user/password`, { password });
  }

  forgotPassword(email: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/password/forgot`, { email });
  }

  resetPassword(password: string, token: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/password/reset`, { password, token });
  }
}
