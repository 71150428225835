import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DefectSummary } from 'src/app/control/model/control.model';
import { findNonCompliantDefectSummary } from 'src/app/control/store/control.action';
import { selectDefectSummary, selectDefectSummaryCount, selectDefectSummaryCurrentPage } from 'src/app/control/store/control.selector';

@Component({
  selector: 'sibat-non-compliant-defects-widget',
  templateUrl: 'non-compliant-defects-widget.component.html',
  styleUrls: ['non-compliant-defects-widget.component.scss'],
})
export class NonCompliantDefectsWidgetComponent implements OnInit {
  displayedColumns: string[] = ['address', 'deadline', 'controlDate'];

  dataSource$: Observable<DefectSummary[]> = this.store.select(selectDefectSummary);
  defectSummaryCount$ = this.store.select(selectDefectSummaryCount);
  defectSummaryCurrentPage$ = this.store.select(selectDefectSummaryCurrentPage);

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.loadDefectSummaryPage(0);
  }

  displayControlDefects(buildingId: number, controlId: number) {
    this.router.navigate([`/building/${buildingId}`], { queryParams: { compliance: controlId } });
  }

  onPageChange(event: PageEvent) {
    this.loadDefectSummaryPage(event.pageIndex);
  }

  private loadDefectSummaryPage(pageIndex: number) {
    this.store.dispatch(findNonCompliantDefectSummary({ page: pageIndex }));
  }
}
