import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { SibatFile } from '../model/sibat-file.model';
import { environment } from '../../../environments/environment';

export const FILE_BROWSER_BASE_URI = `${environment.apiUrl}/files`;

@Injectable({
  providedIn: 'root',
})
export class FileBrowserService {
  constructor(private readonly http: HttpClient) {
  }

  getFiles(caseId: number, relativePath: string): Observable<SibatFile[]> {
    const params = new HttpParams()
      .append('caseId', caseId)
      .append('path', relativePath);
    return this.http.get<SibatFile[]>(FILE_BROWSER_BASE_URI, {params});
  }

  renameFile(caseId: number, fromPath: string, toPath: string): Observable<HttpResponse<void>> {
    const isFolder = false;
    return this.http.post<void>(
      `${FILE_BROWSER_BASE_URI}:move`,
      {
        caseId,
        fromPath,
        toPath,
        isFolder,
      },
      { observe: 'response' }
    );
  }

  moveFile(caseId: number, fromPath: string, toPath: string, isFolder: boolean): Observable<HttpResponse<void>> {
    return this.http.post<void>(
      `${FILE_BROWSER_BASE_URI}:move`,
      {
        caseId,
        fromPath,
        toPath,
        isFolder,
      },
      { observe: 'response' }
    );
  }

  createFolder(caseId: number, parentPath: string, folderName: string): Observable<HttpResponse<void>> {
    const path = buildNestedPath(parentPath, folderName);
    return this.http.post<void>(
      `${FILE_BROWSER_BASE_URI}:createFolder`,
      {
        caseId,
        path,
      },
      { observe: 'response' }
    );
  }

  deleteFile(caseId: number, parentPath: string, fileName: string): Observable<HttpResponse<void>> {
    const params = new HttpParams()
      .append('caseId', caseId)
      .append('filePath', buildNestedPath(parentPath, fileName));
    return this.http.delete<void>(
      `${FILE_BROWSER_BASE_URI}`,
      { observe: 'response', params }
    );
  }

  downloadFile(caseId: number, path: string, filename: string): Observable<Blob> {
    const params = new HttpParams()
      .append('caseId', caseId)
      .append('filePath', buildNestedPath(path, filename));
    return this.http.get(
      `${FILE_BROWSER_BASE_URI}:download`,
      { headers: {}, responseType: 'blob', params,
    });
  }

  uploadFile(caseId: number, path: string, nestedPath: string, file: File, overwrite: boolean): Observable<HttpResponse<void>> {
    const multipartFile = new FormData();
    multipartFile.append('file', file);
    const params = new HttpParams()
      .append('caseId', caseId)
      .append('path', buildNestedPath(path, nestedPath))
      .append('overwrite', overwrite);
    return this.http.post<void>(
      `${FILE_BROWSER_BASE_URI}`,
      multipartFile,
      { observe: 'response', params });
  }

}

export const buildNestedPath = (folderPath: string, nestedPath: string): string =>
  [folderPath, nestedPath].filter(Boolean).join('/');
