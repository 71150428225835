import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { AnnouncementForm, ComplianceForm, MaintenanceContractForm, ReceptionControlForm } from '../model/technical-installations.model';
import { Message } from '../../model/notification.model';
import { TechnicalInstallation, InstallationCertificateForm } from '../model/technical-installations.model';

export const announceInstallation = createAction(
  '[ITECH] announce installation',
  props<{ buildingId: number; form: AnnouncementForm }>()
);
export const installationUpdateSuccess = createAction(
  '[ITECH] installation update success',
  props<{ buildingId: number; installation: TechnicalInstallation }>()
);
export const installationUpdateFailure = createAction(
  '[ITECH] installation update failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);
export const announceInstallationFailure = createAction(
  '[ITECH] announce installation failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);
export const resetActiveTechnicalInstallation = createAction('[ITECH] reset technical installation');
export const getInstallations = createAction(
  '[ITECH] fetch installations',
  props<{ buildingId: number }>()
);
export const getInstallationsSuccess = createAction(
  '[ITECH] fetch installations success',
  props<{ installations: TechnicalInstallation[] }>()
);
export const getInstallationsFailure = createAction(
  '[ITECH] fetch installations failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);
export const setActiveTechnicalInstallation = createAction(
  '[ITECH] set active installation',
  props<{ installation: TechnicalInstallation }>()
);
export const addInstallationCertificate = createAction(
  '[ITECH] add installation certificate',
  props<{ installationId: number; form: InstallationCertificateForm; buildingId: number; isNew: boolean }>()
);
export const addMaintenanceContract = createAction(
  '[ITECH] add maintenance contract',
  props<{ installationId: number; form: MaintenanceContractForm; buildingId: number; isNew: boolean }>()
);
export const cancelMaintenanceContract = createAction(
  '[ITECH] delete maintenance contract',
  props<{ installationId: number; buildingId: number }>()
);
export const addReceptionControlReport = createAction(
  '[ITECH] add reception-control report',
  props<{ installationId: number; form: ReceptionControlForm; buildingId: number; isNew: boolean }>()
);
export const addComplianceReport = createAction(
  '[ITECH] add compliance report',
  props<{ installationId: number; form: ComplianceForm; buildingId: number }>()
);
export const addPeriodicControlReport = createAction(
  '[ITECH] add periodic control report',
  props<{ installationId: number; form: any; buildingId: number; isNew: boolean }>()
);
export const addAssessmentReport = createAction(
  '[ITECH] add assessment report',
  props<{ installationId: number; form: any; buildingId: number; isNew: boolean }>()
);
export const shutdownInstallation = createAction(
  '[ITECH] shutdown installation',
  props<{ installationId: number; form: any; buildingId: number; isNew: boolean }>()
);
export const temporaryShutdownInstallation = createAction(
  '[ITECH] temporary shutdown installation',
  props<{ installationId: number; form: any; buildingId: number; isNew: boolean }>()
);
export const endTemporaryShutdownInstallation = createAction(
  '[ITECH] end temporary shutdown installation',
  props<{ installationId: number; form: any; buildingId: number; isNew: boolean }>()
);
export const updateNextControlDate = createAction(
  '[ITECH] update next control date',
  props<{ installationId: number; form: any; buildingId: number }>()
);
export const updateAnnouncement = createAction(
  '[ITECH] update installation announcement',
  props<{ installationId: number; form: any; buildingId: number }>()
);
