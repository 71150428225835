import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { notifyWarn } from 'src/app/model/notification.model';
import { TechnicalInstallationService } from '../service/technical-installation.service';
import { ITECH_UPDATE_ACTION } from './../model/technical-installations.model';
import {
  addAssessmentReport,
  addComplianceReport,
  addInstallationCertificate,
  addMaintenanceContract,
  addPeriodicControlReport,
  addReceptionControlReport,
  announceInstallation,
  announceInstallationFailure,
  cancelMaintenanceContract,
  endTemporaryShutdownInstallation,
  getInstallations,
  getInstallationsFailure,
  getInstallationsSuccess,
  installationUpdateFailure,
  installationUpdateSuccess,
  shutdownInstallation,
  temporaryShutdownInstallation,
  updateAnnouncement,
  updateNextControlDate,
} from './technical-installation.action';

@Injectable({
  providedIn: 'root',
})
export class TechnicalInstallationEffects {
  announceInstallationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(announceInstallation),
      switchMap(({ form, buildingId }) =>
        this.technicalInstallationService.announce(buildingId, form).pipe(
          map(installation => installationUpdateSuccess({ buildingId, installation })),
          catchError(error => of(announceInstallationFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  updateAnnouncementEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAnnouncement),
      switchMap(({ form, installationId, buildingId }) =>
        this.technicalInstallationService.updateAnnouncement(buildingId, installationId, form).pipe(
          map(installation => installationUpdateSuccess({ buildingId, installation })),
          catchError(error => of(announceInstallationFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  getInstallationsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInstallations, installationUpdateSuccess),
      switchMap(({ buildingId }) =>
        this.technicalInstallationService.getInstallations(buildingId).pipe(
          map(installations => getInstallationsSuccess({ installations })),
          catchError(error => of(getInstallationsFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  addInstallationCertificateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addInstallationCertificate),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.installationCertificate, isNew)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  addMaintenanceContractEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMaintenanceContract),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.maintenanceContract, isNew)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  addReceptionControlReportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addReceptionControlReport),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.receptionControlReport, isNew)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  addComplianceReportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addComplianceReport),
      switchMap(({ form, installationId, buildingId }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.complianceReport, true)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  addPeriodicControlReportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPeriodicControlReport),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.periodicControlReport, isNew)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  addAssessmentReportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAssessmentReport),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.assessmentReport, isNew)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  shutdownInstallationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(shutdownInstallation),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService.updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.shutdown, isNew).pipe(
          map(installation => installationUpdateSuccess({ buildingId, installation })),
          catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  temporaryShutdownInstallationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(temporaryShutdownInstallation),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.temporaryShutdown, isNew)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  endTemporaryShutdownInstallationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(endTemporaryShutdownInstallation),
      switchMap(({ form, installationId, buildingId, isNew }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.endTemporaryShutdown, isNew)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  cancelMaintenanceContractEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelMaintenanceContract),
      switchMap(({ installationId, buildingId }) =>
        this.technicalInstallationService
          .cancelMaintenanceContract(buildingId, installationId, ITECH_UPDATE_ACTION.cancelMaintenanceContract)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  updateNextControlDateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNextControlDate),
      switchMap(({ form, installationId, buildingId }) =>
        this.technicalInstallationService
          .updateInstallation(buildingId, installationId, form, ITECH_UPDATE_ACTION.nextControlDate, true)
          .pipe(
            map(installation => installationUpdateSuccess({ buildingId, installation })),
            catchError(error => of(installationUpdateFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
          )
      )
    )
  );

  constructor(private actions$: Actions, private technicalInstallationService: TechnicalInstallationService) {}
}
