import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Tag } from '../../model/tag.model';
import { addSelectedBuildings } from '../../store/buildings.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'sibat-dialog-tag-detail',
  template: `
    <button mat-icon-button class="close-button" (click)="closeDialog()" matTooltip="{{ 'layout.closeDialog' | transloco }}">
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <div class="dialog">
      <p>
        {{ tag.name }}
      </p>
      <div class="icon-text">
        <mat-icon>local_offer</mat-icon>
        <span>{{ 'building.tags.linked' | transloco: { tagsCount: tag.buildingIds.length } }}</span>
      </div>
      <div class="actions">
        <sibat-secondary-button (clicked)="removeTag()" [label]="'building.tags.remove' | transloco"> </sibat-secondary-button>
        <sibat-primary-button
          (clicked)="addBuildingsToSelection()"
          [label]="'building.tags.add' | transloco"
          [disabled]="!enableAddToSelection"
        >
        </sibat-primary-button>
      </div>
    </div>
  `,
  styleUrls: ['dialog-tag-detail.component.scss'],
})
export class DialogTagDetailComponent implements OnInit {
  enableAddToSelection = false;

  constructor(public dialogRef: MatDialogRef<DialogTagDetailComponent>, @Inject(MAT_DIALOG_DATA) public tag: Tag, private store: Store) {}

  ngOnInit(): void {
    this.enableAddToSelection = this.tag.buildingIds.length > 0;
  }

  addBuildingsToSelection() {
    this.store.dispatch(addSelectedBuildings({ buildingIds: this.tag.buildingIds }));
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  removeTag() {
    this.dialogRef.close(true);
  }
}
