<div
    #rootElement
    *ngIf="dataSource$ | async as documents"
    cdkDropListGroup>

  <input type="file" class="file-input" multiple (change)="handleUploadFile($event.target.files, documents)"
         #fileUpload />

  <div class="table-toolbar">
    <div class="breadcrumb">
      <ng-container *ngFor="let crumbName of crumbs$ | async as crumbs; index as index; first as isFirst; last as isLast">
        <mat-icon class="separator" *ngIf="!isFirst">chevron_right</mat-icon>
        <div
            class="crumb is-folder"
            [class.is-first]="isFirst"
            [class.is-last]="isLast"
            cdkDropList
            [cdkDropListData]="{index, crumbs}"
            (cdkDropListDropped)="droppedOnCrumb($event)"
            (click)="!isLast && onBreadcrumbClick(index, crumbs)">
          <ng-container *ngIf="isFirst"><mat-icon>home</mat-icon>HOME</ng-container>
          <ng-container *ngIf="!isFirst">{{ crumbName | uppercase }}</ng-container>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="isECABOrAbove$ | async">
      <sibat-link-button *ngIf="dynamicUrl$ | async as sharePointUrl"
                         data-testid="sharepointUrl"
                         [href]="sharePointUrl"
                         target="_blank"
                         [external]="true"
                         label="SharePoint"
      ></sibat-link-button>
    </ng-container>
  </div>

  <div *ngFor="let error of errors" class="error-msg" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>error</mat-icon>
    <p [ngSwitch]="error.code">
      <span *ngSwitchCase="errorCodes.maxSizeExceeded"> {{ error.fileName }}: {{ 'building.documents.maxSizeExceeded' |
          transloco }} </span>
    </p>
  </div>

  <div
      class="root-drop-zone"
      sibatDragNDrop
      (fileDropped)="handleUploadFile($event, undefined)">
    <table mat-table [dataSource]="documents" class="document-table">
      <ng-container matColumnDef="node">
        <th mat-header-cell *matHeaderCellDef class="table-header">
          <button mat-icon-button disabled>
            <mat-icon>attach_file</mat-icon>
          </button>
          <span class="name-column-header">{{ 'building.documents.nameColumn' | transloco }}</span>
        </th>
        <td
            mat-cell
            *matCellDef="let element"
            sibatDragNDrop
            [applyHover]="element.isFolder"
            [ngClass]="{ 'is-file': !element.isFolder, 'is-folder': element.isFolder  }"
            cdkDropList [cdkDropListData]="element" [cdkDropListSortingDisabled]="true" (cdkDropListDropped)="droppedOnFolder($event)"
            (fileDropped)="handleUploadFile($event, element)">
          <div class="content" cdkDrag [cdkDragData]="element" [cdkDragBoundary]="rootElement" (click)="onNodeClick(element)">
            <button mat-icon-button>
              <mat-icon *ngIf="element.isFolder && !element.isEmpty">folder</mat-icon>
              <mat-icon *ngIf="element.isFolder && element.isEmpty" class="empty-folder" svgIcon="folder-no-files">
              </mat-icon>
              <mat-icon *ngIf="!element.isFolder">cloud_download</mat-icon>
            </button>
            <button mat-button class="navigation-button">{{ element.name }}</button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actions-header">
          <mat-icon (click)="createFolder()">
            create_new_folder</mat-icon>
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngIf="element.isFolder; else filesMenu">
              <button mat-menu-item>
                <mat-icon>add</mat-icon>
                <span>{{ 'building.documents.addFile' | transloco }}</span>
                <input class="add-file" type="file" multiple
                       (change)="handleUploadFile($event.target.files, element)" />
              </button>
              <button mat-menu-item (click)="removeFile(element)">
                <mat-icon>delete</mat-icon>
                <span>{{ 'building.documents.delete' | transloco }}</span>
              </button>
            </ng-container>
            <ng-template #filesMenu>
              <button mat-menu-item (click)="downloadFile(element.name)">
                <mat-icon>cloud_download</mat-icon>
                <span>{{ 'building.documents.download' | transloco }}</span>
              </button>
              <ng-container *ngIf="isECABOrAbove$ | async">
                <button mat-menu-item (click)="removeFile(element)">
                  <mat-icon>delete</mat-icon>
                  <span>{{ 'building.documents.delete' | transloco }}</span>
                </button>
                <button mat-menu-item (click)="renameFile(element)">
                  <mat-icon>edit</mat-icon>
                  <span>{{ 'building.documents.rename' | transloco }}</span>
                </button>
              </ng-container>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3" sibatDragNDrop (fileDropped)="handleUploadFile($event, undefined)">
          <mat-icon class="folder-off-icon">folder_off</mat-icon>
        </td>
      </tr>
    </table>
    <ng-container *ngIf="isControlCase() || (isECABOrAbove$ | async)">
      <div class="drop-zone-bottom">
        <div class="drop-zone" sibatDragNDrop (fileDropped)="handleUploadFile($event)" (click)="fileUpload.click()">
          <mat-icon>upload</mat-icon>  {{ 'building.documents.addFile' | transloco }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
