import { environment } from 'src/environments/environment';
export const MESSAGE_LEVEL = {
  info: 'info',
  warning: 'warning',
} as const;
export type MessageLevel = typeof MESSAGE_LEVEL[keyof typeof MESSAGE_LEVEL];

export const DEFAULT_TRANSLATION_CONFIG = {
  supportLink: environment.support.supportLink,
};

export interface Message {
  label: string;
  level: MessageLevel;
  translate: boolean;
  translationConfig?: any;
}

export interface NotificationProperty {
  notification: Message;
}

const notify = (message: string, translate = false, level: MessageLevel = 'info', translationConfig?: any): NotificationProperty => ({
  notification: {
    label: message,
    level,
    translate,
    translationConfig,
  }
});

export const notifyInfo = (
  message: string,
  translate = false,
  translationConfig: any = DEFAULT_TRANSLATION_CONFIG,
) => notify(message, translate, MESSAGE_LEVEL.info, translationConfig);

export const notifyWarn = (
  message: string,
  translate = false,
  translationConfig: any = DEFAULT_TRANSLATION_CONFIG,
) => notify(message, translate, MESSAGE_LEVEL.warning, translationConfig);

export const hasNotification = <T extends object>(
  object: T
): object is T & NotificationProperty => object.hasOwnProperty('notification');
