<div class="document-info">
  <mat-form-field>
    <mat-label>{{ 'building.documents.documentName' | transloco }}</mat-label>
    <input matInput type="text" [(ngModel)]="fileName" (keyup.enter)="validate()">
  </mat-form-field>
  <span>{{extension}}</span>
</div>
<mat-dialog-actions align="end">
  <sibat-validate-button class="mat-button-base" (clicked)="validate()" [label]="'building.documents.validate' | transloco">
  </sibat-validate-button>
  <sibat-secondary-button class="mat-button-base" (clicked)="cancel()" [label]="'building.documents.cancel' | transloco">
  </sibat-secondary-button>
</mat-dialog-actions>
