import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { merge, Observable, of, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { renameFile } from 'src/app/shared/files';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { notifyWarn } from '../../../model/notification.model';
import { notifyMessageError } from '../../../notification/store/notification.action';
import { createDefaultFileName, uncompletedValidator } from '../../model/technical-installations-fields.model';
import {
  AnnouncementForm,
  CEA_CONNECTED,
  InstallationType,
  INSTALLATION_NECESSITY,
  INSTALLATION_TYPES,
  MONITORING_SCOPE_TYPES,
  TechnicalInstallation,
} from '../../model/technical-installations.model';

@Component({
  selector: 'sibat-announcement-form-dialog',
  templateUrl: './announcement-form-dialog.component.html',
  styleUrls: ['../form-dialog.style.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        display: 'none',
      })),
      transition('closed => open', [
        animate('.4s', keyframes([
          style({ opacity: 0, display: 'block', offset: 0 }),
          style({ opacity: 1, offset: 1 }),
        ]))
      ]),
    ]),
  ],
})
export class AnnouncementFormDialogComponent implements OnDestroy {
  @ViewChild('transmitterContainer') private transmitterContainer: ElementRef<HTMLDivElement>;

  projectValidators = [
    ['Swiss Safety Center AG', 'Swiss Safety Center AG'],
  ];

  installationTypes = Object.values(INSTALLATION_TYPES);
  necessities = Object.values(INSTALLATION_NECESSITY);
  monitorings = Object.values(MONITORING_SCOPE_TYPES);
  ceaConnected: UntypedFormControl;
  installationType?: UntypedFormControl;
  fileFormControl?: UntypedFormControl;
  temporaryShutdownEndDateFormControl?: UntypedFormControl;
  announcementForm: UntypedFormGroup;
  isUncompleted$: Observable<boolean>;
  hasRequiredError$: Observable<boolean>;

  readonly editionMode: boolean = false;
  readonly ceaConnectedValues = CEA_CONNECTED;
  readonly today = new Date();

  private defaultInstallationType = INSTALLATION_TYPES.fireDetection;
  private ceaSubscription: Subscription;
  private installationTypeSubscription?: Subscription;
  private file?: File;
  private formControls: UntypedFormControl[] = [];

  constructor(
    private dialogRef: MatDialogRef<AnnouncementFormDialogComponent, AnnouncementForm>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private data: { defaultValue?: TechnicalInstallation },
    private dialogService: DialogService,
  ) {
    const defaultValue = this.data.defaultValue;
    this.editionMode = !!defaultValue;
    this.ceaConnected = new UntypedFormControl(defaultValue?.ceaConnected, [Validators.required]);
    const maybeRequiredValidator = this.editionMode ? [uncompletedValidator] : [Validators.required];

    this.announcementForm = new UntypedFormGroup(
      {
        planner: new UntypedFormControl(defaultValue?.planner, maybeRequiredValidator),
        installer: new UntypedFormControl(defaultValue?.installer, maybeRequiredValidator),
        validator: new UntypedFormControl('Swiss Safety Center AG', maybeRequiredValidator),
        necessity: new UntypedFormControl(defaultValue?.necessity, [Validators.required]),
        ceaConnected: this.ceaConnected,
        transmitter: new UntypedFormControl(defaultValue?.transmitter, [(control) => {
          if (this.ceaConnected.value === CEA_CONNECTED.yes && !control.value) {
            return uncompletedValidator(control);
          }

          return null;
        }]),
        monitoringScope:  new UntypedFormControl(defaultValue?.monitoringScope),
        designation: new UntypedFormControl(defaultValue?.designation),
      }
    );

    if (!this.editionMode) {
      this.fileFormControl = new UntypedFormControl(null, [
        () => {
          if (!this.file) {
            return { required: true };
          }
          return null;
        },
        Validators.required,
      ]);
      this.installationType = new UntypedFormControl(this.defaultInstallationType, [Validators.required]);
      this.announcementForm.addControl('file', this.fileFormControl);
      this.announcementForm.addControl('installationType', this.installationType);

      this.installationTypeSubscription = this.installationType.valueChanges.subscribe(value => {
        const currentFileName = this.fileFormControl?.value;
        if (value && currentFileName) {
          this.updateDefaultFileName(currentFileName, value);
        }
      });
    }

    if (this.editionMode && defaultValue?.temporaryShutdownEndDate !== null) {
      this.temporaryShutdownEndDateFormControl = new UntypedFormControl(defaultValue?.temporaryShutdownEndDate, [Validators.required]);
      this.announcementForm.addControl('temporaryShutdownEndDate', this.temporaryShutdownEndDateFormControl);
    }

    this.initErrorObservers();

    this.ceaSubscription = this.ceaConnected.valueChanges.pipe(delay(50)).subscribe(value => {
      if (this.isCeaConnected(value)) {
        this.transmitterContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
        this.announcementForm.get('transmitter')?.updateValueAndValidity();
      } else {
        this.announcementForm.get('transmitter')?.reset();
      }
    });
  }

  uploadFileIfNecessary(sourceElement: HTMLInputElement, fileInput: HTMLInputElement) {
    if (!(sourceElement.value && this.file) && sourceElement.dataset['selectFile'] === undefined) {
      sourceElement.dataset['selectFile'] = 'true';
      fileInput.click();
    } else {
      delete sourceElement.dataset['selectFile'];
    }
  }

  updateFile(file: File) {
    this.file = file;
    this.updateDefaultFileName(file.name, this.installationType?.value || this.defaultInstallationType);
  }

  ngOnDestroy(): void {
    this.ceaSubscription.unsubscribe();
    this.installationTypeSubscription?.unsubscribe();
  }

  async validate() {
    if (this.hasRequiredError()) {
      return;
    }

    if (this.isUncompleted()) {
      const confirmed = await this.dialogService.requestConfirmation({
        translationKey: 'building.technicalInstallation.confirmFormValidation',
      });

      if (!confirmed) {
        return;
      }
    }

    let formValue = this.announcementForm.value;

    if (this.fileFormControl) {
      const file = this.file as File;
      const newFileName = this.fileFormControl.value;
      const renamedFile = renameFile(file, newFileName);

      formValue = {
        ...this.announcementForm.value,
        file: renamedFile,
      };
    }

    if (this.temporaryShutdownEndDateFormControl) {
      formValue.temporaryShutdownEndDate = formValue.temporaryShutdownEndDate.toISOString();
    }

    this.dialogRef.close(formValue);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  fileDropped(fileList: FileList) {
    const file = fileList.item(0);
    if (fileList.length !== 1 || !file) {
      this.store.dispatch(notifyMessageError({ ...notifyWarn('common.notification.oneFileRequired', true) }));
    } else {
      this.updateFile(file);
    }
  }

  private updateDefaultFileName(fileName: string, installationType: InstallationType) {
    const nameParts = fileName.split('.');
    const fileExtension = nameParts[nameParts.length - 1 || 1];
    const newName = createDefaultFileName('annonce', installationType) + (fileExtension ? `.${fileExtension}` : '');

    this.fileFormControl?.setValue(newName);
  }

  private isCeaConnected(value: string): boolean {
    return value === CEA_CONNECTED.yes;
  }

  private initErrorObservers() {
    // initialEvent is needed to trigger the first check
    const initialEvent = of('initial_state_check');
    Object.values(this.announcementForm.controls).forEach(control => {
      if (control instanceof UntypedFormControl) {
        this.formControls.push(control);
      }
    });
    this.isUncompleted$ = merge(...this.formControls.map(control => control.statusChanges), initialEvent).pipe(
      map(() => this.isUncompleted())
    );
    this.hasRequiredError$ = merge(...this.formControls.map(control => control.statusChanges), initialEvent).pipe(
      map(() => this.hasRequiredError())
    );
  }

  private isUncompleted(): boolean {
    return this.formControls.some(control => control.hasError('uncompleted'));
  }

  private hasRequiredError(): boolean {
    return  this.formControls.some(control => control.hasError('required'));
  }
}
