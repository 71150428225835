import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sibat-tertiary-button',
  template: `
    <a class="button-link" role="button" (click)="clicked.emit($event)">
      <span>{{ label }}</span>
    </a>
  `,
  styleUrls: ['buttons.scss'],
})
export class TertiaryButtonComponent {
  @Input() label: string;
  @Output() clicked = new EventEmitter();

  constructor() {}
}
