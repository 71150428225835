import { DefectType } from './defect-type.model';
import { APPROVAL_STATE } from './approval-state.model';
import { User } from '../../authentication/model/user.model';
import { CONTROL_STATUS, ControlStatus } from '../../building/model/history.model';

export const CONTROLS_FOLDER_NAME = '04_Controles';

export interface Control {
  id: number;
  date: string;
  user: User;
  status: ControlStatus;
  landlordOrOperator: string | null;
  nextDate: string | null;
  remarks: string | null;
  peoplePresent: string | null;
  defects: DefectDto[] | null;
  approvalRequestDate?: string;
  approvalDate?: string;
  designation?: string;
}

export interface ControlSummary {
  id: number;
  date: string;
  designation: string | null;
  nextDate: string | null;
}

export const mapToControlSummary = (control: Control): ControlSummary => ({
  id: control.id,
  date: control.date,
  designation: control.designation ?? null,
  nextDate: control.nextDate,
});

export interface ControlDetails {
  landlordOrOperator: string;
  date: string;
  nextDate: string;
  remarks: string;
  peoplePresent: string;
  designation?: string;
}

export interface ControlReportInfo {
  fileName: string;
  relativePath: string;
  buildingId: number;
}

export interface DefectPictureInfo {
  controlId: number;
  pictureName: string;
}

export interface AddDefect {
  id?: number;
  description: string;
  pictures: FileWithPreviewBase64[];
  deadline?: Date;
  type?: DefectType;
  location?: string;
}

export interface EditDefect {
  id?: number;
  description: string;
  existingPictures: EditPictureBase64[];
  picturesToRemove: string[];
  picturesToAdd: FileWithPreviewBase64[];
  deadline?: Date;
  type?: DefectType;
  location?: string;
}

export interface StoredOfflineEditDefect extends EditDefect {
  id: number;
}

export interface EditPictureBase64 {
  name: string;
  file?: FileWithPreviewBase64;
}

export interface FileWithPreviewBase64 {
  data: string;
  fileName: string;
  preview?: string;
}

export interface DefectSummary {
  id: number;
  controlId: number;
  buildingId: number;
  controlDate: string | null;
  deadline: string | null;
  addresses: string[] | null;
  municipality: string | null;
}
export interface DefectDto {
  id: number;
  pictures: string[];
  description: string;
  deadline?: string;
  type?: DefectType;
  location?: string;
  sortingOrder?: number;
  compliance?: DefectCompliance;
}

export interface DefectToRemove {
  buildingId: number;
  defectId: number;
}

export type AbstractControlDetail = {
  [P in keyof ControlDetails]: any;
};

export interface DefectThumbnail {
  pictureFileName: string;
  thumbnail: string;
}

export interface DefectPicture {
  pictureFileName: string;
  pictureData: string;
}

export interface ControlDefects {
  controlId: number;
  defects: DefectDto[];
  selectedDefects: DefectDto[];
}

export interface DefectCompliance {
  defectId: number;
  date: string;
  description: string;
  proofs: string[];
}

export interface AddDefectCompliance {
  controlId: number;
  defectIds: number[];
  date: Date;
  description: string;
  files: File[];
}

export interface ControlsStats {
  count: number;
  color: string;
  status: string;
}

export const canControlBeValidated = (control: Control, nextControlDateRequired: boolean): boolean =>
  Boolean(control.date) &&
  (!nextControlDateRequired || !!control.nextDate) &&
  Boolean(control.landlordOrOperator) &&
  areDefectsComplete(control);

export const newEditDefect = (): EditDefect => ({
  description: '',
  existingPictures: [],
  picturesToAdd: [],
  picturesToRemove: [],
});

export const mapToEditDefect = (defect: DefectDto): EditDefect => ({
  id: defect.id,
  description: defect.description,
  deadline: defect.deadline ? new Date(defect.deadline) : undefined,
  type: defect.type,
  location: defect.location,
  existingPictures: defect.pictures.map(pic => ({ name: pic })),
  picturesToAdd: [],
  picturesToRemove: [],
});

export const areDefectsComplete = (control: Control): boolean => {
  if (!control.defects) {
    return true;
  }
  return control.defects.every(d => d.type && d.location);
};

export const approvalStatus = (control: Control, isSCPI: boolean) => {
  if (isCompleted(control)) {
    return APPROVAL_STATE.controlValidated;
  }

  if (isSCPI) {
    if (needsApproval(control)) {
      return APPROVAL_STATE.scpiRequireExpertApproval;
    }

    if (isAwaitingForApproval(control)) {
      return APPROVAL_STATE.scpiAwaitingExpertApproval;
    }

    if (isApproved(control)) {
      return APPROVAL_STATE.expertHasApprovedControl;
    }
  }

  if (!isSCPI) {
    if (needsApproval(control)) {
      return APPROVAL_STATE.canBeValidated;
    }

    if (isAwaitingForApproval(control)) {
      return APPROVAL_STATE.expertShouldApproveControl;
    }

    if (isApproved(control)) {
      return APPROVAL_STATE.canBeValidated;
    }
  }
};

export const computeNextControlDate = (control: Control, controlInterval: number | undefined): Date | undefined => {
  if (control.nextDate) {
    return new Date(control.nextDate);
  }
  if (controlInterval) {
    const currentControlDate = new Date(control.date);
    const nextControlDate = new Date(currentControlDate.valueOf());
    nextControlDate.setFullYear(currentControlDate.getFullYear() + controlInterval);
    return nextControlDate;
  } else {
    return undefined;
  }
};

export const needsApproval = (control: Control): boolean => !control.approvalRequestDate && !control.approvalDate;

export const isAwaitingForApproval = (control: Control): boolean => !!control.approvalRequestDate && !control.approvalDate;

export const isApproved = (control: Control): boolean => !!control.approvalRequestDate && !!control.approvalDate;

export const isCompleted = (control: Control): boolean => control.status === CONTROL_STATUS.completed;

export const isControlEmpty = (control: Control): boolean =>
  (!control.defects || control.defects.length === 0) &&
  !control.designation &&
  !control.landlordOrOperator &&
  !control.peoplePresent &&
  !control.remarks;
