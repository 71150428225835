import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { deleteBuilding } from '../store/building.action';
import { Subscription } from 'rxjs';
import { selectDeleteInfos } from '../store/building.selector';

@Component({
  selector: 'sibat-building-remove',
  templateUrl: './building-remove.component.html',
  styles: [
  ]
})
export class BuildingRemoveComponent implements OnInit, OnDestroy {
  readonly buildingIdSubscription = this.route.params.subscribe(params => {
    this.buildingId = params.buildingId;
    this.store.dispatch(deleteBuilding({buildingId: this.buildingId}));
  });
  public deleteMsg: string;
  public success: boolean;
  private buildingId: number;
  private confirmMsgSubscription: Subscription;

  constructor(private store: Store, private route: ActivatedRoute) {
  }


  ngOnInit(): void {
    this.confirmMsgSubscription = this.store.select(selectDeleteInfos).subscribe(deleteInfos => {
      this.success = deleteInfos.success;
      this.deleteMsg = deleteInfos.msg;
    });

  }

  ngOnDestroy(): void {
    this.buildingIdSubscription.unsubscribe();
    this.confirmMsgSubscription.unsubscribe();
  }

}
