import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sibat-full-screen-picture',
  template: `
    <div class="full-screen-elem">
      <button mat-icon-button class="close-button" (click)="closeDialog()" matTooltip="{{ 'layout.closeDialog' | transloco }}">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
      </button>
      <img [src]="pictureData | safeUrl" alt="" />
    </div>
  `,
  styleUrls: ['full-screen-picture.component.scss'],
})
export class FullScreenPictureComponent implements OnInit {
  constructor(
    @Optional() public dialogRef: MatDialogRef<FullScreenPictureComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public pictureData: string
  ) {}

  ngOnInit(): void {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
