<ng-container *ngIf="(loading$ | async) === false; else loadingPreview">
  <ng-container *ngIf="building$ | async as building; else noData">
    <div nav-header class="building-header">
      <div class="building-header-content">
        <div class="switcher">
          <sibat-selector [buildingId]="building.id" [count]="selectedBuildingsCount"
                          (prevClicked)="navigateToBuilding($event, -1)"
                          (nextClicked)="navigateToBuilding($event, +1)">
          </sibat-selector>
        </div>
        <div class="address">
          <sibat-building-address [building]="building"></sibat-building-address>
        </div>
        <div class="status">
          <span class="status-value">
            <span [ngSwitch]="building.status">
              <span *ngSwitchCase="buildingStatus.constructed" class="subline">
                <mat-icon class="status-icon">engineering</mat-icon>
                {{ 'building.status.constructed' | transloco }}
              </span>
              <span *ngSwitchCase="buildingStatus.exploitation" class="subline">
                <mat-icon class="status-icon">domain</mat-icon>
                {{ 'building.status.exploitation' | transloco }}
              </span>
              <span *ngSwitchCase="buildingStatus.project" class="subline">
                <mat-icon class="status-icon">assignment</mat-icon>
                {{ 'building.status.project' | transloco }}
              </span>
              <span *ngSwitchCase="buildingStatus.underConstruction" class="subline">
                <mat-icon class="status-icon">construction</mat-icon>
                {{ 'building.status.underConstruction' | transloco }}
              </span>
              <span *ngSwitchCase="buildingStatus.demolished" class="subline">
                <mat-icon class="status-icon">domain_disabled</mat-icon>
                {{ 'building.status.demolished' | transloco }}
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
    <sibat-side-nav class="align-side-nav" *transloco="let t; read: 'building'">
      <mat-nav-list nav-list>
        <a mat-list-item
           [routerLink]="['.']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">
          <mat-icon mat-list-icon>info</mat-icon>
          <div mat-line>{{ t('overview.title') }}</div>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item *ngIf="(canBrowseDocuments$ | async) && authorizedForBuilding && (isCECB$ | async) === false"
           [routerLink]="['documents']" routerLinkActive="active-link">
          <mat-icon mat-list-icon>folder</mat-icon>
          <div mat-line>{{ t('documents.title') }}</div>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item *ngIf="(isCECB$ | async) === false"
           [routerLink]="['history']" routerLinkActive="active-link">
          <mat-icon mat-list-icon>restore</mat-icon>
          <div mat-line>{{ t('history.title') }}</div>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item *ngIf="(isECABOrAbove$ | async) && (isItechEnabled$ | async)"
           [disabled]="((itechs$ | async)?.length ?? 0) === 0"
           [routerLink]="['itech']" routerLinkActive="active-link">
          <mat-icon mat-list-icon>content_paste</mat-icon>
          <div mat-line>{{ t('technicalInstallation.tabTitle') }}</div>
          <mat-icon>chevron_right</mat-icon>
        </a>
        <a mat-list-item *ngIf="isECABOrAbove$ | async"
           [disabled]="((cases$ | async)?.length ?? 0) === 0"
           [routerLink]="['cases']" routerLinkActive="active-link">
          <mat-icon mat-list-icon>hexagon</mat-icon>
          <div mat-line>{{ t('cases.casesInProgress') }}</div>
          <mat-icon>chevron_right</mat-icon>
        </a>
      </mat-nav-list>
    </sibat-side-nav>
  </ng-container>
</ng-container>

<ng-template #noData>
  <sibat-alert data-testid="sibat-no-building" type="warning">{{ 'building.notFound' | transloco }}</sibat-alert>
</ng-template>
<ng-template #loadingPreview></ng-template>
