import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DialogConfirmationComponent } from 'src/app/shared/components/dialog-confirmation/dialog-confirmation.component';
import { createTextTranslation, isTextLiteral, TextLabel } from '../localization';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog, private translocoService: TranslocoService) {}

  requestConfirmation(
    message: TextLabel,
    accept: TextLabel = createTextTranslation('common.yes'),
    cancel: TextLabel = createTextTranslation('common.no')
  ): Promise<boolean> {
    const acceptLabel = this.toTextLiteral(accept);
    const cancelLabel = this.toTextLiteral(cancel);
    const messageLabel = this.toTextLiteral(message);

    return this.openRequestConfirmation(messageLabel, acceptLabel, cancelLabel);
  }

  openDialogComponent<T>(component: ComponentType<any>, data: any = {}, panelClass?: string) {
    return this.dialog
      .open(component, {
        panelClass,
        autoFocus: false,
        data,
      })
      .afterClosed() as Observable<T | undefined>;
  }

  openDialogComponentAsync<T>(component: ComponentType<any>, data: any = {}, panelClass?: string) {
    return firstValueFrom(this.openDialogComponent<T>(component, data, panelClass));
  }

  private openRequestConfirmation(message: string, acceptLabel: string, cancelLabel: string): Promise<boolean> {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      data: { message, acceptLabel, cancelLabel },
      autoFocus: false,
    });
    return firstValueFrom(dialogRef
      .afterClosed()
      .pipe(map(value => !!value)));
  }

  private toTextLiteral(text: TextLabel): string {
    if (isTextLiteral(text)) {
      return text;
    } else {
      return this.translocoService.translate(text.translationKey);
    }
  }
}
