import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AddDefectCompliance, DefectDto } from 'src/app/control/model/control.model';
import { addDefectCompliance } from 'src/app/control/store/control.action';
import { extendTextArea } from 'src/app/shared/style';

@Component({
  selector: 'sibat-control-defect-compliance-form',
  templateUrl: 'control-defect-compliance-form.component.html',
  styleUrls: ['control-defect-compliance-form.component.scss'],
})
export class ControlDefectComplianceFormComponent {
  @Input() controlId: number;
  @Input() defects: DefectDto[];
  @Input() thumbnails: Record<string, string>;

  complianceForm = this.formBuilder.group(
    {
      files: [[]],
      description: '',
      date: new Date(),
    },
    { validators: this.checkProofsValidator }
  );

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) {}

  checkProofsValidator(group: UntypedFormGroup) {
    const description: string = group.controls.description.value;
    const files: File[] = group.controls.files.value;
    return files.length > 0 || !!description.length ? null : { missing: true };
  }

  addFiles(fileList: FileList): void {
    const selectedFiles: File[] = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < fileList.length; i++) {
      selectedFiles.push(fileList[i]);
    }

    const currentFiles = this.complianceForm.get('files')?.value as File[];
    this.complianceForm.patchValue({
      files: [...currentFiles, ...selectedFiles],
    });
  }

  removeFile(fileToRemove: File): void {
    const currentFiles = this.complianceForm.get('files')?.value as File[];
    this.complianceForm.patchValue({
      files: currentFiles.filter(file => file !== fileToRemove),
    });
  }

  extendTextArea(element) {
    extendTextArea(element);
  }

  saveDefectCompliance() {
    if (!this.complianceForm.valid) {
      return;
    }
    const defectIds: number[] = this.defects.map(defect => defect.id);
    const form: AddDefectCompliance = {
      controlId: this.controlId,
      defectIds,
      date: this.complianceForm.get('date')?.value,
      description: this.complianceForm.get('description')?.value,
      files: this.complianceForm.get('files')?.value,
    };
    this.store.dispatch(addDefectCompliance({ form }));
  }
}
