<form>
  <mat-form-field>
    <mat-label>{{ 'building.list.setTag' | transloco }}</mat-label>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let tag of filteredTags$ | async" [value]="tag">
        {{ tag }}
      </mat-option>
    </mat-autocomplete>
    <input
      type="text"
      matInput
      #tag
      data-testid="addTagInput"
      maxlength="60"
      placeholder="{{ 'building.list.tagExample' | transloco }}"
      (keydown.enter)="addTag()"
      (keyup.escape)="onEscape()"
      [formControl]="tagFormControl"
      [matAutocomplete]="auto"
    />
    <mat-hint align="end">{{ tag.value.length }} / 60</mat-hint>
  </mat-form-field>
</form>
