import { DefectDto, EditDefect, mapToEditDefect } from './../../control/model/control.model';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { toggleControlDefectSelection } from 'src/app/control/store/control.action';
@Component({
  selector: 'sibat-control-defect-item',
  templateUrl: './control-defect-item.component.html',
  styleUrls: ['./control-defect-item.component.scss'],
})
export class ControlDefectItemComponent {
  @Input() controlId: number;
  @Input() thumbnails: Record<string, string> = {};
  @Input() defectDto: DefectDto;
  @Input() selected = false;

  isOpen = false;
  editDefect?: EditDefect = undefined;

  constructor(private store: Store) {}

  @Input() set defect(value: DefectDto) {
    this.defectDto = value;
    this.editDefect = value ? mapToEditDefect(value) : undefined;
  }

  toggleDetails() {
    this.isOpen = !this.isOpen;
  }

  onCheckboxChange() {
    this.store.dispatch(toggleControlDefectSelection({ defect: this.defectDto }));
  }
}
