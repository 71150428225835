import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { changeUserStatus, fetchAllUsers } from '../../../admin/store/admin.action';
import { selectAllUsers } from '../../../admin/store/admin.selector';
import { User } from '../../../authentication/model/user.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserAdminService } from '../../../admin/service/user-admin.service';
import { ContextValues } from '../../../admin/model/context-values.model';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'sibat-list-users',
  template: `
    <div class="list-users-wrapper">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 single-action-list">
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.lastName' | transloco }}</th>
          <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.firstName' | transloco }}</th>
          <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.email' | transloco }}</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.role' | transloco }}</th>
          <td mat-cell *matCellDef="let element">{{ 'cockpit.userRole' | transloco: { role: element.role } }}</td>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.phoneNumber' | transloco }}</th>
          <td mat-cell *matCellDef="let element">{{ element.phoneNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="accreditations">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.accreditations' | transloco }}</th>
          <td mat-cell *matCellDef="let element">
            <p>{{ element.roleContextValues | roleContextValues: (rolesContextSCPIValues$ | async) }}</p>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th class="last-row" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.enabled' | transloco }}</th>
          <td class="last-row" mat-cell *matCellDef="let element">
            <sibat-toggle-button
              (toggle)="toggleUserStatus($event, element)"
              [checked]="element.enabled"
              (click)="$event.stopPropagation()"
            >
            </sibat-toggle-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="editUser(row)"></tr>
      </table>
    </div>
  `,
  styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['lastName', 'firstName', 'email', 'role', 'phoneNumber', 'accreditations', 'status'];
  dataSource: MatTableDataSource<User>;
  users$: Observable<User[]> = this.store.select(selectAllUsers);
  rolesContextSCPIValues$: Observable<ContextValues[]> = this.userAdminService
    .fetchContextValues('role_scpi')
    .pipe(shareReplay(1));

  constructor(private store: Store, private router: Router, private userAdminService: UserAdminService) {}

  ngOnInit(): void {
    this.store.dispatch(fetchAllUsers());
    this.users$.subscribe(users => {
      this.dataSource = new MatTableDataSource<User>(users);
      this.dataSource.sort = this.sort;
    });
  }

  toggleUserStatus(enabled: boolean, user: User): void {
    this.store.dispatch(changeUserStatus({ userId: user.id, enabled }));
  }

  editUser(user: User): void {
    this.router.navigateByUrl(`/cockpit/admin/users/edit/${user.id}`);
  }
}
