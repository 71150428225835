import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CasePreview } from 'src/app/case/model/case.model';

@Injectable({
  providedIn: 'root',
})
export class BuildingCaseService {
  constructor(private readonly http: HttpClient) {}

  getBuildingCases(buildingId: number): Observable<CasePreview[]> {
    return this.http.get<CasePreview[]>(`${environment.apiUrl}/buildings/${buildingId}/cases`);
  }
}
