<div class="item" [class.selected]="selected">
  <div class="content">
    <div class="address-info">
      <div class="icons">
        <sibat-typology-indicator [color]="color"></sibat-typology-indicator>
      </div>
      <div class="address">
        <h3 *ngIf="building.addresses?.length > 0; else noAddress">{{ building.addresses[0] }}</h3>
        <ng-template #noAddress><h3>{{ 'building.addressNotAvailable' | transloco }}</h3></ng-template>
      </div>
    </div>

    <div *ngIf="attributesDisplay['municipality']" class="inline-info">
      <div class="icons">
        <mat-icon class="icon-info">account_balance</mat-icon>
      </div>
      <p class="subline subline-truncated">{{ building.municipality }}</p>
    </div>

    <div class="inline-info">
      <div class="icons">
        <mat-icon class="icon-info">corporate_fare</mat-icon>
      </div>
      <p *ngIf="building.localizedAffectations?.length" class="subline subline-truncated">
        {{ building.localizedAffectations | join }}
      </p>
      <p *ngIf="!building.localizedAffectations?.length" class="subline">{{ 'building.affectationToDefine' | transloco }}</p>
    </div>

    <div *ngIf="attributesDisplay['buildingClass']" class="inline-info">
      <div class="icons">
        <mat-icon class="icon-info">business</mat-icon>
      </div>
      <p class="subline subline-truncated">{{ building.buildingClass }}</p>
    </div>

    <div *ngIf="attributesDisplay['constructionPeriod']" class="inline-info">
      <div class="icons">
        <mat-icon class="icon-info">date_range</mat-icon>
      </div>
      <p class="subline subline-truncated" *ngIf="building.period">
        {{ 'building.constructionPeriod' | transloco: { period: building.period } }}
      </p>
      <p *ngIf="!building.period">&nbsp;</p>
    </div>

    <div class="inline-info tags" *ngIf="building.tags?.length">
      <div class="icons">
        <mat-icon class="icon-info">local_offer</mat-icon>
      </div>
      <mat-chip-list>
        <sibat-tag *ngFor="let tag of building.tags" [name]="tag" (delete)="removeTagFromSelectedBuilding(tag)"></sibat-tag>
      </mat-chip-list>
    </div>
  </div>

  <div class="buttons">
    <button *ngIf="!selected && !isInSelectedList" (click)="addBuilding(); $event.stopPropagation()" mat-icon-button>
      <mat-icon>add</mat-icon>
    </button>
    <button *ngIf="selected || isInSelectedList" (click)="removeBuilding(); $event.stopPropagation()" mat-icon-button>
      <mat-icon>remove</mat-icon>
    </button>
    <button (click)="displayBuildingLocation(); $event.stopPropagation()" mat-icon-button>
      <mat-icon>my_location</mat-icon>
    </button>
  </div>
</div>
