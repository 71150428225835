import { notifyInfo, notifyWarn, NotificationProperty } from '../../model/notification.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, ignoreElements, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ControlService } from '../service/control.service';
import { BuildingDocumentsService } from '../../building/service/building-documents.service';
import {
  approveControl,
  approveControlFailure,
  approveControlSuccess,
  cancelOngoingControl,
  cancelOngoingControlFailure,
  cancelOngoingControlSuccess,
  cancelRequestExpertApproval,
  cancelRequestExpertApprovalFailure,
  cancelRequestExpertApprovalSuccess,
  fetchControl,
  fetchControlFailure,
  fetchControlSuccess,
  confirmUploadControlReport,
  getControlsStats,
  getControlsStatsFailure,
  getControlsStatsSuccess,
  linkControlToBuilding,
  linkControlToBuildingFailure,
  linkControlToBuildingSuccess,
  previewControlReport,
  previewControlReportFailure,
  refreshOngoingControl,
  refreshOngoingControlFailure,
  refreshOngoingControlSuccess,
  refreshOrphanControl,
  refreshOrphanControlFailure,
  refreshOrphanControls,
  refreshOrphanControlsFailure,
  refreshOrphanControlsSuccess,
  refreshOrphanControlSuccess,
  reportControlError,
  requestExpertApproval,
  requestExpertApprovalFailure,
  requestExpertApprovalSuccess,
  startNewControl,
  startNewControlFailure,
  startNewControlSuccess,
  startOrphanControl,
  startOrphanControlFailure,
  startOrphanControlSuccess,
  updateControlDetails,
  updateControlDetailsFailure,
  updateControlDetailsSuccess,
  uploadControlReport,
  uploadControlReportFailure,
  uploadControlReportSuccess,
  validateControl,
  validateControlFailure,
  validateControlSuccess,
} from './control.action';
import { Store } from '@ngrx/store';
import { selectBuilding } from '../../building/store/building.selector';
import { getHistory } from '../../building/store/building.action';
import { isFileAlreadyExistsError } from '../../file-browser/model/sibat-file.model';
import {
  FileAlreadyExistsDialogComponent,
  UploadOption
} from '../../file-browser/duplicate-error-dialog/file-already-exists-dialog.component';
import { copyFileIncrement } from '../../shared/files';
import { noopAction } from '../../shared/app.action';
import { DialogService } from '../../shared/service/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class ControlEffects {
  // HANDLE ERRORS

  reportControlError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        startNewControlFailure,
        startOrphanControlFailure,
        refreshOrphanControlsFailure,
        refreshOrphanControlFailure,
        refreshOngoingControlFailure,
        updateControlDetailsFailure,
        linkControlToBuildingFailure,
        cancelOngoingControlFailure,
        requestExpertApprovalFailure,
        cancelRequestExpertApprovalFailure,
        approveControlFailure,
        validateControlFailure,
        fetchControlFailure
      ),
      map(failedAction =>
        reportControlError({
          error: failedAction.error,
          sourceActionType: failedAction.type,
          payload: failedAction.payload,
        })
      )
    )
  );

  // CONTROL

  startNewControlEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNewControl),
      mergeMap(({ buildingId }) =>
        this.controlService.startControl(buildingId).pipe(
          map(ongoingControl => startNewControlSuccess({ ongoingControl })),
          catchError(error =>
            of(startNewControlFailure({ error, payload: { buildingId }, ...notifyWarn('building.error.unexpected', true) }))
          )
        )
      )
    )
  );

  uploadControlReportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadControlReport),
      mergeMap(({ buildingId, controlDate, report, overwrite }) =>
        this.controlService.uploadControlReport(buildingId, controlDate, report, overwrite).pipe(
          map(control => uploadControlReportSuccess({ control })),
          catchError(error => {
            const fileAlreadyExists = isFileAlreadyExistsError(error.error.message);
            if (fileAlreadyExists) {
              return of(confirmUploadControlReport({ buildingId, controlDate, report }));
            } else {
              return of(uploadControlReportFailure({ error, payload: { buildingId } }));
            }
          })
        )
      )
    )
  );

  confirmUploadControlReportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmUploadControlReport),
      mergeMap(({ buildingId, controlDate, report }) => this.dialogService
        .openDialogComponent<UploadOption>(FileAlreadyExistsDialogComponent, {
          fileName: report.name,
        })
        .pipe(
          map(uploadOption => {
            if (uploadOption === 'overwrite') {
              return uploadControlReport({ buildingId, controlDate, report, overwrite: true });
            } else if (uploadOption === 'keepBoth') {
              const renamedReport = copyFileIncrement(report);
              return uploadControlReport({ buildingId, controlDate, report: renamedReport, overwrite: false });
            }

            return noopAction();
          })
        ))
    )
  );

  refreshOngoingControlEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshOngoingControl),
      mergeMap(({ buildingId }) =>
        this.controlService.refreshOngoingControl(buildingId).pipe(
          map(ongoingControl => refreshOngoingControlSuccess({ ongoingControl })),
          catchError(error => of(refreshOngoingControlFailure({ error, payload: { buildingId } })))
        )
      )
    )
  );

  updateControlDetailsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateControlDetails),
      mergeMap(({ controlId, control }) =>
        this.controlService.updateControlDetails(controlId, control).pipe(
          map(ongoingControl =>
            updateControlDetailsSuccess({
              control: ongoingControl,
              ...notifyInfo('notification.info.control.updateControlDetailsSuccess', true),
            })
          ),
          catchError(error =>
            of(
              updateControlDetailsFailure({
                error,
                controlId,
                payload: { controlId },
                ...notifyWarn('notification.warning.control.updateControlDetailsFailure', true),
              })
            )
          )
        )
      )
    )
  );

  validateControlEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateControl),
      mergeMap(({ buildingId }) =>
        this.controlService.validateControl(buildingId).pipe(
          map(historyWithControl => validateControlSuccess({
            buildingId, historyWithControl, ...notifyInfo('notification.info.control.reportIsReady', true)
          })),
          catchError(error =>
            of(validateControlFailure({
              error, buildingId, payload: { buildingId }, ...notifyWarn('building.error.unexpected', true)
            }))
          )
        )
      )
    )
  );

  validateControlRefreshHistoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateControlSuccess),
      concatLatestFrom(() => [this.store.select(selectBuilding)]),
      map(([{ buildingId }, building]) => {
        if (buildingId === building?.id) {
          return getHistory({ buildingId: building.id });
        } else {
          return noopAction();
        }
      }),
    )
  );

  cancelOngoingControlEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelOngoingControl),
      mergeMap(({ controlId }) =>
        this.controlService.cancelOngoingControl(controlId).pipe(
          map(_ => cancelOngoingControlSuccess(notifyInfo('notification.info.control.controlCancelled', true))),
          catchError(error =>
            of(cancelOngoingControlFailure({ error, payload: { controlId }, ...notifyWarn('building.control.cancelOngoingControlError') }))
          )
        )
      )
    )
  );

  previewControlReportEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(previewControlReport),
      mergeMap(({ source }) => {
        let reportContent$: Observable<Blob>;
        let failureNotification: NotificationProperty;
        if (typeof source === 'object') {
          reportContent$ = this.buildingDocumentsService.downloadBuildingDocument(source.buildingId, source.relativePath, source.fileName);
          failureNotification = notifyWarn(translate('building.control.reportNotFoundError', { reportFileName: source.fileName }));
        } else {
          reportContent$ = this.controlService.previewControlReport(source);
          failureNotification = notifyWarn('building.error.unexpected', true);
        }

        return reportContent$.pipe(
          tap(content => {
            const blob = new Blob([content], { type: 'application/pdf' });
            const dataUrl = URL.createObjectURL(blob);
            window.open(dataUrl, '_blank');
          }),
          catchError(error => of(previewControlReportFailure({ error, source, ...failureNotification })))
        );
      })
    )
  );

  displayDocumentsAfterReportGenerationEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(validateControlSuccess),
        tap(({ buildingId }) => {
          void this.router.navigate([`/building/${buildingId}/documents`]);
        }),
        ignoreElements()
      ),
    { dispatch: false }
  );

  requestExpertApprovalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestExpertApproval),
      mergeMap(({ buildingId }) =>
        this.controlService.requestExpertApproval(buildingId).pipe(
          map(control => requestExpertApprovalSuccess({ control, ...notifyInfo('notification.info.control.awaitingForApproval', true) })),
          catchError(error =>
            of(requestExpertApprovalFailure({ error, payload: { buildingId }, ...notifyWarn('building.error.unexpected', true) }))
          )
        )
      )
    )
  );

  cancelRequestExpertApprovalEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelRequestExpertApproval),
      mergeMap(({ buildingId }) =>
        this.controlService.cancelRequestExpertApproval(buildingId).pipe(
          map(control =>
            cancelRequestExpertApprovalSuccess({ control, ...notifyInfo('notification.info.control.awaitingForApprovalCancelled', true) })
          ),
          catchError(error =>
            of(cancelRequestExpertApprovalFailure({ error, payload: { buildingId }, ...notifyWarn('building.error.unexpected', true) }))
          )
        )
      )
    )
  );

  approveControlEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveControl),
      mergeMap(({ buildingId }) =>
        this.controlService.approveControl(buildingId).pipe(
          map(control => approveControlSuccess({ control })),
          catchError(error =>
            of(approveControlFailure({ error, payload: { buildingId }, ...notifyWarn('building.error.unexpected', true) }))
          )
        )
      )
    )
  );

  startOrphanControlEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startOrphanControl),
      mergeMap(() =>
        this.controlService.startControl().pipe(
          map(orphanControl =>
            startOrphanControlSuccess({ control: orphanControl, ...notifyInfo('notification.info.control.startOrphanControl', true) })
          ),
          catchError(error =>
            of(
              startOrphanControlFailure({ error, payload: {}, ...notifyWarn('notification.warning.control.startOrphanControlError', true) })
            )
          )
        )
      )
    )
  );

  refreshOrphanControlsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshOrphanControls, cancelOngoingControlSuccess),
      mergeMap(() =>
        this.controlService.fetchOrphanControls().pipe(
          map(orphanControls => refreshOrphanControlsSuccess({ orphanControls })),
          catchError(error =>
            of(
              refreshOrphanControlsFailure({
                error,
                payload: {},
                ...notifyWarn('notification.warning.control.refreshOrphanControlsError', true),
              })
            )
          )
        )
      )
    )
  );

  getControlEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshOrphanControl),
      mergeMap(({ controlId }) =>
        this.controlService.getControl(controlId).pipe(
          map(control => refreshOrphanControlSuccess({ control })),
          catchError(error =>
            of(
              refreshOrphanControlFailure({
                error,
                payload: {},
                ...notifyWarn('notification.warning.control.refreshOrphanControlError', true),
              })
            )
          )
        )
      )
    )
  );

  linkControlToBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(linkControlToBuilding),
      mergeMap(({ controlId, buildingId }) =>
        this.controlService.linkControlToBuilding(controlId, buildingId).pipe(
          map(control => linkControlToBuildingSuccess({ control, ...notifyInfo('notification.info.control.controlHasBeenLinked', true) })),
          catchError(error =>
            of(
              linkControlToBuildingFailure({
                error,
                payload: { controlId, buildingId },
                ...notifyWarn('notification.warning.control.controlHasNotBeenLinked', true),
              })
            )
          )
        )
      )
    )
  );

  getControlDefectsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchControl),
      switchMap(({ controlId }) =>
        this.controlService.getControl(controlId).pipe(
          map(control => fetchControlSuccess({ control })),
          catchError(error => of(fetchControlFailure({ error, payload: { controlId } })))
        )
      )
    )
  );

  getControlsStatsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getControlsStats),
      switchMap(({ periodStart, periodEnd, municipality }) =>
        this.controlService.getControlsStats(periodStart, periodEnd, municipality).pipe(
          map(controlsStats => getControlsStatsSuccess({ controlsStats })),
          catchError(_ => of(getControlsStatsFailure(notifyWarn('building.error.unexpected', true))))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private controlService: ControlService,
    private buildingDocumentsService: BuildingDocumentsService,
    private readonly dialogService: DialogService,
  ) {}
}
