import { Component, Input } from '@angular/core';

@Component({
  selector: 'sibat-icon-button',
  template: `
    <a mat-icon-button class="button-icon" [href]="href" [target]="target">
      <mat-icon>{{icon}}</mat-icon>
    </a>
  `,
  styleUrls: ['buttons.scss'],
})
export class IconButtonComponent {
  @Input() icon: string;
  @Input() href: string;
  @Input() target = '_self';

  constructor() {}
}
