import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AssessmentDto, EditProtectiveMeasure } from '../model/assessment.model';
import { Store } from '@ngrx/store';
import { addProtectiveMeasure, updateProtectiveMeasure } from '../store/assessment.action';
import { extendTextArea } from '../../shared/style';
import { UntypedFormBuilder } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sibat-edit-protective-measures',
  template: `
    <div *transloco="let t; read: 'building.assessment.protectiveMeasuresForm'">
      <sibat-add-protective-measure
        [readOnly]="readOnly"
        (measureAdded)="addProtectiveMeasure($event)">
      </sibat-add-protective-measure>
      <sibat-protective-measures-list
        [buildingId]="buildingId"
        [assessment]="assessment"
        [readOnly]="readOnly"
        [offline]="offline"
        (measureSaved)="updateProtectiveMeasure($event)"
      >
      </sibat-protective-measures-list>
      <form class="measures-info-card"
            [formGroup]="measuresInfoForm"
            [class.read-only]="readOnly"
      >
        <mat-form-field class="remarks">
          <mat-label>{{ t('remarks') }}</mat-label>
          <textarea
            matInput formControlName="remarksMeasures"
            [readonly]="readOnly"
            (keyup)="extendTextArea($event.target)">
          </textarea>
        </mat-form-field>
        <div>
          <sibat-validate-button
            (clicked)="saveData()"
            [disabled]="offline || measuresInfoForm.pristine"
            [label]="t('saveMeasuresInfo')"
            data-testid="save-button"
          >
          </sibat-validate-button>
        </div>
      </form>
    </div>
  `,
  styleUrls: ['edit-protective-measures.component.scss']
})

export class EditProtectiveMeasuresComponent implements OnInit, OnChanges {
  @Input() assessment: AssessmentDto;
  @Input() buildingId: number;
  @Input() offline: boolean;
  @Input() readOnly: boolean;
  @Output() assessmentDetailsSaved = new EventEmitter<AssessmentDto>();
  @Output() unsavedDetails = new EventEmitter<boolean>();
  formSubscription: Subscription;

  readonly measuresInfoForm = this.formBuilder.group(
    {
      remarksMeasures: this.formBuilder.control(undefined)
    }
  );

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) {
    this.formSubscription = this.measuresInfoForm.statusChanges
      .pipe(
        map(() => this.measuresInfoForm.pristine),
        distinctUntilChanged()
      )
      .subscribe(pristine => {
        this.unsavedDetails.emit(!pristine);
      });
  }
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assessment) {
      this.setValuesForOngoingAssessment(this.assessment);
    }
    if (changes.offline) {
      if (this.offline) {
        this.measuresInfoForm.disable();
      } else {
        this.measuresInfoForm.enable();
      }
    }
  }

  setValuesForOngoingAssessment(assessment: AssessmentDto | undefined): void {
    if (assessment) {
      this.measuresInfoForm.patchValue({
        remarksMeasures: assessment.remarksMeasures
      });
    } else {
      this.measuresInfoForm.reset();
    }
  }

  addProtectiveMeasure(protectiveMeasure: EditProtectiveMeasure) {
    this.store.dispatch(
      addProtectiveMeasure({
        assessmentId: this.assessment.id,
        protectiveMeasure
      })
    );
  }

  updateProtectiveMeasure(protectiveMeasure: EditProtectiveMeasure) {
    this.store.dispatch(updateProtectiveMeasure({assessmentId: this.assessment.id, protectiveMeasure}));
  }

  extendTextArea(element) {
    extendTextArea(element);
  }

  saveData(): void {
    this.assessmentDetailsSaved.emit(this.measuresInfoForm.value);
    this.measuresInfoForm.markAsPristine();
  }

}
