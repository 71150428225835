<h3>{{ 'building.assessment.assessmentDetailsForm.assessmentDetails' | transloco }}</h3>
<form
	data-testid="assessment-form"
	class="form-container"
	[class.read-only]="readOnly"
	fxLayout="column"
	[formGroup]="assessmentDetailsForm"
	*transloco="let t; read: 'building.assessment.assessmentDetailsForm'"
>
	<div class="container-small" fxLayout="column">
		<mat-form-field>
			<mat-label>{{ t('designation') }}</mat-label>
			<input matInput formControlName="designation" />
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ t('landlordOrOperator') }}</mat-label>
			<input matInput formControlName="landlordOrOperator" required="required" />
			<mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ t('peoplePresent') }}</mat-label>
      <textarea matInput formControlName="peoplePresent" (keyup)="extendTextArea($event.target)" required="required"></textarea>
      <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ t('assessmentDate') }}</mat-label>
			<input
				matInput
				formControlName="date"
				[matDatepicker]="assessmentDatePicker"
				required="required"
				(focus)="assessmentDatePicker.open()"
				(dateChange)="preventRequiredDate(assessmentDate)"
			/>
			<mat-datepicker-toggle [disabled]="offline" matSuffix [for]="assessmentDatePicker"></mat-datepicker-toggle>
			<mat-datepicker disabled="false" #assessmentDatePicker></mat-datepicker>
			<mat-error *ngIf="assessmentDate.hasError('matDatepickerParse')">{{ 'errors.invalidDateFormat' | transloco }}</mat-error>
			<mat-error *ngIf="assessmentDate.hasError('required') && !assessmentDate.hasError('matDatepickerParse')">
				{{ 'errors.requiredField' | transloco }}
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ t('remarks') }}</mat-label>
			<textarea matInput formControlName="remarks" (keyup)="extendTextArea($event.target)"></textarea>
		</mat-form-field>
		<div>
			<sibat-validate-button
				(clicked)="saveData()"
				[disabled]="offline || assessmentDetailsForm.pristine"
				[label]="t('saveAssessmentDetails')"
				data-testid="save-button"
			>
			</sibat-validate-button>
		</div>
	</div>
</form>
