import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Configuration } from '../model/configuration.model';

export const CONFIG_BASE_URI = `${environment.apiUrl}/config`;

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private readonly http: HttpClient) {}

  getConfiguration(): Observable<Configuration> {
    return this.http.get<Configuration>(`${CONFIG_BASE_URI}`);
  }
}
