import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Control, DefectDto, EditDefect } from '../model/control.model';
import { Store } from '@ngrx/store';
import { updateDefectsOrder } from '../store/control.action';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'sibat-defects-list',
  template: `
    <div
      *transloco="let t; read: 'building.control'"
      [class.read-only]="readOnly"
      data-testid="sibat-defects-list"
      class="container gap-between-items"
    >
      <h3>{{ t('defect') }}</h3>
      <div cdkDropList (cdkDropListDropped)="draggedDefect($event)" class="gap-between-items">
        <sibat-defect-item
          *ngFor="let defect of orderedDefects"
          [buildingId]="buildingId"
          [control]="control"
          [readOnly]="readOnly"
          [offline]="offline"
          [displayTooltip]="(isMobileOrTabletScreen$ | async) === false"
          [thumbnails]="thumbnails"
          [defect]="defect"
          (defectSaved)="updateDefect($event)"
        ></sibat-defect-item>
      </div>
      <sibat-defects-list-offline [control]="control" [buildingId]="buildingId" [offline]="offline"> </sibat-defects-list-offline>
    </div>
  `,
  styleUrls: ['defects-list.component.scss'],
})
export class DefectsListComponent implements OnInit, OnChanges {
  @Input() buildingId: number | undefined;
  @Input() control: Control;
  @Input() readOnly: boolean;
  @Input() offline: boolean;
  @Input() thumbnails: Record<string, string> = {};
  @Output() defectSaved = new EventEmitter<EditDefect>();

  isMobileOrTabletScreen$: Observable<boolean>;
  orderedDefects: DefectDto[] = [];

  constructor(private store: Store, public media: MediaObserver) {}

  ngOnInit(): void {
    this.isMobileOrTabletScreen$ = this.media.asObservable().pipe(map(events => !!events.find(event => event.mqAlias === 'lt-md')));
  }

  updateOrderedDefects() {
    if (this.control && this.control.defects) {
      this.orderedDefects = [...this.control.defects].sort(this.sortBySortingOrder);
    } else {
      this.orderedDefects = [];
    }
  }

  sortBySortingOrder(defect1: DefectDto, defect2: DefectDto) {
    if (typeof defect1.sortingOrder === 'number' && typeof defect2.sortingOrder === 'number') {
      return defect1.sortingOrder > defect2.sortingOrder ? 1 : -1;
    }
    return 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control) {
      const control = changes.control.currentValue;
      if (control && control.defects) {
        this.updateOrderedDefects();
      }
    }
  }

  updateDefect(defect: EditDefect): void {
    this.defectSaved.emit(defect);
  }

  draggedDefect(event: CdkDragDrop<string[]>) {
    const arrayToSort = [...this.orderedDefects];
    moveItemInArray(arrayToSort, event.previousIndex, event.currentIndex);
    this.orderedDefects = arrayToSort.map((defect, index) => ({ ...defect, sortingOrder: index }));
    this.store.dispatch(updateDefectsOrder({ controlId: this.control.id, defects: this.orderedDefects }));
  }
}
