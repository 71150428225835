import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sibat-batch-next-control-date-edit-dialog',
  templateUrl: './batch-next-control-date-edit-dialog.component.html',
})
export class BatchNextControlDateEditDialogComponent implements AfterViewInit {
  @ViewChild('dateInput') dateInput: ElementRef;
  readonly count: number = 1;
  readonly existingDate: string | undefined;
  nextControlDate: string | null = null;

  constructor(
    private dialogRef: MatDialogRef<BatchNextControlDateEditDialogComponent, string|null>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: { count: number; existingDate: string | undefined },
  ) {
    this.count = this.data.count;
    this.existingDate = this.data.existingDate;
  }

  ngAfterViewInit(): void {
    if (this.existingDate) {
      this.cd.detach(); // work around the ExpressionChangedAfterItHasBeenCheckedError
      this.dateInput.nativeElement.value = this.existingDate;
      this.cd.reattach();
      this.cd.detectChanges();
    }
  }

  clearDate() {
    this.dateInput.nativeElement.value = '';
  }

  dateChanged(newValue: any) {
    if (newValue === null) {
      this.clearDate();
    }
    this.nextControlDate = newValue;
  }

  save() {
    this.dialogRef.close(this.nextControlDate);
  }

  cancel() {
    this.dialogRef.close();
  }

}
