import { merge, Observable, of } from 'rxjs';
import { INSTALLATION_TYPES, InstallationType, TechnicalInstallation } from './technical-installations.model';
import { AbstractControl, UntypedFormControl, Validators } from '@angular/forms';
import { FormField } from '../technical-installations/update-form-dialog/update-form.model';
import { map } from 'rxjs/operators';

const fromDateToISOString = (value: any) => {
  if (value?.toISOString) {
    return value.toISOString();
  }
  return value;
};

const isEmptyInputValue = (value: any): boolean =>
  value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);

export const uncompletedValidator = (control: AbstractControl) => {
  if (isEmptyInputValue(control.value)) {
    return { uncompleted: true };
  }
  return null;
};

const hasDefectsField: (defaultValue?: boolean | null) => FormField = (defaultValue = null) => ({
  name: 'hasDefects',
  formControl: new UntypedFormControl(defaultValue, [Validators.required]),
  labelKey: 'hasDefects',
  type: 'radio',
  choices: [
    {
      labelKey: 'yes',
      value: true,
    },
    {
      labelKey: 'no',
      value: false,
    },
  ],
});

export const createDefaultFileName = (suffix: string, installationType: InstallationType): string => {
  let typePrefix = '';
  const date = new Date().toISOString().split('T')[0];
  switch (installationType) {
    case INSTALLATION_TYPES.sprinkler:
      typePrefix = 'sprinkler';
      break;
    case INSTALLATION_TYPES.fireDetection:
      typePrefix = 'detection';
      break;
  }

  return `${date}_${typePrefix}_${suffix}`;
};

type CreateFormFields = (value?: TechnicalInstallation, defaultFileName?: string) => FieldGroup;

export class FieldGroup {
  readonly isUncompleted$: Observable<boolean>;
  readonly hasRequiredError$: Observable<boolean>;

  constructor(readonly fields: FormField[]) {
    // initialEvent is needed to trigger the first check
    const initialEvent = of('initial_state_check');
    this.isUncompleted$ = merge(...fields.map(field => field.formControl.statusChanges), initialEvent).pipe(
      map(() => this.isUncompleted())
    );
    this.hasRequiredError$ = merge(...fields.map(field => field.formControl.statusChanges), initialEvent).pipe(
      map(() => this.hasRequiredError())
    );
  }

  /**
   * @returns true if any field has a "uncompleted" error
   */
  isUncompleted(): boolean {
    return this.fields.some(field => field.formControl.hasError('uncompleted'));
  }

  /**
   * @returns true if any field has a "required" error
   */
  hasRequiredError(): boolean {
    return this.fields.some(field => field.formControl.hasError('required'));
  }
}

export const periodicControlFormFields: CreateFormFields = (value, defaultFileName) => {
  const formFields: FormField[] = [];
  const maybeRequiredValidator = !!value ? [uncompletedValidator] : [Validators.required];

  if (!value) {
    formFields.push({
      name: 'file',
      defaultFileName,
      formControl: new UntypedFormControl(null, [Validators.required]),
      labelKey: 'addPeriodicControlReport',
      type: 'file',
    });
  }

  formFields.push({
    name: 'controller',
    formControl: new UntypedFormControl(value?.periodicController, maybeRequiredValidator),
    labelKey: 'controller',
    type: 'textarea',
    placeholderKey: 'contactPlaceholder',
  });
  formFields.push({
    name: 'controlDate',
    formControl: new UntypedFormControl(value?.lastControlDate, [Validators.required]),
    labelKey: 'periodicControlDate',
    type: 'date',
    maxDate: new Date(),
    transform: fromDateToISOString,
  });

  if (!value) {
    formFields.push(hasDefectsField());
  }

  return new FieldGroup(formFields);
};

export const assessmentReportFormFields: CreateFormFields = (value, defaultFileName) => {
  const formFields: FormField[] = [];

  if (!value) {
    formFields.push({
      name: 'file',
      defaultFileName,
      formControl: new UntypedFormControl(null, [Validators.required]),
      labelKey: 'addAssessmentReport',
      type: 'file',
    });
  }

  formFields.push({
    name: 'assessmentFirm',
    formControl: new UntypedFormControl(value?.assessmentFirm, [Validators.required]),
    labelKey: 'assessmentFirm',
    type: 'textarea',
    placeholderKey: 'contactPlaceholder',
  });

  return new FieldGroup(formFields);
};

export const shutdownFormFields: CreateFormFields = value =>
  new FieldGroup([
    {
      name: 'shutdownDate',
      formControl: new UntypedFormControl(value?.shutdownDate, [Validators.required]),
      labelKey: 'shutdownDate',
      type: 'date',
      maxDate: new Date(),
      transform: fromDateToISOString,
    },
    {
      name: 'shutdownReason',
      formControl: new UntypedFormControl(value?.shutdownReason, [Validators.required]),
      labelKey: 'shutdownReason',
      type: 'textarea',
    },
  ]);

export const temporaryShutdownFormFields: CreateFormFields = (value, defaultFileName) =>
  new FieldGroup([
    {
      name: 'file',
      defaultFileName,
      formControl: new UntypedFormControl(null, [Validators.required]),
      labelKey: 'addInstallationCertificate',
      type: 'file',
    },
    {
      name: 'temporaryShutdownEndDate',
      formControl: new UntypedFormControl(value?.temporaryShutdownEndDate, [Validators.required]),
      labelKey: 'temporaryShutdownEndDate',
      type: 'date',
      minDate: new Date(),
      transform: fromDateToISOString,
    },
  ]);

export const nextControlFormFields: CreateFormFields = value =>
  new FieldGroup([
    {
      name: 'nextControlDate',
      formControl: new UntypedFormControl(value?.nextControlDate, [Validators.required]),
      labelKey: 'nextControlDate',
      type: 'date',
      transform: fromDateToISOString,
    },
  ]);

export const installationCertificateFormFields: CreateFormFields = (value, defaultFileName) => {
  const formFields: FormField[] = [];

  if (!value) {
    formFields.push({
      name: 'file',
      defaultFileName,
      formControl: new UntypedFormControl(null, [Validators.required]),
      labelKey: 'addInstallationCertificate',
      type: 'file',
    });
  }

  const maybeRequiredValidator = !!value ? [uncompletedValidator] : [Validators.required];
  formFields.push({
    name: 'installationDate',
    formControl: new UntypedFormControl(value?.installationDate, [Validators.required]),
    labelKey: 'installationDate',
    type: 'date',
    maxDate: new Date(),
    transform: fromDateToISOString,
  });
  formFields.push({
    name: 'responsible',
    formControl: new UntypedFormControl(value?.responsible, maybeRequiredValidator),
    labelKey: 'responsible',
    type: 'textarea',
    placeholderKey: 'contactPlaceholder',
  });

  return new FieldGroup(formFields);
};

export const maintenanceContractFormFields: CreateFormFields = (value, defaultFileName) => {
  const formFields: FormField[] = [];

  if (!value) {
    formFields.push({
      name: 'file',
      defaultFileName,
      formControl: new UntypedFormControl(null, [uncompletedValidator]),
      labelKey: 'addMaintenanceContract',
      type: 'file',
    });
  }

  formFields.push({
    name: 'maintenanceFirm',
    formControl: new UntypedFormControl(value?.maintenanceFirm, [Validators.required]),
    labelKey: 'maintenanceFirmName',
    type: 'textarea',
    placeholderKey: 'contactPlaceholder',
  });

  return new FieldGroup(formFields);
};

export const receptionControlReportFormFields: CreateFormFields = (value, defaultFileName) => {
  const formFields: FormField[] = [];

  if (!value) {
    formFields.push({
      name: 'file',
      defaultFileName,
      formControl: new UntypedFormControl(null, [Validators.required]),
      labelKey: 'addReceptionControlReport',
      type: 'file',
    });
  }

  const maybeRequiredValidator = !!value ? [uncompletedValidator] : [Validators.required];
  formFields.push({
    name: 'controlBody',
    formControl: new UntypedFormControl(value?.controlBody, maybeRequiredValidator),
    labelKey: 'controlBody',
    type: 'textarea',
    placeholderKey: 'contactPlaceholder',
  });
  formFields.push({
    name: 'controlDate',
    formControl: new UntypedFormControl(value?.receptionControlDate, maybeRequiredValidator),
    labelKey: 'receptionControlDate',
    type: 'date',
    maxDate: new Date(),
    transform: fromDateToISOString,
  });

  if (!value) {
    formFields.push(hasDefectsField());
  }

  return new FieldGroup(formFields);
};

export const complianceReportFormFields: CreateFormFields = (_, defaultFileName) => {
  const formFields: FormField[] = [];

  formFields.push({
    name: 'file',
    defaultFileName,
    formControl: new UntypedFormControl(null, [Validators.required]),
    labelKey: 'addComplianceReport',
    type: 'file',
  });

  formFields.push({
    name: 'hasDefects',
    descriptionKey: 'areDefectsEliminated',
    formControl: new UntypedFormControl(null, [Validators.required]),
    labelKey: 'defects',
    type: 'radio',
    choices: [
      {
        labelKey: 'yes',
        value: false,
      },
      {
        labelKey: 'no',
        value: true,
      },
    ],
  });

  return new FieldGroup(formFields);
};
