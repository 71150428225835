import { Component, Input } from '@angular/core';
import { FabButton, FabOptions } from '../../service/fab.service';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'sibat-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: [
    './speed-dial-fab.component.scss',
    '../../buttons/buttons.scss',
  ],
  animations: speedDialFabAnimations,
})
export class SpeedDialFabComponent {

  @Input() options: FabOptions;
  @Input() hidden = false;
  @Input() disabled = false;

  buttons: FabButton[] = [];
  fabTogglerState = 'inactive';

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.options.buttons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  toggle() {
    if (this.buttons.length > 0) {
      this.hideItems();
    } else {
      this.showItems();
    }
  }

}
