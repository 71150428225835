import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { ChipBaseComponent } from './components/chips/chip-base/chip-base.component';
import { ChipProofComponent } from './components/chips/chip-proof/chip-proof.component';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';
import { TagComponent } from './components/chips/tag/tag.component';
import { DialogService } from './service/dialog.service';
import { LocalizedPaginatorIntl } from './service/localize-paginator-intl.service';
import { CasePreviewComponent } from './components/case-preview/case-preview.component';
import { ButtonsModule } from './buttons/buttons.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DragNDropDirective } from './directives/dragdrop/drag-ndrop.directive';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { RequiredIndicatorPipe } from './pipes/required-indicator.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SpeedDialFabComponent } from '../shared/components/speed-dial-fab/speed-dial-fab.component';
import { SibatTranslatePipe } from './pipes/sibat-translate.pipe';
import { SideNavComponent } from '../shared/components/side-nav/side-nav.component';
import { AddTagComponent } from './components/chips/add-tag/add-tag.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const declarations = [
  DialogConfirmationComponent,
  ChipBaseComponent,
  ChipProofComponent,
  TagComponent,
  CasePreviewComponent,
  DragNDropDirective,
  AutofocusDirective,
  RequiredIndicatorPipe,
  SafeUrlPipe,
  SpeedDialFabComponent,
  SibatTranslatePipe,
  SideNavComponent,
  AddTagComponent
];

const materialImports = [
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSidenavModule,
];

const imports = [...materialImports, TranslocoRootModule, ButtonsModule, RouterModule, CommonModule, ReactiveFormsModule, FormsModule ];
@NgModule({
  declarations,
  imports,
  exports: [...declarations, ...imports],
  providers: [DialogService, { provide: MatPaginatorIntl, useClass: LocalizedPaginatorIntl }],
})
export class SharedModule {}
