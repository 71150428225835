import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { logoutSuccess } from '../../authentication/store/authentication.action';
import { clearLoading } from './loading.action';

@Injectable({
  providedIn: 'root',
})
export class LoadingEffects {
  logoutSuccessCleanupLoadingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSuccess),
      map(() => clearLoading())
    )
  );

  constructor(private actions$: Actions) {}
}
