import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sibat-dialog-confirmation',
  template: `
    <mat-dialog-content>
      {{ data.message }}
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <sibat-validate-button class="mat-button-base" [mat-dialog-close]="true" [label]="data.acceptLabel"></sibat-validate-button>
      <sibat-secondary-button class="mat-button-base" [mat-dialog-close]="false" [label]="data.cancelLabel"></sibat-secondary-button>
    </mat-dialog-actions>
  `,
})
export class DialogConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string; acceptLabel: string; cancelLabel: string }
  ) {}
}
