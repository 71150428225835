import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ControlSummary } from 'src/app/control/model/control.model';
import { selectOrphanControls } from 'src/app/control/store/control.selector';
import { refreshOrphanControls, startOrphanControl } from '../../control/store/control.action';

@Component({
  selector: 'sibat-orphan-controls',
  template: `
    <sibat-widget title="{{ 'cockpit.orphanControls' | transloco }}">
      <mat-icon widget-icon>domain_disabled</mat-icon>
      <dl widget-content>
        <div *transloco="let t; read: 'cockpit'" class="content">
          <sibat-primary-button
            [label]="t('startOrphanControl')"
            [submit]="false"
            [disabled]="false"
            [stretch]="true"
            (clicked)="startOrphanControl()"
            class="start-button"
          ></sibat-primary-button>
          <sibat-orphan-control-item
            *ngFor="let control of orphanControls$ | async"
            [control]="control"
            class="orphan-control-item"
            data-testid="orphan-control"
            (click)="displayOrphanControl(control.id)"
          >
          </sibat-orphan-control-item>
        </div>
      </dl>
    </sibat-widget>
  `,
  styleUrls: ['./orphan-controls.component.scss'],
})
export class OrphanControlsComponent implements OnInit {
  @Output() orphanControlStarted = new EventEmitter<void>();
  @Output() editOrphanControl = new EventEmitter<number>();

  orphanControls$: Observable<ControlSummary[]>;

  constructor(private store: Store) {
    this.orphanControls$ = this.store.select(selectOrphanControls);
  }

  ngOnInit(): void {
    this.store.dispatch(refreshOrphanControls());
  }

  startOrphanControl() {
    this.orphanControlStarted.emit();
  }

  displayOrphanControl(controlId: number) {
    this.editOrphanControl.emit(controlId);
  }
}
