import { CONTROL_STATUS } from '../../building/model/history.model';
import { createSelector } from '@ngrx/store';
import ControlState from './control.reducer';
import { State } from '../../reducers';
import { formatThumbnails } from 'src/app/shared/format-thumbnails';
import { DefectDto } from '../model/control.model';

export const getControlState = (state: State) => state.control;

export const selectOngoingControl = createSelector(getControlState, (state: ControlState) => state.control);

export const selectOrphanControls = createSelector(getControlState, (state: ControlState) => state.orphanControls);

export const selectThumbnails = createSelector(getControlState, state => formatThumbnails(state.defectThumbnails));

export const selectOfflineDefects = createSelector(getControlState, state => state.offlineDefects);

export const selectHasOfflineDefects = createSelector(getControlState, state => state.offlineDefects.length > 0);

export const selectDefectSummary = createSelector(getControlState, (state: ControlState) => state.defectSummary);

export const selectDefectSummaryCount = createSelector(getControlState, (state: ControlState) => state.defectSummaryCount);

export const selectDefectSummaryCurrentPage = createSelector(getControlState, (state: ControlState) => state.defectSummaryPage);

export const selectControlId = createSelector(getControlState, (state: ControlState) => state.controlDefects?.controlId || 0);

export const selectControlDefects = createSelector(getControlState, (state: ControlState) => {
  const selectedDefects = state.controlDefects?.selectedDefects ?? [];
  const allDefects = state.controlDefects?.defects ?? [];
  const filteredDefects = allDefects.filter(defect => !selectedDefects.includes(defect));
  return [...filteredDefects].sort(sortBySortingOrder);
});

export const selectControlDefectsInEdition = createSelector(getControlState, (state: ControlState) => {
  const selectedDefects = state.controlDefects?.selectedDefects ?? [];
  return [...selectedDefects].sort(sortBySortingOrder);
});

export const selectTotalControlsStat = createSelector(getControlState, (state: ControlState) =>
  state.stats.reduce((acc, stat) => acc + stat.count, 0)
);

export const selectOngoingControlsCount = createSelector(getControlState, (state: ControlState) =>
  state.stats.filter(s => s.status === CONTROL_STATUS.ongoing).reduce((acc, stat) => acc + stat.count, 0)
);

export const selectCompletedControlsCount = createSelector(getControlState, (state: ControlState) =>
  state.stats.filter(s => s.status === CONTROL_STATUS.completed).reduce((acc, stat) => acc + stat.count, 0)
);

export const selectControlsStats = createSelector(getControlState, (state: ControlState) => state.stats);

const sortBySortingOrder = (defect1: DefectDto, defect2: DefectDto) => {
  if (typeof defect1.sortingOrder === 'number' && typeof defect2.sortingOrder === 'number') {
    return defect1.sortingOrder > defect2.sortingOrder ? 1 : -1;
  }
  return 0;
};
