import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { TextLabel } from '../localization';

export class FabButton {
  readonly label: TextLabel;
  readonly action: () => void;
  readonly tooltip?: TextLabel;
  readonly icon?: string;
  disabled: boolean;

  constructor({
    label,
    action,
    disabled = false,
    tooltip,
    icon,
  }: {
    label: TextLabel;
    action: () => void;
    disabled?: boolean;
    tooltip?: TextLabel;
    icon?: string;
  }) {
    this.label = label;
    this.action = action;
    this.disabled = disabled;
    this.tooltip = tooltip;
    this.icon = icon;
  }
}

export interface FabOptions {
  buttons: FabButton[];
}

@Injectable({
  providedIn: 'root',
})
export class FabService {
  options$: Observable<FabOptions | undefined>;
  closeMenu$: Observable<true>;
  disabled$: Observable<boolean>;
  hidden$: Observable<boolean>;

  private optionsSubject = new Subject<FabOptions | undefined>();
  private closeMenu = new Subject<true>();
  private disabled = new BehaviorSubject(false);
  private hidden = new BehaviorSubject(false);

  constructor() {
    this.options$ = this.optionsSubject
      .asObservable()
      .pipe(
        // This delay is needed to prevent ExpressionChangedAfterItHasBeenCheckedError
        delay(0)
      );
      this.closeMenu$ = this.closeMenu.asObservable();
      this.disabled$ = this.disabled.asObservable();
      this.hidden$ = this.hidden.asObservable();
  }

  /**
   * Sets the current fab options.
   * This should show the fab.
   *
   * @param options The fab options to set.
   * @param disabled to disable the fab
   */
  setFabOptions(options: FabOptions, disabled = false) {
    this.optionsSubject.next(options);
    if (disabled !== this.disabled.value) {
      this.disabled.next(disabled);
    }
  }

  /**
   * Clears the current fab options.
   * This should hide the fab.
   */
  clearFabOptions() {
    this.optionsSubject.next(undefined);
  }

  disableFab(disabled: boolean = true) {
    this.closeFabMenu();
    this.disabled.next(disabled);
  }

  closeFabMenu() {
    this.closeMenu.next(true);
  }

  hideFab() {
    this.hidden.next(true);
  }

  showFab() {
    this.hidden.next(false);
  }
}
