<div class="list-wrapper">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 single-action-list">
    <ng-container matColumnDef="nr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.fosNr' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.nr }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.name' | transloco }}</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="expert">
      <th class="last-row" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'cockpit.expert' | transloco }}</th>
      <td class="last-row" mat-cell *matCellDef="let element">{{ element.expert.firstName }} {{ element.expert.lastName }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
