import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Control, StoredOfflineEditDefect } from '../model/control.model';
import { Store } from '@ngrx/store';
import { removeOfflineDefect, setFullScreenDefectPicture, updateOfflineDefect, uploadOfflineDefects } from '../store/control.action';
import { TranslocoService } from '@ngneat/transloco';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { selectOfflineDefects } from '../store/control.selector';
import { DialogService } from 'src/app/shared/service/dialog.service';

@Component({
  selector: 'sibat-defects-list-offline',
  template: `
    <div *transloco="let t; read: 'building.control'" class="gap-between-items">
      <div *ngFor="let defect of offlineDefects; let i = index">
        <div
          class="defect-item"
          [class.missing-defect-info]="tooltipContents[i]"
          [matTooltip]="tooltipContents[i]"
          matTooltipPosition="left"
        >
          <div class="cloud-icon">
            <mat-icon>cloud_off</mat-icon>
          </div>
          <div class="defect-icon">
            <mat-icon *ngIf="defect.picturesToAdd.length === 0">format_align_left</mat-icon>
            <img
              *ngIf="defect.picturesToAdd.length > 0"
              [src]="defect.picturesToAdd[0].preview | safeUrl"
              alt=""
              (click)="displayFullScreenImg(defect.picturesToAdd[0].preview)"
            />
          </div>
          <div class="defect-description">
            <span *ngIf="defect.description">{{ defect.description }}</span>
            <span *ngIf="!defect.description">{{ t('noDescription') }}</span>
            <div *ngIf="defect.deadline" class="defect-deadline">
              <mat-icon class="icon">update</mat-icon>
              <span class="date">{{ defect.deadline | date: 'dd.MM.yyyy' }} </span>
            </div>
          </div>
          <button class="inline-button update-button" mat-flat-button (click)="toggleEditing(i)">
            <mat-icon>mode_edit</mat-icon>
          </button>
          <button class="inline-button delete-button" mat-flat-button (click)="openDialogRemoveConfirmation(defect.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <sibat-edit-defect *ngIf="isEditing[i]" [defect]="defect" (defectSaved)="updateDefect($event, i)" class="zone-edit">
        </sibat-edit-defect>
      </div>
      <sibat-dark-button
        *ngIf="!offline && offlineDefects.length > 0"
        (clicked)="uploadOfflineDefects()"
        [label]="t('uploadOfflineDefects')"
        icon="cloud_upload"
        data-testid="upload-defects"
      >
      </sibat-dark-button>
    </div>
  `,
  styleUrls: ['defects-list-offline.component.scss'],
})
export class DefectsListOfflineComponent implements OnInit, OnDestroy {
  @Input() control: Control;
  @Input() buildingId: number | undefined;
  @Input() offline: boolean;
  isEditing: boolean[];
  minDate: Date;
  tooltipContents: string[];
  langSubscription: Subscription;
  offlineDefects: StoredOfflineEditDefect[] = [];
  offlineDefectsSubscription: Subscription;

  constructor(private store: Store, private dialogService: DialogService, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    const today = new Date();
    this.minDate = new Date(today.setDate(today.getDate() + 1));

    this.langSubscription = this.translocoService.langChanges$
      .pipe(switchMap(() => this.translocoService.selectTranslation()))
      .subscribe(() => this.updateTooltipContents());
    this.offlineDefectsSubscription = this.store.select(selectOfflineDefects).subscribe(defects => (this.offlineDefects = defects));
    this.isEditing = this.offlineDefects.map(() => false);
  }

  ngOnDestroy(): void {
    this.langSubscription?.unsubscribe();
    this.offlineDefectsSubscription?.unsubscribe();
  }

  updateTooltipContents() {
    this.tooltipContents = this.offlineDefects.map(defect => {
      let content = '';
      if (!defect.type) {
        content += this.translocoService.translate('building.control.missingDefectTypeTooltip') + '\n';
      }
      if (!defect.location) {
        content += this.translocoService.translate('building.control.missingDefectLocationTooltip');
      }
      return content;
    });
  }

  toggleEditing(indexInList: number) {
    this.isEditing[indexInList] = !this.isEditing[indexInList];
  }

  async openDialogRemoveConfirmation(defectOfflineId: number): Promise<void> {
    const result = await this.dialogService.requestConfirmation({ translationKey: 'building.control.sureToRemove' });
    if (result) {
      this.store.dispatch(removeOfflineDefect({ defectOfflineId }));
    }
  }

  updateDefect(defect: StoredOfflineEditDefect, defectIndex: number): void {
    this.store.dispatch(updateOfflineDefect({ defect }));
    this.isEditing[defectIndex] = false;
  }

  uploadOfflineDefects(): void {
    this.store.dispatch(uploadOfflineDefects({ controlId: this.control.id }));
  }

  displayFullScreenImg(pictureData: string) {
    this.store.dispatch(setFullScreenDefectPicture({ pictureData }));
  }
}
