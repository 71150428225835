import { AbstractControl } from '@angular/forms';

export const replaceNullValueWithUndefined = (formControl: AbstractControl) => {
  if (formControl.value === null) {
    formControl.setValue(undefined);
  }
};

export const parseFormData = (obj: any): FormData => {
  const formData = new FormData();
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value != null) {
      formData.append(key, value);
    }
  });
  return formData;
};
