import { createSelector } from '@ngrx/store';
import AssessmentState from './assessment.reducer';
import { State } from '../../reducers';
import { selectUserId } from '../../authentication/store/user.selector';

export const getAssessmentState = (state: State) => state.assessment;

export const selectOngoingAssessment = createSelector(
  getAssessmentState,
  (state: AssessmentState) => state.assessment);
export const selectRefAffectationOptions = createSelector(
  getAssessmentState,
  (assessmentState) => assessmentState.refAffectationOptions || []
);
export const selectRefBasegroundOptions = createSelector(
  getAssessmentState,
  (assessmentState) => assessmentState.refBasegroundOptions || []
);
export const selectRefAwningOptions = createSelector(
  getAssessmentState,
  (assessmentState) => assessmentState.refAwningOptions || []
);

export const selectMeasureTypes = createSelector(getAssessmentState, (state: AssessmentState) => state.measureTypes);

export const selectCanEditOngoingAssessment = createSelector(
  selectOngoingAssessment,
  selectUserId,
  (assessment, userId) => {
    let canEdit = false;
    if (assessment && assessment.user?.id === userId) {
      canEdit = true;
    }
    return canEdit;
  }
);
