import { Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAuthenticationError } from '../store/authentication.selector';
import { clearAuthenticationError, signIn } from '../store/authentication.action';
import { Router } from '@angular/router';
@Component({
  selector: 'sibat-signin',
  template: `
    <form *transloco="let t; read: 'authentication'">
      <p *ngIf="error$ | async" class="error-msg">{{ 'authentication.error.loginInvalid' | transloco }}</p>
      <mat-form-field [formGroup]="signInForm" [class.margin-bottom]="email.hasError('email')">
        <mat-label>{{ t('email') }}</mat-label>
        <input matInput type="email" formControlName="email" required="required" />
        <mat-error *ngIf="email.hasError('email')">{{ t('error.requiredValidEmail') }}</mat-error>
      </mat-form-field>
      <mat-form-field [formGroup]="signInForm">
        <mat-label>{{ t('password') }}</mat-label>
        <input #pwdInput matInput type="password" formControlName="password" required="required" />
      </mat-form-field>
      <sibat-primary-button
        submit="true"
        (clicked)="sendLogin()"
        [disabled]="signInForm.touched && signInForm.invalid"
        [label]="t('login')"
      >
      </sibat-primary-button>
      <br />
      <br />
      <sibat-tertiary-button (clicked)="forgotPassword()" [label]="t('passwordForgotten')"> </sibat-tertiary-button>
    </form>
  `,
  styleUrls: ['sign-in.component.scss'],
})
export class SignInComponent {
  @ViewChild('pwdInput') pwdInput: ElementRef;
  signInForm: UntypedFormGroup;
  error$: Observable<Error | undefined>;
  readonly email = this.formBuilder.control('', [Validators.required, Validators.email]);
  readonly password = this.formBuilder.control('', Validators.required);

  constructor(private store: Store, private formBuilder: UntypedFormBuilder, private router: Router) {
    this.signInForm = this.formBuilder.group({
      email: this.email,
      password: this.password,
    });
    this.error$ = this.store.select(selectAuthenticationError);
  }

  sendLogin() {
    this.store.dispatch(
      signIn({
        email: this.signInForm.value.email,
        password: this.signInForm.value.password,
      })
    );
  }

  forgotPassword() {
    this.store.dispatch(clearAuthenticationError());
    this.router.navigate(['/forgotPassword']);
  }
}
