import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Municipality } from '../model/municipality.model';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityService {
  baseUrl = `${environment.apiUrl}/municipalities`;

  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<Municipality[]> {
    return this.http.get<Municipality[]>(this.baseUrl);
  }
}
