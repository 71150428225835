import { Component, Input } from '@angular/core';
import { Building, BUILDING_STATUS } from '../model/building.model';
import { BatchNextControlDateEditDialogComponent } from
    '../batch-next-control-date-edit-dialog/batch-next-control-date-edit-dialog.component';
import { updateNextControlDates } from '../store/building.action';
import { DialogService } from '../../shared/service/dialog.service';
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { selectIsSCPIOrAbove } from '../../authentication/store/user.selector';
import { COLOR_TYPOLOGY } from '../model/typology.model';

@Component({
  selector: 'sibat-building-info',
  templateUrl: `building-info.component.html`,
  styleUrls: ['building-info.component.scss'],
})
export class BuildingInfoComponent {
  @Input() building: Building;
  @Input() section: 'titleMO' | 'titleSibat' = 'titleMO'; // Add this line
  selectIsSCPIOrAbove$ = this.store.select(selectIsSCPIOrAbove);
  readonly buildingStatus = BUILDING_STATUS;
  readonly colorTypology = COLOR_TYPOLOGY;


  constructor(
    private dialogService: DialogService,
    private datePipe: DatePipe,
    private store: Store
  ) {}

  async editNextControlDate() {
    let existingDate;
    if (this.building.nextControlDate) {
      existingDate = this.datePipe.transform(this.building.nextControlDate, 'dd.MM.yyy');
    }
    const nextControlDate = await this.dialogService.openDialogComponentAsync<string | null>(
      BatchNextControlDateEditDialogComponent,
      {
        count: 1,
        existingDate,
      }
    );

    if (nextControlDate || nextControlDate === null) {
      this.store.dispatch(
        updateNextControlDates({
          buildingIds: [this.building.id],
          date: nextControlDate,
        })
      );
    }
  }
  getTextColor(color: string | undefined): string {
    switch (color) {
      case 'vert':
        return 'green';
      case 'rouge':
        return 'red';
      default:
        return 'inherit';
    }
  }
}
