import { createAction, props } from '@ngrx/store';
import { FeatureFlag } from '../model/feature-flags.model';

export const getFeatureFlags = createAction('[Features] get feature flags');

export const getFeatureFlagsSuccess = createAction('[Features] get feature flags success', props<{ featureFlags: FeatureFlag[] }>());

export const getFeatureFlagsFailure = createAction('[Features] get feature flag failure', props<{ error: Error }>());

export const changeFeatureFlagState = createAction('[Admin] change feature state', props<{ key: string; enabled: boolean }>());

export const changeFeatureFlagStateSuccess = createAction(
  '[Admin] change feature state success',
  props<{ key: string; enabled: boolean }>()
);

export const changeFeatureFlagStateFailure = createAction('[Admin] change feature flag state failure ', props<{ error: Error }>());
