<ng-container *transloco="let t; read: 'building.documents'">
  <div mat-dialog-title>
    <h2>{{ fileInfo.fileName }}</h2>
  </div>

  <mat-dialog-content>
    <div class="content">
      <p [innerHtml]="t('fileAlreadyExists')"></p>
      <mat-radio-group class="vertical-radio-group" [formControl]="uploadAction" name="fileTypeGroup">
        <mat-radio-button value="overwrite">{{ t('replaceExistingFile') }}</mat-radio-button>
        <mat-radio-button value="keepBoth">{{ t('keepBothFiles') }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <sibat-validate-button class="mat-button-base" (clicked)="validate()"
      [label]="'building.documents.validate' | transloco">
    </sibat-validate-button>

    <sibat-secondary-button class="mat-button-base" (clicked)="cancel()"
      [label]="'building.documents.cancel' | transloco">
    </sibat-secondary-button>
  </mat-dialog-actions>
</ng-container>
