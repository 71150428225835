import { Action, createReducer, on } from '@ngrx/store';
import { FeatureFlag } from '../model/feature-flags.model';
import { changeFeatureFlagStateSuccess, getFeatureFlagsSuccess } from './feature-flags.action';

export default interface FeatureFlagsState {
  featureFlags: FeatureFlag[];
}

export const initialState: FeatureFlagsState = {
  featureFlags: [],
};

const userReducer = createReducer(
  initialState,
  on(getFeatureFlagsSuccess, (state, action): FeatureFlagsState => ({ ...state, featureFlags: action.featureFlags })),
  on(
    changeFeatureFlagStateSuccess,
    (state, action): FeatureFlagsState => ({
      ...state,
      featureFlags: state.featureFlags.map(flag => (flag.key === action.key ? { key: flag.key, enabled: action.enabled } : flag)),
    })
  )
);

export const reducer = (state: FeatureFlagsState | undefined, action: Action): FeatureFlagsState => userReducer(state, action);
