<mat-drawer-container>
  <mat-drawer #drawer mode="side" [opened]="true">
    <ng-content select="[nav-header]"></ng-content>
    <ng-content select="[nav-list]"></ng-content>
  </mat-drawer>

  <div class="page-content">
    <ng-content select="[content-header]"></ng-content>
    <router-outlet></router-outlet>
  </div>

</mat-drawer-container>
