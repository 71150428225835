import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sibat-sign-in-page',
  template: `
    <section *transloco="let t; read: 'authentication'">
      <article>
        <header>
          <h1>{{ t('authenticate') }}</h1>
        </header>
        <sibat-signin></sibat-signin>
      </article>
      <hr />
      <article>
        <header>
          <h1>{{ t('firstAccess') }}</h1>
        </header>
        <p>
          <sibat-tertiary-button (clicked)="firstAccess()" [label]="t('clickHere')"> </sibat-tertiary-button>
        </p>
      </article>
      <hr />
      <article>
        <p>
          {{ t('noAccount') }}&nbsp;
          <sibat-link-button [href]="supportEmail" [label]="t('contactUs')" [external]="true"> </sibat-link-button>
        </p>
      </article>
    </section>
  `,
  styleUrls: ['../css/authentication.scss', 'sign-in-page.component.scss'],
})
export class SignInPageComponent {
  supportEmail = `mailto:${environment.supportEmail}`;

  constructor(private router: Router) {}

  firstAccess() {
    this.router.navigate(['/forgotPassword']);
  }
}
