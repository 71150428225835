import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  setAffectationsFilter,
  setBuildingClassesFilter,
  setConstructionPeriodsFilter,
  setMunicipalitiesFilter,
  setTypologiesFilter
} from '../../building/store/buildings.action';
import {
  selectAffectationsFilter,
  selectBuildingClassesFilter,
  selectConstructionPeriodsFilter,
  selectControlledStateFilter,
  selectMunicipalitiesFilter,
  selectTypologiesFilter
} from '../../building/store/reference-data.selector';
import { MapQueryService } from '../service/map-query.service';
import { setControlledStateFilter } from './../../building/store/buildings.action';
import { clearExtent, updateMapQuery, updateMapQueryFailure, updateMapQuerySuccess } from './map.action';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapEffects {
  updateMapQueryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMapQuery),
      concatLatestFrom(() => [
        this.store.select(selectMunicipalitiesFilter),
        this.store.select(selectTypologiesFilter),
        this.store.select(selectBuildingClassesFilter),
        this.store.select(selectConstructionPeriodsFilter),
        this.store.select(selectAffectationsFilter),
        this.store.select(selectControlledStateFilter),
      ]),
      mergeMap(([, municipalities, typologies, buildingClasses, constructionPeriods, affectations, controlledState]) => {
        const query = this.mapQueryService.createFilterQuery(
          municipalities,
          typologies,
          buildingClasses,
          constructionPeriods,
          affectations,
          controlledState
        );
        return of(updateMapQuerySuccess({ query })).pipe(
          catchError((error) => {
            console.error('Error updating map query:', error);
            return of(updateMapQueryFailure({ error, payload:  query }));
          })
        );
      })
    )
  );

  filterBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        setMunicipalitiesFilter,
        setTypologiesFilter,
        setBuildingClassesFilter,
        setConstructionPeriodsFilter,
        setAffectationsFilter,
        setControlledStateFilter
      ),
      map(() => updateMapQuery())
    )
  );

  clearExtentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        setMunicipalitiesFilter,
        setTypologiesFilter,
        setBuildingClassesFilter,
        setConstructionPeriodsFilter,
        setAffectationsFilter,
        setControlledStateFilter
      ),
      map(() => clearExtent())
    )
  );

  constructor(private actions$: Actions, private store: Store, private mapQueryService: MapQueryService) {}
}
