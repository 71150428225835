import { BuildingsLayerAttrs } from '../model/map-extent.model';
import { CONTROLLED, NOT_CONTROLLED } from './../../building/model/filter';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapQueryService {
  createFilterQuery(
    municipalities: number[] = [],
    typologies: string[] = [],
    buildingClasses: number[] = [],
    constructionPeriods: number[] = [],
    affectations: string[] = [],
    controlledState = '',
  ): string {
    //  Query construction
    const filtersQuery: string[] = [];
    // Municipalities
    if (municipalities.length > 0) {
      // eslint-disable-next-line @typescript-eslint/quotes
      filtersQuery.push(`${BuildingsLayerAttrs.FOSNR} IN (${municipalities.join(", ")})`);
    }
    // Typologies
    if (typologies.length > 0) {
      // eslint-disable-next-line @typescript-eslint/quotes
      filtersQuery.push(`${BuildingsLayerAttrs.Color} IN (\'${typologies.join("', '")}\')`);
    }
    // BuildingClasses
    if (buildingClasses.length > 0) {
      // eslint-disable-next-line @typescript-eslint/quotes
      filtersQuery.push(`${BuildingsLayerAttrs.GKLAS} IN (${buildingClasses.join(', ')})`);
    }
    // Construction Period
    if (constructionPeriods.length > 0) {
      // eslint-disable-next-line @typescript-eslint/quotes
      filtersQuery.push(`${BuildingsLayerAttrs.GBAUP} IN (${constructionPeriods.join(', ')})`);
    }
    // Affectation
    if (affectations.length > 0) {
      const affectationQuery = affectations.map(affectation =>
        affectation === 'no_affectation'
          ? `${BuildingsLayerAttrs.Affectations} = ''`
          : `${BuildingsLayerAttrs.Affectations} LIKE '%${affectation}%'`
      );
      filtersQuery.push('(' + affectationQuery.join(' OR ') + ')');
    }
    // With Last Control Date
    if (controlledState === CONTROLLED) {
      filtersQuery.push('LAST_CONTROL_DATE IS NOT NULL');
    }
    // Without Last Control Date
    if (controlledState === NOT_CONTROLLED) {
      filtersQuery.push('LAST_CONTROL_DATE IS NULL');
    }
    return filtersQuery.join(' AND ');
  }
}
