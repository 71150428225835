import { Message } from '../../model/notification.model';
import { createAction, props } from '@ngrx/store';
import { BuildingDocument } from '../model/building-document.model';
import { HttpErrorResponse } from '@angular/common/http';

export const uploadBuildingDocument = createAction(
  '[BuildingDocument] upload buildingDocument',
  props<{ file: File; nestedFolder: string; overwrite: boolean }>()
);

export const confirmUploadBuildingDocument = createAction(
  '[BuildingDocument] confirm upload BuildingDocument',
  props<{ file: File; nestedFolder: string }>()
);

export const uploadBuildingDocumentSuccess = createAction(
  '[BuildingDocument] upload buildingDocument success',
  props<{ buildingId: number; notification: Message }>()
);

export const uploadBuildingDocumentFailure = createAction(
  '[BuildingDocument] upload buildingDocument failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);

export const getCurrentBuildingDocuments = createAction('[BuildingDocument] get current buildingDocuments');

export const changeDirectory = createAction('[BuildingDocument] change directory', props<{ path: string }>());
export const changeDirectorySuccess = createAction(
  '[BuildingDocument] change directory success',
  props<{ documents: BuildingDocument[]; path: string }>()
);
export const changeDirectoryFailure = createAction(
  '[BuildingDocument] change directory failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);

export const getBuildingDocumentsSuccess = createAction(
  '[BuildingDocument] get buildingDocuments success',
  props<{ buildingDocuments: BuildingDocument[] }>()
);

export const getBuildingDocumentsFailure = createAction(
  '[BuildingDocument] get buildingDocuments failure',
  props<{ error: any; notification: Message }>()
);

export const downloadBuildingDocument = createAction('[BuildingDocument] download document', props<{ filename: string }>());

export const downloadBuildingDocumentFailure = createAction(
  '[BuildingDocument] download document failure',
  props<{ error: any; notification: Message }>()
);

export const downloadBuildingDocumentSuccess = createAction('[BuildingDocument] download document success');

export const deleteBuildingDocument = createAction('[BuildingDocument] delete document', props<{ fileName: string }>());

export const deleteBuildingDocumentSuccess = createAction(
  '[BuildingDocument] delete document success',
  props<{ fileName: string; notification: Message }>()
);

export const deleteBuildingDocumentFailure = createAction(
  '[BuildingDocument] delete document failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);

export const createBuildingSubFolder = createAction('[BuildingDocument] create subfolder', props<{ folderName: string }>());

export const createBuildingSubFolderSuccess = createAction(
  '[BuildingDocument] create subfolder success',
  props<{ folderName: string; notification: Message }>()
);

export const createBuildingSubFolderFailure = createAction(
  '[BuildingDocument] create subfolder failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);

export const moveDocument = createAction(
  '[BuildingDocument] move document',
  props<{ fromPath: string; toPath: string; isFolder: boolean }>()
);

export const moveDocumentSuccess = createAction(
  '[BuildingDocument] move document success',
  props<{ fromPath: string; toPath: string; notification: Message }>()
);

export const moveDocumentFailure = createAction(
  '[BuildingDocument] move document failure',
  props<{ error: HttpErrorResponse; notification: Message }>()
);
