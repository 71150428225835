import { Message } from '../../model/notification.model';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AddDefectCompliance,
  Control,
  ControlDetails,
  ControlReportInfo,
  ControlsStats,
  ControlSummary,
  DefectDto,
  DefectSummary,
  DefectThumbnail,
  EditDefect,
  StoredOfflineEditDefect,
} from '../model/control.model';
import { EditHistoryWithValidatedControl } from '../../building/model/history.model';

// HANDLE ERROR

export const reportControlError = createAction(
  '[Control] error while calling building APIs',
  props<{ error: HttpErrorResponse; sourceActionType: string; payload: any }>()
);

// CONTROL

export const startNewControl = createAction('[Control] start new control', props<{ buildingId?: number }>());

export const startNewControlSuccess = createAction('[Control] start new control success', props<{ ongoingControl: Control }>());

export const startNewControlFailure = createAction(
  '[Control] start new control failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const clearCurrentControl = createAction('[Control] clear current control');

export const uploadControlReport = createAction(
  '[Control] upload control report',
  props<{ buildingId: number; controlDate: Date; report: File; overwrite: boolean }>()
);

export const confirmUploadControlReport = createAction(
  '[Control] confirm upload control report',
  props<{ buildingId: number; controlDate: Date; report: File }>()
);

export const uploadControlReportSuccess = createAction('[Control] upload control report success', props<{ control: Control }>());

export const uploadControlReportFailure = createAction(
  '[Control] upload control report failure',
  props<{ error: HttpErrorResponse; payload: any }>()
);

export const extractOngoingControl = createAction('[Control] extract control from retrieved building', props<{ control?: Control }>());

export const refreshOngoingControl = createAction('[Control] refresh ongoing control', props<{ buildingId: number }>());

export const refreshOngoingControlSuccess = createAction(
  '[Control] refresh ongoing control success',
  props<{ ongoingControl?: Control }>()
);

export const refreshOngoingControlFailure = createAction(
  '[Control] refresh ongoing control failure',
  props<{ error: HttpErrorResponse; payload: any }>()
);

export const updateControlDetails = createAction(
  '[Control] update control details',
  props<{ controlId: number; control: ControlDetails }>()
);

export const updateControlDetailsSuccess = createAction(
  '[Control] update control details success',
  props<{ control: Control; notification: Message }>()
);

export const updateControlDetailsFailure = createAction(
  '[Control] update control details failure',
  props<{ error: HttpErrorResponse; controlId: number; payload: any; notification: Message }>()
);

export const previewControlReport = createAction('[Control] preview control report', props<{ source: number | ControlReportInfo }>());

export const previewControlReportFailure = createAction(
  '[Control] preview control report failure',
  props<{ error: HttpErrorResponse; source: number | ControlReportInfo; notification: Message }>()
);

export const startOrphanControl = createAction('[Control] start orphan control');

export const startOrphanControlSuccess = createAction(
  '[Control] start orphan control success',
  props<{ control: Control; notification: Message }>()
);

export const startOrphanControlFailure = createAction(
  '[Control] start orphan control failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const refreshOrphanControls = createAction('[Control] refresh orphan controls');

export const refreshOrphanControlsSuccess = createAction(
  '[Control] refresh orphan controls success',
  props<{ orphanControls: ControlSummary[] }>()
);

export const refreshOrphanControlsFailure = createAction(
  '[Control] refresh orphan controls failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const refreshOrphanControl = createAction('[Control] refresh orphan control', props<{ controlId: number }>());

export const refreshOrphanControlSuccess = createAction('[Control] refresh orphan control success', props<{ control: Control }>());

export const refreshOrphanControlFailure = createAction(
  '[Control] refresh orphan control failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const linkControlToBuilding = createAction('[Control] link control to building', props<{ controlId: number; buildingId: number }>());

export const linkControlToBuildingSuccess = createAction(
  '[Control] link control to building success',
  props<{ control: Control; notification: Message }>()
);

export const linkControlToBuildingFailure = createAction(
  '[Control] link control to building failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const cancelOngoingControl = createAction('[Control] cancel ongoing control', props<{ controlId: number }>());

export const cancelOngoingControlSuccess = createAction('[Control] cancel control success', props<{ notification: Message }>());

export const cancelOngoingControlFailure = createAction(
  '[Control] cancel control failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

// DEFECTS

export const addDefect = createAction('[Control] add defect', props<{ controlId: number; defect: EditDefect }>());

export const addDefectSuccess = createAction('[Control] add defect success', props<{ control: Control }>());

export const storeOfflineDefectSuccess = createAction('[Control] store offline defect success');

export const sendDefect = createAction('[Control] send defect', props<{ defect: EditDefect; controlId: number }>());

export const sendDefectSuccess = createAction('[Control] send defect success', props<{ control: Control; notification: Message }>());

export const sendDefectFailure = createAction(
  '[Control] send defect failure',
  props<{ error: HttpErrorResponse; defect: EditDefect; payload: any; notification: Message }>()
);

export const removeDefect = createAction('[Control] remove defect', props<{ controlId: number; defectId: number }>());

export const removeDefectSuccess = createAction('[Control] remove defect success', props<{ control: Control; notification: Message }>());

export const removeDefectFailure = createAction(
  '[Control] remove defect failure',
  props<{ error: HttpErrorResponse; defectId: number; payload: any; notification: Message }>()
);

export const updateDefect = createAction('[Control] update defect', props<{ controlId: number; editDefect: EditDefect }>());

export const updateDefectSuccess = createAction('[Control] update defect success', props<{ control: Control; notification: Message }>());

export const updateDefectFailure = createAction(
  '[Control] update defect failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const updateDefectsOrder = createAction('[Control] update defects order', props<{ controlId: number; defects: DefectDto[] }>());

export const updateDefectsOrderSuccess = createAction('[Control] update defects order success', props<{ defects: DefectDto[] }>());

export const updateDefectsOrderFailure = createAction(
  '[Control] update defects order failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const fetchControl = createAction('[Control] Fetch control', props<{ controlId: number }>());

export const fetchControlSuccess = createAction('[Control] Fetch control success', props<{ control: Control }>());

export const fetchControlFailure = createAction('[Control] Fetch control failure', props<{ error: HttpErrorResponse; payload: any }>());

export const clearControlDefects = createAction('[Control] Clear control defects');

// OFFLINE DEFECTS

export const addOfflineDefect = createAction('[Control] add offline defect', props<{ defect: EditDefect }>());

export const addOfflineDefectSuccess = createAction('[Control] add offline defect success');

export const addOfflineDefectFailure = createAction(
  '[Control] add offline defect failure',
  props<{ error: HttpErrorResponse; defect: EditDefect; payload: any }>()
);

export const removeOfflineDefect = createAction('[Control] remove offline defect', props<{ defectOfflineId: number }>());

export const updateOfflineDefect = createAction('[Control] update offline defect', props<{ defect: StoredOfflineEditDefect }>());

export const updateOfflineDefectSuccess = createAction('[Control] update offline defect success', props<{ notification: Message }>());

export const updateOfflineDefectFailure = createAction(
  '[Control] update offline defect failure',
  props<{ error?: any; payload: any; notification: Message }>()
);

export const uploadOfflineDefects = createAction('[Control] upload offline defects', props<{ controlId: number }>());

export const sendOfflineDefects = createAction(
  '[Control] send offline defects',
  props<{ controlId: number; offlineDefects: StoredOfflineEditDefect[] }>()
);

export const sendOfflineDefectsSuccess = createAction(
  '[Control] send offline defects success',
  props<{ control: Control; defectOfflineIds: number[] }>()
);

export const sendOfflineDefectsFailure = createAction(
  '[Control] send offline defects failure',
  props<{ error: HttpErrorResponse; payload: any }>()
);

export const removeOfflineDefectsSuccess = createAction('[Control] remove offline defects success');

export const removeOfflineDefectsFailure = createAction('[Control] remove offline defects failure', props<{ error?: any; payload: any }>());

export const removeOfflineDefectSuccess = createAction('[Control] remove offline defect success', props<{ notification: Message }>());

export const removeOfflineDefectFailure = createAction(
  '[Control] remove offline defect failure',
  props<{ error?: any; payload: any; notification: Message }>()
);

export const refreshOfflineDefectsInStore = createAction('[Control] refresh offline defects in store');

export const refreshOfflineDefectsInStoreSuccess = createAction(
  '[Control] refresh offline defects in store success',
  props<{ defects: StoredOfflineEditDefect[] }>()
);

export const refreshOfflineDefectsInStoreFailure = createAction(
  '[Control] refresh offline defects in store failure',
  props<{ error: Error; payload: any }>()
);

// PICTURES

export const fetchThumbnails = createAction('[Control] fetch defects thumbnails', props<{ controlId: number }>());

export const fetchThumbnailsSuccess = createAction(
  '[Control] fetch defects thumbnails success',
  props<{ thumbnails: DefectThumbnail[] }>()
);

export const downloadFullScreenDefectPicture = createAction('[Control] get thumbnail', props<{ controlId: number; pictureName: string }>());

export const downloadFullScreenDefectPictureSuccess = createAction(
  '[Control] download full screen defect picture success',
  props<{ pictureData: string }>()
);

export const downloadFullScreenDefectPictureFailure = createAction(
  '[Control] download full screen defect picture failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const setFullScreenDefectPicture = createAction('[Control] set full screen defect picture', props<{ pictureData: string }>());

// APPROVAL AND VALIDATION

export const requestExpertApproval = createAction('[Control] request expert validation', props<{ buildingId: number }>());

export const requestExpertApprovalSuccess = createAction(
  '[Control] request expert validation success',
  props<{ control: Control; notification: Message }>()
);

export const requestExpertApprovalFailure = createAction(
  '[Control] request expert validation failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const cancelRequestExpertApproval = createAction('[Control] cancel request expert validation', props<{ buildingId: number }>());

export const cancelRequestExpertApprovalSuccess = createAction(
  '[Control] cancel request expert validation success',
  props<{ control: Control; notification: Message }>()
);

export const cancelRequestExpertApprovalFailure = createAction(
  '[Control] cancel request expert validation failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const approveControl = createAction('[Control] approve control', props<{ buildingId: number }>());

export const approveControlSuccess = createAction('[Control] approve control success', props<{ control: Control }>());

export const approveControlFailure = createAction(
  '[Control] approve controle failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const validateControl = createAction('[Control] validate control', props<{ buildingId: number }>());

export const validateControlSuccess = createAction(
  '[Control] validate control success',
  props<{ buildingId: number; historyWithControl: EditHistoryWithValidatedControl; notification: Message }>()
);

export const validateControlFailure = createAction(
  '[Control] validate control failure',
  props<{ error: HttpErrorResponse; buildingId: number; payload: any; notification: Message }>()
);

// COCKPIT DEFECT SUMMARY

export const findNonCompliantDefectSummary = createAction('[Defect] Find non-compliant defect summary', props<{ page: number }>());
export const findNonCompliantDefectSummarySuccess = createAction(
  '[Defect] Find non-compliant defect summary success',
  props<{ defects: DefectSummary[]; count: number; page: number }>()
);
export const findNonCompliantDefectSummaryFailure = createAction(
  '[Defect] Find non-compliant defect summary failure',
  props<{ error: HttpErrorResponse; payload: any }>()
);

// COCKPIT CONTROLS DASHBOARD

export const getControlsStats = createAction(
  '[Control] get controls stats',
  props<{ periodStart: string; periodEnd: string; municipality?: number[] }>()
);
export const getControlsStatsSuccess = createAction('[Control] get controls stats success', props<{ controlsStats: ControlsStats[] }>());
export const getControlsStatsFailure = createAction('[Control] get controls stats failure', props<{ notification: Message }>());

// COMPLIANCE CONTROL DEFECT SELECTION

export const toggleControlDefectSelection = createAction('[Compliance] Toggle defect selection', props<{ defect: DefectDto }>());

export const addDefectCompliance = createAction('[Compliance] Add defect compliance', props<{ form: AddDefectCompliance }>());

export const addDefectComplianceSuccess = createAction(
  '[Compliance] Add defect compliance success',
  props<{ control: Control; notification: Message }>()
);

export const addDefectComplianceFailure = createAction(
  '[Compliance] Add defect compliance failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const downloadComplianceProof = createAction(
  '[Compliance] Download compliance proof',
  props<{ controlId: number; defectId: number; proofName: string }>()
);

export const downloadComplianceProofSuccess = createAction('[Compliance] Download compliance proof success');

export const downloadComplianceProofFailure = createAction(
  '[Compliance] Download compliance proof failure',
  props<{ error: HttpErrorResponse; payload: any }>()
);
