export const INSTALLATION_TYPES = {
  sprinkler: 'SPRINKLER',
  fireDetection: 'FIRE_DETECTION',
} as const;
export type InstallationType = typeof INSTALLATION_TYPES[keyof typeof INSTALLATION_TYPES];

export const INSTALLATION_NECESSITY = {
  mandatory: 'MANDATORY',
  optional: 'OPTIONAL',
  unknown: 'UNKNOWN',
} as const;
export type InstallationNecessity = typeof INSTALLATION_NECESSITY[keyof typeof INSTALLATION_NECESSITY];

export const MONITORING_SCOPE_TYPES = {
  full: 'FULL',
  partial: 'PARTIAL',
} as const;
export type MonitoringScopeType = typeof MONITORING_SCOPE_TYPES[keyof typeof MONITORING_SCOPE_TYPES];

export const CEA_CONNECTED = {
  yes: 'YES',
  no: 'NO',
  unknown: 'UNKNOWN',
} as const;
export type CeaConnected = typeof CEA_CONNECTED[keyof typeof CEA_CONNECTED];

export const ITECH_UPDATE_ACTION = {
  announcement: ':update-announcement',
  installationCertificate: ':add-certificate',
  maintenanceContract: ':add-maintenance-contract',
  receptionControlReport: ':add-reception-control',
  complianceReport: ':update-compliance',
  periodicControlReport: ':add-periodic-control',
  assessmentReport: ':add-assessment-report',
  shutdown: ':shutdown',
  temporaryShutdown: ':temporary-shutdown',
  endTemporaryShutdown: ':end-temporary-shutdown',
  cancelMaintenanceContract: ':cancel-maintenance-contract',
  nextControlDate: ':update-next-control-date',
} as const;
export type ItechUpdateAction = typeof ITECH_UPDATE_ACTION[keyof typeof ITECH_UPDATE_ACTION];

export interface AnnouncementForm {
  file: File;
  installationType: string;
  planner: string;
  installer: string;
  validator: string;
  necessity: string;
  ceaConnected: string;
  transmitter?: string;
}

export interface TechnicalInstallation {
  id: number;
  installationType: InstallationType;
  planner: string;
  installer: string;
  validator: string;
  necessity: InstallationNecessity;
  ceaConnected: CeaConnected;
  transmitter?: string;
  createdAt: string;
  installationDate?: string;
  responsible?: string;
  nextAssessmentYear?: number;
  maintenanceFirm?: string;
  controlBody?: string;
  receptionControlDate?: string;
  hasDefects?: boolean;
  lastControlDate?: string;
  nextControlDate?: string;
  periodicController?: string;
  assessmentFirm?: string;
  shutdownDate?: string;
  shutdownReason?: string;
  monitoringScope?: MonitoringScopeType;
  designation?: string;
  temporaryShutdownEndDate?: string;
}

export interface InstallationCertificateForm {
  file: File;
  installationDate: string;
  responsible: string;
}

export interface MaintenanceContractForm {
  file: File;
  maintenanceFirm: string;
}

export interface ComplianceForm {
  file: File;
  hasDefects: boolean;
}

export interface ReceptionControlForm {
  file: File;
  controlBody: string;
  controlDate: string;
  hasDefects?: boolean;
}

export interface UpdateMessage {
  form: any;
  installationId: number;
  buildingId: number;
}
