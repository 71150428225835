import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import AdminState, * as fromAdmin from '../admin/store/admin.reducer';
import AuthenticationState, * as fromAuthentication from '../authentication/store/authentication.reducer';
import UserState, * as fromUser from '../authentication/store/user.reducer';
import BuildingState, * as fromBuilding from '../building/store/building.reducer';
import BuildingsState, * as fromBuildings from '../building/store/buildings.reducer';
import * as fromControl from '../control/store';
import ControlState from '../control/store/control.reducer';
import FeatureFlagState, * as fromFeatureFlags from '../features/store/feature-flags.reducer';
import * as fromLoading from '../loading/store';
import LoadingState from '../loading/store/loading.reducer';
import MapState, * as fromMap from '../map/store/map.reducer';
import NetworkState, * as fromNetwork from '../network/store/network.reducer';
import { RouterStateUrl } from '../router/sibat-route-serializer';
import EnvironmentConfigState, * as fromEnvironmentConfig from '../environment-config/store/environment-config.reducer';
import AssessmentState from '../assessment/store/assessment.reducer';
import * as fromAssessment from '../assessment/store';

export interface State {
  buildings: BuildingsState;
  building: BuildingState;
  control: ControlState;
  map: MapState;
  loading: LoadingState;
  authentication: AuthenticationState;
  user: UserState;
  admin: AdminState;
  router?: RouterReducerState<RouterStateUrl>;
  featureFlags: FeatureFlagState;
  network: NetworkState;
  environmentConfig: EnvironmentConfigState;
  assessment: AssessmentState;
}

export const reducers: ActionReducerMap<State> = {
  buildings: fromBuildings.reducer,
  building: fromBuilding.reducer,
  control: fromControl.reducer,
  map: fromMap.reducer,
  loading: fromLoading.reducer,
  authentication: fromAuthentication.reducer,
  user: fromUser.reducer,
  admin: fromAdmin.reducer,
  router: routerReducer,
  featureFlags: fromFeatureFlags.reducer,
  network: fromNetwork.reducer,
  environmentConfig: fromEnvironmentConfig.reducer,
  assessment: fromAssessment.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
