import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { EditDefect, EditPictureBase64, FileWithPreviewBase64, newEditDefect } from '../model/control.model';
import { extractPreview, compressImage, addUniqueIdPrefix } from '../../shared/images';
import { extendTextArea } from 'src/app/shared/style';
import { downloadFullScreenDefectPicture, setFullScreenDefectPicture } from '../store/control.action';
import { Store } from '@ngrx/store';
import { replaceNullValueWithUndefined } from 'src/app/shared/form-control';
@Component({
  selector: 'sibat-edit-defect',
  templateUrl: './edit-defect.component.html',
  animations: [trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate(400)])])],
  styleUrls: ['edit-defect.component.scss'],
})
export class EditDefectComponent implements OnChanges {
  @Input() thumbnails: Record<string, string> = {};
  @Input() controlId?: number;
  @Input() isReadOnly = false;
  @Output() defectSaved = new EventEmitter<EditDefect>();

  minDate: Date;
  readonly defectForm = this.formBuilder.group(
    {
      description: '',
      deadline: undefined,
      type: undefined,
      location: undefined,
      existingPictures: [[]],
      picturesToAdd: [[]],
      picturesToRemove: [[]],
    },
    { validators: Validators.required }
  );

  private originalDefect: EditDefect;

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) {
    const today = new Date();
    this.minDate = new Date(today.setDate(today.getDate() + 1));
  }

  get isEditionMode() {
    return this.originalDefect?.id && !this.isReadOnly;
  }

  @Input() set defect(defect: EditDefect) {
    this.originalDefect = defect;
    let initialValues: EditDefect;
    if (!defect) {
      initialValues = newEditDefect();
    } else {
      initialValues = Object.assign({}, defect);
    }

    this.defectForm.patchValue({
      description: initialValues.description,
      deadline: initialValues.deadline ? new Date(initialValues.deadline) : undefined,
      type: initialValues.type,
      location: initialValues.location,
      existingPictures: initialValues.existingPictures || [],
      picturesToAdd: initialValues.picturesToAdd || [],
      picturesToRemove: initialValues.picturesToRemove || [],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isReadOnly) {
      this.defectForm.disable();
    }
  }

  async onFileInput({ target }): Promise<void> {
    if (target.files[0]) {
      const compressedFile = await compressImage(target.files[0]);
      const fileWithPreview = await extractPreview(compressedFile);
      const fileWithPreviewAndUniqueId = addUniqueIdPrefix(fileWithPreview);
      this.addPicture(fileWithPreviewAndUniqueId);
    }
  }

  addPicture(fileWithPreview: FileWithPreviewBase64) {
    this.defectForm.patchValue({
      picturesToAdd: [...this.defectForm.value['picturesToAdd'], fileWithPreview],
    });
    this.defectForm.markAsDirty();
  }

  deleteExistingPicture(file: EditPictureBase64): void {
    this.defectForm.patchValue({
      picturesToRemove: [...this.defectForm.value['picturesToRemove'], file.name],
      existingPictures: [...this.defectForm.value['existingPictures']].filter(picture => picture.name !== file.name),
    });
    this.defectForm.markAsDirty();
  }

  deleteAddedPicture(file: FileWithPreviewBase64): void {
    this.defectForm.patchValue({
      picturesToAdd: [...this.defectForm.value['picturesToAdd']].filter(picture => picture.data !== file.data),
    });
    this.defectForm.markAsDirty();
  }

  saveDefect() {
    const currentDefect: EditDefect = this.originalDefect ? this.originalDefect : newEditDefect();
    const updatedDefect: EditDefect = { ...currentDefect, ...this.defectForm.value };
    this.defectSaved.emit(updatedDefect);
    this.defectForm.markAsPristine();
  }

  extendTextArea(element) {
    extendTextArea(element);
  }

  displayFullScreenImgWithData(pictureData: string) {
    this.store.dispatch(setFullScreenDefectPicture({ pictureData }));
  }

  displayFullScreenImgWithPictureName(pictureName: string) {
    if (this.controlId) {
      this.store.dispatch(
        downloadFullScreenDefectPicture({
          controlId: this.controlId,
          pictureName,
        })
      );
    }
  }

  preventRequiredDate(formControl: AbstractControl) {
    replaceNullValueWithUndefined(formControl);
  }
}
