import { Message } from './../../model/notification.model';
import { createAction, props } from '@ngrx/store';
import { Configuration } from '../model/configuration.model';
import { User } from '../model/user.model';
import { Motd } from '../model/motd.model';
import { UserUpdate } from '../../admin/model/user-creation.model';

export const UNDO_LOAD_USER_SUCCESS = 'UNDO_LOAD_USER_SUCCESS';

export const getUserAfterTokenLoaded = createAction('[User] get user');

export const getUserAfterTokenLoadedFailure = createAction('[User] loaded token could not be used');

export const getUserAfterLoginSuccess = createAction('[User] get user after login');

export const updateUserLanguage = createAction('[User] update user language', props<{ language: string }>());

export const updateUserLanguageSuccess = createAction('[User] update user language success', props<{ language: string }>());

export const updateUserLanguageFailure = createAction('[User] update user language failed');

export const getUserAfterLoginSuccessFailure = createAction('[User] could get user after successful login');

export const getUserSuccess = createAction('[User] get user success', props<{ user: User }>());

export const getUserSuccessWithRedirect = createAction('[User] get user success with redirect', props<{ user: User }>());

export const clearUser = createAction('[User] clear current user');

export const registerGisToken = createAction('[User] register GIS read token', props<{ user: User; arcGisUrl: string }>());

export const registerGisTokenSuccess = createAction('[User] successfully registered GIS read token');

export const registerGisTokenFailure = createAction(
  '[User] failed register GIS read token',
  props<{ error: Error; notification: Message }>()
);

export const getConfigSuccess = createAction('[User] get config success', props<{ user: User; configuration: Configuration }>());

export const showMotd = createAction('[User] show MOTD', props<{ motd: Motd }>());

export const markMotdAsRead = createAction('[User] mark MOTD as read', props<{ uuid: string }>());

export const updateUserInState = createAction('[User] Update User In State', props<{ updatedUser: UserUpdate }>());

export const updateActiveRole = createAction('[User] Update Active Role', props<{ newRole: string }>());

export const loadUser = createAction('[User] Load User');

export const loadUserSuccess = createAction('[User] Load User Success', props<{ user: User; previousUser?: User }>());

export const loadUserFailure = createAction('[User] Load User Failure', props<{ error: any }>());

export const checkUserAccessToBuilding = createAction('[Building] Check User Access to Building',
  props<{ idBuilding: number; idUser: number }>()
);

export const checkUserAccessToBuildingSuccess = createAction('[Building] Check User Access to Building Success',
  props<{ hasAccessToBuilding: boolean }>()
);

export const checkUserAccessToBuildingFailure = createAction('[Building] Check User Access to Building Failure',
  props<{ error: any }>()
);

export const undoLoadUserSuccess = createAction('[User] Undo Load User Success');
