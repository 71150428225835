import { Observable, Subscription } from 'rxjs';
import { DefectDto } from '../../control/model/control.model';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectControlDefects, selectControlDefectsInEdition, selectThumbnails } from 'src/app/control/store/control.selector';
import { fetchThumbnails } from 'src/app/control/store/control.action';
import { selectBuildingAddresses } from '../store/building.selector';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'sibat-control-defect-list',
  templateUrl: './control-defect-list.component.html',
  styleUrls: ['./control-defect-list.component.scss'],
})
export class ControlDefectListComponent implements OnInit, OnDestroy {
  controlId: number;
  addresses$: Observable<string[]>;
  defects$: Observable<DefectDto[]>;
  defectsInEdition$: Observable<DefectDto[]>;

  thumbnails: Record<string, string> = {};
  private selectThumbnailsSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<ControlDefectListComponent>,
    private store: Store,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: { controlId: number }
  ) {
    this.controlId = data.controlId;
  }

  ngOnInit(): void {
    this.store.dispatch(fetchThumbnails({ controlId: this.controlId }));
    this.defects$ = this.store.select(selectControlDefects);
    this.defectsInEdition$ = this.store.select(selectControlDefectsInEdition);
    this.addresses$ = this.store.select(selectBuildingAddresses);
    this.selectThumbnailsSubscription = this.store.select(selectThumbnails).subscribe(thumbnails => {
      if (!!thumbnails) {
        this.thumbnails = thumbnails;
      }
    });
  }

  close() {
    this.dialogRef.close();
    this.router.navigate([], { queryParams: { } });
  }

  trackById(_: number, item: DefectDto) {
    return item.id;
  }

  ngOnDestroy(): void {
    this.selectThumbnailsSubscription.unsubscribe();
  }
}
