<ng-container *ngIf="defects && defects.length">
  <div class="form-card" *transloco="let t; read: 'building.control'">
    <p>{{ t('defectsCompliance.formCardTitle') }}</p>

    <div class="list">
      <sibat-control-defect-item
        data-testid="control-list-defect-item-selected"
        *ngFor="let defect of defects"
        [defect]="defect"
        [thumbnails]="thumbnails"
        [controlId]="controlId"
        selected="true"
      ></sibat-control-defect-item>
    </div>

    <form [formGroup]="complianceForm" class="compliance-form">
      <h4>{{ t('defectsCompliance.formTitle') }}</h4>

      <mat-form-field>
        <mat-label>{{ t('defectsCompliance.addNote') }}</mat-label>
        <textarea matInput formControlName="description" (keyup)="extendTextArea($event.target)"></textarea>
        <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
      </mat-form-field>

      <fieldset>
        <legend>{{ t('defectsCompliance.documents') }}</legend>

        <mat-chip-list>
          <sibat-tag *ngFor="let file of complianceForm.value['files']" [name]="file.name" (delete)="removeFile(file)"> </sibat-tag>
        </mat-chip-list>

        <input #fileInput type="file" multiple (change)="addFiles($event.target.files)" style="display: none" />
        <sibat-dark-button (clicked)="fileInput.click()" [label]="t('defectsCompliance.addDocument')" icon="add"> </sibat-dark-button>
      </fieldset>

      <mat-form-field>
        <mat-label>{{ t('defectsCompliance.complianceDate') }}</mat-label>
        <input matInput formControlName="date" [matDatepicker]="controlDatePicker" (focus)="controlDatePicker.open()" />
        <mat-datepicker-toggle matSuffix [for]="controlDatePicker"></mat-datepicker-toggle>
        <mat-datepicker disabled="false" #controlDatePicker></mat-datepicker>
      </mat-form-field>

      <sibat-validate-button
        [label]="t('defectsCompliance.validate')"
        [disabled]="!complianceForm.valid"
        submit="true"
        (clicked)="saveDefectCompliance()"
      ></sibat-validate-button>
    </form>
  </div>
</ng-container>
