import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sibat-toggle-button',
  template: `
    <mat-slide-toggle
      class="toggle"
      data-testid="the-toggle"
      [disabled]="disabled"
      [checked]="checked"
      (change)="toggle.emit($event.checked)"
    >
      <span>{{ label }}</span>
    </mat-slide-toggle>
  `,
  styleUrls: ['buttons.scss'],
})
export class ToggleButtonComponent {
  @Input() label: string;
  @Input() disabled = false;
  @Input() checked = false;
  @Input() formControlName: string;
  @Output() toggle = new EventEmitter<boolean>();

  constructor() {}
}
