import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsOffline } from './store/network.selector';

@Component({
  selector: 'sibat-network-status',
  template: `
    <div *ngIf="offline$ | async" class="network-status-banner">
      <mat-icon>cloud_off</mat-icon>
      <span *transloco="let t">{{ t('notification.offline') }}</span>
    </div>
  `,
  styleUrls: ['network-status.component.scss'],
})
export class NetworkStatusComponent implements OnInit {
  offline$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.offline$ = this.store.select(selectIsOffline);
  }
}
