import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {
  deleteBuildingDocument,
  downloadBuildingDocument,
  getBuildingDocumentsFailure,
  getBuildingDocumentsSuccess,
  uploadBuildingDocument,
  uploadBuildingDocumentFailure,
  uploadBuildingDocumentSuccess,
  deleteBuildingDocumentSuccess,
  deleteBuildingDocumentFailure,
  downloadBuildingDocumentFailure,
  downloadBuildingDocumentSuccess,
  getCurrentBuildingDocuments,
  createBuildingSubFolder,
  createBuildingSubFolderFailure,
  createBuildingSubFolderSuccess,
  changeDirectory,
  changeDirectorySuccess,
  changeDirectoryFailure,
  moveDocument,
  moveDocumentSuccess,
  moveDocumentFailure,
  confirmUploadBuildingDocument,
} from './document.action';
import { EMPTY, of } from 'rxjs';
import { BuildingDocumentsService } from '../service/building-documents.service';
import { Store } from '@ngrx/store';
import { selectBuildingDocumentBrowsingData, selectCurrentBuildingId } from './building.selector';
import { uploadControlReportSuccess } from '../../control/store/control.action';
import { notifyInfo, notifyWarn } from 'src/app/model/notification.model';
import { noopAction } from 'src/app/shared/app.action';
import { DialogService } from 'src/app/shared/service/dialog.service';
import {
  FileAlreadyExistsDialogComponent, UploadOption
} from 'src/app/file-browser/duplicate-error-dialog/file-already-exists-dialog.component';
import { copyFileIncrement } from 'src/app/shared/files';
import { isFileAlreadyExistsError } from 'src/app/file-browser/model/sibat-file.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentEffects {
  uploadBuildingDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadBuildingDocument),
      concatLatestFrom(() => this.store.select(selectBuildingDocumentBrowsingData)),
      mergeMap(([{ file, nestedFolder, overwrite }, { buildingId, documentsPath }]) =>
        this.buildingDocumentsService.uploadBuildingDocument(buildingId, documentsPath, nestedFolder, file, overwrite).pipe(
          map(() => uploadBuildingDocumentSuccess({ buildingId, ...notifyInfo('notification.info.building.fileUploaded', true) })),
          catchError(error => {
            const fileAlreadyExists = isFileAlreadyExistsError(error.error.message);
            if (fileAlreadyExists) {
              return of(confirmUploadBuildingDocument({ file, nestedFolder }));
            } else {
              return of(uploadBuildingDocumentFailure({ error, ...notifyWarn('building.error.uploadFailed', true) }));
            }
          })
        )
      )
    )
  );

  confirmUploadBuildingDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmUploadBuildingDocument),
      mergeMap(({ file, nestedFolder }) => this.dialogService
      .openDialogComponent<UploadOption>(FileAlreadyExistsDialogComponent, {
        fileName: file.name,
      })
      .pipe(
        map(uploadOption => {
          if (uploadOption === 'overwrite') {
            return uploadBuildingDocument({ file, nestedFolder, overwrite: true });
          } else if (uploadOption === 'keepBoth') {
            const renamedFile = copyFileIncrement(file);
            return uploadBuildingDocument({ file: renamedFile, nestedFolder, overwrite: false });
          }

          return noopAction();
        })
      ))
    )
  );

  changeDirectoryEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeDirectory),
      concatLatestFrom(() => this.store.select(selectCurrentBuildingId)),
      switchMap(([{ path }, buildingId]) => {
        if (buildingId) {
          return this.buildingDocumentsService.getBuildingDocuments(buildingId as number, path).pipe(
            map(documents => changeDirectorySuccess({path, documents})),
            catchError(error => of(changeDirectoryFailure({error, ...notifyWarn('building.error.unexpected', true)})))
          );
        } else {
          return EMPTY;
        }
      })
    )
  );

  refreshBuildingDocumentAfterNavigationOrEdition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadBuildingDocumentSuccess, createBuildingSubFolderSuccess, uploadControlReportSuccess, moveDocumentSuccess),
      map(() => getCurrentBuildingDocuments())
    )
  );

  getCurrentBuildingDocumentsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentBuildingDocuments),
      concatLatestFrom(() => this.store.select(selectBuildingDocumentBrowsingData)),
      mergeMap(([, { buildingId, documentsPath }]) =>
        this.buildingDocumentsService.getBuildingDocuments(buildingId, documentsPath).pipe(
          map(buildingDocuments => getBuildingDocumentsSuccess({ buildingDocuments })),
          catchError(error => of(getBuildingDocumentsFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  downloadDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadBuildingDocument),
      concatLatestFrom(() => this.store.select(selectBuildingDocumentBrowsingData)),
      mergeMap(([{ filename }, { buildingId, documentsPath }]) =>
        this.buildingDocumentsService.downloadBuildingDocument(buildingId, documentsPath, filename).pipe(
          map(content => {
            const blob = new Blob([content], { type: 'application/octet-stream' });
            saveAs(blob, filename);
            return downloadBuildingDocumentSuccess();
          }),
          catchError(error => of(downloadBuildingDocumentFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  deleteDocumentEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBuildingDocument),
      concatLatestFrom(() => this.store.select(selectBuildingDocumentBrowsingData)),
      mergeMap(([{ fileName }, { buildingId, documentsPath }]) =>
        this.buildingDocumentsService.deleteBuildingDocument(buildingId, documentsPath, fileName).pipe(
          map(_ => deleteBuildingDocumentSuccess({ fileName, ...notifyInfo('notification.info.building.fileDeleted', true) })),
          catchError(error => of(deleteBuildingDocumentFailure({ error, ...notifyWarn('building.error.unexpected', true) })))
        )
      )
    )
  );

  createSubFolderEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBuildingSubFolder),
      concatLatestFrom(() => this.store.select(selectBuildingDocumentBrowsingData)),
      mergeMap(([{ folderName }, { buildingId, documentsPath }]) =>
        this.buildingDocumentsService.createBuildingSubFolder(buildingId, documentsPath, folderName).pipe(
          map(_ => createBuildingSubFolderSuccess({ folderName, ...notifyInfo('notification.info.building.folderCreated', true) })),
          catchError(error =>
            of(createBuildingSubFolderFailure({ error, ...notifyWarn('notification.warning.building.folderCreationFailure', true) }))
          )
        )
      )
    )
  );

  moveFileEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveDocument),
      concatLatestFrom(() => this.store.select(selectBuildingDocumentBrowsingData)),
      mergeMap(([{ fromPath, toPath, isFolder }, { buildingId }]) => {
        const fromBasePath = fromPath.split('/').slice(0, -1).join('/');
        const toBasePath = toPath.split('/').slice(0, -1).join('/');
        let successMessage = 'notification.info.building.successfullyMovedFile';
        let failureMessage = 'notification.warning.building.failedToMoveFile';

        if (isFolder) {
          successMessage = 'notification.info.building.successfullyMovedFolder';
          failureMessage = 'notification.info.building.failedToMoveFolder';
        } else if (fromBasePath === toBasePath) {
          successMessage = 'notification.info.building.successfullyRenamedFile';
          failureMessage = 'notification.info.building.failedToRename';
        }

        return this.buildingDocumentsService.moveBuildingDocument(buildingId, fromPath, toPath, isFolder).pipe(
          map(_ => moveDocumentSuccess({ fromPath, toPath, ...notifyInfo(successMessage, true) })),
          catchError(error => of(moveDocumentFailure({ error, ...notifyWarn(failureMessage, true) })))
        );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly buildingDocumentsService: BuildingDocumentsService,
    private readonly store: Store,
    private readonly dialogService: DialogService
  ) {}
}
