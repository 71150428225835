import Basemap from '@arcgis/core/Basemap';
import { environment } from '../../../environments/environment';
import { Configuration } from '../../authentication/model/configuration.model';
import WMTSLayer from '@arcgis/core/layers/WMTSLayer';

export const swissTopoService = () =>
  new Basemap({
    portalItem: {
      id: environment.swissTopoBasemapId,
    },
  });

export const orthoService = (configuration: Configuration) =>
  new Basemap({
    baseLayers: [
      new WMTSLayer({
        url: configuration.orthoPhotoUrl,
        id: 'orthoService',
        activeLayer: {
          id: environment.swissTopoImageLayer,
        },
      })
    ],
    title: 'orthoService',
    id: 'orthoService',
    thumbnailUrl: 'https://js.arcgis.com/4.2/esri/images/basemap/satellite.jpg',
  });
