import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectBuildingDocuments } from '../../building/store/building.selector';

@Component({
  selector: 'sibat-folder-name-input',
  template: `
    <div>
      <mat-form-field>
        <mat-label>{{ 'building.documents.folderName' | transloco }}</mat-label>
        <input #folderNameInput sibatAutofocus matInput type="text"
               [formControl]="folderNameForm" (keyup.enter)="onValidateFolderNameClick()"/>
        <mat-error *ngIf="folderNameForm.hasError('existingFolderName')">
          {{ 'building.documents.noFolderDuplicates' | transloco }}
        </mat-error>
      </mat-form-field>
      <div mat-dialog-actions>
        <button
          mat-stroked-button
          color="warn"
          (click)="onValidateFolderNameClick()"
          [disabled]="!!!folderNameForm.value || folderNameForm.hasError('existingFolderName')"
        >
          {{ 'building.documents.validate' | transloco }}
        </button>
        <button mat-stroked-button color="warn" (click)="closeDialog()">
          {{ 'building.documents.cancel' | transloco }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .mat-form-field {
        min-width: 50vw;
      }
    `,
  ],
})
export class FolderNameInputComponent implements OnInit {
  @ViewChild('folderNameInput', { static: true }) private folderNameInput: ElementRef;

  folderNameForm = new UntypedFormControl('', [Validators.required]);
  dataSource$ = this.store.select(selectBuildingDocuments);
  existingFolderNames: string[];

  constructor(public dialogRef: MatDialogRef<any, string>, private store: Store) {}

  ngOnInit(): void {
    this.folderNameForm.setErrors({ existingFolderName: false });
    this.dataSource$.subscribe(buildingDocuments => {
      this.existingFolderNames = buildingDocuments.filter(doc => doc.isFolder).map(doc => doc.name);
    });
  }

  onValidateFolderNameClick() {
    const folderName = this.folderNameForm.value;
    if (!this.existingFolderNames.includes(folderName)) {
      this.folderNameForm.setErrors({ existingFolderName: false });
      this.dialogRef.close(this.folderNameForm.value);
    } else {
      this.folderNameInput.nativeElement.blur();
      this.folderNameForm.setErrors({ existingFolderName: true });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
