import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class JWTService {
  readonly jwtCookieName = 'sibat_jwt';
  readonly cookieExpirationDays = 365;

  jwtToken?: string;
  decodedToken?: { [key: string]: string };

  constructor(
    private cookieService: CookieService,
  ) {}

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
      this.decodedToken = jwt_decode(this.jwtToken) as { [key: string]: string };
      this.cookieService.set(this.jwtCookieName, token, this.cookieExpirationDays, '/');
    }
  }

  loadToken() {
    const token = this.cookieService.get(this.jwtCookieName);
    if (token) {
      this.setToken(token);
    }
    return token;
  }

  clearToken() {
    this.jwtToken = undefined;
    this.decodedToken = undefined;
    this.cookieService.delete(this.jwtCookieName, '/');
  }

  getUser(): string | undefined {
    return this.decodedToken ? this.decodedToken.displayname : undefined;
  }

  getEmailId(): string | undefined {
    return this.decodedToken ? this.decodedToken.email : undefined;
  }

  getExpiryTime(): string | undefined {
    return this.decodedToken ? this.decodedToken.exp : undefined;
  }

  // TODO proper verification
  isTokenValid(): boolean {
    const expiryTime = Number(this.getExpiryTime());
    if (expiryTime) {
      return 1000 * expiryTime - new Date().getTime() >= 5000;
    } else {
      return false;
    }
  }
}
