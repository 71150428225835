import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  addTag,
  addTagsFailure,
  addTagSuccess,
  deleteTag,
  deleteTagFailure,
  deleteTagSuccess,
  getTags,
  getTagsFailure,
  getTagsSuccess,
  removeTagFromBuilding,
  removeTagFromBuildingFailure,
  removeTagFromBuildingSuccess,
} from './tag.action';
import { of } from 'rxjs';
import { TagService } from '../service/tag.service';
import { RemoveTagDto, Tag } from '../model/tag.model';
import { HttpErrorResponse } from '@angular/common/http';
import { notifyInfo, notifyWarn } from 'src/app/model/notification.model';

@Injectable({
  providedIn: 'root',
})
export class TagEffects {
  removeTagFromBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTagFromBuilding),
      mergeMap(({ tagName, buildingId }) =>
        this.tagsService.removeTagFromBuilding({ tagName, buildingId } as RemoveTagDto).pipe(
          map(() => removeTagFromBuildingSuccess({ tagName, buildingId })),
          catchError(error => of(removeTagFromBuildingFailure({
            error,
            ...notifyWarn('building.error.unexpected', true)
          })))
        )
      )
    )
  );

  removeTagFromBuildingSuccessTagRefreshEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTagFromBuildingSuccess),
      map(() => getTags())
    )
  );

  deleteTagEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTag),
      mergeMap(({ tag }) =>
        this.tagsService.deleteTag(tag).pipe(
          map(() => deleteTagSuccess({ tag, ...notifyInfo('notification.info.tag.tagDeleted', true) })),
          catchError(error => of(deleteTagFailure({ error })))
        )
      )
    )
  );

  addTagsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTag),
      mergeMap(({ tagName, buildingIds }) =>
        this.tagsService.applyTag({ name: tagName, buildingIds } as Tag).pipe(
          map(() => addTagSuccess({ tagName, buildingIds })),
          catchError((error: HttpErrorResponse) => of(addTagsFailure({
            error,
            tagName,
            buildingIds,
            ...notifyWarn('building.error.unexpected', true)
          })))
        )
      )
    )
  );

  getTagsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTags),
      mergeMap(() =>
        this.tagsService.getTags().pipe(
          map(tags => getTagsSuccess({ tags })),
          catchError(error => of(getTagsFailure({
            error,
            ...notifyWarn('building.error.unexpected', true)
          })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private tagsService: TagService) {}
}
