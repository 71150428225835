import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RemoveTagDto, Tag } from '../model/tag.model';

export const TAGS_BASE_URI = `${environment.apiUrl}/tags`;

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private readonly http: HttpClient) {}

  applyTag(tag: Tag): Observable<void> {
    return this.http.post<void>(TAGS_BASE_URI, tag);
  }

  getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(TAGS_BASE_URI);
  }

  removeTagFromBuilding(removeTagDto: RemoveTagDto): Observable<void> {
    return this.http.post<void>(`${TAGS_BASE_URI}/removeSingle`, removeTagDto);
  }

  deleteTag(tag: Tag): Observable<void> {
    return this.http.delete<void>(`${TAGS_BASE_URI}/${tag.name}`);
  }
}
