import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ButtonsModule } from '../buttons/buttons.module';

@NgModule({
  declarations: [ModalHeaderComponent],
  imports: [CommonModule, MatIconModule, ButtonsModule],
  exports: [ModalHeaderComponent],
})
export class ModalModule {}
