import { Message } from '../../model/notification.model';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AssessmentDto,
  AssessmentReportInfo,
  RefAffectation,
  RefAwning,
  RefBaseground,
  MeasureType,
  EditProtectiveMeasure,
  AssessmentThumbnail,
  EditPicture
} from '../model/assessment.model';

// HANDLE ERROR

export const reportAssessmentError = createAction(
  '[Assessment] error while calling building APIs',
  props<{ error: HttpErrorResponse; sourceActionType: string; payload: any }>()
);

// ASSESSMENT

export const startNewAssessment = createAction('[Assessment] start new assessment', props<{ buildingId?: number }>());

export const startNewAssessmentSuccess = createAction(
  '[Assessment] start new assessment success',
  props<{ ongoingAssessment: AssessmentDto }>()
);

export const startNewAssessmentFailure = createAction(
  '[Assessment] start new assessment failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const clearCurrentAssessment = createAction(
  '[Assessment] clear current assessment');

export const extractOngoingAssessment = createAction(
  '[Assessment] extract assessment from retrieved building',
  props<{ assessment?: AssessmentDto }>()
);

export const refreshOngoingAssessment = createAction(
  '[Assessment] refresh ongoing assessment',
  props<{ buildingId: number }>()
);

export const refreshOngoingAssessmentSuccess = createAction(
  '[Assessment] refresh ongoing assessment success',
  props<{ ongoingAssessment?: AssessmentDto }>()
);

export const refreshOngoingAssessmentFailure = createAction(
  '[Assessment] refresh ongoing assessment failure',
  props<{ error: HttpErrorResponse; payload: any }>()
);

export const cancelOngoingAssessment = createAction('[Assessment] cancel ongoing assessment', props<{ assessmentId: number }>());

export const cancelOngoingAssessmentSuccess = createAction(
  '[Assessment] cancel assessment success', props<{ notification: Message }>());

export const cancelOngoingAssessmentFailure = createAction(
  '[Assessment] cancel assessment failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

// PICTURES

export const downloadFullScreenAssessmentPicture = createAction(
  '[Assessment] get thumbnail', props<{ assessmentId: number; pictureName: string; subject: string }>());

export const downloadFullScreenAssessmentPictureSuccess = createAction(
  '[Assessment] download full screen defect picture success',
  props<{ pictureData: string }>()
);

export const downloadFullScreenAssessmentPictureFailure = createAction(
  '[Assessment] download full screen defect picture failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const setFullScreenAssessmentPicture = createAction(
  '[Assessment] set full screen defect picture', props<{ pictureData: string }>());

// APPROVAL AND VALIDATION

export const requestSecretaryApproval = createAction('[Assessment] request secretary approval', props<{ buildingId: number }>());

export const requestSecretaryApprovalSuccess = createAction(
  '[Assessment] request secretary approval success',
  props<{ assessment: AssessmentDto; notification: Message }>()
);

export const requestSecretaryApprovalFailure = createAction(
  '[Assessment] request secretary approval failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);


export const cancelRequestSecretaryApproval = createAction(
  '[Assessment] cancel request secretary approval', props<{ buildingId: number }>());

export const cancelRequestSecretaryApprovalSuccess = createAction(
  '[Assessment] cancel request secretary approval success',
  props<{ assessment: AssessmentDto; notification: Message }>()
);

export const cancelRequestSecretaryApprovalFailure = createAction(
  '[Assessment] cancel request secretary approval failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const approveAssessment = createAction('[Assessment] approve assessment', props<{ buildingId: number }>());

export const approveAssessmentSuccess = createAction('[Assessment] approve assessment success',
  props<{ assessment: AssessmentDto; notification: Message }>()
);

export const approveAssessmentFailure = createAction(
  '[Assessment] approve assessment failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);


export const previewAssessmentReport = createAction(
  '[Assessment] preview assessment report', props<{ source: number | AssessmentReportInfo }>());


export const updateAssessmentDetails = createAction(
  '[Assessment] update assessment details',
  props<{
    assessmentId: number;
    assessment: AssessmentDto;
  }>()
);

export const updateAssessmentDetailsSuccess = createAction(
  '[Assessment] update assessment details success',
  props<{ assessment?: AssessmentDto }>()
);

export const updateAssessmentDetailsFailure = createAction(
  '[Assessment] update assessment details failure',
  props<{ error: HttpErrorResponse; payload: any; notification: Message }>()
);

export const getMeasureTypes = createAction('[Assessment] get assessment measure types');

export const getMeasureTypesSuccess = createAction(
  '[Assessment] get assessment measure types success',
  props<{measureTypes: MeasureType[]}>()
);

export const addProtectiveMeasure = createAction(
  '[Assessment] add protective measure',
  props<{assessmentId: number; protectiveMeasure: EditProtectiveMeasure}>()
);

export const addProtectiveMeasureSuccess = createAction(
  '[Assessment] save protective measure success',
  props<{ assessment: AssessmentDto; notification: Message}>()
);

export const addProtectiveMeasureFailure = createAction(
  '[Assessment] add protective measure failure',
  props<{ error: HttpErrorResponse; protectiveMeasure: EditProtectiveMeasure; payload: any; notification: Message}>()
);

export const updateProtectiveMeasure = createAction(
  '[Assessment] update protective measure',
  props<{ assessmentId: number; protectiveMeasure: EditProtectiveMeasure}>()
);

export const updateProtectiveMeasureSuccess = createAction(
  '[Assessment] update protective measure success',
  props<{ assessment: AssessmentDto; notification: Message}>()
);

export const updateProtectiveMeasureFailure = createAction(
  '[Assessment] update protective measure success',
  props<{ error: HttpErrorResponse; protectiveMeasure: EditProtectiveMeasure; payload: any; notification: Message}>()
);

export const removeProtectiveMeasure = createAction(
  '[Assessment] remove protective measure',
  props<{ assessmentId: number; measureId: number}>()
);

export const removeProtectiveMeasureSuccess = createAction(
  '[Assessment] remove protective measure success',
  props<{assessment: AssessmentDto; notification: Message}>()
);

export const removeProtectiveMeasureFailure = createAction(
  '[Assessment] remove protective measure failure',
  props<{error: HttpErrorResponse; payload: any;  notification: Message}>()
);


export const loadRefAffectationOptions = createAction('[Edit Building Details] Load Ref Affectation Options');
export const loadRefAffectationOptionsSuccess = createAction(
  '[Edit Building Details] Load Ref Affectation Options Success', props<{ refsAffectation: RefAffectation[] }>());
export const loadRefAffectationOptionsFailure = createAction(
  '[Edit Building Details] Load Ref Affectation Options Failure',
  props<{ error: HttpErrorResponse; notification: Message }>());

export const loadRefBasegroundOptions = createAction('[Edit Building Details] Load Ref Baseground Options');
export const loadRefBasegroundOptionsSuccess = createAction(
  '[Edit Building Details] Load Ref Baseground Options Success', props<{ refsBaseground: RefBaseground[] }>());
export const loadRefBasegroundOptionsFailure = createAction(
  '[Edit Building Details] Load Ref Baseground Options Failure',
  props<{ error: HttpErrorResponse; notification: Message }>());

export const loadRefAwningOptions = createAction('[Edit Building Details] Load Ref Awning Options');
export const loadRefAwningOptionsSuccess = createAction(
  '[Edit Building Details] Load Ref Awning Options Success', props<{ refsAwning: RefAwning[] }>());
export const loadRefAwningOptionsFailure = createAction('[Edit Building Details] Load Ref Awning Options Failure',
  props<{ error: HttpErrorResponse; notification: Message }>());

export const loadReferenceOptions = createAction('[Edit Building Details] load Referenced options');

