import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AlertModule } from '../alert/alert.module';
import { AppRoutingModule } from '../app-routing.module';
import { ArrayPipeModule } from '../shared/array.module';
import { ModalModule } from '../shared/modal/ModalModule';
import { SharedModule } from '../shared/shared.module';
import { StringPipeModule } from '../shared/string.module';
import { MiniMapComponent } from './components/mini-map.component';
import { WebMapComponent } from './components/web-map.component';

@NgModule({
  declarations: [WebMapComponent, MiniMapComponent],
  imports: [
    AppRoutingModule,
    ArrayPipeModule,
    FlexModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    StringPipeModule,
    InfiniteScrollModule,
    NgScrollbarModule,
    NgxMatSelectSearchModule,
    AlertModule,
    DragDropModule,
    ModalModule,
    SharedModule,
  ],
  exports: [WebMapComponent, MiniMapComponent],
  providers: [],
})
export class MapModule {}
