import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { parseFormData } from '../../shared/form-control';
import {
  AnnouncementForm,
  ItechUpdateAction,
  TechnicalInstallation
} from '../model/technical-installations.model';
import { BUILDINGS_BASE_URI } from './building.service';

@Injectable({
  providedIn: 'root',
})
export class TechnicalInstallationService {
  constructor(private readonly http: HttpClient) {}

  announce(buildingId: number, form: AnnouncementForm): Observable<TechnicalInstallation> {
    const formData = parseFormData(form);
    return this.http.post<TechnicalInstallation>(this.getBaseUrl(buildingId), formData);
  }

  updateAnnouncement(buildingId: number, installationId: number, form: any): Observable<TechnicalInstallation> {
    const formData = parseFormData(form);
    return this.http.put<TechnicalInstallation>(this.getBaseUrl(buildingId, installationId), formData);
  }

  getInstallations(buildingId: number): Observable<TechnicalInstallation[]> {
    return this.http.get<TechnicalInstallation[]>(this.getBaseUrl(buildingId));
  }

  updateInstallation(
    buildingId: number,
    installationId: number,
    form: any,
    endpoint: ItechUpdateAction,
    isNew: boolean
  ): Observable<TechnicalInstallation> {
    const url = this.getBaseUrl(buildingId, installationId) + endpoint;
    const formData = parseFormData(form);

    if (isNew) {
      return this.http.post<TechnicalInstallation>(url, formData);
    }
    return this.http.put<TechnicalInstallation>(url, formData);
  }

  cancelMaintenanceContract(buildingId: number, installationId: number, endpoint: ItechUpdateAction): Observable<TechnicalInstallation> {
    const url = this.getBaseUrl(buildingId, installationId) + endpoint;

    return this.http.post<TechnicalInstallation>(url, null);
  }

  getBaseUrl(buildingId: number, installationId?: number): string {
    const itechId = installationId == null ? '' : `/${installationId}`;
    return `${BUILDINGS_BASE_URI}/${buildingId}/technical-installations${itechId}`;
  }
}
