import { language } from '../../shared/locale';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { BuildingService } from '../service/building.service';
import { BuildingSummary } from '../model/building.model';
import { catchError, map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {
  addAllBuildings,
  addAllBuildingsFailure,
  addAllBuildingsSuccess,
  addSelectedBuildings,
  addSelectedBuildingsSuccess,
  clearBuildingsStore,
  downloadBuildingList,
  downloadCurrentBuildings,
  downloadSelectedBuildings,
  findBuildings,
  findBuildingsFailure,
  findBuildingsSuccess,
  findMoreBuildings,
  findMoreBuildingsSuccess,
  refreshSelectedBuildings,
  refreshSelectedBuildingsSuccess,
  reinitializeAllFilters,
  reloadLocalizedData,
  replaceBuildingsSelection,
  replaceBuildingsSelectionSuccess,
} from './buildings.action';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Page } from '../../model/page.model';
import { selectBuildingsCurrentPage, selectSelectedBuildings } from './buildings.selector';
import {
  selectAffectationsFilter,
  selectBuildingClassesFilter,
  selectConstructionPeriodsFilter,
  selectControlledStateFilter,
  selectMunicipalitiesFilter,
  selectTypologiesFilter,
} from './reference-data.selector';
import { logoutSuccess } from '../../authentication/store/authentication.action';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Router } from '@angular/router';
import { updateMapQuerySuccess } from '../../map/store/map.action';
import { notifyWarn } from '../../model/notification.model';

@Injectable({
  providedIn: 'root',
})
export class BuildingsEffects {
  findBuildingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findBuildings, reloadLocalizedData),
      concatLatestFrom(() => [
        this.store.select(selectMunicipalitiesFilter),
        this.store.select(selectTypologiesFilter),
        this.store.select(selectBuildingClassesFilter),
        this.store.select(selectConstructionPeriodsFilter),
        this.store.select(selectAffectationsFilter),
        this.store.select(selectControlledStateFilter),
      ]),
      switchMap(([, municipalities, typologies, buildingClasses, constructionPeriod, affectations, controlledState]) =>
        this.buildingService.findAll(municipalities, typologies, buildingClasses, constructionPeriod, affectations, controlledState).pipe(
          map((buildingsPage: Page<BuildingSummary>) =>
            findBuildingsSuccess({
              buildings: buildingsPage.content,
              count: buildingsPage.totalElements,
            })
          ),
          catchError(error => of(findBuildingsFailure({
            error,
            ...notifyWarn('building.error.unexpected', true)
          })))
        )
      )
    )
  );

  findMoreBuildingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findMoreBuildings),
      concatLatestFrom(() => [
        this.store.select(selectMunicipalitiesFilter),
        this.store.select(selectTypologiesFilter),
        this.store.select(selectBuildingClassesFilter),
        this.store.select(selectConstructionPeriodsFilter),
        this.store.select(selectAffectationsFilter),
        this.store.select(selectBuildingsCurrentPage),
        this.store.select(selectControlledStateFilter),
      ]),
      mergeMap(([, municipalities, typologies, buildingClasses, constructionPeriod, affectations, currentPage, controlledState]) =>
        this.buildingService
          .findAll(municipalities, typologies, buildingClasses, constructionPeriod, affectations, controlledState, currentPage + 1)
          .pipe(
            map((buildingsPage: Page<BuildingSummary>) =>
              findMoreBuildingsSuccess({
                buildings: buildingsPage.content,
                count: buildingsPage.totalElements,
                page: buildingsPage.number,
              })
            ),
            catchError(error => of(findBuildingsFailure({
              error,
              ...notifyWarn('building.error.unexpected', true)
            })))
          )
      )
    )
  );

  initializeFindBuildingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMapQuerySuccess, reinitializeAllFilters),
      map(() => findBuildings())
    )
  );

  downloadBuildingsEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadCurrentBuildings),
        concatLatestFrom(() => [
          this.store.select(selectMunicipalitiesFilter),
          this.store.select(selectTypologiesFilter),
          this.store.select(selectBuildingClassesFilter),
          this.store.select(selectConstructionPeriodsFilter),
          this.store.select(selectAffectationsFilter),
          this.store.select(selectControlledStateFilter),
        ]),
        mergeMap(([, municipalities, typologies, buildingClasses, constructionPeriod, affectations, controlledState]) =>
          this.buildingService.downloadBuildings(
            municipalities,
            typologies,
            buildingClasses,
            constructionPeriod,
            affectations,
            controlledState,
          )
        ),
        tap(content => {
          const blob = new Blob([content], { type: 'text/csv' });
          saveAs(blob, language(this.localeService.getLocale()) === 'de' ? 'gebaude.csv' : 'batiments.csv');
        })
      ),
    { dispatch: false }
  );

  downloadSelectedBuildingsEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadSelectedBuildings),
        concatLatestFrom(() => this.store.select(selectSelectedBuildings)),
        mergeMap(([, selectedBuildings]) => this.buildingService.downloadBuildingList(selectedBuildings.map(b => b.id))),
        tap(content => this.saveBuildingsFile(content))
      ),
    { dispatch: false }
  );

  downloadBuildingListEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(downloadBuildingList),
        switchMap(({ buildingIds }) => this.buildingService.downloadBuildingList(buildingIds)),
        tap(content => this.saveBuildingsFile(content))
      ),
    { dispatch: false }
  );

  addSelectedBuildingEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSelectedBuildings),
      concatLatestFrom(() => this.store.select(selectSelectedBuildings)),
      map(([{ buildingIds }, selectedBuildings]) => {
        const selectedBuildingIds = selectedBuildings.map(building => building.id);
        return buildingIds.filter(id => !selectedBuildingIds.includes(id));
      }),
      mergeMap(newSelectedBuildingIds => this.buildingService.getSummaries(newSelectedBuildingIds)),
      map(buildings => buildings.map(building => ({ ...building, selected: true }))),
      map(buildings => addSelectedBuildingsSuccess({ buildings }))
    )
  );

  addAllBuildingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAllBuildings),
      concatLatestFrom(() => [
        this.store.select(selectMunicipalitiesFilter),
        this.store.select(selectTypologiesFilter),
        this.store.select(selectBuildingClassesFilter),
        this.store.select(selectConstructionPeriodsFilter),
        this.store.select(selectAffectationsFilter),
        this.store.select(selectControlledStateFilter),
      ]),
      mergeMap(([, municipalities, typologies, buildingClasses, constructionPeriod, affectations, controlledState]) =>
        this.buildingService
          .findAll(municipalities, typologies, buildingClasses, constructionPeriod, affectations, controlledState, 0, 10000)
          .pipe(
            map((buildingsPage: Page<BuildingSummary>) => {
              const selectedBuildings = buildingsPage.content.map(b => {
                b.selected = true;
                return b;
              });
              return addAllBuildingsSuccess({
                buildings: selectedBuildings,
              });
            }),
            catchError(error => of(addAllBuildingsFailure({ error })))
          )
      )
    )
  );

  refreshSelectedBuildingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshSelectedBuildings),
      concatLatestFrom(() => this.store.select(selectSelectedBuildings)),
      mergeMap(([, selectedBuildings]) => this.buildingService.getSummaries(selectedBuildings.map(building => building.id))),
      map(buildings => refreshSelectedBuildingsSuccess({ buildings }))
    )
  );

  replaceBuildingsSelectionEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replaceBuildingsSelection),
      mergeMap(({ buildingIds }) => this.buildingService.getSummaries(buildingIds)),
      map(buildings => buildings.map(building => ({ ...building, selected: true }))),
      map(buildings => replaceBuildingsSelectionSuccess({ buildings }))
    )
  );

  navigateToReplacedSelectionEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(replaceBuildingsSelectionSuccess),
        tap(action => this.router.navigate(['/building/' + action.buildings[0].id]))
      ),
    { dispatch: false }
  );

  logoutSuccessCleanupBuildingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutSuccess),
      map(() => clearBuildingsStore())
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private buildingService: BuildingService,
    private localeService: TranslocoLocaleService,
  ) {}

  private saveBuildingsFile(content: any) {
    const data = new Blob([content], { type: 'text/csv' });
    saveAs(data, language(this.localeService.getLocale()) === 'de' ? 'gebaude.csv' : 'batiments.csv');
  }
}
