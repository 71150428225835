export const FILE_NAME_ITERATION_PATTERN = /\((\d+)\)$/;

export const renameFile = (file: File, newName: string): File => new File([file], newName, { type: file.type });

export const renameFileKeepExtension = (oldNameWithExtension: string, newName: string): string => {
  const nameParts = oldNameWithExtension.split('.');
  const isDotFile = nameParts[0] === '';
  const fileExtension = nameParts[nameParts.length - 1 || 1];
  return isDotFile ? newName : `${newName}${fileExtension ? `.${fileExtension}` : ''}`;
};

export const getFileName = (file: File): string => {
  const nameParts = file.name.split('.');
  const fileName = nameParts.slice(0, nameParts.length - 1).join('.');
  const isDotFile = nameParts[0] === '';
  const hasExtension = nameParts.length > 1;
  return isDotFile || !hasExtension ? file.name : fileName;
};

export const incrementName = (fileName: string): string => {
  const iterationMatch = fileName.match(FILE_NAME_ITERATION_PATTERN);
  return iterationMatch ? fileName.replace(FILE_NAME_ITERATION_PATTERN, `(${Number(iterationMatch[1]) + 1})`) : `${fileName} (1)`;
};

export const copyFileIncrement = (file: File): File => {
  const fileName = getFileName(file);
  const incrementedFileName = incrementName(fileName);
  const newFileName = renameFileKeepExtension(file.name, incrementedFileName);
  return renameFile(file, newFileName);
};
