import { Pipe, PipeTransform } from '@angular/core';
import { isTextTranslation } from '../localization';

type TranslateFn = (key: string, params?: Record<string, any>) => any;

@Pipe({
  name: 'sibatTranslate',
})
export class SibatTranslatePipe implements PipeTransform {
  transform<T>(source: T, translateFn: TranslateFn): string | T {
    if (isTextTranslation(source)) {
      return translateFn(source.translationKey, source.translationOptions);
    }

    return source;
  }
}
