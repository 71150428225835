import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TechnicalInstallation } from '../../model/technical-installations.model';
import { selectTechnicalInstallations } from '../../store/building.selector';
import { getInstallations, setActiveTechnicalInstallation } from '../../store/technical-installation.action';
import { DialogService } from '../../../shared/service/dialog.service';
import {
  TechnicalInstallationSummaryComponent
} from '../technical-installation-summary/technical-installation-summary.component';

@Component({
  selector: 'sibat-installation-list',
  templateUrl: './installation-list.component.html',
  styleUrls: ['./installation-list.component.scss'],
})
export class InstallationListComponent implements OnDestroy {
  readonly displayedColumns = ['installationType', 'designation', 'date', 'temporaryShutdownEndDate'];
  readonly installations$ = this.store.select(selectTechnicalInstallations);
  readonly buildingIdSubscription = (this.route.parent ?? this.route).params.subscribe(params => {
    this.buildingId = params.buildingId;
    this.store.dispatch(getInstallations({buildingId: this.buildingId}));
  });
  private buildingId: number;

  constructor(private store: Store, private route: ActivatedRoute, private dialogService: DialogService) {
  }

  ngOnDestroy(): void {
    this.buildingIdSubscription.unsubscribe();
  }

  selectInstallation(installation: TechnicalInstallation) {
    this.store.dispatch(setActiveTechnicalInstallation({installation}));
    this.dialogService.openDialogComponent(
      TechnicalInstallationSummaryComponent,
      {
        buildingId: this.buildingId,
      },
      'sibat-responsive-dialog'
    );
  }

  isBeforeToday(date: Date): boolean {
    return date !== null && new Date(date) <= new Date();
  }
}
