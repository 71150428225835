import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateFormDialogOptions } from './update-form.model';
import { Store } from '@ngrx/store';
import { notifyMessageError } from '../../../notification/store/notification.action';
import { notifyWarn } from '../../../model/notification.model';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { renameFile } from 'src/app/shared/files';

@Component({
  selector: 'sibat-update-form-dialog',
  templateUrl: './update-form-dialog.component.html',
  styleUrls: ['../form-dialog.style.scss'],
})
export class UpdateFormDialogComponent {
  @ViewChild('dialogContent') dialogContentElement: ElementRef<HTMLDivElement>;
  readonly documentForm = new UntypedFormGroup({});
  readonly acceptsOneFile: boolean = false;
  readonly isUncompleted$ = this.data.fieldGroup.isUncompleted$;
  readonly hasRequiredError$ = this.data.fieldGroup.hasRequiredError$;

  private files = new Map<string, File | undefined>();

  constructor(
    private dialogRef: MatDialogRef<UpdateFormDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) readonly data: UpdateFormDialogOptions,
    private store: Store,
    private dialogService: DialogService,
  ) {
    data.fieldGroup.fields.forEach(formField => {
      this.documentForm.addControl(formField.name, formField.formControl);
      if (formField.type === 'file') {
        this.files.set(formField.name, undefined);
        const formControlName = formField.name;
        formField.formControl.addValidators([
          (control: AbstractControl) => {
            const isRequired = control.hasValidator(Validators.required);
            const hasValue = control.value !== undefined && control.value !== null;
            const hasFile = this.files.get(formControlName) !== undefined;

            if ((isRequired || hasValue) && !hasFile) {
              return { required: true };
            }
            return null;
          },
        ]);
      }
    });
    this.acceptsOneFile = this.files.size === 1;
  }

  uploadFileIfNecessary(sourceElement: HTMLInputElement, fileInput: HTMLInputElement, fieldName: string) {
    if (!(sourceElement.value && this.files.get(fieldName)) && sourceElement.dataset['selectFile'] === undefined) {
      sourceElement.dataset['selectFile'] = 'true';
      fileInput.click();
    } else {
      delete sourceElement.dataset['selectFile'];
    }
  }

  updateFileField(fieldName: string, file: File) {
    this.files.set(fieldName, file);
    const field = this.data.fieldGroup.fields.find(formField => formField.name === fieldName);
    if (field) {
      if (field.defaultFileName) {
        const nameParts = file.name.split('.');
        const fileExtension = nameParts[nameParts.length - 1 || 1];
        const newName = field.defaultFileName + (fileExtension ? `.${fileExtension}` : '');
        field.formControl.setValue(newName);
      } else {
        field.formControl.setValue(file.name);
      }
    }
  }

  async validate() {
    if (this.data.fieldGroup.hasRequiredError()) {
      return;
    }

    if (this.data.fieldGroup.isUncompleted()) {
      const confirmed = await this.dialogService.requestConfirmation({
        translationKey: 'building.technicalInstallation.confirmFormValidation',
      });

      if (!confirmed) {
        return;
      }
    }

    const result = this.documentForm.value;
    this.data.fieldGroup.fields.forEach(formField => {
      if (formField.transform) {
        result[formField.name] = formField.transform(result[formField.name]);
      }
      if (this.files.has(formField.name)) {
        const file = this.files.get(formField.name);
        const newFileName = result[formField.name];

        if (file && newFileName !== '') {
          const renamedFile = renameFile(file, newFileName);
          result[formField.name] = renamedFile;
        }
      }
    });
    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close();
  }

  fileDropped(fileList: FileList) {
    const file = fileList.item(0);
    const fileField = this.data.fieldGroup.fields.find(formField => formField.type === 'file');
    if (!this.acceptsOneFile || !fileField) {
      this.store.dispatch(notifyMessageError({...notifyWarn( 'common.notification.noFileAccepted', true)}));
    } else if (fileList.length !== 1 || !file) {
      this.store.dispatch(notifyMessageError({...notifyWarn('common.notification.oneFileRequired', true)}));
    } else {
      this.updateFileField(fileField.name, file);
    }
  }
}
