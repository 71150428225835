import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { EditDefect } from '../model/control.model';

@Component({
  selector: 'sibat-add-defect',
  template: `
    <div *transloco="let t; read: 'building.control'" [class.read-only]="readOnly" data-testid="sibat-add-defect">
      <sibat-dark-button
        *ngIf="!addingDefect"
        (clicked)="addingDefect = true"
        [label]="t('addDefect')"
        icon="add"
        class="add-defect-button"
        data-testid="add-defect"
      >
      </sibat-dark-button>
      <div class="add-new-defect" *ngIf="addingDefect">
        <h4>
          {{ t('addDefect') }}
          <sibat-dark-button (clicked)="addingDefect = false" [label]="t('cancel')" class="cancel"> </sibat-dark-button>
        </h4>
        <sibat-edit-defect [thumbnails]="thumbails" (defectSaved)="addDefect($event)"></sibat-edit-defect>
      </div>
    </div>
  `,
  animations: [trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate(400)])])],
  styleUrls: ['add-defect.component.scss'],
})
export class AddDefectComponent {
  @Input() readOnly: boolean;
  @Input() thumbails: Record<string, string> = {};
  @Output() defectAdded = new EventEmitter<EditDefect>();
  addingDefect = false;

  constructor() {}

  addDefect(newDefect: EditDefect) {
    this.defectAdded.emit(newDefect);
    this.addingDefect = false;
  }
}
