import { AlertComponent } from './alert.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [AlertComponent],
  imports: [SharedModule],
  exports: [AlertComponent],
})
export class AlertModule {}
