import { Action, createReducer, on } from '@ngrx/store';
import {
  clearAuthenticationError,
  clearNewPasswordSetState,
  clearPasswordResetState,
  forgotPasswordSuccess,
  loadExistingTokenSuccess,
  logoutSuccess,
  setNewPasswordSuccess,
  signInFailure,
  signInSuccess,
} from './authentication.action';

export default interface AuthenticationState {
  token?: string;
  passwordReset: boolean;
  newPasswordSet: boolean;
  error?: Error;
}

export const initialState: AuthenticationState = {
  token: undefined,
  passwordReset: false,
  newPasswordSet: false,
  error: undefined,
};

const authenticationReducer = createReducer(
  initialState,
  on(
    signInSuccess,
    loadExistingTokenSuccess,
    (state, action): AuthenticationState => ({ ...state, token: action.token, error: undefined })
  ),
  on(
    signInFailure,
    (state, action): AuthenticationState => ({
      ...state,
      token: undefined,
      error: action.error,
    })
  ),
  on(
    logoutSuccess,
    (state, action): AuthenticationState => ({
      ...state,
      token: undefined,
      error: undefined,
    })
  ),
  on(forgotPasswordSuccess, (state, action): AuthenticationState => ({ ...state, passwordReset: true })),
  on(clearPasswordResetState, (state, action): AuthenticationState => ({ ...state, passwordReset: false })),
  on(setNewPasswordSuccess, (state, action): AuthenticationState => ({ ...state, newPasswordSet: true })),
  on(clearNewPasswordSetState, (state, action): AuthenticationState => ({ ...state, newPasswordSet: false })),
  on(clearAuthenticationError, (state, action): AuthenticationState => ({ ...state, error: undefined }))
);

export const reducer = (state: AuthenticationState | undefined, action: Action): AuthenticationState =>
  authenticationReducer(state, action);
