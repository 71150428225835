import { Component, Input } from '@angular/core';
import { Building } from '../model/building.model';

@Component({
  selector: 'sibat-building-address',
  templateUrl: `building-address.component.html`,
  styleUrls: ['building-address.component.scss'],
})
export class BuildingAddressComponent {
  @Input() building: Building;

  constructor() {}
}
