import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sibat-municipality-widget',
  template: `
    <sibat-widget title="{{ 'cockpit.municipalities' | transloco }}">
      <mat-icon widget-icon>location_city</mat-icon>
      <div class="button-container" widget-content>
        <sibat-dark-button
          (clicked)="router.navigate(['/cockpit/admin/municipalities'])"
          [label]="'cockpit.municipalityList' | transloco"
        ></sibat-dark-button>
      </div>
    </sibat-widget>
  `,
  styles: [
    `
      sibat-dark-button {
        margin-top: 0.5em;
      }
    `,
  ],
})
export class MunicipalityWidgetComponent {
  constructor(public router: Router) {}
}
