<div [ngClass]="typologyColor" class="typology-container">
  <mat-chip-list [disabled]="disabled">
    <mat-chip
      *ngFor="let affectation of affectationMap[typologyColor]"
      (click)="affectationClick(affectation)"
      [class.selected]="typology?.affectationKeys | includes: affectation.key"
      matTooltip="{{ affectation.description }}"
      #tooltip="matTooltip"
    >
      <mat-icon *ngIf="typology?.affectationKeys | includes: affectation.key" matChipAvatar>check </mat-icon>
      {{ affectation.title }}
      <mat-icon *ngIf="affectation.description" (click)="tooltip.toggle()" matChipRemove>info </mat-icon>
    </mat-chip>
  </mat-chip-list>
  <ng-content></ng-content>
</div>
