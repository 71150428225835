import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { Control, DefectDto, DefectPictureInfo, DefectToRemove, EditDefect, mapToEditDefect } from '../model/control.model';
import { downloadFullScreenDefectPicture, removeDefect } from '../store/control.action';

@Component({
  selector: 'sibat-defect-item',
  template: `
    <div *transloco="let t; read: 'building.control'" [class.read-only]="readOnly" cdkDrag [cdkDragDisabled]="offline">
      <div
        class="defect-item"
        [class.missing-defect-info]="tooltipContent"
        [matTooltip]="tooltipContent"
        matTooltipPosition="left"
        [matTooltipDisabled]="!displayTooltip"
      >
        <div class="drag-icon" [class.drag-disabled]="offline" cdkDragHandle>
          <mat-icon>drag_indicator</mat-icon>
        </div>
        <div class="defect-icon">
          <mat-icon *ngIf="defect.pictures.length === 0">format_align_left</mat-icon>
          <img
            *ngIf="defect.pictures.length > 0"
            [src]="thumbnails[defect.pictures[0]] | safeUrl"
            alt=""
            (click)="
              displayFullScreenImg({
                controlId: control.id,
                pictureName: defect.pictures[0]
              })
            "
          />
        </div>
        <div class="defect-description">
          <span *ngIf="defect.description">{{ defect.description }}</span>
          <span *ngIf="!defect.description">{{ t('noDescription') }}</span>
          <div *ngIf="defect.deadline" class="defect-deadline">
            <mat-icon class="icon">update</mat-icon>
            <span class="date">{{ defect.deadline | date: 'dd.MM.yyyy' }} </span>
          </div>
        </div>
        <button class="inline-button update-button" mat-flat-button [disabled]="offline" (click)="toggleEditing()">
          <mat-icon>mode_edit</mat-icon>
        </button>
        <button class="inline-button delete-button" mat-flat-button [disabled]="offline" (click)="openDialogRemoveConfirmation(defect.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <sibat-edit-defect
        *ngIf="isEditing"
        [defect]="editDefect"
        [thumbnails]="thumbnails"
        [controlId]="control.id"
        (defectSaved)="updateDefect($event)"
        class="zone-edit"
      >
      </sibat-edit-defect>
    </div>
  `,
  styleUrls: ['defect-item.component.scss'],
})
export class DefectItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() buildingId: number | undefined;
  @Input() control: Control;
  @Input() readOnly: boolean;
  @Input() offline: boolean;
  @Input() displayTooltip: boolean;
  @Input() thumbnails: Record<string, string> = {};
  @Output() defectSaved = new EventEmitter<EditDefect>();

  @Input() defect: DefectDto;
  editDefect: EditDefect;

  isEditing = false;
  minDate: Date;
  tooltipContent: string;
  langSubscription: Subscription;

  constructor(private store: Store, private dialogService: DialogService, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.langSubscription = this.translocoService.langChanges$
      .pipe(switchMap(() => this.translocoService.selectTranslation()))
      .subscribe(() => this.updateTooltipContent());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defect && changes.defect.currentValue) {
      this.updateTooltipContent();
    }
  }

  ngOnDestroy(): void {
    this.langSubscription?.unsubscribe();
  }

  updateTooltipContent() {
    let content = '';
    if (!this.defect.type) {
      content += this.translocoService.translate('building.control.missingDefectTypeTooltip') + '\n';
    }
    if (!this.defect.location) {
      content += this.translocoService.translate('building.control.missingDefectLocationTooltip');
    }
    this.tooltipContent = content;
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.editDefect = mapToEditDefect(this.defect);
    }
  }

  updateDefect(defect: EditDefect): void {
    this.defectSaved.emit(defect);
    this.isEditing = false;
  }

  async openDialogRemoveConfirmation(defectId: number): Promise<void> {
    const defectToRemove = {
      buildingId: this.buildingId,
      defectId,
    } as DefectToRemove;

    const result = await this.dialogService.requestConfirmation({ translationKey: 'building.control.sureToRemove' });
    if (result) {
      this.store.dispatch(
        removeDefect({
          controlId: this.control.id,
          defectId: defectToRemove.defectId,
        })
      );
    }
  }

  displayFullScreenImg(defectPictureInfo: DefectPictureInfo) {
    if (defectPictureInfo.controlId) {
      this.store.dispatch(
        downloadFullScreenDefectPicture({
          controlId: defectPictureInfo.controlId,
          pictureName: defectPictureInfo.pictureName,
        })
      );
    }
  }
}
