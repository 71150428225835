import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/authentication/model/configuration.model';
import { CONFIG_BASE_URI } from 'src/app/authentication/service/configuration.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigurationService {
  constructor(private readonly http: HttpClient) {}

  getEnvironmentConfiguration(): Observable<EnvironmentConfiguration> {
    return this.http.get<EnvironmentConfiguration>(`${CONFIG_BASE_URI}/env`);
  }
}
