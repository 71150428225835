import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Building } from '../model/building.model';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sibat-external-links',
  template: `
    <div class="link-list">
      <div *ngIf="!!building?.urlDangersFR || !!building?.urlDangersDE" class="link-block" data-testid="dangers-link">
        <span class="label">{{ 'building.detail.dangersLink' | transloco }}</span>
        <span class="link" *ngIf="activeLang === 'fr' && !!building?.urlDangersFR">
          <sibat-link-button
            [href]="building.urlDangersFR"
            [external]="true"
            [target]="'_blank'"
            [label]="'building.detail.goToDangersLink1' | transloco"
          >
          </sibat-link-button>
        </span>
        <span class="link" *ngIf="activeLang === 'de' && !!building?.urlDangersDE">
          <sibat-link-button
            [href]="building.urlDangersDE"
            [external]="true"
            [target]="'_blank'"
            [label]="'building.detail.goToDangersLink2' | transloco"
          >
          </sibat-link-button>
        </span>
      </div>
<!--      Needs to be implemented later-->
<!--      <div *ngIf="!!building?.urlCartoECAB" class="link-block" data-testid="cartoEcab-link">-->
<!--        <span class="label">{{ 'building.detail.CartoEcabLink' | transloco }}</span>-->
<!--        <span class="link">-->
<!--          <sibat-link-button-->
<!--            [href]="building.urlCartoECAB"-->
<!--            [external]="true"-->
<!--            [target]="'_blank'"-->
<!--            [label]="'building.detail.goToCartoEcabLink' | transloco"-->
<!--          >-->
<!--          </sibat-link-button>-->
<!--        </span>-->
<!--      </div>-->
      <div *ngIf="ecabOrAbove && !!building?.urlIcare" class="link-block" data-testid="icare-link">
        <span class="label">{{ 'building.detail.iCarePlusLink' | transloco }}</span>
        <span class="link">
          <sibat-link-button
            [href]="building.urlIcare"
            [external]="true"
            [target]="'_blank'"
            [label]="'building.detail.goToICarePlus' | transloco"
          >
          </sibat-link-button>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['external-links.component.scss'],
})
export class ExternalLinksComponent implements OnInit, OnDestroy {
  @Input() building: Building;
  @Input() ecabOrAbove: boolean;
  activeLang: string;
  langChangeSubscription: Subscription;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.activeLang = this.translocoService.getActiveLang();
    this.langChangeSubscription = this.translocoService.langChanges$.subscribe(lang => {
      this.activeLang = lang;
    });
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
