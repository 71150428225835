<ng-scrollbar track="horizontal" [sensorDebounce]="1" [autoHeightDisabled]="false">
  <div class="filters">
    <ng-container *ngFor="let filter of filters$ | async">
      <mat-form-field class="light" [class.not-default-value]="filter.formControl.value[0] !== filter.defaultValue">
        <mat-label>{{ filter.label }}</mat-label>
        <mat-select [formControl]="filter.formControl" (selectionChange)="updateFilter(filter)" multiple>
          <mat-option>
            <ngx-mat-select-search
              (keydown.enter)="selectFirstSearchResult(filter)"
              [formControl]="filter.searchFormControl"
              [placeholderLabel]="filter.label"
            >
            </ngx-mat-select-search>
          </mat-option>
          <ng-container *ngFor="let optionGr of filter.filteredOptions | async; let grpLast = last">
            <mat-option
              *ngFor="let option of optionGr.options"
              [value]="option.value"
              [disabled]="option.value === filter.disabledOption && isDisabled(filter)"
            >
              {{ option.label }}
            </mat-option>
            <mat-divider *ngIf="!grpLast"></mat-divider>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <div>
      <button class="mat-elevation-z0 flat-mini-fab" mat-mini-fab (click)="resetFilters()">
        <mat-icon>replay</mat-icon>
      </button>
    </div>
  </div>
</ng-scrollbar>
