import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  getMotd,
  getMotdAndCheck,
  getMotdAndCheckSuccess,
  getMotdFailure,
  getMotdSuccess,
  updateMotd,
  updateMotdFailure,
  updateMotdSuccess,
} from './motd.action';
import { MotdService } from '../service/motd.service';

@Injectable({
  providedIn: 'root',
})
export class MotdEffects {
  updateMOTDEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMotd),
      mergeMap(({ motd }) =>
        this.motdService.updateMotd(motd).pipe(
          map(uuid => ({ ...motd, uuid })),
          map(updatedMotd => updateMotdSuccess({ motd: updatedMotd })),
          catchError(error => of(updateMotdFailure({ error })))
        )
      )
    )
  );

  getMotdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMotd),
      mergeMap(() =>
        this.motdService.getMotd().pipe(
          map(motd => getMotdSuccess({ motd })),
          catchError(error => of(getMotdFailure({ error })))
        )
      )
    )
  );

  getMotdAndCheckEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMotdAndCheck),
      mergeMap(() =>
        this.motdService.getMotd().pipe(
          map(motd => getMotdAndCheckSuccess({ motd })),
          catchError(error => of(getMotdFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private motdService: MotdService) {}
}
