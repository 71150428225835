import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LoadedEvent, TranslocoEvents, TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { filter, first, pluck } from 'rxjs/operators';

@Injectable()
export class LocalizedPaginatorIntl implements MatPaginatorIntl, OnDestroy {
  changes = new Subject<void>();
  firstPageLabel = '';
  lastPageLabel = '';
  itemsPerPageLabel = '';
  nextPageLabel = '';
  previousPageLabel = '';

  private changeSubscription = this.translocoService.langChanges$.subscribe(() => {
    // Boilerplate required while transloco doesn't expose a proper Observable.
    // @see https://github.com/ngneat/transloco/issues/461
    this.changes.next();
    this.translocoService.events$
      .pipe(
        filter(e => this.isTranslationLoadSuccess(e)),
        pluck('payload'),
        first()
      )
      .subscribe(() => {
        this.changes.next();
      });
  });

  constructor(private translocoService: TranslocoService) {}

  ngOnDestroy(): void {
    this.changeSubscription.unsubscribe();
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return '';
    }
    const firstPageElementIndex = page * pageSize;
    return this.translocoService.translate('common.pagination.range', {
      first: firstPageElementIndex + 1,
      last: Math.min(firstPageElementIndex + pageSize, length),
      total: length,
    });
  }

  private isTranslationLoadSuccess(event: TranslocoEvents): event is LoadedEvent {
    const translationLoadSuccess: typeof event.type = 'translationLoadSuccess';
    return event.type === translationLoadSuccess;
  }
}
