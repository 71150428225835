<h3>{{ 'building.assessment.buildingDetailsForm.buildingDetails' | transloco }}</h3>
<form
	data-testid="assessment-building-form"
	class="form-container"
	[class.read-only]="readOnly"
	fxLayout="column"
	[formGroup]="buildingDetailsForm"
	*transloco="let t; read: 'building.assessment.buildingDetailsForm'"
>
	<div class="container-small" fxLayout="column">
    <mat-accordion class="full-width-accordion" multi="true" [class.read-only]="readOnly">
      <!-- Affectation -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ t('affectation') }}
          </mat-panel-title>
        </mat-expansion-panel-header>
            <mat-chip-list>
              <mat-chip
                  *ngFor="let usage of refAffectationOptions$ | async"
                  selectable
                  (click)="onAffectationUsageSelected(usage)"
                  [class.selected]="isAffectationUsageSelected(usage.id)"
              >
                <mat-icon matChipAvatar *ngIf="isAffectationUsageSelected(usage.id)">check</mat-icon>
                {{t('ref_affectation.' + usage.text)}}
              </mat-chip>
            </mat-chip-list>
      </mat-expansion-panel>
      <!-- Utilisation -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ t('use') }}
          </mat-panel-title>
        </mat-expansion-panel-header>
            <mat-form-field class="adjust-width">
              <mat-label>{{ t('useDetails.flatNumber') }}</mat-label>
              <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="flatNumber" min="0" required data-testid="flatNumber">
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
            <mat-form-field class="adjust-width">
              <mat-label>{{ t('useDetails.abovegroundLevelNumber') }}</mat-label>
              <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)"  formControlName="abovegroundLevelNumber" min="0" required data-testid="abovegroundLevelNumber">
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
            <mat-form-field class="adjust-width">
              <mat-label>{{ t('useDetails.undergroundLevelNumber') }}</mat-label>
              <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="undergroundLevelNumber" min="0" required data-testid="undergroundLevelNumber">
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          <div>
            <div class="mat-card-subtitle">
              {{ t('refBaseground') }}:
            </div>
            <mat-chip-list>
              <mat-chip
                  *ngFor="let usage of refBasegroundOptions$ | async"
                  selectable
                  (click)="onBasegroundUsageSelected(usage)"
                  [class.green]="isBasegroundUsageSelected(usage.id)"
              >
                <mat-icon matChipAvatar *ngIf="isBasegroundUsageSelected(usage.id)">check</mat-icon>
                {{t('ref_baseground.' + usage.text)}}
              </mat-chip>
            </mat-chip-list>
          </div>
        <div class="buttons-section">
                <mat-form-field class="adjust-width">
                  <mat-label>{{ t('useDetails.stairwellNumber') }}</mat-label>
                  <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="stairwellNumber" min="0" required data-testid="stairwellNumber">
                  <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
                </mat-form-field>
                <input #stairwellFileInput type="file" accept="image/png, image/jpeg" (change)="handleFileInput('stairwell', $event)" style="display: none" />
                <sibat-dark-button *ngIf="!isReadOnly" (clicked)="stairwellFileInput.click()" [label]="t('stairwellPictures')" icon="add"> </sibat-dark-button>
          <div class="pictures">
            <div class="placeholder" *ngIf="!assessment?.stairwellThumbnails?.length"></div>
              <div class="picture" *ngFor="let thumbnail of assessment?.stairwellThumbnails">
                <img [src]="thumbnail.thumbnail | safeUrl" alt="" (click)="displayFullScreenImgWithPictureName(thumbnail.pictureName, 'stairwell')">
              <button
                  data-testid="delete-existing-picture-button"
                  *ngIf="!isReadOnly"
                  class="delete-button"
                  mat-flat-button
                  (click)="deleteExistingPicture('stairwell', thumbnail)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div class="picture" *ngFor="let picture of buildingDetailsForm.get('stairwellPictures')?.value?.picturesToAdd">
              <img [src]="picture.preview | safeUrl" alt="" (click)="displayFullScreenImgWithData(picture.preview)" />
              <button
                  data-testid="delete-picture-to-add-button"
                  *ngIf="!isReadOnly"
                  class="delete-button"
                  mat-flat-button
                  (click)="deleteAddedPicture('stairwell', picture)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <!-- Enveloppe -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ t('shell') }}
          </mat-panel-title>
        </mat-expansion-panel-header>
          <div>
            <mat-form-field class="adjust-width">
              <mat-label>{{ t('shellDetails.facadesArea') }}</mat-label>
              <input matInput type="text" inputmode="decimal" pattern="[0-9]*[.,]?[0-9]+" (input)="floatOnly($event)" formControlName="facadesArea" required data-testid="facadesArea">
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
            <mat-form-field class="adjust-width">
              <mat-label>{{ t('shellDetails.roofArea') }}</mat-label>
              <input matInput type="text" inputmode="decimal" pattern="[0-9]*[.,]?[0-9]+" (input)="floatOnly($event)" formControlName="roofArea" required data-testid="roofArea">
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
            <mat-form-field class="adjust-width">
              <mat-label>{{ t('shellDetails.awningArea') }}</mat-label>
              <input matInput type="text" inputmode="decimal" pattern="[0-9]*[.,]?[0-9]+" (input)="floatOnly($event)" formControlName="awningArea" required data-testid="awningArea">
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
            <mat-form-field class="adjust-width">
              <mat-label>{{ t('shellDetails.awningNumber') }}</mat-label>
              <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="awningNumber" min="0" required data-testid="awningNumber">
              <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div class="radio-container-wrapper">
            <div class="radio-container">
              <label class="mat-card-subtitle" id="refAwning">{{ t('shellDetails.refAwning') }}:</label>
              <mat-chip-list>
                <mat-chip
                    *ngFor="let usage of refAwningOptions$ | async"
                    selectable
                    (click)="onAwningUsageSelected(usage)"
                    [class.green]="isAwningUsageSelected(usage.id)"
                >
                  <mat-icon matChipAvatar *ngIf="isAwningUsageSelected(usage.id)">check</mat-icon>
                  {{ t('ref_awning.' + usage.text) }}
                </mat-chip>
              </mat-chip-list>
            </div>
            <div class="radio-container">
              <label class="mat-card-subtitle" id="automation">{{ t('shellDetails.automation') }}:</label>
              <mat-chip-list aria-labelledby="automation">
                <mat-chip class="radio-option" [selected]="buildingDetailsForm.get('automation')?.value" (click)="buildingDetailsForm.get('automation')?.setValue(true)">
                  {{ 'common.yes' | transloco }}
                </mat-chip>
                <mat-chip class="radio-option" [selected]="!buildingDetailsForm.get('automation')?.value" (click)="buildingDetailsForm.get('automation')?.setValue(false)">
                  {{ 'common.no' | transloco }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        <div class="buttons-section">
          <div class="button-container">
            <div>
              <input #facadeFileInput type="file" accept="image/png, image/jpeg" (change)="handleFileInput('facade', $event)" style="display: none" />
              <sibat-dark-button *ngIf="!isReadOnly" (clicked)="facadeFileInput.click()" [label]="t('facadePictures')" icon="add"> </sibat-dark-button>
              <div class="pictures">
                <div class="placeholder" *ngIf="!assessment?.facadeThumbnails?.length"></div>
                <div class="picture" *ngFor="let thumbnail of assessment?.facadeThumbnails">
                  <img [src]="thumbnail.thumbnail | safeUrl" alt="" (click)="displayFullScreenImgWithPictureName(thumbnail.pictureName, 'facade')">
                  <button
                      data-testid="delete-existing-picture-button"
                      *ngIf="!isReadOnly"
                      class="delete-button"
                      mat-flat-button
                      (click)="deleteExistingPicture('facade', thumbnail)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <div class="picture" *ngFor="let picture of buildingDetailsForm.get('facadePictures')?.value?.picturesToAdd">
                  <img [src]="picture.preview | safeUrl" alt="" (click)="displayFullScreenImgWithData(picture.preview)" />
                  <button
                      data-testid="delete-picture-to-add-button"
                      *ngIf="!isReadOnly"
                      class="delete-button"
                      mat-flat-button
                      (click)="deleteAddedPicture('facade', picture)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <div class="button-container">
              <input #buildingSituationFileInput type="file" accept="image/png, image/jpeg" (change)="handleFileInput('buildingSituation', $event)" style="display: none" />
              <sibat-dark-button *ngIf="!isReadOnly" (clicked)="buildingSituationFileInput.click()" [label]="t('buildingSituationPictures')" icon="add"> </sibat-dark-button>
              <div class="pictures">
                <div class="placeholder" *ngIf="!assessment?.buildingSituationThumbnails?.length"></div>
                <div class="picture" *ngFor="let thumbnail of assessment?.buildingSituationThumbnails">
                  <img [src]="thumbnail.thumbnail | safeUrl" alt="" (click)="displayFullScreenImgWithPictureName(thumbnail.pictureName, 'buildingSituation')">
                  <button
                      data-testid="delete-existing-picture-button"
                      *ngIf="!isReadOnly"
                      class="delete-button"
                      mat-flat-button
                      (click)="deleteExistingPicture('buildingSituation', thumbnail)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
                <div class="picture" *ngFor="let picture of buildingDetailsForm.get('buildingSituationPictures')?.value?.picturesToAdd">
                  <img [src]="picture.preview | safeUrl" alt="" (click)="displayFullScreenImgWithData(picture.preview)" />
                  <button
                      data-testid="delete-picture-to-add-button"
                      *ngIf="!isReadOnly"
                      class="delete-button"
                      mat-flat-button
                      (click)="deleteAddedPicture('buildingSituation', picture)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <!-- Ouverture du bâtiment -->
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ t('buildingOpening') }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Section Rez-de-chaussée -->
        <div class="mat-card-subtitle">{{ t('buildingOpeningDetails.groundFloor') }}:</div>
          <mat-form-field class="adjust-width">
            <mat-label>{{ t('buildingOpeningDetails.exteriorDoorNumber') }}</mat-label>
            <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="exteriorDoorNumber" min="0" required data-testid="exteriorDoorNumber">
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
          <mat-form-field class="adjust-width">
            <mat-label>{{ t('buildingOpeningDetails.parkingEntranceNumber') }}</mat-label>
            <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="parkingEntranceNumber" min="0" required data-testid="parkingEntranceNumber">
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
          <mat-form-field class="adjust-width">
            <mat-label>{{ t('buildingOpeningDetails.stepOverNumber') }}</mat-label>
            <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="stepOverNumber" min="0" required data-testid="stepOverNumber">
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
          <mat-form-field class="adjust-width">
            <mat-label>{{ t('buildingOpeningDetails.stairsNumber') }}</mat-label>
            <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="stairsNumber" min="0" required data-testid="stairsNumber">
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
          <mat-form-field class="adjust-width">
            <mat-label>{{ t('buildingOpeningDetails.pcShelterNumber') }}</mat-label>
            <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="pcShelterNumber" min="0" required data-testid="pcShelterNumber">
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        <mat-form-field class="adjust-width">
          <mat-label>{{ t('buildingOpeningDetails.ventilationDuctNumber') }}</mat-label>
          <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="ventilationDuctNumber" min="0" required data-testid="ventilationDuctNumber">
          <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
        </mat-form-field>
        <!-- Section Toiture -->
        <div class="mat-card-subtitle">{{ t('buildingOpeningDetails.roof') }}:</div>
          <mat-form-field class="adjust-width">
            <mat-label>{{ t('buildingOpeningDetails.domeNumber') }}</mat-label>
            <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="domeNumber" min="0" required data-testid="domeNumber">
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        <mat-form-field class="adjust-width">
          <mat-label>{{ t('buildingOpeningDetails.glassDomeNumber') }}</mat-label>
          <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="glassDomeNumber" min="0" required data-testid="glassDomeNumber">
          <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
        </mat-form-field>
          <mat-form-field class="adjust-width">
            <mat-label>{{ t('buildingOpeningDetails.skylightNumber') }}</mat-label>
            <input matInput type="number" pattern="[0-9]*" (input)="integerOnly($event)" formControlName="skylightNumber" min="0" required data-testid="skylightNumber">
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
      </mat-expansion-panel>
      <div>
        <sibat-validate-button
            (clicked)="saveData()"
            [disabled]="offline || buildingDetailsForm.pristine"
            [label]="t('saveBuildingDetails')"
            data-testid="save-button"
        >
        </sibat-validate-button>
      </div>
    </mat-accordion>
	</div>
</form>
