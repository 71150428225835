import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BaseButtonComponent } from './base-button.component';
import { DarkButtonComponent } from './dark-button.component';
import { LinkButtonComponent } from './link-button.component';
import { PrimaryButtonComponent } from './primary-button.component';
import { SecondaryButtonComponent } from './secondary-button.component';
import { TertiaryButtonComponent } from './tertiary-button.component';
import { ToggleButtonComponent } from './toggle-button.component';
import { UpdateButtonComponent } from './update-button.component';
import { ValidateButtonComponent } from './validate-button.component';
import { IconButtonComponent } from './icon-button.component';

@NgModule({
  declarations: [
    BaseButtonComponent,
    DarkButtonComponent,
    IconButtonComponent,
    LinkButtonComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    ToggleButtonComponent,
    UpdateButtonComponent,
    ValidateButtonComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatSlideToggleModule, MatIconModule],
  exports: [
    DarkButtonComponent,
    IconButtonComponent,
    LinkButtonComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    ToggleButtonComponent,
    UpdateButtonComponent,
    ValidateButtonComponent,
  ],
})
export class ButtonsModule {}
