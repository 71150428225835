<div class="content-container" [formGroup]="typologyForm">
  <div class="radio-container">
    <mat-radio-group aria-labelledby="typology-radio-group-label" formControlName="color">
      <sibat-typology-affectations
        [disabled]="isEditDenied || disableAll"
        [typology]="buildingTypology"
        [typologyColor]="colorTypology.red"
        [affectations]="affectations"
        (affectationClicked)="affectationClick($event)"
      >
        <sibat-typology-affectations
          [disabled]="disableAll"
          [typology]="buildingTypology"
          [typologyColor]="colorTypology.green"
          [affectations]="affectations"
          (affectationClicked)="affectationClick($event)"
        >
          <sibat-typology-affectations
            [disabled]="disableAll"
            [typology]="buildingTypology"
            [typologyColor]="colorTypology.white"
            [affectations]="affectations"
            (affectationClicked)="affectationClick($event)"
          >
            <mat-radio-button
              [value]="colorTypology.white"
              [checked]="buildingTypology.color === colorTypology.white"
              [disabled]="disableAll"
              (change)="setSelectedTypology(colorTypology.white)"
              name="color"
              >{{ 'building.typology.white' | transloco }}
            </mat-radio-button>
          </sibat-typology-affectations>
          <mat-radio-button
            class="green-typology"
            [value]="colorTypology.green"
            [checked]="buildingTypology.color === colorTypology.green"
            [disabled]="disableAll"
            (change)="setSelectedTypology(colorTypology.green)"
            name="color"
            >{{ 'building.typology.green' | transloco }}
          </mat-radio-button>
        </sibat-typology-affectations>
        <mat-radio-button
          class="red-typology"
          [value]="colorTypology.red"
          [checked]="buildingTypology.color === colorTypology.red"
          [disabled]="isEditDenied || disableAll"
          (change)="setSelectedTypology(colorTypology.red)"
          name="color"
          >{{ 'building.typology.red' | transloco }}
        </mat-radio-button>
      </sibat-typology-affectations>
    </mat-radio-group>
  </div>
  <div flex fxLayout="row" fxLayout.xs="column" *ngIf="isEditDenied" class="alert-container">
    <div fxFlex="50" class="alert">
      <p *ngIf="selectedTypology === forbiddenColor" innerHTML="{{ 'building.typology.cantEditRed' | transloco }}"></p>
      <p *ngIf="selectedTypology !== forbiddenColor" innerHTML="{{ 'building.typology.cantSetRed' | transloco }}"></p>
    </div>
    <div fxFlex="50" class="alert">
      <p *ngIf="referringExpert">
        <b> {{ referringExpert.firstName }} {{ referringExpert.lastName }} </b><br />
        <ng-container>
          <a href="mailto:{{ referringExpert.email }}" class="mailto">{{ referringExpert.email }}</a>
        </ng-container>
        <br />
        {{ referringExpert.phoneNumber }} <br />
        <ng-container
          ><a href="mailto:{{ mailPrevention }}" class="mailto">{{ mailPrevention }}</a></ng-container
        >
      </p>
    </div>
  </div>

  <sibat-alert type="lock" class="alert-container" *ngIf="justificationRequired && disableAll === false">
    <span innerHTML="{{ 'building.typology.justificationRequired' | transloco }}"></span>
  </sibat-alert>

  <mat-form-field>
    <mat-label>{{ 'building.typology.justification' | transloco }}</mat-label>
    <textarea matInput formControlName="justification"></textarea>
  </mat-form-field>

  <p *ngIf="!hideLastUpdate">
    {{ 'building.typology.lastUpdate' | transloco }}&nbsp; <strong>{{ typologyDate }}</strong
    >&nbsp; {{ 'building.typology.by' | transloco }}&nbsp;
    <strong>{{ 'building.typology.sourceOfChange' | transloco: { source: buildingTypology.source } }}</strong>
  </p>
</div>
