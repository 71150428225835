import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { changeFeatureFlagState, getFeatureFlags } from '../../features/store/feature-flags.action';
import { selectFeatureFlags } from '../../features/store/feature-flags.selector';
import { Observable } from 'rxjs';
import { FeatureFlag } from '../../features/model/feature-flags.model';

@Component({
  selector: 'sibat-feature-flags-activator-widget',
  template: `
    <sibat-widget *transloco="let t; read: 'cockpit'" title="{{ t('buildingControl') }}">
      <mat-icon widget-icon>settings</mat-icon>
      <div widget-content>
        <table mat-table [dataSource]="dataSource$" class="single-action-list">
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef>{{ t('featureFlagName') }}</th>
            <td mat-cell *matCellDef="let element">{{ t('' + element.key) }}</td>
          </ng-container>

          <ng-container matColumnDef="enabled">
            <th class="last-row" mat-header-cell *matHeaderCellDef>{{ t('featureFlagState') }}</th>
            <td class="last-row" mat-cell *matCellDef="let element">
              <sibat-toggle-button (toggle)="changeFeatureState($event, element)" [checked]="element.enabled"> </sibat-toggle-button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="changeFeatureState(!row.enabled, row)"></tr>
        </table>
      </div>
    </sibat-widget>
  `,
  styleUrls: ['./feature-flags-activator.component.scss'],
})
export class FeatureFlagsActivatorComponent implements OnInit {
  displayedColumns: string[] = ['key', 'enabled'];
  dataSource$: Observable<FeatureFlag[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(getFeatureFlags());
    this.dataSource$ = this.store.select(selectFeatureFlags);
  }

  changeFeatureState(enabled: boolean, featureFlag: FeatureFlag): void {
    this.store.dispatch(changeFeatureFlagState({ key: featureFlag.key, enabled }));
  }
}
