import { NgModule } from '@angular/core';
import {
  DisplayNamePipe,
  BreakAddressPipe,
  ReplacePipe,
  StartsWithPipe,
  SubstringPipe,
  ReplaceLineBreaksPipe
} from './string.pipe';

@NgModule({
  declarations: [ReplacePipe, StartsWithPipe, SubstringPipe, DisplayNamePipe, BreakAddressPipe, ReplaceLineBreaksPipe],
  imports: [],
  exports: [ReplacePipe, StartsWithPipe, SubstringPipe, DisplayNamePipe, BreakAddressPipe, ReplaceLineBreaksPipe],
})
export class StringPipeModule {}
