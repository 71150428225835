import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sibat-notice',
  template: `
    <h3 mat-dialog-title>{{ 'notification.motd' | transloco }}</h3>
    <div class="mat-typography">
      <p>{{ message }}</p>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{ 'layout.closeDialog' | transloco }}</button>
    </mat-dialog-actions>
  `,
  styleUrls: ['motd.component.scss'],
})
export class MotdComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public message: string) {}
}
