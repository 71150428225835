import { EditHistory } from '../model/history.model';
import { createAction, props } from '@ngrx/store';
import { Building, DeleteInfos } from '../model/building.model';
import { Typology } from '../model/typology.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateTypologyDto } from '../model/typology.dto';
import { Message } from 'src/app/model/notification.model';

export const getBuilding = createAction('[Building] get', props<{ buildingId: number }>());

export const getBuildingSuccess = createAction('[Building] get success', props<{ building: Building }>());

export const getBuildingFailure = createAction('[Building] get failure', props<{ error: any }>());

export const clearCurrentBuilding = createAction('[Building] clear current building');

export const deleteBuilding = createAction('[building] delete building', props<{buildingId: number}>());

export const deleteBuildingSuccess = createAction('[building] delete building success', props<{deleteInfos: DeleteInfos}>());

export const deleteBuildingFailure = createAction('[building] delete building failure', props<{error: any}>());

export const updateTypologies = createAction(
  '[Typology] update',
  props<{ buildingIds: number[]; typology: UpdateTypologyDto }>()
);

export const updateTypologiesSuccess = createAction(
  '[Typology] update success',
  props<{ buildingIds: number[]; failedBuildingIds: number[]; typology: Typology }>()
);

export const updateTypologyFailure = createAction(
  '[Building typology] update failure',
  props<{ error: HttpErrorResponse; typology: UpdateTypologyDto; notification: Message }>()
);

export const updateNextControlDates = createAction(
  '[Building next control date] update many',
  props<{ buildingIds: number[]; date: string | null }>()
);

export const updateNextControlDatesSuccess = createAction(
  '[Building next control date] update many success',
  props<{ buildingIds: number[]; failedBuildingIds: number[]; date: string | null }>()
);

export const updateNextControlDatesError = createAction(
  '[Building next control date] update many error',
  props<{ error: HttpErrorResponse }>()
);

export const getHistory = createAction('[Building] get history', props<{ buildingId: number }>());

export const getHistorySuccess = createAction('[Building] get history success', props<{ history: EditHistory }>());

export const reportBuildingError = createAction(
  '[Building] error while calling building APIs',
  props<{ error: HttpErrorResponse; sourceActionType: string; payload: any }>()
);
