import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Affectation } from '../model/affectation.model';

@Injectable({
  providedIn: 'root',
})
export class AffectationService {
  baseUrl = `${environment.apiUrl}/affectations`;

  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<Affectation[]> {
    return this.http.get<Affectation[]>(this.baseUrl);
  }
}
