import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

export const FILTER_OPTION_BEHAVIOUR = {
  default: 0,
  exclusive: 1,
} as const;
export type FilterOptionBehavior = typeof FILTER_OPTION_BEHAVIOUR[keyof typeof FILTER_OPTION_BEHAVIOUR];

export const ALL_VALUE = 'all';
export const CONTROLLED = 'CONTROLLED';
export const NOT_CONTROLLED = 'NOT_CONTROLLED';

type FilterRequiredParams = Required<Pick<Filter, 'formControl'>>;
type FilterCreatorParams = Partial<Filter> & FilterRequiredParams;
export interface Filter {
  icon: string;
  label: string;
  formControl: UntypedFormControl;
  searchFormControl: UntypedFormControl;
  options: OptionGroup[];
  filteredOptions: ReplaySubject<OptionGroup[]>;
  disabledOption: string;
  defaultValue?: string;
  onChange: (value: string[]) => void;
}

export interface OptionGroup {
  searchable: boolean;
  options: FilterOption[];
}

export interface FilterOption {
  label: string;
  value: string;
  behavior: FilterOptionBehavior;
}

export const optionGroupOf = (options: FilterOption[], searchable = false): OptionGroup => ({ options, searchable });

export const createFilterOption = (
  label: string,
  value: string,
  behavior: FilterOptionBehavior = FILTER_OPTION_BEHAVIOUR.default
): FilterOption => ({
  label,
  value,
  behavior,
});

export const createFilter = ({
  formControl,
  icon = 'home',
  label = 'filter',
  options = [optionGroupOf([])],
  defaultValue = ALL_VALUE,
  disabledOption = ALL_VALUE,
  onChange = () => {},
  searchFormControl = new UntypedFormControl(),
}: FilterCreatorParams): Filter => {
  const filteredOptions = new ReplaySubject<OptionGroup[]>(1);
  filteredOptions.next(options);
  const filter = {
    icon,
    label,
    options,
    defaultValue,
    formControl,
    disabledOption,
    filteredOptions,
    searchFormControl,
    onChange,
  };

  return filter;
};

export const allOptions = (groups: OptionGroup[]): FilterOption[] =>
  groups.reduce((acc, curr) => acc.concat(curr.options), [] as FilterOption[]);

export const firstUnselectedOption = (options: FilterOption[], selected: string[]): FilterOption | undefined =>
  options.find(x => !selected.includes(x.value));

export const selectedOptions = (filter: Filter): FilterOption[] => {
  const filterValue = filter.formControl.value;
  return allOptions(filter.options).filter(x => filterValue.includes(x.value));
};
