import { NotificationService } from 'src/app/notification/notification.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { clearNewPasswordSetState, setNewPassword } from '../store/authentication.action';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { selectNewPasswordSetState } from '../store/authentication.selector';
import { translate } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sibat-new-password',
  template: `
    <form>
      <div *ngIf="(completed$ | async) === false">
        <mat-form-field [formGroup]="newPasswordForm">
          <mat-label>{{ 'authentication.newPassword' | transloco }}</mat-label>
          <input matInput type="password" formControlName="newPassword" autocomplete="new-password" />
        </mat-form-field>
        <mat-form-field [formGroup]="newPasswordForm">
          <mat-label>{{ 'authentication.confirmNewPassword' | transloco }}</mat-label>
          <input matInput type="password" formControlName="confirmPassword" [errorStateMatcher]="matcher" />
          <mat-error>
            {{ 'authentication.passwordsMismatch' | transloco }}
          </mat-error>
        </mat-form-field>
        <sibat-primary-button
          (clicked)="setNewPassword()"
          [disabled]="newPasswordForm.invalid"
          [label]="'authentication.setNewPassword' | transloco"
        >
        </sibat-primary-button>
      </div>
      <div *ngIf="completed$ | async" class="completed">
        {{ 'authentication.passwordChanged' | transloco }}
      </div>
      <p><sibat-tertiary-button (clicked)="signin()" [label]="'authentication.signin' | transloco"> </sibat-tertiary-button></p>
      <hr />
      <p>
        {{ 'authentication.noAccount' | transloco }}&nbsp;
        <sibat-link-button [href]="supportEmail" [label]="'authentication.contactUs' | transloco" [external]="true"> </sibat-link-button>
      </p>
    </form>
  `,
  styleUrls: ['../css/authentication.scss'],
})
export class NewPasswordComponent implements OnInit {
  newPasswordForm = this.formBuilder.group(
    {
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    },
    { validators: this.checkPasswordsValidator }
  );
  token?: string;
  completed$ = this.store.select(selectNewPasswordSetState);
  supportEmail = `mailto:${environment.supportEmail}`;
  matcher = new FormErrorStateMatcher();

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.route.queryParams.pipe(take(1)).subscribe(queryParams => {
      const token = queryParams['token'];
      if (this.checkToken(token)) {
        this.token = token;
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(clearNewPasswordSetState());
  }

  checkPasswordsValidator(group: UntypedFormGroup) {
    const password = group.controls.newPassword.value;
    const confirmPassword = group.controls.confirmPassword.value;
    return password === confirmPassword ? null : { different: true };
  }

  setNewPassword() {
    if (this.checkToken(this.token)) {
      this.store.dispatch(
        setNewPassword({
          password: this.newPasswordForm.value.newPassword,
          token: this.token,
        })
      );
    }
  }

  signin() {
    this.router.navigate(['/signin']);
  }

  private checkToken(token?: string): token is string {
    const isString = typeof token === 'string';
    if (!isString) {
      this.notificationService.flashWarning(translate('building.error.unexpected'));
      this.router.navigate(['/']);
    }
    return isString;
  }
}

export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return invalidCtrl || invalidParent;
  }
}
