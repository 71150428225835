import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sibat-control-dashboard-widget',
  templateUrl: './control-dashboard-widget.component.html',
  styleUrls: ['./control-dashboard-widget.component.scss'],
})
export class ControlDashboardWidgetComponent {

  constructor(public router: Router) { }

  goToControlDashboard(): void {
    this.router.navigate(['/cockpit/control-dashboard']);
  }

}
