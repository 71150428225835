import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentDto, EditProtectiveMeasure } from '../../model/assessment.model';
import { Store } from '@ngrx/store';
import { DialogService } from '../../../shared/service/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { removeProtectiveMeasure } from '../../store/assessment.action';

@Component({
  selector: 'sibat-protective-measure-item',
  template: `
    <div *transloco="let t; read: 'building.assessment.protectiveMeasuresForm'" [class.read-only]="readOnly">
      <div
        class="protective-measure-item"
        [class.missing-measure-info]="tooltipContent"
        [matTooltip]="tooltipContent"
        matTooltipPosition="left"
        [matTooltipDisabled]="!displayTooltip"
      >
        <div class="measure-icon">
          <mat-icon >format_align_left</mat-icon>
        </div>
        <div class="measure-description">
          <span *ngIf="measure.description">{{ measure.description }}</span>
          <span *ngIf="!measure.description">{{ t('noDescription') }}</span>
        </div>
        <button class="inline-button update-button" mat-flat-button [disabled]="offline" (click)="toggleEditing(!isEditing)">
          <mat-icon>mode_edit</mat-icon>
        </button>
        <button
          class="inline-button delete-button"
          mat-flat-button
          [disabled]="offline"
          (click)="openDialogRemoveConfirmation(measure.id!!)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <sibat-edit-measure
        *ngIf="isEditing"
        [measure]="editMeasure"
        [readOnly]="readOnly"
        [buildingId]="buildingId"
        (measureSaved)="updateMeasure($event)"
        class="zone-edit"
      >
      </sibat-edit-measure>
    </div>
  `,
  styleUrls: ['protective-measure-item.component.scss'],
})
export class ProtectiveMeasureItemComponent implements OnInit {

  @Input() buildingId: number | undefined;
  @Input() assessment: AssessmentDto;
  @Input() displayTooltip: boolean;
  @Input() offline: boolean;
  @Input() readOnly: boolean;
  @Input() measure: EditProtectiveMeasure;
  @Output() measureSaved = new EventEmitter<EditProtectiveMeasure>();

  editMeasure: EditProtectiveMeasure;
  tooltipContent: string;
  isEditing = false;

  constructor(private store: Store, private dialogService: DialogService, private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.toggleEditing(this.readOnly);
  }

  toggleEditing(enable: boolean) {
    this.isEditing = enable;
    if (enable) {
      this.editMeasure = this.measure;
    }
  }

  async openDialogRemoveConfirmation(measureId: number): Promise<void> {

    const result = await this.dialogService.requestConfirmation({
      translationKey: 'building.assessment.protectiveMeasuresForm.sureToRemove',
    });
    if(result) {
      this.store.dispatch(
        removeProtectiveMeasure({
          assessmentId: this.assessment.id,
          measureId
        })
      );
    }

  }

  updateMeasure(measure: EditProtectiveMeasure): void {
    this.measureSaved.emit(measure);
    this.isEditing = false;
  }

}
