import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import AuthenticationState from './authentication.reducer';

export const getAuthenticationState = (state: State) => state.authentication;

export const selectAuthenticationError = createSelector(getAuthenticationState, (state: AuthenticationState) => state.error);

export const selectAuthenticationStatus = createSelector(getAuthenticationState, (state: AuthenticationState) => Boolean(state.token));

export const selectSigninUrlIfUnauthenticated = createSelector(getAuthenticationState, (state: AuthenticationState) => {
  if (state.token) {
    return undefined;
  } else {
    return '/signin';
  }
});

export const selectAuthenticationToken = createSelector(getAuthenticationState, (state: AuthenticationState) => state.token);

export const selectPasswordResetState = createSelector(getAuthenticationState, (state: AuthenticationState) => state.passwordReset);

export const selectNewPasswordSetState = createSelector(getAuthenticationState, (state: AuthenticationState) => state.newPasswordSet);
