import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { selectSelectedBuildingIndex } from '../store/buildings.selector';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sibat-selector',
  template: `
    <ng-container *ngIf="index >= 0">
      <ng-container *transloco="let t; read: 'building.selector'">
        <button class="nav-button" mat-icon-button data-testid="button-previous" [disabled]="index <= 0" (click)="prevClick()">
          <mat-icon>navigate_before</mat-icon>
          <span class="visually-hidden">{{ t('previous') }}</span>
        </button>
        <h5>
          <span class="current">{{ index + 1 }}</span> / {{ count }}
          <span class="detail">{{ t('selectedBuildings', { count: count }) }}</span>
        </h5>
        <button class="nav-button" mat-icon-button data-testid="button-next" [disabled]="index + 1 >= count" (click)="nextClick()">
          <mat-icon>navigate_next</mat-icon>
          <span class="visually-hidden">{{ t('next') }}</span>
        </button>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['selector.component.scss'],
})
export class SelectorComponent implements OnChanges, OnDestroy {
  @Input() buildingId: number;
  @Input() count: number;
  @Output() prevClicked = new EventEmitter<number>();
  @Output() nextClicked = new EventEmitter<number>();

  subscriptions = new Subscription();
  index = -1;

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.subscriptions.unsubscribe();
    this.subscriptions.add(this.store.select(selectSelectedBuildingIndex(this.buildingId)).subscribe(index => (this.index = index)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  prevClick() {
    this.prevClicked.emit(this.buildingId);
  }

  nextClick() {
    this.nextClicked.emit(this.buildingId);
  }
}
