import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectTagByName } from '../store/tag.selector';
import { DialogTagDetailComponent } from '../tag/dialog-tag-detail/dialog-tag-detail.component';
import { deleteTag } from '../store/tag.action';
import { MatDialog } from '@angular/material/dialog';
import { BuildingSummary } from '../model/building.model';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'sibat-building-list-buttons',
  templateUrl: './building-list-buttons.component.html',
  styleUrls: ['building-list-buttons.component.scss'],
})
export class BuildingListButtonsComponent {
  @Input() enableTags: boolean;
  @Input() tags: string[] = [];
  @Input() displayAddButton: boolean;
  @Input() enableLocation: boolean;
  @Input() enableBatchActions: boolean;
  @Input() maxDownloadSize: number;
  @Output() public addAll = new EventEmitter<void>();
  @Output() public removeAll = new EventEmitter<void>();
  @Output() public download = new EventEmitter<void>();
  @Output() public center = new EventEmitter<void>();

  public selectedBuildingIds: number[] = [];
  public writeTagActivated = false;
  public tagFormControl = new UntypedFormControl();

  public constructor(private store: Store, private dialog: MatDialog) {}

  @Input() set selectedBuildings(buildings: BuildingSummary[]) {
    this.selectedBuildingIds = buildings.map(building => building.id);
  }

  public toggleActivateTags(): void {
    this.tagFormControl.reset();
    this.writeTagActivated = !this.writeTagActivated;
  }

  async openDialogTagDetail(tagName: string) {
    const tag = await firstValueFrom(this.store.select(selectTagByName(tagName)));
    const dialogRef = this.dialog.open(DialogTagDetailComponent, {
      data: tag,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && tag !== undefined) {
        this.store.dispatch(deleteTag({ tag }));
      }
    });
  }

  public closeTagForm(): void {
    this.toggleActivateTags();
  }
}
