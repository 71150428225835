import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Building, BuildingSummary, DeleteInfos } from '../model/building.model';
import { environment } from '../../../environments/environment';
import { Typology, TypologyUpdateResult } from '../model/typology.model';
import { Page } from '../../model/page.model';
import { UpdateTypologiesDto, UpdateTypologyDto } from '../model/typology.dto';
import { EditHistory } from '../model/history.model';
import { UpdateNextControlDateDto } from '../model/building-control-date.dto';

export const BUILDINGS_BASE_URI = `${environment.apiUrl}/buildings`;
export const EXPORT_BASE_URI = `${environment.apiUrl}/export`;

export const BUILDINGS_PAGE_SIZE = 25;
export const DOWNLOAD_BUILDINGS_LIST_SIZE = 10000;

interface PageInfo {
  page: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  constructor(private readonly http: HttpClient) {}

  private static createQueryParams(
    municipalities: number[] = [],
    typologies: string[] = [],
    buildingClasses: number[] = [],
    constructionPeriods: number[] = [],
    affectations: string[] = [],
    controlledState?: string,
    pageInfo?: PageInfo
  ) {
    let params = new HttpParams();
    if (pageInfo) {
      params = params.append('page', pageInfo.page.toString()).append('size', pageInfo.pageSize.toString());
    }
    params = municipalities.reduce((acc, current) => acc.append('municipalities', current.toString()), params);
    params = typologies.reduce((acc, current) => acc.append('typologies', current.toString()), params);
    params = buildingClasses.reduce((acc, current) => acc.append('buildingClasses', current.toString()), params);
    params = constructionPeriods.reduce((acc, current) => acc.append('constructionPeriods', current.toString()), params);
    params = affectations.reduce((acc, current) => acc.append('affectations', current.toString()), params);

    if (controlledState) {
      params = params.append('controlledState', controlledState);
    }

    return params;
  }

  findAll(
    municipalities: number[],
    typologies: string[],
    buildingClasses: number[],
    constructionPeriod: number[],
    affectations: string[],
    controlledState?: string,
    page: number = 0,
    pageSize: number = BUILDINGS_PAGE_SIZE
  ): Observable<Page<BuildingSummary>> {
    const queryParams = BuildingService.createQueryParams(
      municipalities,
      typologies,
      buildingClasses,
      constructionPeriod,
      affectations,
      controlledState,
      {
        page,
        pageSize,
      }
    );
    return this.http.get<Page<BuildingSummary>>(BUILDINGS_BASE_URI, { params: queryParams });
  }

  downloadBuildings(
    municipalities: number[],
    typologies: string[],
    buildingClasses: number[],
    constructionPeriod: number[],
    affectations: string[],
    controlledState: string
  ): Observable<any> {
    const page = 0;
    const pageSize = DOWNLOAD_BUILDINGS_LIST_SIZE;
    const queryParams = BuildingService.createQueryParams(
      municipalities,
      typologies,
      buildingClasses,
      constructionPeriod,
      affectations,
      controlledState,
      {
        page,
        pageSize,
      }
    );
    return this.http.get(EXPORT_BASE_URI, { headers: {}, responseType: 'text', params: queryParams });
  }

  downloadBuildingList(buildingIds: number[]): Observable<string> {
    const page = 0;
    const pageSize = DOWNLOAD_BUILDINGS_LIST_SIZE;
    const queryParams = BuildingService.createQueryParams([], [], [], [], [], undefined, { page, pageSize });
    return this.http.post(`${EXPORT_BASE_URI}/selectedBuildings`, buildingIds, {
      headers: {},
      responseType: 'text',
      params: queryParams,
    });
  }

  get(buildingId: number): Observable<Building> {
    return this.http.get<Building>(`${BUILDINGS_BASE_URI}/${buildingId}`);
  }

  updateTypology(buildingId: number, typology: UpdateTypologyDto): Observable<Typology> {
    const updateTypology = {
      color: typology.color,
      affectationKeys: typology.affectationKeys,
      justification: typology.justification ?? null,
    } as UpdateTypologyDto;
    return this.http.put<Typology>(`${BUILDINGS_BASE_URI}/${buildingId}/typology`, updateTypology);
  }

  updateTypologies(buildingIds: number[], typology: UpdateTypologyDto): Observable<TypologyUpdateResult> {
    const updateTypology = {
      ...typology,
      justification: typology.justification ?? null,
    } as UpdateTypologyDto;
    const payload = {
      buildingIds,
      typology: updateTypology,
    } as UpdateTypologiesDto;
    return this.http.put<TypologyUpdateResult>(`${BUILDINGS_BASE_URI}/typology`, payload);
  }

  updateNextControlDates(buildingIds: number[], date: string | null): Observable<number[]> {
    const updatePayload = {
      buildingIds,
      date,
    } as UpdateNextControlDateDto;
    return this.http.put<number[]>(`${BUILDINGS_BASE_URI}/nextControlDate`, updatePayload);
  }

  getHistory(buildingId: number): Observable<EditHistory> {
    return this.http.get<EditHistory>(`${BUILDINGS_BASE_URI}/${buildingId}/history`);
  }

  deleteBuilding(buildingId: number): Observable<DeleteInfos> {
    return this.http.get<DeleteInfos>(`${BUILDINGS_BASE_URI}/${buildingId}/delete`);
  }

  getSummary(buildingId: number): Observable<BuildingSummary> {
    return this.http.get<BuildingSummary>(`${BUILDINGS_BASE_URI}/${buildingId}/summary`);
  }

  getSummaries(buildingId: number[]): Observable<BuildingSummary[]> {
    return this.http.post<BuildingSummary[]>(`${BUILDINGS_BASE_URI}/summaries`, buildingId);
  }
}
