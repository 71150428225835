<div mat-dialog-title class="title-container">
  <h2>{{ 'building.technicalInstallation.title' | transloco }}</h2>
  <sibat-icon-button href="https://ecab.atlassian.net/l/cp/pQKgiw1V"
                     icon="help"
                     target="_blank">
  </sibat-icon-button>
</div>

<mat-dialog-content fxLayout="column">
  <sibat-installation-detail *ngIf="installation$ | async as installation" [installation]="installation"
                             [buildingId]="buildingId">
  </sibat-installation-detail>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <sibat-secondary-button
    class="mat-button-base"
    (clicked)="close()"
    [label]="'layout.closeDialog' | transloco">
  </sibat-secondary-button>
</mat-dialog-actions>
