import { RoleFullDto } from '../../authentication/model/role.model';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

export const getContextCode = (roles: RoleFullDto[], role: string): string | null => {
  const selectedRole = roles.find(r => r.code === role);
  return selectedRole && selectedRole.context ? selectedRole.context.code : null;
};

export const getLabelForRole = (roles: RoleFullDto[], role: string): string | null => {
  const selectedRole = roles.find(r => r.code === role);
  return selectedRole ? selectedRole.name : null;
};

export const isRoleSelected = (userForm: UntypedFormGroup, role: string): boolean => {
  const selectedRoles: [{code: string; name: string}] = userForm.get('roles')?.value || [];
  return selectedRoles.some(selectedRole => selectedRole.code === role);
};

export const toggleRole = (userForm: UntypedFormGroup, role: string, roles$: Observable<RoleFullDto[]>): void => {
  const selectedRoles: {code: string; name: string}[] = userForm.get('roles')?.value || [];
  const roleIndex = selectedRoles.findIndex(r => r.code === role);
  if (roleIndex !== -1) {
    userForm.patchValue({
      roles: selectedRoles.filter(r => r.code !== role),
    });
  } else {
    roles$.subscribe(roles => {
      const name = getLabelForRole(roles, role) || role;

      userForm.patchValue({
        roles: [...selectedRoles, { code: role, name }],
      });
    });
  }
};

