import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AssessmentDto, EditProtectiveMeasure } from '../../model/assessment.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'sibat-protective-measures-list',
  template: `
    <div
      *transloco="let t; read: 'building.assessment.protectiveMeasuresForm'"
      [class.read-only]="readOnly"
      data-testid="sibat-protective-measures-list"
      class="container gap-between-items"
    >
     <h4 *ngIf="listProtectiveMeasures?.length > 0">{{ t('listeMeasures') }}</h4>
      <div class="gap-between-items">
        <sibat-protective-measure-item
          *ngFor="let measure of listProtectiveMeasures"
          [buildingId]="buildingId"
          [assessment]="assessment"
          [readOnly]="readOnly"
          [offline]="offline"
          [displayTooltip]="(isMobileOrTabletScreen$ | async) === false"
          [measure]="measure"
          (measureSaved)="updateMeasure($event)"
        ></sibat-protective-measure-item>
      </div>
      <!-- TODO protective-measure-list-offline as defects-list-offline-->
    </div>
  `,
  styleUrls: ['protective-measures-list-component.scss'],
})
export class ProtectiveMeasuresListComponent implements OnInit, OnChanges {

  @Input() readOnly: boolean;
  @Input() buildingId: number | undefined;
  @Input() assessment: AssessmentDto;
  @Input() offline: boolean;
  @Output() measureSaved = new EventEmitter<EditProtectiveMeasure>();

  isMobileOrTabletScreen$: Observable<boolean>;
  listProtectiveMeasures: EditProtectiveMeasure[];

  constructor(private store: Store, public media: MediaObserver) { }

  ngOnInit(): void {
    this.isMobileOrTabletScreen$ = this.media.asObservable().pipe(map(events => !!events.find(event => event.mqAlias === 'lt-md')));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.assessment) {
      const assessment = changes.assessment.currentValue;
       if (assessment && assessment.protectiveMeasures) {
         this.listProtectiveMeasures = [
           ...this.assessment.protectiveMeasures
         ];
       }
    }
  }

  updateMeasure(measure: EditProtectiveMeasure): void {
    this.measureSaved.emit(measure);
  }

}
