import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from '../sibat-route-serializer';

export const getRouterState = (state: State) => state.router;

export const selectComponentNavRoot = createSelector(getRouterState, (router?: RouterReducerState<RouterStateUrl>): string => {
  if (router) {
    const state = router.state;
    return state.data.nav;
  } else {
    return '';
  }
});
