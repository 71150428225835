import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
  parse(value: string): Date {
    dayjs.extend(customParseFormat);
    return dayjs(value, 'DD.MM.YYYY').toDate();
  }
}
