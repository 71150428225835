import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { RouterNavigationAction, routerNavigationAction } from '@ngrx/router-store';
import { tap } from 'rxjs/operators';
import { RouterStateUrl } from '../sibat-route-serializer';

@Injectable({
  providedIn: 'root',
})
export class RouterEffects {
  titleUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<RouterNavigationAction<RouterStateUrl>>(routerNavigationAction),
        concatLatestFrom(() => this.translocoService.selectTranslation()),
        tap(([action]) => {
          const rawTitle = action.payload.routerState.title;
          const title = rawTitle ? this.translocoService.translate('layout.title.' + rawTitle) : '';
          this.titleService.setTitle(title);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private titleService: Title, private translocoService: TranslocoService) {}
}
