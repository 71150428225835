import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { selectTagNames } from 'src/app/building/store/tag.selector';
import { addTag } from 'src/app/building/store/tag.action';

@Component({
  selector: 'sibat-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['add-tag.component.scss'],
})
export class AddTagComponent implements OnInit, OnDestroy{

  @Input() buildingIds: number[];
  @Output() escape = new EventEmitter();

  public tagFormControl = new FormControl();
  public tagsList: string[];
  public filteredTags$: Observable<string[]>;
  private subscriptionTags: Subscription;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.subscriptionTags = this.store.select(selectTagNames).subscribe(val => this.tagsList = val);
    this.filteredTags$ = this.tagFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  ngOnDestroy(): void {
    this.subscriptionTags.unsubscribe();
  }

  public addTag(): void {
    const tag = this.tagFormControl.value;
    if (tag.trim()) {
      this.store.dispatch(addTag({ tagName: tag, buildingIds: this.buildingIds }));
    }
    this.tagFormControl.reset();
  }

  public onEscape(): void{
    this.tagFormControl.reset();
    this.escape.emit();
  }

  private filter(value: string | null): string[] {
    const filterValue = value?.toLowerCase() ?? '';
    return this.tagsList.filter(option => option.toLowerCase().includes(filterValue));
  }
}
