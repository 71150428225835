import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { undoLoadUserSuccess } from '../../authentication/store/user.action';

@Component({
  selector: 'sibat-popup-changing-role',
  template:  `
    <h1 mat-dialog-title>Accès Refusé</h1>
    <div mat-dialog-content>
      <mat-icon class="test">error</mat-icon>
      <p>Vous allez changer de compte et vous n'avez pas accès à ce bâtiment. Vous allez donc être redirigé(e)
        vers la page d’accueil. Êtes-vous sûr(e) de vouloir quitter la session ?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="goToMapPage()">Oui</button>
      <button mat-button (click)="undo()">Non</button>
    </div>
  `,
  styleUrls: ['./popup-changing-role.component.scss']
})
export class PopupChangingRoleComponent implements OnInit {

  constructor(private router: Router, private dialogRef: MatDialogRef<PopupChangingRoleComponent>,private store: Store) { }

  ngOnInit(): void {
  }

  goToMapPage(){
    this.dialogRef.close();
    this.router.navigate(['/']);
  }
  undo() {
    this.store.dispatch(undoLoadUserSuccess());
    this.dialogRef.close();
  }

}
