import { DefectThumbnail } from '../control/model/control.model';
import { AssessmentThumbnail } from '../assessment/model/assessment.model';

export const formatThumbnails = (thumbnails: DefectThumbnail[]): Record<string, string> => {
  // this is only because of tests, throwing an error in afterAll :(
  if (Array.isArray(thumbnails)) {
    return thumbnails.reduce((acc: Record<string, string>, thumbnail) => {
      acc[thumbnail.pictureFileName] = thumbnail.thumbnail;
      return acc;
    }, {});
  } else {
    return {};
  }
};

// export const formatAssessmentThumbnails = (thumbnails: AssessmentThumbnail[]): Record<string, string> => {
//   // this is only because of tests, throwing an error in afterAll :(
//   if (Array.isArray(thumbnails)) {
//     return thumbnails.reduce((acc: Record<string, string>, thumbnail) => {
//       acc[thumbnail.pictureFileName] = thumbnail.thumbnail;
//       return acc;
//     }, {});
//   } else {
//     return {};
//   }
// };
