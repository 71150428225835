import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(url: string): SafeUrl {
    if (url) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    } else {
      return '' as SafeUrl;
    }
  }
}
