import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AlertModule } from '../alert/alert.module';
import { AppRoutingModule } from '../app-routing.module';
import { ArrayPipeModule } from '../shared/array.module';
import { CustomDateAdapter } from '../shared/date-adapter';
import { ModalModule } from '../shared/modal/ModalModule';
import { SharedModule } from '../shared/shared.module';
import { StringPipeModule } from '../shared/string.module';
import { AddDefectComponent } from './add-defect/add-defect.component';
import { ControlApprovalButtonComponent } from './control-report/control-approval-button/control-approval-button.component';
import { ControlReportComponent } from './control-report/control-report.component';
import { StatusAlertsComponent } from './control-report/status-alerts/status-alerts.component';
import { DefectItemComponent } from './defect-item/defect-item.component';
import { DefectTypeRadioButtonComponent } from './defect-type-radio-button/defect-type-radio-button.component';
import { DefectsListOfflineComponent } from './defects-list-offline/defects-list-offline.component';
import { DefectsListComponent } from './defects-list/defects-list.component';
import { EditControlDetailsComponent } from './edit-control-details/edit-control-details.component';
import { EditDefectComponent } from './edit-defect/edit-defect.component';
import { FullScreenPictureComponent } from './full-screen-picture/full-screen-picture.component';

@NgModule({
  declarations: [
    AddDefectComponent,
    ControlApprovalButtonComponent,
    ControlReportComponent,
    DefectsListComponent,
    DefectsListOfflineComponent,
    DefectTypeRadioButtonComponent,
    EditControlDetailsComponent,
    EditDefectComponent,
    FullScreenPictureComponent,
    StatusAlertsComponent,
    DefectItemComponent,
  ],
  imports: [
    AppRoutingModule,
    ArrayPipeModule,
    FlexModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    StringPipeModule,
    NgxMatSelectSearchModule,
    AlertModule,
    DragDropModule,
    ModalModule,
    SharedModule,
  ],
  exports: [ControlReportComponent, EditDefectComponent],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class ControlModule {}
