import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BuildingSummary } from '../model/building.model';
import { Store } from '@ngrx/store';
import { addAllBuildings, findMoreBuildings, removeAllSelectedBuildings } from '../store/buildings.action';
import { DOWNLOAD_BUILDINGS_LIST_SIZE } from '../service/building.service';
import { Observable } from 'rxjs';
import { selectTagNames } from '../store/tag.selector';

@Component({
  selector: 'sibat-building-list',
  template: `
    <sibat-building-list-buttons
      [enableTags]="highlightSelectedBuildings"
      [tags]="tags$ | async"
      [displayAddButton]="displayAddButton"
      [enableLocation]="buildingsCount > 0"
      [enableBatchActions]="!downloadOrAddRemoveDisabled"
      [maxDownloadSize]="maxDownloadSize"
      [selectedBuildings]="buildingsList"
      (addAll)="addAllBuildings()"
      (removeAll)="removeAllSelectedBuildings()"
      (download)="downloadBuildings()"
      (center)="centerOnAll()"
    ></sibat-building-list-buttons>
    <ng-scrollbar *ngIf="this.buildingsCount; else emptyList" [sensorDebounce]="1">
      <div
        infinite-scroll
        scrollViewport
        [scrollWindow]="false"
        [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScrollEnd()"
      >
        <div class="list single-action-list">
          <sibat-building-list-item
            *ngFor="let building of buildingsList; trackBy: trackBuildingId"
            [routerLink]="'/building/' + building.id"
            [selected]="building.selected && !highlightSelectedBuildings"
            [isInSelectedList]="highlightSelectedBuildings"
            [building]="building"
            [color]="building.currentColor"
            [attributesDisplay]="attributesDisplay"
            (centerOnBuilding)="centerOnSpecifiedBuilding($event)"
            class="clickable-item"
          >
          </sibat-building-list-item>
        </div>
      </div>
    </ng-scrollbar>
    <ng-template #emptyList>
      <p class="empty-list" matLine>{{ 'building.list.empty' | transloco }}</p>
    </ng-template>
  `,
  styleUrls: ['building-list.component.scss'],
})
export class BuildingListComponent implements OnInit, OnChanges {
  @Input() buildingsList: BuildingSummary[];
  @Input() buildingsCount: number;
  @Input() highlightSelectedBuildings: boolean;
  @Input() displayAddButton: boolean;
  @Input() attributesDisplay: Map<string, boolean>;
  @Output() downloadList = new EventEmitter<boolean>();
  @Output() centerOnBuilding = new EventEmitter<number>();
  @Output() centerOnAllBuildings = new EventEmitter<any>();

  selectedBuildingsListEmpty = true;
  downloadOrAddRemoveDisabled: boolean;
  maxDownloadSize = DOWNLOAD_BUILDINGS_LIST_SIZE;

  throttle = 300;
  scrollDistance = 2;

  tags$: Observable<string[]>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.tags$ = this.store.select(selectTagNames);
    this.refreshButtons();
  }

  trackBuildingId(index: number, item: BuildingSummary) {
    return item.id;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.selectedBuildingsListEmpty = changes.buildingsCount?.currentValue === 0;
    this.refreshButtons();
  }

  refreshButtons() {
    this.downloadOrAddRemoveDisabled = this.buildingsCount > DOWNLOAD_BUILDINGS_LIST_SIZE || this.buildingsCount === 0;
  }

  onScrollEnd() {
    this.store.dispatch(findMoreBuildings());
  }

  downloadBuildings() {
    this.downloadList.emit(this.highlightSelectedBuildings);
  }

  addAllBuildings() {
    this.store.dispatch(addAllBuildings());
  }

  removeAllSelectedBuildings() {
    this.store.dispatch(removeAllSelectedBuildings());
  }

  centerOnAll() {
    this.centerOnAllBuildings.emit();
  }

  centerOnSpecifiedBuilding(buildingId: number) {
    this.centerOnBuilding.emit(buildingId);
  }
}
