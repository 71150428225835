import { NgModule } from '@angular/core';
import { CockpitComponent } from './cockpit.component';
import { UserWidgetComponent } from './user-widget/user-widget.component';
import { WidgetComponent } from './widget/widget.component';
import { NewUserComponent } from './account-management/new-user/new-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { AccountManagementComponent } from './account-management/account-management.component';
import { ListUsersComponent } from './account-management/list-users/list-users.component';
import { EditUserComponent } from './account-management/edit-user/edit-user.component';
import { MunicipalityNamesPipe } from './pipes/municipality-names.pipe';
import { TagsWidgetComponent } from './tags-widget/tags-widget.component';
import { FeatureFlagsActivatorComponent } from './features-activator/feature-flags-activator.component';
import { OrphanControlsComponent } from './orphan-controls/orphan-controls.component';
import { ControlModule } from '../control/control.module';
import { OrphanControlItemComponent } from './orphan-controls/orphan-control-item.component';
import { SharedModule } from '../shared/shared.module';
import { MotdWidgetComponent } from './motd-widget/motd-widget.component';
import { MotdEditDialogComponent } from './motd-edit-dialog/motd-edit-dialog.component';
import { NonCompliantDefectsWidgetComponent } from './non-compliant-defects-widget/non-compliant-defects-widget.component';
import { ControlDashboardWidgetComponent } from './control-dashboard/control-dashboard-widget.component';
import { ControlDashboardComponent } from './control-dashboard/control-dashboard.component';
import { MunicipalityListComponent } from './municipality-list/municipality-list.component';
import { MunicipalityWidgetComponent } from './municipality-widget/municipality-widget.component';
import { UserNamesPipe } from './pipes/expert-names.pipe';
import {RoleContextValuesPipe} from './pipes/roles-context-values.pipe';

@NgModule({
  declarations: [
    CockpitComponent,
    UserWidgetComponent,
    WidgetComponent,
    NewUserComponent,
    AccountManagementComponent,
    ListUsersComponent,
    EditUserComponent,
    MunicipalityNamesPipe,
    TagsWidgetComponent,
    FeatureFlagsActivatorComponent,
    OrphanControlsComponent,
    OrphanControlItemComponent,
    MotdWidgetComponent,
    MotdEditDialogComponent,
    NonCompliantDefectsWidgetComponent,
    ControlDashboardWidgetComponent,
    ControlDashboardComponent,
    MunicipalityListComponent,
    MunicipalityWidgetComponent,
    UserNamesPipe,
    RoleContextValuesPipe
  ],
  imports: [FormsModule, ReactiveFormsModule, FlexModule, ControlModule, SharedModule],
})
export class CockpitModule {}
