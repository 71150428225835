import { Component } from '@angular/core';
import { BaseButtonComponent } from './base-button.component';

@Component({
  selector: 'sibat-update-button',
  template: `
    <button
      mat-button
      class="button button-orange"
      [type]="submit ? 'submit' : 'button'"
      [class.button-disabled]="disabled"
      [class.button-stretch]="stretch"
      [disabled]="disabled"
      (click)="clicked.emit($event)"
    >
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      <span>{{ label }}</span>
    </button>
  `,
  styleUrls: ['buttons.scss'],
})
export class UpdateButtonComponent extends BaseButtonComponent {}
