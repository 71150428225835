import { Pipe, PipeTransform } from '@angular/core';
import { ContextValues } from '../../admin/model/context-values.model';

@Pipe({
  name: 'roleContextValues',
})
export class RoleContextValuesPipe implements PipeTransform {
  transform(value: Array<{ contextValues: number[] }>, contextValues: ContextValues[] | null): string {
    if (!contextValues || contextValues.length === 0) {
      return '';
    }

    const result = new Set<string>();

    value.forEach(v => {
      v.contextValues.forEach(contextValue => {
        const alias = contextValues.find(m => m.geoField === contextValue.toString())?.geoFieldAlias;
        if (alias) {
          result.add(alias);
        }
      });
    });

    return Array.from(result).join(', ');
  }
}
