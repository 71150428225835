import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConstructionPeriod } from '../model/construction-period.model';

@Injectable({
  providedIn: 'root',
})
export class ConstructionPeriodService {
  baseUrl = `${environment.apiUrl}/construction-periods`;

  constructor(private readonly http: HttpClient) {}

  findAll(): Observable<ConstructionPeriod[]> {
    return this.http.get<ConstructionPeriod[]>(this.baseUrl);
  }
}
