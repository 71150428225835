import { createAction, props } from '@ngrx/store';
import { MapExtent } from '../model/map-extent.model';
import { HttpErrorResponse } from '@angular/common/http';

export const updateExtent = createAction('[Map] update extent', props<{ extent: MapExtent }>());

export const clearExtent = createAction('[Map] clear extent');

export const updateMapQuery = createAction('[Filters] update map query');

export const updateMapQuerySuccess = createAction('[Filters] update map query success', props<{ query: string }>());

export const updateMapQueryFailure = createAction(
  '[Filters] update map query failure',
  props<{ error: HttpErrorResponse; payload: any }>()
);

export const reportMapError = createAction('[Building] report error while calling web map', props<{ error: Error }>());
