import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DefectType } from '../model/defect-type.model';

@Component({
  selector: 'sibat-defect-type-radio-button',
  template: `
    <div *transloco="let t; read: 'building.control.editDefectForm'" class="defect-type" [formGroup]="group">
      <mat-label [class.warn]="!group?.value.type">{{ t('defectType') }}</mat-label>
      <mat-radio-group formControlName="type" name="defectTypeGroup">
        <mat-radio-button name="defectType" [value]="defectTypes.minor">{{ t('minorDefect') }}</mat-radio-button>
        <mat-radio-button name="defectType" [value]="defectTypes.major">{{ t('majorDefect') }}</mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  styleUrls: ['defect-type-radio-button.component.scss'],
})
export class DefectTypeRadioButtonComponent implements OnInit {
  @Input() group: UntypedFormGroup;

  defectTypes = DefectType;

  constructor() {}

  ngOnInit(): void {}
}
