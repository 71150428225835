export const MAX_SIZE_DOC = 262144000;
export const FILE_ALREADY_EXISTS_PATTERN = /^A file with the same name already exists.+File name: (?<fileName>.+)$/;

export const SIBAT_FILE_ERROR_CODE = {
  maxSizeExceeded: 0,
} as const;
export type SibatFileErrorCode = typeof SIBAT_FILE_ERROR_CODE[keyof typeof SIBAT_FILE_ERROR_CODE];

export interface SibatFile {
  name: string;
  isFolder: boolean;
  isEmpty: boolean;
}

export interface SibatFileError {
  code: SibatFileErrorCode;
  fileName: string;
}

export interface SibatFileBrowsingData {
  root: string;
  documentsPath: string;
}

export interface TargetBreadcrumb {
  index: number;
  crumbs: string[];
}

export const isFileAlreadyExistsError = (errorMessage: string): boolean =>
  FILE_ALREADY_EXISTS_PATTERN.test(errorMessage);
