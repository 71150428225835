<div class="mat-subheader" [ngClass]="{ tags: writeTagActivated }">
  <button *ngIf="enableTags" mat-mini-fab (click)="toggleActivateTags()">
    <mat-icon>local_offer</mat-icon>
  </button>
  <div class="other-buttons" *ngIf="!writeTagActivated">
    <div matTooltip="{{ 'building.list.addTooltip' | transloco: { maxDownloadSize: maxDownloadSize } }}">
      <button mat-mini-fab style="margin-left: 16px" *ngIf="displayAddButton" [disabled]="!enableBatchActions"
              (click)="addAll.emit()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <button
      mat-mini-fab
      style="margin-left: 16px"
      *ngIf="!displayAddButton"
      [disabled]="!enableBatchActions"
      (click)="removeAll.emit()"
    >
      <mat-icon>remove</mat-icon>
    </button>
    <div matTooltip="{{ 'building.list.downloadTooltip' | transloco: { maxDownloadSize: maxDownloadSize } }}">
      <button mat-mini-fab style="margin-left: 16px" [disabled]="!enableBatchActions" (click)="download.emit()">
        <mat-icon>cloud_download</mat-icon>
      </button>
    </div>
    <button mat-mini-fab [disabled]="!enableLocation" (click)="center.emit()">
      <mat-icon>my_location</mat-icon>
    </button>
  </div>
  <div class="tags-header" *ngIf="writeTagActivated">
    <h3>{{ 'building.list.tags' | transloco }}</h3>
    <button
      mat-icon-button
      [disabled]="tags.length === 0"
      [matMenuTriggerFor]="tagList"
      [attr.aria-label]="'building.list.tags' | transloco"
    >
      <mat-icon [hidden]="tags.length === 0">arrow_drop_down</mat-icon>
    </button>
  </div>
  <mat-menu #tagList="matMenu">
    <button mat-menu-item (click)="openDialogTagDetail(tag)" *ngFor="let tag of tags" [value]="tag">
      <span class="tag-item">{{ tag }}</span>
    </button>
  </mat-menu>
</div>

<sibat-add-tag *ngIf="writeTagActivated && selectedBuildingIds.length > 0"
               [buildingIds]="selectedBuildingIds"
               (escape)="closeTagForm()"
></sibat-add-tag>
