import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Building } from '../../building/model/building.model';
import { User } from '../../authentication/model/user.model';
import { Event } from '@bugsnag/js';
import { UpdateTypologyDto } from 'src/app/building/model/typology.dto';
import { AssessmentDto } from '../../assessment/model/assessment.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingService {
  addUserToBugsnag = (user: User): void => {
    Bugsnag.setUser(`${user.id}`, user.email, `${user.firstName} ${user.lastName}`);
  };

  clearUserData = (): void => {
    Bugsnag.setUser(undefined, undefined, undefined);
  };

  reportBuildingError(error: Error, user: User | undefined, building: Building | undefined, sourceActionType: string, payload?: any) {
    Bugsnag.notify(error, event => {
      this.addRoleData(event, user);
      this.addBuildingData(event, building);
      if (payload !== undefined) {
        event.addMetadata('payload', payload);
      }
      event.addMetadata('action', { sourceAction: sourceActionType });
    });
  }

  reportTypologyError(error: Error, user: User | undefined, building: Building | undefined, typology: UpdateTypologyDto) {
    Bugsnag.notify(error, event => {
      this.addRoleData(event, user);
      this.addBuildingData(event, building);
      if (typology) {
        event.addMetadata('typology', {
          color: typology.color,
        });
      }
    });
  }

  reportAddTagError(error: Error, user: User | undefined, tagName: string, buildingIds: number[]) {
    Bugsnag.notify(error, event => {
      this.addRoleData(event, user);
      if (tagName || buildingIds) {
        event.addMetadata('tag', {
          tagName,
          buildingIds,
        });
      }
    });
  }

  reportStartControlError(error: Error, user: User | undefined, building: Building | undefined) {
    Bugsnag.notify(error, event => {
      this.addRoleData(event, user);
      this.addBuildingData(event, building);
    });
  }

  reportMapError(error: Error, user: User | undefined) {
    console.error('Error: ', error);
    Bugsnag.notify(error, event => {
      this.addRoleData(event, user);
    });
  }

  reportMapQueryError(error: Error, query: string | undefined) {
    console.error('Error: ', error);
    Bugsnag.notify(error, event => {
      event.addMetadata('MapQuery', {
        query
      });
    });
  }

  reportAssessmentError(error: Error, assessment: AssessmentDto | undefined) {
    Bugsnag.notify(error, event => {
      this.addAssessmentData(event, assessment);
    });
  }

  addRoleData = (event: Event, user?: User) => {
    if (user) {
      event.addMetadata('role', {
        id: user.id,
        role: user.role,
        accreditations: user.accreditations.map(municipality => municipality.name),
      });
    }
  };

  addBuildingData = (event: Event, building?: Building) => {
    if (building) {
      event.addMetadata('building', {
        buildingId: building.id,
        municipality: building.municipality,
        color: building.typology?.color,
      });
    }
  };

  addAssessmentData = (event: Event, assessment?: AssessmentDto) => {
    if (assessment) {
      event.addMetadata('assessment',{
        id: assessment.id,
        caseId: assessment.caseId,
        status: assessment.status,
        approvalRequestDate: assessment.approvalRequestDate
      });
    }
  };
}
