import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectTags } from '../../building/store/tag.selector';
import { Observable } from 'rxjs';
import { Tag } from '../../building/model/tag.model';
import { replaceBuildingsSelection } from '../../building/store/buildings.action';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { deleteTag } from 'src/app/building/store/tag.action';

@Component({
  selector: 'sibat-tags-widget',
  template: `
    <sibat-widget title="{{ 'cockpit.tags' | transloco }}">
      <mat-icon widget-icon>sell</mat-icon>
      <div widget-content class="content">
        <ng-container *ngIf="tags$ | async as tags; else noTags">
          <mat-chip-list *ngIf="tags.length; else noTags">
            <sibat-tag *ngFor="let tag of tags" [name]="tag.name" (selected)="onSelection(tag)" (delete)="onDelete(tag)"></sibat-tag>
          </mat-chip-list>
        </ng-container>
        <ng-template #noTags>
          <p>{{ 'cockpit.noTags' | transloco }}</p>
        </ng-template>
      </div>
    </sibat-widget>
  `,
  styleUrls: ['tags-widget.component.scss'],
})
export class TagsWidgetComponent implements OnInit {
  tags$: Observable<Tag[]>;

  constructor(private store: Store, private dialogService: DialogService, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.tags$ = this.store.select(selectTags);
  }

  onSelection(tag: Tag) {
    this.store.dispatch(replaceBuildingsSelection({ buildingIds: tag.buildingIds }));
  }

  async onDelete(tag: Tag): Promise<void> {
    const label = this.translocoService.translate('building.tags.confirmRemove', { tag: tag.name });
    const result = await this.dialogService.requestConfirmation(label);
    if (result) {
      this.store.dispatch(deleteTag({ tag }));
    }
  }
}
