import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Store } from '@ngrx/store';
import dayjs from 'dayjs';
import localeDe from 'dayjs/esm/locale/de';
import localeFr from 'dayjs/esm/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Subscription } from 'rxjs';
import { role } from 'src/app/authentication/capability';
import { Role } from 'src/app/authentication/model/role.model';
import { selectUserRole } from 'src/app/authentication/store/user.selector';
import { toggleSelectionInList } from 'src/app/shared/array';
import { Affectation } from '../model/affectation.model';
import { Expert } from '../model/expert.model';
import { UpdateTypologyDto } from '../model/typology.dto';
import { ColorTypology, COLOR_TYPOLOGY, emptyTypology, Typology } from '../model/typology.model';

interface CanSaveTypologyData {
  canSave: boolean;
  currentValue: UpdateTypologyDto;
}

@Component({
  selector: 'sibat-typology-editor',
  templateUrl: './typology-editor.component.html',
  styleUrls: ['./typology-editor.component.scss'],
})
export class TypologyEditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isEditDenied: boolean;
  @Input() affectations: Affectation[] = [];
  @Input() referringExpert?: Expert;
  @Input() hideLastUpdate = false;

  @Output() typologyChanged = new EventEmitter<UpdateTypologyDto>();
  @Output() canSave = new EventEmitter<CanSaveTypologyData>();

  typologyForm: UntypedFormGroup;
  disableAll: boolean;
  justificationRequired: boolean;
  selectedTypology?: ColorTypology;
  buildingTypology: Typology = emptyTypology;
  typologyDate?: string;
  colorTypology = COLOR_TYPOLOGY;
  forbiddenColor = COLOR_TYPOLOGY.red;
  mailPrevention = 'prevention@ecab.ch';

  private userRoleSubscription?: Subscription;
  private formStatusSubscription?: Subscription;

  constructor(private store: Store, private localeService: TranslocoLocaleService) {
    this.typologyForm = new UntypedFormGroup({
      color: new UntypedFormControl('', Validators.required),
      justification: new UntypedFormControl(''),
      affectationKeys: new UntypedFormControl([], Validators.required),
    });
    dayjs.extend(relativeTime);
    this.formStatusSubscription = this.typologyForm.statusChanges.subscribe(status => {
      const currentValue: UpdateTypologyDto = {
        color: this.typologyForm.value.color,
        affectationKeys: this.typologyForm.value.affectationKeys,
        justification: this.typologyForm.value.justification,
      };
      this.canSave.emit({
        canSave: status === 'VALID' && !this.typologyForm.pristine,
        currentValue,
      });
    });
  }

  @Input() set typology(value: Typology | undefined) {
    if (value) {
      this.buildingTypology = value;
    } else {
      this.buildingTypology = emptyTypology;
    }
  }

  ngOnInit(): void {
    this.userRoleSubscription = this.store.select(selectUserRole).subscribe((r: Role) => {
      this.isEditDenied = !role(r).isEcabOrAbove();
      this.refreshDisplay();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.typology) {
      this.selectedTypology = this.buildingTypology.color;
      if (this.buildingTypology.date) {
        this.typologyDate = this.buildingTypology.date ? this.fromNow(this.buildingTypology.date) : '';
        this.typologyForm.patchValue({
          color: this.buildingTypology.color,
          affectationKeys: this.buildingTypology.affectationKeys,
          justification: this.buildingTypology.justification,
        });
      }
      this.refreshDisplay();
    }
  }

  ngOnDestroy() {
    this.userRoleSubscription?.unsubscribe();
    this.formStatusSubscription?.unsubscribe();
  }

  fromNow = (date: string): string =>
    dayjs(date)
      .locale(this.localeService.getLocale().includes('fr') ? localeFr : localeDe)
      .fromNow();

  refreshDisplay() {
    this.justificationRequired = this.getColorAccordingToAffectationsSelected() !== this.selectedTypology;
    if (this.justificationRequired) {
      this.typologyForm.get('justification')?.setValidators([Validators.required, Validators.minLength(1)]);
    } else {
      this.typologyForm.get('justification')?.setValidators(null);
    }
    this.typologyForm.get('justification')?.updateValueAndValidity();
    this.disableAll = this.computeDisableAll(this.isEditDenied);
    if (this.disableAll) {
      this.typologyForm.get('justification')?.disable();
    } else {
      this.typologyForm.get('justification')?.enable();
    }
  }

  setSelectedTypology(color: ColorTypology) {
    this.selectedTypology = color;
    this.refreshDisplay();
  }

  affectationClick(affectation: Affectation) {
    const newAffectations = toggleSelectionInList(affectation.key, this.buildingTypology.affectationKeys ?? []);
    this.typology = { ...this.buildingTypology, affectationKeys: newAffectations };
    this.typologyForm.get('affectationKeys')?.setValue(newAffectations);
    this.typologyForm.markAsDirty();
    this.defineColorAccordingToAffectationsSelected();
  }

  defineColorAccordingToAffectationsSelected() {
    const color = this.getColorAccordingToAffectationsSelected();
    this.typologyForm.get('color')?.setValue(color);
    this.setSelectedTypology(color);
  }

  getColorAccordingToAffectationsSelected(): ColorTypology {
    if (this.buildingTypology.affectationKeys.length > 0) {
      if (this.isAffectationListContainingAnySelectedAffectation(COLOR_TYPOLOGY.red)) {
        return COLOR_TYPOLOGY.red;
      } else if (this.isAffectationListContainingAnySelectedAffectation(COLOR_TYPOLOGY.green)) {
        return COLOR_TYPOLOGY.green;
      }
      return COLOR_TYPOLOGY.white;
    }
    return this.buildingTypology.color;
  }

  isAffectationListContainingAnySelectedAffectation(color: ColorTypology): boolean {
    return this.affectations
      .filter(affectation => affectation.color === color)
      .map(affectation => affectation.key)
      .some(affectationId => this.buildingTypology.affectationKeys.includes(affectationId));
  }

  computeDisableAll(isNotExpert: boolean): boolean {
    return isNotExpert && this.selectedTypology === COLOR_TYPOLOGY.red;
  }
}
