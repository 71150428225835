import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { getBuilding } from './building.action';
import { selectIsECABOrAbove } from '../../authentication/store/user.selector';
import { Store } from '@ngrx/store';
import { getInstallations } from './technical-installation.action';

@Injectable({
  providedIn: 'root',
})
export class BuildingTechnicalInstallationsEffects {
  fetchBuildingTechnicalInstallationsEffect$ = createEffect(() =>
    // eslint-disable-next-line ngrx/avoid-cyclic-effects
    combineLatest([this.actions$.pipe(ofType(getBuilding)), this.store.select(selectIsECABOrAbove)]).pipe(
      filter(([, ecabOrAbove]) => ecabOrAbove),
      map(([{ buildingId }]) => getInstallations({ buildingId }))
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
