import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BuildingSummary } from '../model/building.model';
import { ColorTypology } from '../model/typology.model';
import { Store } from '@ngrx/store';
import { addSelectedBuildings, removeSelectedBuilding } from '../store/buildings.action';
import { removeTagFromBuilding } from '../store/tag.action';

@Component({
  selector: 'sibat-building-list-item',
  templateUrl: 'building-list-item.component.html',
  styleUrls: ['building-list-item.component.scss'],
})
export class BuildingListItemComponent {
  @Input() building: BuildingSummary;
  @Input() color: ColorTypology;
  @Input() selected: boolean;
  @Input() isInSelectedList: boolean;
  @Input() attributesDisplay: Map<string, boolean> = new Map<string, boolean>();
  @Output() centerOnBuilding = new EventEmitter<number>();

  constructor(private store: Store) {}

  addBuilding() {
    this.store.dispatch(addSelectedBuildings({ buildingIds: [this.building.id] }));
  }

  removeBuilding() {
    this.store.dispatch(removeSelectedBuilding({ buildingId: this.building.id }));
  }

  displayBuildingLocation() {
    this.centerOnBuilding.emit(this.building.id);
  }

  removeTagFromSelectedBuilding(tagName: string) {
    this.store.dispatch(removeTagFromBuilding({ tagName, buildingId: this.building.id }));
  }
}
