import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NotificationService } from '../notification.service';
import { hasNotification, MESSAGE_LEVEL } from './../../model/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationEffects {
  onNotifyMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        tap(action => {
          if (hasNotification(action)) {
            const notification = action.notification;
            const message = notification.translate
              ? translate<string>(notification.label, notification.translationConfig)
              : notification.label;

            if (!message) {
              this.notificationService.dismissVisible();
              return;
            }

            if(notification.level === MESSAGE_LEVEL.warning) {
              this.notificationService.flashWarning(message);
            } else {
              this.notificationService.flashInfo(message);
            }
          }
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private notificationService: NotificationService) {}
}
