import { createAction, props } from '@ngrx/store';

export const goOnline = createAction('[Network] going online');
export const goOffline = createAction('[Network] going offline');

export const forceOffline = createAction('[Network] force offline');
export const releaseForcedOffline = createAction('[Network] release forced offline');
export const releaseForcedOfflineAndUploadDefects = createAction(
  '[Network] release forced offline and upload defects',
  props<{ controlId: number }>()
);

export const releaseForcedOfflineAndUploadAssessment = createAction(
  '[Network] release forced offline and upload assessment',
  props<{ assessmentId: number }>()
);
