import { ALL_VALUE, createFilterOption, FILTER_OPTION_BEHAVIOUR, FilterOption, CONTROLLED, NOT_CONTROLLED } from '../model/filter';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ConstructionPeriod } from '../model/construction-period.model';
import { BuildingClass } from '../model/building-class.model';
import { Affectation } from '../model/affectation.model';
import { ColorTypology } from '../model/typology.model';
import { MunicipalitySummary } from '../model/municipality.model';

@Injectable({
  providedIn: 'root',
})
export class FilterOptionService {
  constructor(private translocoService: TranslocoService) {}

  municipalityFilterOption(municipality: MunicipalitySummary): FilterOption {
    return createFilterOption(municipality.name, municipality.nr.toString());
  }

  typologyFilterOptions(typologyMap: Array<[ColorTypology, string]>): FilterOption[] {
    return typologyMap.map(([value, translationCode]) => createFilterOption(this.translocoService.translate(translationCode), value));
  }

  assignmentFilterOption(assignment: Affectation): FilterOption {
    return createFilterOption(assignment.title, assignment.key);
  }

  buildingClassFilterOption(buildingClass: BuildingClass): FilterOption {
    return createFilterOption(buildingClass.name, buildingClass.code.toString());
  }

  constructionPeriodsFilterOption(period: ConstructionPeriod): FilterOption {
    return createFilterOption(period.period, period.code.toString());
  }

  withoutDefaultValue(value: string[]): string[] {
    return value.filter(x => x !== ALL_VALUE);
  }

  tbdOption() {
    return createFilterOption(this.translocoService.translate('building.filter.toBeDefined'), 'a_definir');
  }

  noAssignmentOption() {
    return createFilterOption(this.translocoService.translate('building.filter.withoutAssignment'), 'no_affectation');
  }

  /**
   * Create a filter option to select all results.
   *
   * @param format translation format can be m for male, or f for female.
   */
  allOption(format: 'm' | 'f' = 'f') {
    return createFilterOption(
      this.translocoService.translate('building.filter.all', { format }),
      ALL_VALUE,
      FILTER_OPTION_BEHAVIOUR.exclusive
    );
  }

  controlledOption() {
    return createFilterOption(this.translocoService.translate('building.filter.controlled'), CONTROLLED, FILTER_OPTION_BEHAVIOUR.exclusive);
  }

  notControlledOption() {
    return createFilterOption(
      this.translocoService.translate('building.filter.notControlled'),
      NOT_CONTROLLED,
      FILTER_OPTION_BEHAVIOUR.exclusive
    );
  }

  filterOn = items => {
    const keys = {
      ofMunicipality: 'nr',
      ofBuildingClass: 'code',
      ofAssignment: 'key',
      ofTypology: '0',
      ofConstructionPeriod: 'code',
    };

    const getIncludedItems = (key: string) => selectedItems => items.filter(item => selectedItems.includes(item[key]));
    const getUnincludedItems = (key: string) => selectedItems => items.filter(item => !selectedItems.includes(item[key]));

    return {
      withMunicipalityKey: {
        including: getIncludedItems(keys.ofMunicipality),
        notIncluding: getUnincludedItems(keys.ofMunicipality),
      },
      withTypologyKey: {
        including: getIncludedItems(keys.ofTypology),
        notIncluding: getUnincludedItems(keys.ofTypology),
      },
      withAssignmentKey: {
        including: getIncludedItems(keys.ofAssignment),
        notIncluding: getUnincludedItems(keys.ofAssignment),
      },
      withBuildingClassKey: {
        including: getIncludedItems(keys.ofBuildingClass),
        notIncluding: getUnincludedItems(keys.ofBuildingClass),
      },
      withConstructionPeriodKey: {
        including: getIncludedItems(keys.ofConstructionPeriod),
        notIncluding: getUnincludedItems(keys.ofConstructionPeriod),
      },
    };
  };
}
