import { Control, ControlDetails, ControlsStats, ControlSummary } from '../model/control.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BUILDINGS_BASE_URI } from '../../building/service/building.service';
import { EditHistory, EditHistoryWithValidatedControl } from '../../building/model/history.model';
import { environment } from '../../../environments/environment';
import { filterResponseBody } from 'src/app/shared/http-event';

export const CONTROLS_BASE_URI = `${environment.apiUrl}/controls`;
export const CONTROL_STATS_URI = `${environment.apiUrl}/stats/controls`;

@Injectable({
  providedIn: 'root',
})
export class ControlService {
  constructor(private readonly http: HttpClient) {}

  getControlApiBasePath = (buildingId?: number): string => {
    if (buildingId) {
      return `${BUILDINGS_BASE_URI}/${buildingId}/control`;
    } else {
      return CONTROLS_BASE_URI;
    }
  };

  startControl(buildingId?: number): Observable<Control> {
    return this.http.post<Control>(this.getControlApiBasePath(buildingId), {});
  }

  uploadControlReport(buildingId: number, controlDate: Date, report: File, overwrite: boolean = false): Observable<Control> {
    const formData = new FormData();
    formData.append('date', controlDate.toISOString());
    formData.append('file', report);
    const params = new HttpParams()
      .append('overwrite', overwrite);
    const request = this.http.post<Control>(`${BUILDINGS_BASE_URI}/${buildingId}/control:upload-report`, formData, {
      reportProgress: true,
      observe: 'events',
      params,
    });
    return filterResponseBody(request);
  }

  refreshOngoingControl(buildingId: number): Observable<Control | undefined> {
    return this.http.get<Control | undefined>(`${BUILDINGS_BASE_URI}/${buildingId}/control`);
  }

  cancelOngoingControl(controlId: number): Observable<EditHistory> {
    return this.http.delete<EditHistory>(`${CONTROLS_BASE_URI}/${controlId}`, {});
  }

  updateControlDetails(controlId: number, controlDetails: ControlDetails): Observable<Control> {
    return this.http.put<Control>(`${CONTROLS_BASE_URI}/${controlId}`, controlDetails);
  }

  fetchOrphanControls(): Observable<ControlSummary[]> {
    return this.http.get<ControlSummary[]>(`${CONTROLS_BASE_URI}/orphans`);
  }

  getControl(controlId: number): Observable<Control> {
    return this.http.get<Control>(`${CONTROLS_BASE_URI}/${controlId}`);
  }

  linkControlToBuilding(controlId: number, buildingId: number): Observable<Control> {
    return this.http.post<Control>(`${BUILDINGS_BASE_URI}/${buildingId}/control/${controlId}`, {});
  }

  // PREVIEW

  previewControlReport(controlId: number): Observable<Blob> {
    return this.http.get(`${CONTROLS_BASE_URI}/${controlId}/preview`, {
      headers: {},
      responseType: 'blob',
    });
  }

  // VALIDATION

  requestExpertApproval(buildingId: number): Observable<Control> {
    return this.http.post<Control>(`${BUILDINGS_BASE_URI}/${buildingId}/control/request-approval`, {});
  }

  cancelRequestExpertApproval(buildingId: number): Observable<Control> {
    return this.http.delete<Control>(`${BUILDINGS_BASE_URI}/${buildingId}/control/request-approval`, {});
  }

  approveControl(buildingId: number): Observable<Control> {
    return this.http.post<Control>(`${BUILDINGS_BASE_URI}/${buildingId}/control/approve`, {});
  }

  validateControl(buildingId: number): Observable<EditHistoryWithValidatedControl> {
    return this.http.post<EditHistoryWithValidatedControl>(`${BUILDINGS_BASE_URI}/${buildingId}/control/validate`, {});
  }

  getControlsStats(periodStart: string, periodEnd: string, municipality?: number[]): Observable<ControlsStats[]> {
    const params = {
      start: periodStart,
      end: periodEnd,
    };
    if (municipality) {
      params['municipality'] = municipality;
    }

    return this.http.get<ControlsStats[]>(CONTROL_STATS_URI, {
      params
    });
  }
}
