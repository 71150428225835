import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private translocoService: TranslocoService, private breakpointObserver: BreakpointObserver) {}

  setActiveLang(language: string) {
    this.translocoService.setActiveLang(language);
  }

  ensureActiveLang(language: string) {
    const currentLanguage = this.translocoService.getActiveLang();
    if (language !== currentLanguage) {
      this.translocoService.setActiveLang(language);
    }
  }

  isSmallScreen(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 600px)');
  }
}
