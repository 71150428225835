import {
  addAllBuildingsSuccess,
  addSelectedBuildingsSuccess,
  clearBuildingsStore,
  findBuildingClassesSuccess,
  findBuildings,
  findBuildingsSuccess,
  findConstructionPeriodsSuccess,
  findMoreBuildingsSuccess,
  findMunicipalitiesSuccess,
  findTypologyAffectationsSuccess,
  refreshSelectedBuildingsSuccess,
  reinitializeAllFilters,
  reloadLocalizedDataSuccess,
  removeAllSelectedBuildings,
  removeSelectedBuilding,
  removeSelectedBuildings,
  replaceBuildingsSelectionSuccess,
  setAffectationsFilter,
  setBuildingClassesFilter,
  setConstructionPeriodsFilter,
  setControlledStateFilter,
  setMunicipalitiesFilter,
  setTypologiesFilter,
} from './buildings.action';
import { Action, createReducer, on } from '@ngrx/store';
import { BuildingSummary } from '../model/building.model';
import { Affectation } from '../model/affectation.model';
import { Municipality } from '../model/municipality.model';
import { BuildingClass } from '../model/building-class.model';
import { ConstructionPeriod } from '../model/construction-period.model';
import { Tag } from '../model/tag.model';
import {
  addTagSuccess,
  deleteTagSuccess,
  getTagsSuccess,
  removeTagFromBuildingSuccess,
} from './tag.action';
import { updateNextControlDatesSuccess, updateTypologiesSuccess } from './building.action';
import { Typology } from '../model/typology.model';
import { validateControlSuccess } from '../../control/store/control.action';

export default interface BuildingsState {
  buildings: BuildingSummary[];
  buildingsCount: number;
  buildingsPage: number;
  selectedBuildings: BuildingSummary[];
  municipalities: Municipality[];
  municipalitiesFilter: number[];
  typologiesFilter: string[];
  buildingClasses: BuildingClass[];
  buildingClassesFilter: number[];
  constructionPeriods: ConstructionPeriod[];
  constructionPeriodsFilter: number[];
  affectations: Affectation[];
  affectationsFilter: string[];
  controlledStateFilter: string;
  tags: Tag[];
}

export const initialState: BuildingsState = {
  buildings: [],
  buildingsCount: 0,
  buildingsPage: 0,
  selectedBuildings: [],
  municipalities: [],
  municipalitiesFilter: [],
  typologiesFilter: [],
  buildingClasses: [],
  buildingClassesFilter: [],
  constructionPeriods: [],
  constructionPeriodsFilter: [],
  affectations: [],
  affectationsFilter: [],
  controlledStateFilter: '',
  tags: [],
};

const buildingsReducer = createReducer(
  initialState,
  on(
    findBuildings,
    (state): BuildingsState => ({
      ...state,
      buildings: initialState.buildings,
      buildingsCount: initialState.buildingsCount,
      buildingsPage: initialState.buildingsPage,
    })
  ),
  on(
    findBuildingsSuccess,
    (state, action): BuildingsState => ({
      ...state,
      buildings: markBuildingsIfSelected(action.buildings, state.selectedBuildings),
      buildingsCount: action.count,
      buildingsPage: 0,
    })
  ),
  on(
    findMoreBuildingsSuccess,
    (state, action): BuildingsState => ({
      ...state,
      buildings: state.buildings.concat(markBuildingsIfSelected(action.buildings, state.selectedBuildings)),
      buildingsCount: action.count,
      buildingsPage: action.page > state.buildingsPage ? action.page : state.buildingsPage,
    })
  ),
  on(clearBuildingsStore, (): BuildingsState => ({ ...initialState })),
  on(findTypologyAffectationsSuccess, (state, action): BuildingsState => ({ ...state, affectations: action.affectations })),
  on(findMunicipalitiesSuccess, (state, action): BuildingsState => ({ ...state, municipalities: action.municipalities })),
  on(setMunicipalitiesFilter, (state, action): BuildingsState => ({ ...state, municipalitiesFilter: action.municipalitiesFilter })),
  on(setTypologiesFilter, (state, action): BuildingsState => ({ ...state, typologiesFilter: action.typologiesFilter })),
  on(findBuildingClassesSuccess, (state, action): BuildingsState => ({ ...state, buildingClasses: action.buildingClasses })),
  on(setBuildingClassesFilter, (state, action): BuildingsState => ({ ...state, buildingClassesFilter: action.buildingClassesFilter })),
  on(findConstructionPeriodsSuccess, (state, action): BuildingsState => ({ ...state, constructionPeriods: action.constructionPeriods })),
  on(
    setConstructionPeriodsFilter,
    (state, action): BuildingsState => ({ ...state, constructionPeriodsFilter: action.constructionPeriodFilter })
  ),
  on(setAffectationsFilter, (state, action): BuildingsState => ({ ...state, affectationsFilter: action.affectationFilter })),
  on(setControlledStateFilter, (state, action): BuildingsState => ({ ...state, controlledStateFilter: action.controlledStateFilter })),
  on(
    reinitializeAllFilters,
    (state): BuildingsState => ({
      ...state,
      affectationsFilter: [],
      constructionPeriodsFilter: [],
      municipalitiesFilter: [],
      typologiesFilter: [],
      buildingClassesFilter: [],
      controlledStateFilter: '',
    })
  ),
  on(addSelectedBuildingsSuccess, addAllBuildingsSuccess, (state, action): BuildingsState => {
    const selectedBuildingIds = action.buildings.map(building => building.id);
    const existingSelectedBuildingIds = state.selectedBuildings.map(building => building.id);
    const updatedBuildings = state.buildings.map(building => ({
      ...building,
      selected: selectedBuildingIds.includes(building.id) || existingSelectedBuildingIds.includes(building.id),
    }));
    const newSelectedBuildings = action.buildings.filter(building => !existingSelectedBuildingIds.includes(building.id));
    const updatedNewSelectedBuildings = newSelectedBuildings.map(building => ({
      ...building,
      selected: true,
    }));

    return {
      ...state,
      selectedBuildings: state.selectedBuildings.concat(updatedNewSelectedBuildings),
      buildings: updatedBuildings,
    };
  }),
  on(
    replaceBuildingsSelectionSuccess,
    (state, action): BuildingsState => ({
      ...state,
      buildings: markBuildingsIfSelected(state.buildings, action.buildings),
      selectedBuildings: action.buildings,
    })
  ),
  on(
    removeSelectedBuildings,
    (state, action): BuildingsState => ({
      ...state,
      buildings: state.buildings.map(building => ({
        ...building,
        selected: (action.buildingIds.indexOf(building.id) > -1) ? false : building.selected,
      })),
      selectedBuildings: state.selectedBuildings.filter(building => action.buildingIds.indexOf(building.id) < 0),
    })
  ),
  on(removeSelectedBuilding, (state, action): BuildingsState => {
    const selectedBuildingIndex = state.buildings.findIndex(building => building.id === action.buildingId);
    if (selectedBuildingIndex > -1) {
      const newBuildingsStart = state.buildings.slice(0, selectedBuildingIndex);
      const newBuildingsEnd = state.buildings.slice(selectedBuildingIndex + 1, state.buildings.length);
      const currBuilding = state.buildings.find(building => building.id === action.buildingId);
      const clone = {
        ...(currBuilding as BuildingSummary),
        selected: false,
      };
      const newBuildings = newBuildingsStart.concat(clone).concat(newBuildingsEnd);
      return {
        ...state,
        selectedBuildings: state.selectedBuildings.filter((building: BuildingSummary) => building.id !== action.buildingId),
        buildings: newBuildings,
      };
    } else {
      return {
        ...state,
        selectedBuildings: state.selectedBuildings.filter((building: BuildingSummary) => building.id !== action.buildingId),
      };
    }
  }),
  on(
    removeAllSelectedBuildings,
    (state): BuildingsState => ({
      ...state,
      buildings: state.buildings.map(building => ({
        ...building,
        selected: false,
      })),
      selectedBuildings: [],
    })
  ),
  on(
    refreshSelectedBuildingsSuccess,
    (state, action): BuildingsState => ({
      ...state,
      selectedBuildings: action.buildings,
    })
  ),
  on(
    getTagsSuccess,
    (state, action): BuildingsState => ({
      ...state,
      tags: action.tags,
    })
  ),
  on(deleteTagSuccess, (state, action): BuildingsState => {
    const updatedBuildings = state.buildings.map(building => ({
      ...building,
      tags: building.tags?.filter(tagItem => tagItem !== action.tag.name),
    }));
    const updatedSelectedBuildings = state.selectedBuildings.map(building => ({
      ...building,
      tags: building.tags?.filter(tagItem => tagItem !== action.tag.name),
    }));
    return {
      ...state,
      tags: state.tags.filter(tagItem => tagItem.name !== action.tag.name),
      buildings: updatedBuildings,
      selectedBuildings: updatedSelectedBuildings,
    };
  }),
  on(updateNextControlDatesSuccess, (state, action): BuildingsState => {
    const updatedBuildings = state.buildings.map(building =>
      action.buildingIds.includes(building.id)
        ? {
          ...building,
          nextControlDate: action.date,
        } as BuildingSummary
        : building
    ) ;
    const updatedSelectedBuildings = state.selectedBuildings
      .map(building => {
        if(action.buildingIds.includes(building.id)) {
          return ({
            ...building,
            nextControlDate: action.date,
          }) as BuildingSummary;
        } else {
          return building;
        }
  });
    return {
      ...state,
      buildings: updatedBuildings,
      selectedBuildings: updatedSelectedBuildings,
    };
  }),
  on(addTagSuccess, (state, action): BuildingsState => {
    const updatedBuildings = state.buildings.map(building =>
      action.buildingIds.includes(building.id)
        ? {
            ...building,
            tags: Array.from(new Set(building.tags?.concat(action.tagName))),
          }
        : building
    );
    const updatedSelectedBuildings = state.selectedBuildings
      .filter(building => action.buildingIds.includes(building.id))
      .map(building => ({
        ...building,
        tags: Array.from(new Set(building.tags?.concat(action.tagName))),
      }));
    const existingTag = state.tags.find(tag => tag.name === action.tagName);
    const newTag = existingTag
      ? ({
          ...existingTag,
          buildingIds: Array.from(new Set(existingTag.buildingIds?.concat(action.buildingIds))),
        } as Tag)
      : { name: action.tagName, buildingIds: action.buildingIds };
    return {
      ...state,
      tags: existingTag === undefined ? state.tags.concat(newTag) : state.tags,
      buildings: updatedBuildings,
      selectedBuildings: updatedSelectedBuildings,
    };
  }),
  on(removeTagFromBuildingSuccess, (state, action): BuildingsState => {
    const updatedBuildings = state.buildings.map(building =>
      building.id === action.buildingId
        ? {
            ...building,
            tags: building.tags?.filter(tag => tag !== action.tagName),
          }
        : building
    );
    const updatedSelectedBuildings = state.selectedBuildings.map(building =>
      building.id === action.buildingId
        ? {
            ...building,
            tags: building.tags?.filter(tag => tag !== action.tagName),
          }
        : building
    );
    return {
      ...state,
      buildings: updatedBuildings,
      selectedBuildings: updatedSelectedBuildings,
    };
  }),
  on(
    reloadLocalizedDataSuccess,
    (state, action): BuildingsState => ({
      ...state,
      municipalities: action.municipalities,
      affectations: action.affectations,
      buildingClasses: action.buildingClasses,
      constructionPeriods: action.constructionPeriods,
    })
  ),
  on(
    updateTypologiesSuccess,
    (state, action): BuildingsState => ({
      ...state,
      selectedBuildings: state.selectedBuildings.map(building => {
        const updated = action.buildingIds.some(buildingId => buildingId === building.id);
        return updated ? updateWithTypology(building, action.typology, state.affectations) : building;
      }),
      buildings: state.buildings.map(building => {
        const updated = action.buildingIds.some(buildingId => buildingId === building.id);
        return updated ? updateWithTypology(building, action.typology, state.affectations) : building;
      }),
    })
  ),
  on(
    updateNextControlDatesSuccess,
    (state, action): BuildingsState => ({
      ...state,
      selectedBuildings: state.selectedBuildings.map(building => {
        const updated = action.buildingIds.some(buildingId => buildingId === building.id);
        return updated ? updateWithNextControlDate(building, action.date) : building;
      }),
    })
  ),
  on(
    validateControlSuccess,
    (state, action): BuildingsState => ({
      ...state,
      selectedBuildings: state.selectedBuildings.map(building => {
        const updated = building.id === action.buildingId;
        return updated ? updateWitControlDates(
          building,
          action.historyWithControl.validatedControl.date,
          action.historyWithControl.validatedControl.nextDate
        ) : building;
      }),
    })
  )
);

export const reducer = (state: BuildingsState | undefined, action: Action): BuildingsState => buildingsReducer(state, action);

export const markBuildingsIfSelected = (buildings: BuildingSummary[], selectedBuildings: BuildingSummary[]): BuildingSummary[] => {
  const selectedBuildingIds = selectedBuildings.map(building => building.id);
  return buildings.map(building => ({
    ...building,
    selected: selectedBuildingIds.includes(building.id),
  }));
};

export const updateWithTypology = (building: BuildingSummary, typology: Typology, affectations: Affectation[]): BuildingSummary => ({
  ...building,
  currentColor: typology.color,
  localizedAffectations: typology.affectationKeys.map(key => {
    const affectationFound = affectations.find(affectation => affectation.key === key);
    return affectationFound?.title ?? key;
  }),
});

export const updateWithNextControlDate = (building: BuildingSummary, date: string | null): BuildingSummary => ({
  ...building,
  nextControlDate: date !== null ? date : undefined,
});

export const updateWitControlDates = (
  building: BuildingSummary,
  lastControlDate: string | null,
  nextControlDate: string | null
): BuildingSummary => ({
  ...building,
  lastControlDate: lastControlDate !== null ? lastControlDate : undefined,
  nextControlDate: nextControlDate !== null ? nextControlDate : undefined,
});
