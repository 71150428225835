<div mat-dialog-title class="title-container">
  <h2>{{ 'building.control.title' | transloco }}</h2>
</div>

<mat-dialog-content fxLayout="column">
  <div *ngIf="addresses$ | async as addresses" class="building-address">
    <p *ngIf="addresses.length > 0; else noAddress">{{ addresses[0] }}</p>
    <ng-template #noAddress><p>{{ 'building.addressNotAvailable' | transloco }}</p></ng-template>
  </div>
  <div class="content">
    <h3>{{ 'building.control.defectsCompliance.title' | transloco }}</h3>

    <ng-container *ngIf="defectsInEdition$ | async as selection">
      <sibat-control-defect-compliance-form
        *ngIf="selection.length > 0"
        [controlId]="controlId"
        [defects]="selection"
        [thumbnails]="thumbnails"
      ></sibat-control-defect-compliance-form>
    </ng-container>

    <div class="list">
      <sibat-control-defect-item
        data-testid="control-list-defect-item"
        *ngFor="let defect of defects$ | async; trackBy: trackById"
        [defect]="defect"
        [thumbnails]="thumbnails"
        [controlId]="controlId"
        class="list-item"
      >
      </sibat-control-defect-item>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <sibat-secondary-button
    data-testid="control-defect-list-close-button"
    class="mat-button-base"
    (click)="close()"
    [label]="'layout.closeDialog' | transloco">
  </sibat-secondary-button>
</mat-dialog-actions>
