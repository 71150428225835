import {
  AssessmentDto,
  EditProtectiveMeasure,
  MeasureType,
  RefAffectation,
  RefAwning,
  RefBaseground,
  AssessmentPicture,
  AddPicture,
  EditPicture,
  FileWithPreviewBase64,
  AssessmentThumbnail
} from '../model/assessment.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BUILDINGS_BASE_URI } from '../../building/service/building.service';
import { environment } from '../../../environments/environment';
import { EditHistory } from '../../building/model/history.model';

export const ASSESSMENTS_BASE_URI = `${environment.apiUrl}/assessments`;
export const ASSESSMENT_STATS_URI = `${environment.apiUrl}/stats/assessments`;

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  constructor(private readonly http: HttpClient) {}

  getAssessmentApiBasePath = (buildingId?: number): string => {
    if (buildingId) {
      return `${BUILDINGS_BASE_URI}/${buildingId}/assessment`;
    } else {
      return ASSESSMENTS_BASE_URI;
    }
  };

  startAssessment(buildingId?: number): Observable<AssessmentDto> {
    return this.http.post<AssessmentDto>(this.getAssessmentApiBasePath(buildingId), {});
  }

  refreshOngoingAssessment(buildingId: number): Observable<AssessmentDto | undefined> {
    return this.http.get<AssessmentDto | undefined>(`${BUILDINGS_BASE_URI}/${buildingId}/assessment`);
  }

  cancelOngoingAssessment(assessmentId: number): Observable<void> {
    return this.http.delete<void>(`${ASSESSMENTS_BASE_URI}/${assessmentId}`, {});
  }

  updateAssessmentDetails(assessmentId: number, assessmentDetails: AssessmentDto): Observable<AssessmentDto> {
    return this.http.put<AssessmentDto>(`${ASSESSMENTS_BASE_URI}/${assessmentId}`, assessmentDetails);
  }

  getAssessment(assessmentId: number): Observable<AssessmentDto> {
    return this.http.get<AssessmentDto>(`${ASSESSMENTS_BASE_URI}/${assessmentId}`);
  }

  getRefAwningOptions(): Observable<RefAwning[]> {
    return this.http.get<RefAwning[]>(`${ASSESSMENTS_BASE_URI}/refAwning`);
  }

  getRefBasegroundOptions(): Observable<RefBaseground[]> {
    return this.http.get<RefBaseground[]>(`${ASSESSMENTS_BASE_URI}/refBaseground`);
  }

  getRefAffectationOptions(): Observable<RefAffectation[]> {
    return this.http.get<RefAffectation[]>(`${ASSESSMENTS_BASE_URI}/refAffectation`);
  }

  getMeasureTypes(): Observable<MeasureType[]> {
    return this.http.get<MeasureType[]>(`${ASSESSMENTS_BASE_URI}/measureTypes`);
  }

  addProtectiveMeasure(assessmentId: number, protectiveMeasure: EditProtectiveMeasure): Observable<AssessmentDto> {
    return this.http.post<AssessmentDto>(
      `${ASSESSMENTS_BASE_URI}/${assessmentId}/protectiveMeasures`,
      protectiveMeasure
    );
  }

  updateProtectiveMeasure(assessmentId: number, protectiveMeasure: EditProtectiveMeasure): Observable<AssessmentDto> {
    return this.http.put<AssessmentDto>(
      `${ASSESSMENTS_BASE_URI}/${assessmentId}/protectiveMeasures/${protectiveMeasure.id}`,
      protectiveMeasure
    );
  }

  removeProtectiveMeasure(assessmentId: number, measureId: number): Observable<AssessmentDto> {
    return this.http.delete<AssessmentDto>(
      `${ASSESSMENTS_BASE_URI}/${assessmentId}/protectiveMeasures/${measureId}`
    );
  }

  // VALIDATION

  requestSecretaryApproval(buildingId: number): Observable<AssessmentDto> {
    return this.http.post<AssessmentDto>(`${BUILDINGS_BASE_URI}/${buildingId}/assessment/request-approval`, {});
  }

  cancelRequestSecretaryApproval(buildingId: number): Observable<AssessmentDto> {
    return this.http.delete<AssessmentDto>(`${BUILDINGS_BASE_URI}/${buildingId}/assessment/request-approval`, {});
  }

  approveAssessment(buildingId: number): Observable<AssessmentDto> {
    return this.http.post<AssessmentDto>(`${BUILDINGS_BASE_URI}/${buildingId}/assessment/approve`, {});
  }


  //PICTURES

  // fetchAssessmentsThumbnails(assessmentId: number): Observable<AssessmentThumbnail[]> {
  //   return this.http.get<AssessmentThumbnail[]>(`${ASSESSMENTS_BASE_URI}/${assessmentId}/pictures/thumbnails`);
  // }
  //
// Angular Service
  getAssessmentPicture(assessmentId: number, pictureName: string, subject: string): Observable<AssessmentPicture> {
    const adjustedPictureName = pictureName.replace('thumb-', '');
    return this.http.post<AssessmentPicture>(`${ASSESSMENTS_BASE_URI}/${assessmentId}/${subject}/picture`, adjustedPictureName);
  }

  removePreviewDataFromPictures(editPictures: EditPicture | EditPicture[] | null | undefined): EditPicture[] {
    if (!Array.isArray(editPictures)) {
      if (editPictures !== null && editPictures !== undefined) {
        editPictures = [editPictures];
      } else {
        return [];
      }
    }

    return editPictures.map(picture => ({
      ...picture,
      picturesToAdd: picture.picturesToAdd.map(file => ({
        data: file.data,
        fileName: file.fileName,
        preview: undefined
      })),
      existingPictures: picture.existingPictures,
      picturesToRemove: picture.picturesToRemove
    }));
  }

  updateAssessmentWithPictures(assessmentId: number, assessmentDetails: AssessmentDto): Observable<AssessmentDto> {
    const stairwellPrepared = this.removePreviewDataFromPictures(assessmentDetails.stairwellPictures);
    const facadePrepared = this.removePreviewDataFromPictures(assessmentDetails.facadePictures);
    const buildingSituationPrepared = this.removePreviewDataFromPictures(assessmentDetails.buildingSituationPictures);

    const updatedDetails: AssessmentDto = {
      ...assessmentDetails,
      stairwellPictures: stairwellPrepared,
      facadePictures: facadePrepared,
      buildingSituationPictures: buildingSituationPrepared,
    };

    return this.updateAssessmentDetails(assessmentId, updatedDetails);
  }

}

