export const extendTextArea = element => {
  element.style.height = '1px';
  element.style.height = element.scrollHeight + 16 + 'px';
};

export const adjustFormFieldWidth = matFormFieldElement => {
  const label = matFormFieldElement.querySelector('mat-label');

  if (label) {
    const span = document.createElement('span');
    span.style.position = 'absolute';
    span.style.height = 'auto';
    span.style.width = 'auto';
    span.style.whiteSpace = 'nowrap';
    span.textContent = label.textContent;

    document.body.appendChild(span);
    const textWidth = span.offsetWidth;
    document.body.removeChild(span);

    const minWidth = 215;
    const totalWidth = Math.max(textWidth + 70, minWidth);

    matFormFieldElement.style.width = `${totalWidth}px`;
  }
};
