import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { EditProtectiveMeasure } from '../../model/assessment.model';

@Component({
  selector: 'sibat-add-protective-measure',
  template: `
    <div *transloco="let t; read: 'building.assessment.protectiveMeasuresForm'"
         [class.read-only]="readOnly"
         data-testid="sibat-add-measure">
      <h4>
        {{ t('title') }}
      </h4>
      <sibat-dark-button
        *ngIf="!addingMeasure"
        (clicked)="addingMeasure = true"
        [label]="t('addMeasure')"
        [disabled]="readOnly"
        icon="add"
        class="add-measure-button"
        data-testid="add-measure"
      >
      </sibat-dark-button>
      <div class="add-new-measure" *ngIf="addingMeasure">
        <h4>
          {{ t('addMeasure') }}
          <sibat-dark-button (clicked)="addingMeasure = false" [label]="t('cancel')" class="cancel"> </sibat-dark-button>
        </h4>
        <sibat-edit-measure (measureSaved)="addMeasure($event)"></sibat-edit-measure>
      </div>
    </div>
  `,
  animations: [trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate(400)])])],
  styleUrls: ['add-protective-measure.component.scss']
})
export class AddProtectiveMeasureComponent implements OnInit {

  @Input() readOnly: boolean;
  @Output() measureAdded = new EventEmitter<EditProtectiveMeasure>();
  addingMeasure = false;

  constructor() { }

  ngOnInit(): void {
  }

  addMeasure(newMeasure: EditProtectiveMeasure) {
    this.measureAdded.emit(newMeasure);
    this.addingMeasure = false;
  }

}
