import { Action, createReducer, on } from '@ngrx/store';
import { forceOffline, goOffline, goOnline, releaseForcedOffline, releaseForcedOfflineAndUploadDefects } from './network.action';

export default interface NetworkState {
  online: boolean;
  forceOffline: boolean;
}

export const initialState: NetworkState = {
  online: true,
  forceOffline: false,
};

const networkReducer = createReducer(
  initialState,
  on(goOnline, (state): NetworkState => ({ ...state, online: true })),
  on(goOffline, (state): NetworkState => ({ ...state, online: false })),
  on(forceOffline, (state): NetworkState => ({ ...state, forceOffline: true })),
  on(
    releaseForcedOffline,
    releaseForcedOfflineAndUploadDefects,
    (state): NetworkState => ({
      ...state,
      forceOffline: false,
    })
  )
);

export const reducer = (state: NetworkState | undefined, action: Action): NetworkState => networkReducer(state, action);
