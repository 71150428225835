import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Store } from '@ngrx/store';
import {
  addDefect,
  approveControl,
  cancelOngoingControl,
  cancelRequestExpertApproval,
  clearCurrentControl,
  fetchThumbnails,
  previewControlReport,
  refreshOfflineDefectsInStore,
  requestExpertApproval,
  updateControlDetails,
  updateDefect,
  validateControl,
} from '../store/control.action';
import {
  approvalStatus,
  canControlBeValidated,
  Control,
  ControlDetails,
  DefectDto,
  EditDefect,
  isControlEmpty,
} from '../model/control.model';
import { Observable, Subscription } from 'rxjs';
import { selectHasOfflineDefects, selectThumbnails } from '../store/control.selector';
import { ALERT_TYPE } from 'src/app/alert/alert.model';
import { StatusAlert } from './status-alerts/status-alert.model';
import { APPROVAL_STATE } from '../model/approval-state.model';
import { forceOffline, releaseForcedOfflineAndUploadDefects } from '../../network/store/network.action';
import { selectIsForcedOffline } from '../../network/store/network.selector';
import { selectIsUploadInProgress } from 'src/app/loading/store/loading.selector';
import { Building, nextControlInterval } from '../../building/model/building.model';
import { COLOR_TYPOLOGY } from '../../building/model/typology.model';
import { DialogService } from 'src/app/shared/service/dialog.service';

@Component({
  selector: 'sibat-control-report',
  template: `
    <div *transloco="let t; read: 'building.control'" fxLayout="column">
      <div *ngIf="control" fxLayout="column">
        <sibat-toggle-button
          (toggle)="setOfflineMode($event)"
          [checked]="forcedOffline$ | async"
          [label]="t((forcedOffline$ | async) ? 'forcedOfflineModeEnabled' : 'forcedOfflineModeDisabled')"
        >
        </sibat-toggle-button>
        <div class="spaced-info">
          <p *ngIf="control.designation" class="address">{{ control.designation }}&nbsp;</p>
          <p *ngIf="building" class="address">{{ building?.addresses[0] }}</p>
          <sibat-status-alerts [alerts]="statusAlerts"></sibat-status-alerts>
        </div>
        <mat-horizontal-stepper>
          <mat-step label="{{ t('steps.control') }}" state="enterDetails">
            <div data-testid="step-information-section">
              <sibat-edit-control-details
                [control]="control"
                [controlInterval]="controlInterval"
                [readOnly]="readOnly"
                [offline]="offline"
                (unsavedDetails)="hasUnsavedDetails($event)"
                (controlDetailsSaved)="saveControlDetails($event)"
              ></sibat-edit-control-details>
            </div>
          </mat-step>
          <mat-step label="{{ t('steps.defects') }}" state="enterDefects">
            <div data-testid="step-control-section">
              <sibat-add-defect [readOnly]="readOnly" [thumbails]="thumbnails" (defectAdded)="addDefect($event)"></sibat-add-defect>
              <sibat-defects-list
                [buildingId]="building?.id"
                [control]="control"
                [readOnly]="readOnly"
                [offline]="offline"
                [thumbnails]="thumbnails"
                (defectSaved)="updateDefect($event)"
              ></sibat-defects-list>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
      <div class="container">
        <sibat-secondary-button
          (clicked)="previewReport()"
          [disabled]="offline || unsavedDetails"
          [label]="t('previewControlReport')"
          icon="open_in_new"
          [stretch]="true"
          data-testid="generate-report"
        >
        </sibat-secondary-button>
        <sibat-primary-button
          *ngIf="!redBuilding"
          (clicked)="validateControl()"
          [label]="t('validateControl')"
          [disabled]="offline || !ongoingControlCanBeValidated || unsavedDetails || !building"
          [stretch]="true"
          data-testid="validate-control"
          class="stretch-button"
        >
        </sibat-primary-button>
        <sibat-control-approval-button
          *ngIf="redBuilding"
          data-testid="approval-control"
          class="stretch-button"
          [isDisabled]="offline || !ongoingControlCanBeValidated || unsavedDetails"
          [isSCPI]="isSCPI"
          [control]="control"
          (validateControl)="validateControl()"
          (approveControl)="approveControl()"
          (requestApproval)="requestApproval()"
          (cancelRequestApproval)="cancelRequestApproval()"
        ></sibat-control-approval-button>
      </div>
      <div class="container">
        <sibat-secondary-button
          (clicked)="requestOngoingControlCancellation()"
          [label]="t('cancelOngoingControl')"
          [disabled]="offline || (isControlApproved && redBuilding)"
          [stretch]="true"
          data-testid="cancel-control"
        >
        </sibat-secondary-button>
      </div>
    </div>
  `,
  styleUrls: ['control-report.component.scss'],
  animations: [
    trigger('fadeSlide', [
      state('void', style({ opacity: 0, transform: 'translateX(50px)' })),
      transition('void => *', [animate(400)]),
      transition('* => void', [animate(400)]),
    ]),
    trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate(400)])]),
    trigger('progress', [
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateX(-200px)',
        }),
        animate(400),
      ]),
    ]),
  ],
})
export class ControlReportComponent implements OnInit, OnChanges, OnDestroy {
  @Output() closeModal = new EventEmitter<void>();
  @Input() control: Control;
  @Input() building?: Building;
  @Input() isSCPI: boolean;
  @Input() offline: boolean;
  buildingId: number;
  controlInterval?: number;
  forcedOffline$: Observable<boolean>;
  ongoingControlCanBeValidated = false;
  unsavedDetails = false;
  redBuilding = false;
  isControlApproved: boolean;
  readOnly: boolean;
  subscriptions = new Subscription();
  thumbnails: Record<string, string> = {};
  alertTypes = ALERT_TYPE;
  statusAlerts: StatusAlert[] = [];
  hasOfflineDefects = false;
  isUploadInProgress = false;

  constructor(private store: Store, private dialogService: DialogService) {}

  static isReadOnly(isSCPI: boolean, control: Control): boolean {
    if (!isSCPI) {
      return false;
    }
    return !!control.approvalRequestDate;
  }

  private static isNumber(val: unknown): val is number {
    return typeof val === 'number';
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasOfflineDefects || this.isUploadInProgress) {
      $event.returnValue = true;
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(selectThumbnails).subscribe(thumbnails => {
        if (!!thumbnails) {
          this.thumbnails = thumbnails;
        }
      })
    );
    this.forcedOffline$ = this.store.select(selectIsForcedOffline);
    this.subscriptions.add(
      this.store.select(selectHasOfflineDefects).subscribe(offlineDefects => (this.hasOfflineDefects = offlineDefects))
    );
    this.subscriptions.add(this.store.select(selectIsUploadInProgress).subscribe(isInProgress => (this.isUploadInProgress = isInProgress)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offline) {
      this.updateStatusAlerts();
    }
    this.store.dispatch(refreshOfflineDefectsInStore());
    if (changes.building) {
      this.redBuilding = this.building?.typology?.color === COLOR_TYPOLOGY.red;
      this.controlInterval = nextControlInterval(this.building?.typology);
      this.buildingId = this.building?.id ? this.building.id : 0; // TODO handle case with no building
    }
    if (changes.control) {
      this.ongoingControlCanBeValidated = canControlBeValidated(this.control, this.building?.typology?.color !== COLOR_TYPOLOGY.white);
      this.isControlApproved = !!this.control.approvalDate;
      this.readOnly = ControlReportComponent.isReadOnly(this.isSCPI, this.control);
      this.updateStatusAlerts();
      const control = changes.control.currentValue;
      if (control && this.thumbnailsHasChanged(control.defects, this.thumbnails)) {
        const nbOfPictures = control.defects?.reduce((acc, defect) => acc + defect.pictures.length, 0);
        if (nbOfPictures) {
          const controlId = control.id;
          if (controlId) {
            this.store.dispatch(fetchThumbnails({ controlId }));
          }
        } else {
          this.thumbnails = {};
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store.dispatch(clearCurrentControl());
  }

  thumbnailsHasChanged(defects: DefectDto[], thumbnails: Record<string, string>): boolean {
    const defectsPictureFileNames = defectList => defectList.reduce((acc, defect) => [...acc, ...defect.pictures], []);
    return defects.length !== 0 && !defectsPictureFileNames(defects).every(thumbnail => Object.keys(thumbnails).includes(thumbnail));
  }

  addDefect(defect: EditDefect) {
    this.store.dispatch(
      addDefect({
        controlId: this.control.id,
        defect,
      })
    );
  }

  updateDefect(editDefect: EditDefect) {
    this.store.dispatch(updateDefect({ controlId: this.control.id, editDefect }));
  }

  hasUnsavedDetails(unsaved: boolean) {
    setTimeout(() => (this.unsavedDetails = unsaved));
  }

  saveControlDetails(control: ControlDetails) {
    this.store.dispatch(
      updateControlDetails({
        controlId: this.control.id,
        control,
      })
    );
  }

  async validateControl(): Promise<void> {
    const result = await this.dialogService.requestConfirmation({ translationKey: 'building.control.sureToValidate' });
    if (result) {
      this.store.dispatch(
        validateControl({
          buildingId: this.buildingId,
        })
      );
      this.close();
    }
  }

  previewReport(): void {
    if (ControlReportComponent.isNumber(this.control.id)) {
      this.store.dispatch(
        previewControlReport({
          source: this.control.id,
        })
      );
    }
  }

  requestApproval() {
    if (ControlReportComponent.isNumber(this.control.id)) {
      this.store.dispatch(requestExpertApproval({ buildingId: this.buildingId }));
    }
  }

  cancelRequestApproval() {
    if (ControlReportComponent.isNumber(this.control.id)) {
      this.store.dispatch(cancelRequestExpertApproval({ buildingId: this.buildingId }));
    }
  }

  approveControl() {
    if (ControlReportComponent.isNumber(this.control.id)) {
      this.store.dispatch(approveControl({ buildingId: this.buildingId }));
    }
  }

  setOfflineMode(enabled: boolean) {
    this.store.dispatch(enabled ? forceOffline() : releaseForcedOfflineAndUploadDefects({ controlId: this.control.id }));
  }

  close() {
    this.closeModal.emit();
  }

  async requestOngoingControlCancellation(): Promise<void> {
    if (isControlEmpty(this.control)) {
      this.cancelOngoingControl();
    } else {
      const result = await this.dialogService.requestConfirmation({ translationKey: 'building.control.sureToCancel' });
      if (result) {
        this.cancelOngoingControl();
      }
    }
  }

  private cancelOngoingControl() {
    this.store.dispatch(cancelOngoingControl({ controlId: this.control.id }));
    this.close();
  }

  private updateStatusAlerts() {
    const alerts: StatusAlert[] = [];

    if (this.offline) {
      this.statusAlerts = [{ type: ALERT_TYPE.warn, messageKey: 'building.control.statusHint.offlineActivated' }];
      return;
    }

    const approvalState = approvalStatus(this.control, this.isSCPI);
    const needsExpertApproval = this.building?.typology?.color === COLOR_TYPOLOGY.red && this.isSCPI && !this.ongoingControlCanBeValidated;

    const userShouldFillInFields =
      this.building &&
      !this.ongoingControlCanBeValidated &&
      ((this.building.typology?.color !== COLOR_TYPOLOGY.red && this.isSCPI) || !this.isSCPI);

    const userShouldLinkControlToBuilding = !this.building;

    if (needsExpertApproval && approvalState === APPROVAL_STATE.scpiRequireExpertApproval) {
      alerts.push({
        type: ALERT_TYPE.warn,
        messageKey: 'building.control.statusHint.specialistMustFillInRequiredFields',
      });
    }

    if (approvalState === APPROVAL_STATE.scpiAwaitingExpertApproval) {
      alerts.push({ type: ALERT_TYPE.locked, messageKey: 'building.control.waitingForApproval' });
    }

    if (approvalState === APPROVAL_STATE.expertHasApprovedControl) {
      alerts.push({ type: ALERT_TYPE.locked, messageKey: 'building.control.controlApproved' });
    }

    if (userShouldFillInFields) {
      alerts.push({ type: ALERT_TYPE.warn, messageKey: 'building.control.statusHint.userMustFillInRequiredFields' });
    }

    if (userShouldLinkControlToBuilding) {
      alerts.push({ type: ALERT_TYPE.locked, messageKey: 'building.control.statusHint.userShouldLinkControlToBuilding' });
    }

    this.statusAlerts = alerts;
  }
}
