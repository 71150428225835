import { createSelector } from '@ngrx/store';
import BuildingsState from './buildings.reducer';
import { getBuildingsState } from './buildings.selector';

export const selectTags = createSelector(getBuildingsState, (state: BuildingsState) => state.tags);

export const selectTagNames = createSelector(getBuildingsState, (state: BuildingsState) => state.tags.map(tag => tag.name));

export const selectTagByName = (tagName: string) =>
  createSelector(getBuildingsState, (state: BuildingsState) => state.tags.find(tag => tag.name === tagName));
