import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AlertModule } from '../alert/alert.module';
import { AppRoutingModule } from '../app-routing.module';
import { AssessmentApprovalButtonComponent } from './assessment-report/assessment-approval-button/assessment-approval-button.component';
import { ArrayPipeModule } from '../shared/array.module';
import { CustomDateAdapter } from '../shared/date-adapter';
import { ModalModule } from '../shared/modal/ModalModule';
import { SharedModule } from '../shared/shared.module';
import { StringPipeModule } from '../shared/string.module';
import { AssessmentReportComponent } from './assessment-report/assessment-report.component';
import { EditAssessmentDetailsComponent } from './edit-assessment-details/edit-assessment-details.component';
import { EditProtectiveMeasuresComponent } from './edit-protective-measures/edit-protective-measures.component';
import { AddProtectiveMeasureComponent } from './edit-protective-measures/add-protective-measure/add-protective-measure.component';
import { EditMeasureComponent } from './edit-protective-measures/edit-measure/edit-measure.component';
import { EditBuildingDetailsComponent } from './edit-building-details/edit-building-details.component';
import { ProtectiveMeasuresListComponent } from './edit-protective-measures/protective-measures-list/protective-measures-list.component';
import { ProtectiveMeasureItemComponent } from './edit-protective-measures/protective-measure-item/protective-measure-item.component';
import { AssessmentStatusAlertsComponent } from './assessment-report/assessment-status-alerts/assessment-status-alerts.component';


@NgModule({
  declarations: [
    AssessmentReportComponent,
    AssessmentApprovalButtonComponent,
    EditAssessmentDetailsComponent,
    EditBuildingDetailsComponent,
    EditProtectiveMeasuresComponent,
    AddProtectiveMeasureComponent,
    EditMeasureComponent,
    ProtectiveMeasuresListComponent,
    ProtectiveMeasureItemComponent,
    AssessmentStatusAlertsComponent
  ],
    imports: [
        AppRoutingModule,
        ArrayPipeModule,
        FlexModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        StringPipeModule,
        NgxMatSelectSearchModule,
        AlertModule,
        DragDropModule,
        ModalModule,
        SharedModule,
    ],
  exports: [
    AssessmentReportComponent,
  ],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class AssessmentModule {}
