/* eslint-disable @typescript-eslint/naming-convention */

export interface MapExtent {
  x: number;
  y: number;
  zoom: number;
}

export enum GenericAttributs {
  ObjectID = 'OBJECTID',
  All = '*',
}

export enum BuildingsLayerAttrs {
  ObjectID = 'objectid',
  BuildingId = 'id_bat_prev',
  EGID = 'regbl_egid',
  Affectations = 'aff_pi_txt',
  GKLAS = 'gklas',
  FOSNR = 'fosnr',
  Color = 'couleur',
  GBAUP = 'gbaup',
  Type = 'type',
  FosName = 'fosname',
  FidBat = 'fid_batiment',
  LayerContext1 = 'layer_context_1'
}

export enum CasesLayerAttrs {
  UrlSharepoint = 'url_sharepoint',
  NoAffaireFriac = 'no_affaire_friac',
  TypeAffaire = 'type_affaire',
  StatutAffaire = 'statut_affaire',
}

export enum DossiersLayerAttrs {
  ID = 'id_dpi',
}

export enum FriacLayerAttrs {
  Affaire = 'Affaire',
  Camacid = 'CAMACID',
  Annule = 'Annule',
  NomPrenomRequerant = 'NomPrenomRequerant',
  Description = 'Description',
  Affectation1 = 'Affectation1',
  GenreConstruction = 'GenreConstruction',
  DestinationOuvrage = 'DestinationOuvrage',
  DateDebutEnquete = 'DateDebutEnquete',
  PreavisCommunal = 'PreavisCommunal',
  DateEnvoiAuSeCA = 'DateEnvoiAuSeCA',
  DateReceptionAuSeCA = 'DateReceptionAuSeCA',
  Procedure_ = 'Procedure_',
  DateEnvoiALaPrefecture = 'DateEnvoiALaPrefecture',
  DateReceptionALaPrefecture = 'DateReceptionALaPrefecture',
  DateDecision = 'DateDecision',
  Decision = 'Decision',
  DateDecisionFinale = 'DateDecisionFinale',
  Commune = 'Commune',
  NomRaisonSocialAuteurPlans = 'NomRaisonSocialAuteurPlans',
  NumeroArticle = 'NumeroArticle',
  NombreOppositions = 'NombreOppositions',
  DatePreavisCommunal = 'DatePreavisCommunal',
  LienFriac = 'LienFriac',
  DatePreavisSyntheseSeCA = 'DatePreavisSyntheseSeCA',
  Recours = 'Recours ',
}

export enum AddressesLayerAttrs {
  Adresse = 'ADRESSE',
}

export enum BordersLayerAttrs {
  FOSNR = 'FOSNR',
  Name = 'NAME',
}

export enum ParcelNumbersLayerAttrs {
  Label = 'LABEL_TEXT',
}
