import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { clearPasswordResetState, forgotPassword } from '../store/authentication.action';
import { Observable } from 'rxjs';
import { selectAuthenticationError, selectPasswordResetState } from '../store/authentication.selector';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'sibat-password-reinitialization',
  templateUrl: `./password-reinitialization.component.html`,
  styleUrls: ['../css/authentication.scss'],
})
export class PasswordReinitializationComponent implements OnInit {
  signInForm: UntypedFormGroup;
  error$: Observable<Error | undefined>;
  completed: Observable<boolean>;
  supportEmail = `mailto:${environment.supportEmail}`;
  readonly email = this.formBuilder.control('', [Validators.required, Validators.email]);

  constructor(private store: Store, private formBuilder: UntypedFormBuilder, private router: Router) {
    this.completed = this.store.select(selectPasswordResetState);
    this.signInForm = this.formBuilder.group({
      email: this.email,
    });
    this.error$ = this.store.select(selectAuthenticationError);
  }

  ngOnInit(): void {
    this.store.dispatch(clearPasswordResetState());
  }

  sendPasswordReset() {
    this.store.dispatch(
      forgotPassword({
        email: this.signInForm.value.email,
      })
    );
  }

  signin() {
    this.router.navigate(['/signin']);
  }
}
