import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import {
  AbstractAssessmentDetail,
  AssessmentDto,
  RefAwning,
  RefAffectation,
  RefBaseground,
  FileWithPreviewBase64,
  AssessmentThumbnail } from '../model/assessment.model';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { adjustFormFieldWidth } from 'src/app/shared/style';
import { AssessmentService } from '../service/assessment.service';
import {
  downloadFullScreenAssessmentPicture,
  loadReferenceOptions,
  setFullScreenAssessmentPicture
} from '../store/assessment.action';
import { selectRefAwningOptions, selectRefBasegroundOptions, selectRefAffectationOptions } from '../store/assessment.selector';
import { addUniqueIdPrefix, compressImage, extractPreview } from '../../shared/images';


@Component({
  selector: 'sibat-edit-building-details',
  templateUrl: 'edit-building-details.component.html',
  styleUrls: ['edit-building-details.component.scss'],
})
export class EditBuildingDetailsComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() assessment: AssessmentDto | undefined;
  @Input() readOnly: boolean;
  @Input() isReadOnly = false;
  @Input() offline: boolean;
  @Output() assessmentDetailsSaved = new EventEmitter<AssessmentDto>();
  @Output() unsavedDetails = new EventEmitter<boolean>();
  formSubscription: Subscription;

  readonly affectations = this.formBuilder.control(undefined, Validators.required);
  readonly flatNumber = this.formBuilder.control(undefined, Validators.required);
  readonly stairwellNumber = this.formBuilder.control(undefined, Validators.required);
  readonly abovegroundLevelNumber = this.formBuilder.control(undefined, Validators.required);
  readonly undergroundLevelNumber = this.formBuilder.control(undefined, Validators.required);
  readonly baseground = this.formBuilder.control(undefined, Validators.required);
  readonly facadesArea = this.formBuilder.control(undefined, Validators.required);
  readonly roofArea = this.formBuilder.control(undefined, Validators.required);
  readonly awningArea = this.formBuilder.control(undefined, Validators.required);
  readonly awningNumber = this.formBuilder.control(undefined, Validators.required);
  readonly awnings = this.formBuilder.control(undefined, Validators.required);
  readonly automation = this.formBuilder.control(null, Validators.required);
  readonly exteriorDoorNumber = this.formBuilder.control(undefined, Validators.required);
  readonly parkingEntranceNumber = this.formBuilder.control(undefined, Validators.required);
  readonly stepOverNumber = this.formBuilder.control(undefined, Validators.required);
  readonly stairsNumber = this.formBuilder.control(undefined, Validators.required);
  readonly pcShelterNumber = this.formBuilder.control(undefined, Validators.required);
  readonly domeNumber = this.formBuilder.control(undefined, Validators.required);
  readonly glassDomeNumber = this.formBuilder.control(undefined, Validators.required);
  readonly ventilationDuctNumber = this.formBuilder.control(undefined, Validators.required);
  readonly skylightNumber = this.formBuilder.control(undefined, Validators.required);
  readonly stairwellPictures = this.formBuilder.control({ picturesToAdd: [], existingPictures: [], picturesToRemove: [] });
  readonly facadePictures = this.formBuilder.control({ picturesToAdd: [], existingPictures: [], picturesToRemove: [] });
  readonly buildingSituationPictures = this.formBuilder.control({ picturesToAdd: [], existingPictures: [], picturesToRemove: [] });

  readonly buildingDetailsForm = this.formBuilder.group(
    {
      flatNumber: this.flatNumber,
      stairwellNumber: this.stairwellNumber,
      abovegroundLevelNumber: this.abovegroundLevelNumber,
      undergroundLevelNumber: this.undergroundLevelNumber,
      facadesArea: this.facadesArea,
      roofArea: this.roofArea,
      awningArea: this.awningArea,
      awningNumber: this.awningNumber,
      awnings: [],
      automation: this.automation,
      exteriorDoorNumber: this.exteriorDoorNumber,
      parkingEntranceNumber: this.parkingEntranceNumber,
      stepOverNumber: this.stepOverNumber,
      stairsNumber: this.stairsNumber,
      pcShelterNumber: this.pcShelterNumber,
      domeNumber: this.domeNumber,
      glassDomeNumber: this.glassDomeNumber,
      ventilationDuctNumber: this.ventilationDuctNumber,
      skylightNumber: this.skylightNumber,
      affectations: [],
      baseground: [],
      stairwellPictures: this.stairwellPictures,
      facadePictures: this.facadePictures,
      buildingSituationPictures: this.buildingSituationPictures,

    } as AbstractAssessmentDetail,
    {validators: Validators.required}
  );

  refAwningOptions$: Observable<RefAwning[]>;
  refBasegroundOptions$: Observable<RefBaseground[]>;
  refAffectationOptions$: Observable<RefAffectation[]>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private assessmentService: AssessmentService,
    private store: Store
  ) {
    this.formSubscription = this.buildingDetailsForm.statusChanges
      .pipe(
        map(() => this.buildingDetailsForm.pristine),
        distinctUntilChanged()
      )
      .subscribe(pristine => {
        this.unsavedDetails.emit(!pristine);
    });

    this.store.dispatch(loadReferenceOptions());
    this.refAwningOptions$ = this.store.select(selectRefAwningOptions);
    this.refBasegroundOptions$ = this.store.select(selectRefBasegroundOptions);
    this.refAffectationOptions$ = this.store.select(selectRefAffectationOptions);
  }

  ngAfterViewInit() {
    this.adjustMatFormFieldWidth();
  }

  onAwningUsageSelected(usage: RefAwning): void {
    const selectedAwning = this.buildingDetailsForm.get('awnings') as UntypedFormControl;
    const currentValue = selectedAwning.value || [];
    const newValue = currentValue.includes(usage.id)
      ? currentValue.filter((id: number) => id !== usage.id)
      : [...currentValue, usage.id];
    selectedAwning.setValue(newValue);
    selectedAwning.markAsDirty();
  }

  onBasegroundUsageSelected(usage: RefBaseground): void {
    const selectedBaseground = this.buildingDetailsForm.get('baseground') as UntypedFormControl;
    const currentValue = selectedBaseground.value || [];
    const newValue = currentValue.includes(usage.id)
      ? currentValue.filter((id: number) => id !== usage.id)
      : [...currentValue, usage.id];
    selectedBaseground.setValue(newValue);
    selectedBaseground.markAsDirty();
  }

  onAffectationUsageSelected(usage: RefAffectation): void {
    const selectedAffectations = this.buildingDetailsForm.get('affectations') as UntypedFormControl;
    const currentValue = selectedAffectations.value || [];
    const newValue = currentValue.includes(usage.id)
      ? currentValue.filter((id: number) => id !== usage.id)
      : [...currentValue, usage.id];
    selectedAffectations.setValue(newValue);
    selectedAffectations.markAsDirty();
  }

  isAwningUsageSelected(id: number): boolean {
    const selectedAwning = this.buildingDetailsForm.get('awnings') as UntypedFormControl;
    const selectedValues = selectedAwning.value || [];
    return selectedValues.includes(id);
  }

  isBasegroundUsageSelected(id: number): boolean {
    const selectedBaseground = this.buildingDetailsForm.get('baseground') as UntypedFormControl;
    const selectedValues = selectedBaseground.value || [];
    return selectedValues.includes(id);
  }

  isAffectationUsageSelected(id: number): boolean {
    const selectedAffectations = this.buildingDetailsForm.get('affectations') as UntypedFormControl;
    const selectedValues = selectedAffectations.value || [];
    return selectedValues.includes(id);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assessment) {
      this.setValuesForOngoingAssessment(this.assessment);
    }
    if (changes.offline) {
      if (changes.offline.currentValue) {
        this.buildingDetailsForm.disable();
      } else {
        this.buildingDetailsForm.enable();
      }
    }
  }

  adjustMatFormFieldWidth() {
    setTimeout(() => {
      const matFormFields = document.querySelectorAll('.mat-form-field.adjust-width');
      matFormFields.forEach(field => {
        adjustFormFieldWidth(field);
      });
    });
  }


  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
  }

  getValueOrDefault(value) {
    return value === undefined ? 0 : value;
  }

  setValuesForOngoingAssessment(assessment: AssessmentDto | undefined) {
    if (assessment) {
      this.buildingDetailsForm.patchValue({
        affectations: assessment.affectations,
        flatNumber: this.getValueOrDefault(assessment.flatNumber),
        stairwellNumber: this.getValueOrDefault(assessment.stairwellNumber),
        abovegroundLevelNumber: this.getValueOrDefault(assessment.abovegroundLevelNumber),
        undergroundLevelNumber: this.getValueOrDefault(assessment.undergroundLevelNumber),
        baseground: assessment.baseground,
        facadesArea: this.getValueOrDefault(assessment.facadesArea),
        roofArea: this.getValueOrDefault(assessment.roofArea),
        awningArea: this.getValueOrDefault(assessment.awningArea),
        awningNumber: this.getValueOrDefault(assessment.awningNumber),
        awnings: assessment.awnings,
        automation: !!assessment.automation,
        exteriorDoorNumber: this.getValueOrDefault(assessment.exteriorDoorNumber),
        parkingEntranceNumber: this.getValueOrDefault(assessment.parkingEntranceNumber),
        stepOverNumber: this.getValueOrDefault(assessment.stepOverNumber),
        stairsNumber: this.getValueOrDefault(assessment.stairsNumber),
        pcShelterNumber: this.getValueOrDefault(assessment.pcShelterNumber),
        domeNumber: this.getValueOrDefault(assessment.domeNumber),
        glassDomeNumber: this.getValueOrDefault(assessment.glassDomeNumber),
        ventilationDuctNumber: this.getValueOrDefault(assessment.ventilationDuctNumber),
        skylightNumber: this.getValueOrDefault(assessment.skylightNumber),
        stairwellPictures: assessment.stairwellPictures || { picturesToAdd: [], existingPictures: [], picturesToRemove: [] },
        facadePictures: assessment.facadePictures || { picturesToAdd: [], existingPictures: [], picturesToRemove: [] },
        buildingSituationPictures: assessment.buildingSituationPictures
      || { picturesToAdd: [], existingPictures: [], picturesToRemove: [] },
      });

    } else {
      this.buildingDetailsForm.reset();
    }
  }

  integerOnly(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, '');
  }

  floatOnly(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    value = value.replace(/,/g, '.');
    input.value = value.replace(/[^\d.]/g, '');

    if ((value.match(/\./g) || []).length > 1) {
      input.value = value.substring(0, value.lastIndexOf('.'));
    }
  }

  async handleFileInput(controlName: string, event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
      const compressedFile = await compressImage(target.files[0]);
      const fileWithPreview = await extractPreview(compressedFile);
      const fileWithPreviewAndUniqueId = addUniqueIdPrefix(fileWithPreview);
      this.addPicture(controlName, fileWithPreviewAndUniqueId);
    }
  }

  addPicture(controlName: string, fileWithPreview: FileWithPreviewBase64) {
    const control = this.buildingDetailsForm.get(`${controlName}Pictures`);

    if (control instanceof FormControl) {
      const currentValue = control.value;
      const updatedValue = {
        ...currentValue,
        picturesToAdd: [...currentValue.picturesToAdd, fileWithPreview]
      };

      control.patchValue(updatedValue);
      this.buildingDetailsForm.markAsDirty();
    }
  }

  deleteExistingPicture(controlName: string, thumbnail: AssessmentThumbnail) {
    if (!thumbnail || !thumbnail.pictureName) {
      console.error('Thumbnail or pictureFileName is undefined', thumbnail);
      return;
    }

    const actualFileName = thumbnail.pictureName.replace('thumb-', '');
    const updatedThumbnails = this.assessment?.[`${controlName}Thumbnails`]?.filter(
      t => t.pictureName !== thumbnail.pictureName
    ) || [];

    this.assessment = {
      ...this.assessment,
      [`${controlName}Thumbnails`]: updatedThumbnails
    } as AssessmentDto;

    const control = this.buildingDetailsForm.get(`${controlName}Pictures`);

    if (control instanceof FormControl) {
      const currentValue = control.value || { picturesToAdd: [], existingPictures: [], picturesToRemove: [] };
      const updatedValue = {
        ...currentValue,
        picturesToRemove: [...currentValue.picturesToRemove, actualFileName]
      };

      control.patchValue(updatedValue);

      this.buildingDetailsForm.markAsDirty();
    }
  }

  deleteAddedPicture(controlName: string, file: FileWithPreviewBase64) {
    const control = this.buildingDetailsForm.get(`${controlName}Pictures`);

    if (control instanceof FormControl) {
      const currentValue = control.value || { picturesToAdd: [], existingPictures: [], picturesToRemove: [] };
      const updatedValue = {
        ...currentValue,
        picturesToAdd: [...currentValue.picturesToAdd].filter(picture => picture.data !== file.data)
      };

      control.patchValue(updatedValue);
      this.buildingDetailsForm.markAsDirty();
    }
  }

  displayFullScreenImgWithData(pictureData: string) {
    this.store.dispatch(setFullScreenAssessmentPicture({ pictureData }));
  }

  displayFullScreenImgWithPictureName(pictureName: string, subject: string) {
    if (this.assessment?.id) {
      this.store.dispatch(
        downloadFullScreenAssessmentPicture({
          assessmentId: this.assessment?.id,
          pictureName,
          subject
        })
      );
    }
  }

  saveData(): void {

    const assessmentDetailsWithPictures: AssessmentDto = {
      ...this.buildingDetailsForm.value,
        stairwellPictures: this.stairwellPictures.value,
        facadePictures: this.facadePictures.value,
        buildingSituationPictures: this.buildingSituationPictures.value,
    };

    this.assessmentDetailsSaved.emit(assessmentDetailsWithPictures);
    this.buildingDetailsForm.markAsPristine();
  }

}
