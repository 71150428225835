<div [ngClass]="isReadOnly ? 'readonly-mode' : 'edit-mode'" *transloco="let t; read: 'building.control.editDefectForm'">
  <form class="defect-card" [formGroup]="defectForm">
    <span class="title" *ngIf="isEditionMode" data-testid="modification-text">
      <mat-icon>mode_edit</mat-icon>
      <p>{{ t('modification') }}</p>
    </span>
    <mat-form-field class="description">
      <mat-label>{{ t('defectDesc') }}</mat-label>
      <textarea data-testid="description-field" matInput formControlName="description" (keyup)="extendTextArea($event.target)"></textarea>
    </mat-form-field>
    <div>
      <fieldset>
        <legend>{{ t('defectPictures') }}</legend>
        <div class="pictures">
          <div class="placeholder" *ngIf="isReadOnly && defectForm.controls['existingPictures'].value.length === 0">{{ t('noImage') }}</div>
          <div class="picture" *ngFor="let picture of defectForm.controls['existingPictures'].value">
            <img
              [src]="(picture.file ? picture.file.preview : thumbnails[picture.name]) | safeUrl"
              alt=""
              (click)="
                picture.file ? displayFullScreenImgWithData(picture.file.preview) : displayFullScreenImgWithPictureName(picture.name)
              "
            />
            <button
              data-testid="delete-existing-picture-button"
              *ngIf="!isReadOnly"
              class="delete-button"
              mat-flat-button
              (click)="deleteExistingPicture(picture)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div class="picture" *ngFor="let picture of defectForm.controls['picturesToAdd'].value">
            <img [src]="picture.preview | safeUrl" alt="" (click)="displayFullScreenImgWithData(picture.preview)" />
            <button
              data-testid="delete-picture-to-add-button"
              *ngIf="!isReadOnly"
              class="delete-button"
              mat-flat-button
              (click)="deleteAddedPicture(picture)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <input #fileInput type="file" accept="image/png, image/jpeg" (change)="onFileInput($event)" style="display: none" />
        <sibat-dark-button *ngIf="!isReadOnly" (clicked)="fileInput.click()" [label]="t('addPicture')" icon="add"> </sibat-dark-button>
      </fieldset>
    </div>
    <mat-form-field class="deadline">
      <mat-label>{{ t('deadline') }}</mat-label>
      <input
        matInput
        [min]="minDate"
        formControlName="deadline"
        [matDatepicker]="controlDatePicker"
        (dateChange)="preventRequiredDate(defectForm.controls['deadline'])"
        (focus)="controlDatePicker.open()"
      />
      <mat-datepicker-toggle [disabled]="isReadOnly" matSuffix [for]="controlDatePicker"></mat-datepicker-toggle>
      <mat-datepicker disabled="false" #controlDatePicker></mat-datepicker>
      <mat-error *ngIf="defectForm.controls['deadline'].hasError('matDatepickerParse')">{{
        'errors.invalidDateFormat' | transloco
      }}</mat-error>
      <mat-error *ngIf="defectForm.controls['deadline'].hasError('matDatepickerMin')">{{ 'errors.minDateNotMet' | transloco }}</mat-error>
    </mat-form-field>
    <mat-form-field class="location" [class.warn]="!defectForm.controls['location'].value">
      <mat-label>{{ t('location') }}</mat-label>
      <input matInput type="text" autocomplete="off" formControlName="location" required="required" />
    </mat-form-field>
    <sibat-defect-type-radio-button [group]="defectForm"></sibat-defect-type-radio-button>
  </form>
  <sibat-validate-button
    data-testid="validate-changes-button"
    *ngIf="!isReadOnly"
    (clicked)="saveDefect()"
    [label]="t('validateChanges')"
    [disabled]="defectForm.pristine"
  >
  </sibat-validate-button>
</div>
