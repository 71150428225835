import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Control } from 'src/app/control/model/control.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { selectOrphanControls } from '../../control/store/control.selector';
import { linkControlToBuilding } from '../../control/store/control.action';

@Component({
  selector: 'sibat-link-control-to-building',
  template: `
    <div mat-dialog-title class="title-container">
      <h2>{{ 'building.linkControlToBuilding' | transloco }}</h2>
    </div>

    <div class="orphan-controls-container">
      <mat-radio-group class="orphan-controls-wrapper" [(ngModel)]="selectedControl">
        <mat-radio-button class="orphan-control-item" *ngFor="let control of orphanControls$ | async" [value]="control">
          {{ control.designation ? control.designation : control.id }}
          ({{ control.date | date }})&nbsp;
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <mat-dialog-actions align="end">
      <sibat-validate-button class="mat-button-base"
                             (clicked)="linkControl()"
                             [label]="'common.validate' | transloco"
                             [disabled]="!!!selectedControl">
      </sibat-validate-button>
      <sibat-secondary-button class="mat-button-base" (clicked)="close()" [label]="'layout.closeDialog' | transloco">
      </sibat-secondary-button>
    </mat-dialog-actions>
  `,
  styleUrls: ['./link-control-to-building.scss'],
})
export class LinkControlToBuildingComponent implements OnInit {
  @Output() linkControlToBuilding = new EventEmitter<number>();

  orphanControls$ = this.store.select(selectOrphanControls);
  selectedControl: Control;

  constructor(
    private dialogRef: MatDialogRef<LinkControlToBuildingComponent>,
    private store: Store,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: { buildingId: number }
  ) {
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  linkControl() {
    this.store.dispatch(linkControlToBuilding({controlId: this.selectedControl.id, buildingId: this.data.buildingId}));
    this.linkControlToBuilding.emit(this.selectedControl.id);
    this.close();
  }
}
