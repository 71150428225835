import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { StoredOfflineEditDefect, EditDefect } from '../model/control.model';
import { Observable, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

export const DEFECTS_STORE = 'defects';

@Injectable({
  providedIn: 'root',
})
export class OfflineDefectService {
  constructor(private dbService: NgxIndexedDBService) {}

  getOfflineDefects(): Observable<StoredOfflineEditDefect[]> {
    return this.dbService.getAll(DEFECTS_STORE);
  }

  storeOfflineDefect(defect: EditDefect): Observable<StoredOfflineEditDefect> {
    const defectWithId = {
      ...defect,
      id: Date.now(),
    };
    return this.dbService.add(DEFECTS_STORE, defectWithId);
  }

  removeOfflineDefectFromStore(defectId: number): Observable<boolean> {
    return this.dbService.deleteByKey(DEFECTS_STORE, defectId);
  }

  removeOfflineDefectsFromStore(defectIds: number[]): Observable<boolean> {
    const observableArray: Observable<boolean>[] = defectIds.map(id => this.removeOfflineDefectFromStore(id));
    return zip(...observableArray).pipe(map(booleans => booleans.every(b => b)));
  }

  updateOfflineDefect(defect: StoredOfflineEditDefect): Observable<StoredOfflineEditDefect> {
    return this.dbService.deleteByKey(DEFECTS_STORE, defect.id).pipe(mergeMap(() => this.dbService.add(DEFECTS_STORE, defect)));
  }
}
