import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import FeatureFlagsState from './feature-flags.reducer';
import { FeatureFlag, FEATURE } from '../model/feature-flags.model';

export const getFeatureFlagsState = (state: State) => state.featureFlags;

export const selectFeatureFlags = createSelector(getFeatureFlagsState, (state: FeatureFlagsState) => state.featureFlags ?? []);

export const selectIsUploadDocumentEnabled = createSelector(getFeatureFlagsState, (state: FeatureFlagsState) =>
  isFlagEnabled(FEATURE.documentUpload, state.featureFlags)
);

export const selectIsControlEnabled = createSelector(getFeatureFlagsState, (state: FeatureFlagsState) =>
  isFlagEnabled(FEATURE.startBuildingControl, state.featureFlags)
);

export const selectIsWelcomeMessageEnabled = createSelector(getFeatureFlagsState, (state: FeatureFlagsState) =>
  isFlagEnabled(FEATURE.welcomeMessage, state.featureFlags)
);

export const selectAreOrphanControlsEnabled = createSelector(
  getFeatureFlagsState,
  selectIsControlEnabled,
  (state: FeatureFlagsState, controlEnabled) => controlEnabled && isFlagEnabled(FEATURE.orphanControls, state.featureFlags)
);

export const selectIsBuildingDocumentBrowsingEnabled = createSelector(getFeatureFlagsState, (state: FeatureFlagsState) =>
  isFlagEnabled(FEATURE.buildingDocumentBrowsing, state.featureFlags)
);

export const selectIsItechEnabled = createSelector(getFeatureFlagsState, (state: FeatureFlagsState) =>
  isFlagEnabled(FEATURE.itech, state.featureFlags)
);

export const selectControlPlanningEnabled = createSelector(getFeatureFlagsState, (state: FeatureFlagsState) =>
  isFlagEnabled(FEATURE.controlPlanning, state.featureFlags)
);

const isFlagEnabled = (flagKey: string, flags: FeatureFlag[]) => flags.some(flag => flag.key === flagKey && flag.enabled);
