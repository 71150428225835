import { clearExtent, updateExtent, updateMapQuerySuccess } from './map.action';
import { Action, createReducer, on } from '@ngrx/store';
import { MapExtent } from '../model/map-extent.model';

export default interface MapState {
  mapQuery: string;
  currentExtent?: MapExtent;
}

export const initialState: MapState = {
  mapQuery: '',
  currentExtent: undefined,
};

const mapReducer = createReducer(
  initialState,
  on(updateMapQuerySuccess, (state, action): MapState => ({ ...state, mapQuery: action.query })),
  on(
    updateExtent,
    (state, action): MapState => ({
      ...state,
      currentExtent: action.extent,
    })
  ),
  on(
    clearExtent,
    (state): MapState => ({
      ...state,
      currentExtent: undefined,
    })
  )
);

export const reducer = (state: MapState | undefined, action: Action): MapState => mapReducer(state, action);
