import { createSelector } from '@ngrx/store';
import BuildingsState from './buildings.reducer';
import { getBuildingsState } from './buildings.selector';
import { Municipality } from '../model/municipality.model';

export const selectAffectations = createSelector(getBuildingsState, (state: BuildingsState) => state.affectations);

export const selectMunicipalities = createSelector(getBuildingsState, (state: BuildingsState) => state.municipalities);

export const selectSortedMunicipalities = createSelector(selectMunicipalities, (municipalities: Municipality[]) =>
  municipalities.slice().sort((a, b) => a.name.localeCompare(b.name))
);

export const selectBuildingClasses = createSelector(getBuildingsState, (state: BuildingsState) => state.buildingClasses);

export const selectConstructionPeriods = createSelector(getBuildingsState, (state: BuildingsState) => state.constructionPeriods);

export const selectMunicipalitiesFilter = createSelector(getBuildingsState, (state: BuildingsState) => state.municipalitiesFilter);

export const selectTypologiesFilter = createSelector(getBuildingsState, (state: BuildingsState) => state.typologiesFilter);

export const selectBuildingClassesFilter = createSelector(getBuildingsState, (state: BuildingsState) => state.buildingClassesFilter);

export const selectConstructionPeriodsFilter = createSelector(
  getBuildingsState,
  (state: BuildingsState) => state.constructionPeriodsFilter
);

export const selectAffectationsFilter = createSelector(getBuildingsState, (state: BuildingsState) => state.affectationsFilter);

export const selectControlledStateFilter = createSelector(getBuildingsState, (state: BuildingsState) => state.controlledStateFilter);

export const selectCurrentFilters = createSelector(
  selectMunicipalitiesFilter,
  selectAffectationsFilter,
  selectBuildingClassesFilter,
  selectConstructionPeriodsFilter,
  selectTypologiesFilter,
  selectControlledStateFilter,
  (municipalityFilters, assignmentFilters, buildingClassFilters, constructionPeriodFilters, typologyFilters, controlledStateFilters) => ({
    municipalityFilters,
    assignmentFilters,
    buildingClassFilters,
    constructionPeriodFilters,
    typologyFilters,
    controlledStateFilters: !!controlledStateFilters ? [controlledStateFilters] : [],
  })
);

export const selectFilterableData = createSelector(
  selectSortedMunicipalities,
  selectAffectations,
  selectBuildingClasses,
  selectConstructionPeriods,
  selectCurrentFilters,
  (municipalities, assignments, buildingClasses, constructionPeriods, currentFilters) => ({
    municipalities,
    assignments,
    buildingClasses,
    constructionPeriods,
    currentFilters,
  })
);
