import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sibat-modal-header',
  template: `
    <div class="header-modal" fxLayout="row" fxLayoutAlign="center">
      <h2 class="title">
        {{ title }}
      </h2>
      <sibat-icon-button *ngIf="helpLink"
                         [href]="helpLink"
                         icon="help"
                         target="_blank">
      </sibat-icon-button>
      <div class="filler"></div>
      <button data-testid="header-modal-close-button" class="close-button" (click)="clicked.emit()">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </div>
  `,
  styleUrls: ['modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit {
  @Output() clicked = new EventEmitter<void>();
  @Input() title: string;
  @Input() helpLink?: string;

  constructor() {}

  ngOnInit(): void {}
}
