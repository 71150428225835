<ng-container *transloco="let t; read: 'building.technicalInstallation'">
  <div mat-dialog-title class="title-container">
    <h2>{{ t(editionMode ? 'editAnnounceFile' : 'addAnnounceFile') }}</h2>
    <sibat-icon-button
      href="https://ecab.atlassian.net/wiki/spaces/SS/pages/994607133/Installations+Techniques+ITECH#Annoncer-une-installation-technique"
      icon="help" target="_blank">
    </sibat-icon-button>
  </div>

  <mat-dialog-content sibatDragNDrop (fileDropped)="fileDropped($event)">
    <div [formGroup]="announcementForm">
      <ng-container *ngIf="!editionMode">
        <p>
          <mat-form-field appearance="standard">
            <input formControlName="file" #fileField matInput (focus)="uploadFileIfNecessary(fileField, fileInput)"
              [placeholder]="t('addAnnounceFile')" />
            <input aria-hidden="true" type="file" class="hidden" [multiple]="false"
              #fileInput (change)="updateFile($event.target.files[0])" />
            <mat-icon matPrefix class="validation-icon" *ngIf="fileField.value && fileFormControl?.valid">check_circle</mat-icon>
            <button matSuffix mat-icon-button (click)="fileInput.click()">
              <mat-icon>file_download</mat-icon>
            </button>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>

        <div>
          <label>{{ t('installationTypeLabel') }} {{ announcementForm | requiredIndicator : 'installationType'
            }}</label>
          <p>
            <mat-form-field>
              <mat-select formControlName="installationType">
                <mat-option *ngFor="let type of installationTypes"
                            [value]="type">
                  {{ t('installationType', {type}) }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </p>
        </div>
      </ng-container>

      <div>
        <label>{{ t('designation') }}</label>
        <p>
          <mat-form-field>
            <input matInput formControlName="designation" />
          </mat-form-field>
        </p>
      </div>

      <div>
        <label>{{ t('planner') }} {{ announcementForm | requiredIndicator : 'planner' }}</label>
        <p>
          <mat-form-field>
            <textarea matInput formControlName="planner" [placeholder]="t('contactPlaceholder')">
            </textarea>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
      </div>

      <div>
        <label>{{ t('installer') }} {{ announcementForm | requiredIndicator : 'installer' }}</label>
        <p>
          <mat-form-field>
            <textarea matInput formControlName="installer" [placeholder]="t('contactPlaceholder')">
            </textarea>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
      </div>

      <div>
        <label>{{ t('projectValidator') }} {{ announcementForm | requiredIndicator : 'validator' }}</label>
        <p>
          <mat-form-field>
            <mat-select formControlName="validator">
              <mat-option *ngFor="let validator of projectValidators" [value]="validator[0]">
                {{ validator[1] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </p>
      </div>

      <div>
        <label>{{ t('monitoringScope') }} {{ announcementForm | requiredIndicator : 'monitoringScope' }}</label>
        <p class="radio-field">
          <mat-radio-group formControlName="monitoringScope" [attr.aria-label]="t('monitoringScope')">
            <mat-radio-button *ngFor="let monitoring of monitorings" [value]="monitoring">
              {{ t('monitoringScopeType', { type: monitoring }) }}
            </mat-radio-button>
          </mat-radio-group>
        </p>
      </div>

      <div>
        <label>{{ t('requirementLabel') }} {{ announcementForm | requiredIndicator : 'necessity' }}</label>
        <p class="radio-field">
          <mat-radio-group formControlName="necessity" [attr.aria-label]="t('requirementLabel')">
            <mat-radio-button *ngFor="let necessity of necessities"
                              [value]="necessity">
              {{ t('requirementType', { type: necessity }) }}</mat-radio-button>
          </mat-radio-group>
        </p>
      </div>

      <div>
        <label>{{ t('ceaConnectedLabel') }} {{ announcementForm | requiredIndicator : 'ceaConnected' }}</label>
        <p class="radio-field">
          <mat-radio-group formControlName="ceaConnected" [attr.aria-label]="t('ceaConnectedLabel')">
            <mat-radio-button value="YES">{{ t('ceaConnectedType', { type: 'YES' }) }}</mat-radio-button>
            <mat-radio-button value="NO">{{ t('ceaConnectedType', { type: 'NO' }) }}</mat-radio-button>
            <mat-radio-button value="UNKNOWN">{{ t('ceaConnectedType', { type: 'UNKNOWN' }) }}</mat-radio-button>
          </mat-radio-group>
        </p>
      </div>

      <div #transmitterContainer [@openClose]="ceaConnected.value === ceaConnectedValues.yes ? 'open' : 'closed'">
        <label>{{ t('transmitter') }} *</label>
        <p>
          <mat-form-field>
            <textarea matInput formControlName="transmitter" [placeholder]="t('contactPlaceholder')">
            </textarea>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
      </div>

      <div *ngIf="temporaryShutdownEndDateFormControl !== undefined">
        <label>{{ t('temporaryShutdownEndDate') }} *</label>
        <p>
          <mat-form-field>
            <input
              matInput
              formControlName="temporaryShutdownEndDate"
              [matDatepicker]="temporaryShutdownEndDatePicker"
              [min]="today"
              (focus)="temporaryShutdownEndDatePicker.open()"
            />
            <mat-datepicker-toggle matSuffix [for]="temporaryShutdownEndDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #temporaryShutdownEndDatePicker></mat-datepicker>
            <mat-error>{{ 'errors.requiredField' | transloco }}</mat-error>
          </mat-form-field>
        </p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <sibat-update-button *ngIf="isUncompleted$ | async; else validation" class="mat-button-base" (clicked)="validate()"
      [disabled]="hasRequiredError$ | async" [label]="'building.documents.validate' | transloco">
    </sibat-update-button>
    <ng-template #validation>
      <sibat-validate-button [class.uncompleted]="isUncompleted$ | async" class="mat-button-base" (clicked)="validate()"
        [disabled]="hasRequiredError$ | async" [label]="'building.documents.validate' | transloco">
      </sibat-validate-button>
    </ng-template>

    <sibat-secondary-button class="mat-button-base" (clicked)="cancel()"
      [label]="'building.documents.cancel' | transloco">
    </sibat-secondary-button>
  </mat-dialog-actions>
</ng-container>
