import { Component, Input } from '@angular/core';

@Component({
  selector: 'sibat-link-button',
  template: `
    <a class="button-link" [href]="href" [target]="target">
      <span>{{ label }}</span>
      <mat-icon style="font-size: smaller" *ngIf="external">open_in_new</mat-icon>
    </a>
  `,
  styleUrls: ['buttons.scss'],
})
export class LinkButtonComponent {
  @Input() label: string;
  @Input() href: string;
  @Input() external: boolean;
  @Input() target = '_self';

  constructor() {}
}
