<div class="root-container mat-elevation-z8">
  <div class="expand-button mat-elevation-z8" (click)="hideFabAndSelf()">
    <mat-icon svgIcon="arrow-right"></mat-icon>
  </div>
  <div class="table-container" *transloco="let t; read: 'building.list'">
    <table mat-table matSort fixedLayout="true" [dataSource]="dataSource" [trackBy]="buildingId"
      class="buildings-table single-action-list" aria-describedby="detailed list of selected buildings">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox [indeterminate]="someRemoved" [checked]="noneRemoved" (change)="removeAll($event.checked)">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <p>
            <mat-checkbox [checked]="!element.removed" (click)="$event.stopPropagation()"
              (change)="removeOne(element, $event.checked)">
            </mat-checkbox>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('addresses') }}</th>
        <td mat-cell *matCellDef="let element">
          <p *ngIf="element.addresses.length === 0" aria-hidden="true">&nbsp;</p>
          <p *ngFor="let address of element.addresses; count as length">
            <span *ngIf="length > 1">—&nbsp;</span>{{ address }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="typology">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ t('typology') }} </th>
        <td mat-cell *matCellDef="let element">
          <p>
            <sibat-typology-indicator [color]="element.currentColor"></sibat-typology-indicator>
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="assignment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'building.summary.assignment' | transloco }} </th>
        <td mat-cell *matCellDef="let element">
          <p *ngFor="let assignment of element.localizedAffectations; count as length">
            <span *ngIf="length > 1">—&nbsp;</span>{{ assignment }}
          </p>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastControlDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ t('lastControlDate') }} </th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.lastControlDate | date: 'dd.MM.yyyy' }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="nextControlDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ t('nextControlDate') }} </th>
        <td mat-cell *matCellDef="let element">
          <p>{{ element.nextControlDate | date: 'dd.MM.yyyy' }}</p>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.removed]="row.removed"
        (click)="removeOne(row, row.removed)"></tr>
    </table>

    <mat-paginator hidePageSize="true" pageSize="25"></mat-paginator>
  </div>
</div>
