import { Message } from '../../model/notification.model';
import { createAction, props } from '@ngrx/store';
import { BuildingSummary } from '../model/building.model';
import { Affectation } from '../model/affectation.model';
import { Municipality } from '../model/municipality.model';
import { BuildingClass } from '../model/building-class.model';
import { ConstructionPeriod } from '../model/construction-period.model';

export const findBuildings = createAction('[Building] find all');

export const findMoreBuildings = createAction('[Building] find more');

export const findBuildingsSuccess = createAction('[Building] find success', props<{ buildings: BuildingSummary[]; count: number }>());

export const findMoreBuildingsSuccess = createAction(
  '[Building] find more success',
  props<{ buildings: BuildingSummary[]; count: number; page: number }>()
);

export const findBuildingsFailure = createAction('[Building] find failure', props<{ error: any }>());

export const clearBuildingsStore = createAction('[Building] clear building store');

export const findTypologyAffectations = createAction('[Building] find typology affectations');

export const findTypologyAffectationsSuccess = createAction(
  '[Building] find typology affectations success',
  props<{ affectations: Affectation[] }>()
);

export const findMunicipalities = createAction('[Building] find municipalities');

export const findMunicipalitiesSuccess = createAction(
  '[Building] find municipalities success',
  props<{ municipalities: Municipality[] }>()
);

export const reloadLocalizedData = createAction('[Building] reload localized data');

export const reloadLocalizedDataSuccess = createAction(
  '[Building] reload localized data success',
  props<{
    municipalities: Municipality[];
    affectations: Affectation[];
    buildingClasses: BuildingClass[];
    constructionPeriods: ConstructionPeriod[];
  }>()
);

export const reloadLocalizedDataFailure = createAction(
  '[Building] reload localized data failure',
  props<{ error: any; notification: Message }>()
);

export const setMunicipalitiesFilter = createAction('[Building] set municipalities filter', props<{ municipalitiesFilter: number[] }>());

export const setTypologiesFilter = createAction('[Building] set typologies filter', props<{ typologiesFilter: string[] }>());

export const findBuildingClasses = createAction('[Building] find buildingClasses');

export const findBuildingClassesSuccess = createAction(
  '[Building] find building classes success',
  props<{ buildingClasses: BuildingClass[] }>()
);

export const setBuildingClassesFilter = createAction(
  '[Buildings] set building classes filter',
  props<{ buildingClassesFilter: number[] }>()
);

export const findConstructionPeriods = createAction('[Buildings] find construction periods');

export const findConstructionPeriodsSuccess = createAction(
  '[Buildings] find construction period success',
  props<{ constructionPeriods: ConstructionPeriod[] }>()
);

export const setConstructionPeriodsFilter = createAction(
  '[Buildings] set construction period filter',
  props<{ constructionPeriodFilter: number[] }>()
);

export const setAffectationsFilter = createAction('[Buildings] set affectation filter', props<{ affectationFilter: string[] }>());

export const setControlledStateFilter = createAction('[Buildings] set controlled state filter', props<{ controlledStateFilter: string }>());

export const reinitializeAllFilters = createAction('[Buildings] reinitialize all filters');

export const downloadCurrentBuildings = createAction('[Buildings] download buildings list');

export const downloadSelectedBuildings = createAction('[Buildings] download selected buildings list');

export const downloadBuildingList = createAction('[Buildings] download building list', props<{ buildingIds: number[] }>());

export const addAllBuildings = createAction('[Buildings] add all buildings');

export const addAllBuildingsSuccess = createAction('[Buildings] add all buildings success', props<{ buildings: BuildingSummary[] }>());

export const addAllBuildingsFailure = createAction('[Buildings] add all buildings failure', props<{ error: any }>());

export const addSelectedBuildings = createAction('[Buildings] add selected buildings', props<{ buildingIds: number[] }>());

export const addSelectedBuildingsSuccess = createAction(
  '[Buildings] add selected building success',
  props<{ buildings: BuildingSummary[] }>()
);

export const removeSelectedBuilding = createAction('[Buildings] remove selected building', props<{ buildingId: number }>());

export const removeSelectedBuildings = createAction('[Buildings] remove selected buildings', props<{ buildingIds: number[] }>());

export const removeAllSelectedBuildings = createAction('[Buildings] remove all selected buildings');

export const replaceBuildingsSelection = createAction('[Buildings] replace buildings selection', props<{ buildingIds: number[] }>());

export const replaceBuildingsSelectionSuccess = createAction(
  '[Buildings] replace buildings selection success',
  props<{ buildings: BuildingSummary[] }>()
);

export const refreshSelectedBuildings = createAction('[Buildings] refresh selected buildings');

export const refreshSelectedBuildingsSuccess = createAction(
  '[Buildings] refresh selected buildings success',
  props<{ buildings: BuildingSummary[] }>()
);
