import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import Fuse from 'fuse.js';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { map, shareReplay, withLatestFrom } from 'rxjs/operators';
import {
  allOptions,
  ALL_VALUE,
  createFilter,
  Filter,
  FILTER_OPTION_BEHAVIOUR,
  firstUnselectedOption,
  optionGroupOf,
  selectedOptions,
} from '../model/filter';
import { typologyTranslations } from '../model/typology.model';
import {
  reinitializeAllFilters,
  setAffectationsFilter,
  setBuildingClassesFilter,
  setConstructionPeriodsFilter,
  setControlledStateFilter,
  setMunicipalitiesFilter,
  setTypologiesFilter,
} from '../store/buildings.action';
import { selectFilterableData } from '../store/reference-data.selector';
import { FilterOptionService } from './filter-option.service';

@Component({
  selector: 'sibat-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'legacy' },
    },
  ],
})
export class FilterComponent implements OnInit, OnDestroy {
  filters$: Observable<Filter[]>;

  private previousFormValue: Map<Filter, string[]>;
  private searchFormsSubscription = new Subscription();
  private filtersSubscription: Subscription;
  private searchOptions = {
    keys: ['label'],
  };

  constructor(private store: Store, private filterOptionService: FilterOptionService, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.filters$ = this.store.select(selectFilterableData).pipe(
      withLatestFrom(this.translocoService.selectTranslation()),
      map(([filters, _]) => {
        const municipalities =
          filters.currentFilters.municipalityFilters.length > 0
            ? new UntypedFormControl(filters.currentFilters.municipalityFilters.map(String))
            : new UntypedFormControl([ALL_VALUE]);
        const typologies =
          filters.currentFilters.typologyFilters.length > 0
            ? new UntypedFormControl(filters.currentFilters.typologyFilters.map(String))
            : new UntypedFormControl([ALL_VALUE]);
        const assignments =
          filters.currentFilters.assignmentFilters.length > 0
            ? new UntypedFormControl(filters.currentFilters.assignmentFilters.map(String))
            : new UntypedFormControl([ALL_VALUE]);
        const buildingClasses =
          filters.currentFilters.buildingClassFilters.length > 0
            ? new UntypedFormControl(filters.currentFilters.buildingClassFilters.map(String))
            : new UntypedFormControl([ALL_VALUE]);
        const constructionPeriods =
          filters.currentFilters.constructionPeriodFilters.length > 0
            ? new UntypedFormControl(filters.currentFilters.constructionPeriodFilters.map(String))
            : new UntypedFormControl([ALL_VALUE]);
        const controlledState =
          filters.currentFilters.controlledStateFilters.length > 0
            ? new UntypedFormControl(filters.currentFilters.controlledStateFilters)
            : new UntypedFormControl([ALL_VALUE]);

        return [
          createFilter({
            formControl: municipalities,
            icon: 'account_balance',
            label: this.translocoService.translate('building.filter.municipality'),
            options: [
              optionGroupOf([
                this.filterOptionService.allOption(),
                ...this.filterOptionService
                  .filterOn(filters.municipalities)
                  .withMunicipalityKey.including(filters.currentFilters.municipalityFilters)
                  .map(this.filterOptionService.municipalityFilterOption),
              ]),
              optionGroupOf(
                this.filterOptionService
                  .filterOn(filters.municipalities)
                  .withMunicipalityKey.notIncluding(filters.currentFilters.municipalityFilters)
                  .map(this.filterOptionService.municipalityFilterOption),
                true
              ),
            ],
            onChange: value => this.municipalityFilterChanged(value),
          }),
          createFilter({
            formControl: typologies,
            icon: 'house',
            label: this.translocoService.translate('building.filter.typology'),
            options: [
              optionGroupOf([
                this.filterOptionService.allOption(),
                this.filterOptionService.tbdOption(),
                ...this.filterOptionService.typologyFilterOptions(
                  this.filterOptionService.filterOn(typologyTranslations).withTypologyKey.including(filters.currentFilters.typologyFilters)
                ),
              ]),
              optionGroupOf(
                this.filterOptionService.typologyFilterOptions(
                  this.filterOptionService
                    .filterOn(typologyTranslations)
                    .withTypologyKey.notIncluding(filters.currentFilters.typologyFilters)
                ),
                true
              ),
            ],
            onChange: value => this.typologyFilterChanged(value),
          }),
          createFilter({
            formControl: assignments,
            icon: 'corporate_fare',
            label: this.translocoService.translate('building.filter.assignment'),
            options: [
              optionGroupOf([
                this.filterOptionService.allOption(),
                this.filterOptionService.noAssignmentOption(),
                ...this.filterOptionService
                  .filterOn(filters.assignments)
                  .withAssignmentKey.including(filters.currentFilters.assignmentFilters)
                  .map(this.filterOptionService.assignmentFilterOption),
              ]),
              optionGroupOf(
                this.filterOptionService
                  .filterOn(filters.assignments)
                  .withAssignmentKey.notIncluding(filters.currentFilters.assignmentFilters)
                  .map(this.filterOptionService.assignmentFilterOption),
                true
              ),
            ],
            onChange: value => this.affectationFilterChanged(value),
          }),
          createFilter({
            formControl: buildingClasses,
            icon: 'business',
            label: this.translocoService.translate('building.filter.buildingClass'),
            options: [
              optionGroupOf([
                this.filterOptionService.allOption(),
                ...this.filterOptionService
                  .filterOn(filters.buildingClasses)
                  .withBuildingClassKey.including(filters.currentFilters.buildingClassFilters)
                  .map(this.filterOptionService.buildingClassFilterOption),
              ]),
              optionGroupOf(
                this.filterOptionService
                  .filterOn(filters.buildingClasses)
                  .withBuildingClassKey.notIncluding(filters.currentFilters.buildingClassFilters)
                  .map(this.filterOptionService.buildingClassFilterOption),
                true
              ),
            ],
            onChange: value => this.buildingClassFilterChanged(value),
          }),
          createFilter({
            formControl: constructionPeriods,
            icon: 'date_range',
            label: this.translocoService.translate('building.filter.constructionPeriod'),
            options: [
              optionGroupOf([
                this.filterOptionService.allOption(),
                ...this.filterOptionService
                  .filterOn(filters.constructionPeriods)
                  .withConstructionPeriodKey.including(filters.currentFilters.constructionPeriodFilters)
                  .map(this.filterOptionService.constructionPeriodsFilterOption),
              ]),
              optionGroupOf(
                this.filterOptionService
                  .filterOn(filters.constructionPeriods)
                  .withConstructionPeriodKey.notIncluding(filters.currentFilters.constructionPeriodFilters)
                  .map(this.filterOptionService.constructionPeriodsFilterOption),
                true
              ),
            ],
            onChange: value => this.constructionPeriodFilterChanged(value),
          }),
          createFilter({
            formControl: controlledState,
            icon: 'done_all',
            label: this.translocoService.translate('building.filter.controlledState'),
            options: [
              optionGroupOf([
                this.filterOptionService.allOption('m'),
                this.filterOptionService.controlledOption(),
                this.filterOptionService.notControlledOption(),
              ]),
            ],
            onChange: value => this.controlledSateFilterChanged(value),
          }),
        ];
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.filtersSubscription = this.filters$.subscribe(filters => {
      this.previousFormValue = new Map(filters.map(x => [x, x.formControl.value]));
      if (filters) {
        this.handleSearch(filters);
      }
    });
  }

  ngOnDestroy(): void {
    this.searchFormsSubscription.unsubscribe();
    this.filtersSubscription?.unsubscribe();
  }

  handleSearch(filters: Filter[]) {
    this.searchFormsSubscription.unsubscribe();
    this.searchFormsSubscription = new Subscription();
    filters.forEach(filter => {
      this.searchFormsSubscription.add(
        filter.searchFormControl.valueChanges.subscribe(value => {
          this.searchIn(filter, value);
        })
      );
    });
  }

  searchIn(filter: Filter, value: string): void {
    if (!value) {
      // reset filtered options
      filter.filteredOptions.next([...filter.options]);
      return;
    }

    const result = filter.options.map(group => {
      if (group.searchable) {
        const fuse = new Fuse(group.options, this.searchOptions);
        const searchResult = fuse.search(value);
        const options = searchResult.map(x => x.item);
        return { ...group, options };
      }
      return group;
    });

    filter.filteredOptions.next(result);
  }

  isDisabled(filter: Filter): boolean {
    return filter.disabledOption !== undefined && (filter.formControl.value?.includes(filter.disabledOption) ?? false);
  }

  resetFilters() {
    this.store.dispatch(reinitializeAllFilters());
  }

  updateFilter(filter: Filter) {
    const currentValue: string[] = filter.formControl.value;
    const previousValue = this.previousFormValue.get(filter) ?? [];
    const [addedValue] = filter.formControl.value.filter(x => !previousValue.includes(x));

    if (addedValue) {
      this.setFilterValue(filter, addedValue);
    } else if (currentValue.length === 0) {
      this.resetFilterValue(filter);
    }

    this.previousFormValue.set(filter, filter.formControl.value);
    filter.onChange(filter.formControl.value);
  }

  async selectFirstSearchResult(filter: Filter): Promise<void> {
    if (filter.searchFormControl.value) {
      const filtered = await firstValueFrom(filter.filteredOptions);
      const searchResults = allOptions(filtered.filter(x => x.searchable));
      const unselected = firstUnselectedOption(searchResults, filter.formControl.value);

      if (unselected) {
        filter.formControl.setValue([...filter.formControl.value, unselected.value]);
        this.updateFilter(filter);
      }
    }
  }

  resetFilterValue(filter: Filter) {
    if (filter.defaultValue !== undefined) {
      filter.formControl.setValue([filter.defaultValue]);
    }
  }

  setFilterValue(filter: Filter, addedValue: string) {
    const selected = selectedOptions(filter);
    const [addedOption] = selected.filter(x => x.value === addedValue);
    const exclusiveOptions = selected.filter(x => x.behavior === FILTER_OPTION_BEHAVIOUR.exclusive);

    if (addedOption.behavior === FILTER_OPTION_BEHAVIOUR.exclusive) {
      filter.formControl.setValue([addedValue]);
    } else if (exclusiveOptions.length > 0 && !exclusiveOptions.some(x => x.value === addedValue)) {
      const currentValue = filter.formControl.value;
      filter.formControl.setValue(currentValue.filter(x => !exclusiveOptions.some(xx => xx.value === x)));
    }
  }

  typologyFilterChanged(value: string[]) {
    const typologiesFilter = this.filterOptionService.withoutDefaultValue(value);
    this.store.dispatch(setTypologiesFilter({ typologiesFilter }));
  }

  controlledSateFilterChanged(value: string[]) {
    const [controlledStateFilter] = this.filterOptionService.withoutDefaultValue(value);
    this.store.dispatch(setControlledStateFilter({ controlledStateFilter }));
  }

  buildingClassFilterChanged(value: string[]) {
    const buildingClassesFilter = this.filterOptionService.withoutDefaultValue(value).map(Number);
    this.store.dispatch(setBuildingClassesFilter({ buildingClassesFilter }));
  }

  constructionPeriodFilterChanged(value: string[]) {
    const constructionPeriodFilter = this.filterOptionService.withoutDefaultValue(value).map(Number);
    this.store.dispatch(setConstructionPeriodsFilter({ constructionPeriodFilter }));
  }

  affectationFilterChanged(value: string[]) {
    const affectationFilter = this.filterOptionService.withoutDefaultValue(value);
    this.store.dispatch(setAffectationsFilter({ affectationFilter }));
  }

  municipalityFilterChanged(value: string[]) {
    const municipalitiesFilter = this.filterOptionService.withoutDefaultValue(value).map(Number);
    this.store.dispatch(setMunicipalitiesFilter({ municipalitiesFilter }));
  }
}
