
<div class="justification" *transloco="let t; read: 'building.control'">
  <div class="short-details">
    <div (click)="onToggleDetailsClick()" *ngIf="!isOpen" class="details clickable">
      <p *ngIf="compliance.proofs.length > 0">{{compliance.proofs[0]}}
        <span *ngIf="compliance.proofs.length > 1">(+{{compliance.proofs.length}})</span>
      </p>
      <p *ngIf="compliance.description">{{compliance.description}}</p>
    </div>
    <mat-icon *ngIf="!isOpen" class="clickable" (click)="onToggleDetailsClick()" data-testid="open-details">keyboard_arrow_down</mat-icon>
  </div>

  <div *ngIf="isOpen" class="long-details" data-testid="long-details">
    <div class="close-details-wrapper">
      <mat-icon *ngIf="isOpen" class="clickable" (click)="onToggleDetailsClick()" data-testid="close-details">keyboard_arrow_up</mat-icon>
    </div>
    <div>
      <mat-form-field data-testid="note" *ngIf="compliance.description">
        <mat-label class="capitalized">{{ t('defectsCompliance.note') }}</mat-label>
        <textarea matInput disabled>{{compliance.description}}</textarea>
      </mat-form-field>

      <fieldset data-testid="proofs" *ngIf="compliance.proofs.length > 0">
        <legend>{{ t('defectsCompliance.documents') }}</legend>
        <mat-chip-list>
          <sibat-chip-proof *ngFor="let proof of compliance.proofs" [proofName]="proof" (downloaded)="downloadComplianceProof(proof)"> </sibat-chip-proof>
        </mat-chip-list>
      </fieldset>
    </div>
  </div>
</div>

