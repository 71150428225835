import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import BuildingsState from './buildings.reducer';

export const getBuildingsState = (state: State) => state.buildings;

export const selectBuildings = createSelector(getBuildingsState, (state: BuildingsState) => state.buildings);

export const selectSelectedBuildings = createSelector(getBuildingsState, (state: BuildingsState) => {
  if (!state) {
    // TODO: this should not happen, but it does when running tests on GitLab CI
    console.warn('state is not supposed to be undefined');
    return [];
  }
  return state.selectedBuildings;
});

export const selectSelectedBuildingsCount = createSelector(getBuildingsState, (state: BuildingsState) => state.selectedBuildings.length);

export const selectSelectedBuildingIndex = (buildingId?: number) =>
  createSelector(getBuildingsState, (state: BuildingsState) => {
    if (buildingId) {
      return state.selectedBuildings.findIndex(building => building.id === buildingId);
    } else {
      return -1;
    }
  });

export const selectBuildingsCount = createSelector(getBuildingsState, (state: BuildingsState) => state.buildingsCount);

export const selectBuildingsCurrentPage = createSelector(getBuildingsState, (state: BuildingsState) => state.buildingsPage);
