import { CanDeactivate } from '@angular/router';
import { Component, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsOnline } from '../../network/store/network.selector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineDefectsGuard implements CanDeactivate<Component> {
  constructor(private store: Store) {}

  canDeactivate(component: Component): Observable<boolean> {
    return this.store.select(selectIsOnline);
  }
}
