import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { EditProtectiveMeasure, MeasureType, newEditMeasure } from '../../model/assessment.model';
import { extendTextArea } from '../../../shared/style';
import { getMeasureTypes } from '../../store/assessment.action';
import { selectMeasureTypes } from '../../store/assessment.selector';

@Component({
  selector: 'sibat-edit-measure',
  templateUrl: './edit-measure.component.html',
  styleUrls: ['edit-measure.component.scss'],
})
export class EditMeasureComponent implements OnInit, OnChanges {

  @Input() isReadOnly = false;
  @Input() readOnly: boolean;
  @Input() buildingId?: number;
  @Output() measureSaved = new EventEmitter<EditProtectiveMeasure>();


  measureTypes$ = this.store.select(selectMeasureTypes);

  readonly type = this.formBuilder.control(undefined);
  readonly description = this.formBuilder.control(undefined);
  readonly proposition = this.formBuilder.control(undefined);
  readonly measureForm = this.formBuilder.group(
    {
      type: this.type,
      description: this.description,
      proposition: this.proposition
    }
  );

  private originalMeasure: EditProtectiveMeasure;

  constructor(private store: Store, private formBuilder: UntypedFormBuilder) { }

  get isEditionMode() {
    return this.originalMeasure?.id && !this.isReadOnly;
  }

  @Input() set measure(measure: EditProtectiveMeasure) {
    this.originalMeasure = measure;
    let initialValues: EditProtectiveMeasure;
    if (!measure) {
      initialValues = newEditMeasure();
    } else {
      initialValues = Object.assign({}, measure);
    }

    this.measureForm.patchValue({
      type: initialValues.type !== undefined ? initialValues.type : undefined,
      description: initialValues.description,
      proposition: initialValues.proposition
    });
  }

  ngOnInit(): void {
    this.store.dispatch(getMeasureTypes());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readOnly) {
      if (this.readOnly) {
        this.measureForm.disable();
      } else {
        this.measureForm.enable();
      }
    }
  }


  saveMeasure() {
    //const currentMeasure: EditDefect = this.originalDefect ? this.originalDefect : newEditDefect();
    const updatedMeasure: EditProtectiveMeasure = { ...this.originalMeasure, ...this.measureForm.value };
    this.measureSaved.emit(updatedMeasure);
    this.measureForm.markAsPristine();
  }

  onMeasureTypeSelected(measureType: MeasureType) {
    const selectedMeasureType = this.measureForm.get('type') as FormControl;

    if (!this.isMeasureTypeSelected(measureType.id)) {
      selectedMeasureType.setValue(measureType.id);
      selectedMeasureType.markAsDirty();
    }
    selectedMeasureType.markAsDirty();
  }

  isMeasureTypeSelected(idMeasureType: number) {
    const selectedMeasureType = this.measureForm.get('type') as FormControl;
    const selectedValues = selectedMeasureType.value;
    return selectedValues === idMeasureType;
  }


  extendTextArea(element) {
    extendTextArea(element);
  }

  atLeastOneElement(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && control.value.length > 0) {
      return null;
    } else {
      return { atLeastOneElement: true };
    }
  }

}
