<mat-card class="card form-card">
  <mat-card-title>{{ 'cockpit.controlsDashboard' | transloco }}</mat-card-title>
  <mat-card-content>
    <div class="form-content" [formGroup]="dataSample">
      <mat-form-field>
        <mat-label>{{ 'cockpit.observedPeriod' | transloco}}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="start" (focus)="picker.open()">
          <input matEndDate formControlName="end" (focus)="picker.open()">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
  
      <mat-form-field
        data-testid="municipality-selector"
        class="municipalities"
      >
        <mat-label>{{ 'cockpit.observedMunicipalities' | transloco }}</mat-label>
        <mat-select multiple formControlName="municipality">
          <mat-option *ngFor="let municipality of municipalities$ | async" value="{{ municipality.nr }}">
            {{ municipality.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="dataSample.valid" class="card stats-card">
  <mat-card-title class="stats-title">
    {{ ongoingControlsCount$ | async }}
    <small>{{ 'cockpit.ongoingControlCount' | transloco }}</small>
    <span>,&nbsp;</span>
    {{ completedControlsCount$ | async }}
    <small>{{ 'cockpit.completedControlCount' | transloco }}</small>
  </mat-card-title>
  <mat-card-content>
    <table mat-table [dataSource]="controlsStats$ | async" class="data-table">

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef> {{ 'cockpit.controlColor' | transloco }} </th>
        <td mat-cell *matCellDef="let element"> {{element.color ?? '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{ 'cockpit.controlStatus' | transloco }} </th>
        <td mat-cell *matCellDef="let element"> {{ 'cockpit.controlStatusValue'| transloco:{status: element.status} }} </td>
      </ng-container>

      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef> {{ 'cockpit.controlCount' | transloco }} </th>
        <td mat-cell *matCellDef="let element"> {{element.count}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
