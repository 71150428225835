<sibat-widget *transloco="let t; read: 'cockpit.defectWidget'" [title]="t('defectTracking')" [width]="'large-widget'"
  [borderColor]="'border-red'">
  <mat-icon widget-icon>assignment</mat-icon>
  <div widget-content>
    <table class="single-action-list" mat-table [dataSource]="dataSource$ | async">
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{ t('address') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="address-line" *ngFor="let addressLine of element.addresses?.at(0)?.split(',')">
            {{ addressLine }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="deadline">
        <th mat-header-cell *matHeaderCellDef>{{ t('defectDeadline') }}</th>
        <td mat-cell *matCellDef="let element">{{ element.deadline | date: 'dd.MM.yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="controlDate">
        <th mat-header-cell *matHeaderCellDef>{{ t('controlDate') }}</th>
        <td mat-cell *matCellDef="let element">{{ element.controlDate | date: 'dd.MM.yyyy' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"
        (click)="displayControlDefects(row.buildingId, row.controlId)"></tr>
    </table>
    <mat-paginator [hidePageSize]="true" [length]="defectSummaryCount$ | async" [pageSize]="5"
      [pageIndex]="defectSummaryCurrentPage$ | async" (page)="onPageChange($event)"></mat-paginator>
  </div>
</sibat-widget>
