import { KeyValue } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { HistoryEntry, today } from '../model/history.model';

@Component({
  selector: 'sibat-timeline',
  template: `
    <div class="timeline">
      <ng-container *ngFor="let record of timelineData | keyvalue: sortTimeline; first as isFirst; last as isLast">
        <mat-accordion>
          <mat-expansion-panel hideToggle [expanded]="isFirst">
            <mat-expansion-panel-header>
              <div class="timeline__item date-item dot-invisible">
                <mat-icon>expand_more</mat-icon>
                <div class="timeline__date">
                  <div class="date-label">{{ record.key }}</div>
                </div>
                <div class="timeline__content"></div>
              </div>
            </mat-expansion-panel-header>

            <ng-container *ngFor="let content of record.value">
              <building-timeline-entry
                *ngIf="content.type === 'typology'"
                [typology]="content.data"
                [date]="content.date"
              ></building-timeline-entry>

              <building-timeline-entry
                *ngIf="content.type === 'control'"
                [control]="content.data"
                [date]="content.date"
              ></building-timeline-entry>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </div>
  `,
  styleUrls: ['timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent {
  @Input() timelineData: Map<string, HistoryEntry[]>;

  today = today;

  constructor() {}

  sortTimeline(a: KeyValue<string, HistoryEntry[]>, b: KeyValue<string, HistoryEntry[]>): number {
    return a.key === today ? 1 : b.key.localeCompare(a.key);
  }
}
