import { State } from '../../reducers';
import { createSelector } from '@ngrx/store';
import NetworkState from './network.reducer';

export const getNetworkState = (state: State) => state.network;

export const selectIsOffline = createSelector(getNetworkState, (state: NetworkState) => state.forceOffline || !state.online);

export const selectIsOnline = createSelector(getNetworkState, (state: NetworkState) => !state.forceOffline && state.online);

export const selectIsForcedOffline = createSelector(getNetworkState, (state: NetworkState) => state.forceOffline);
