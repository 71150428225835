import { SibatFile } from '../model/sibat-file.model';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface FileBrowserState {
  url: string;
  root: number;
  path: string;
  files: SibatFile[];
}

interface FolderContent {
  path: string;
  files: SibatFile[];
}

interface RenameFile {
  oldName: string;
  newName: string;
}

const initialState: FileBrowserState = {
  url: '',
  root: 0,
  path: '',
  files: []
};

@Injectable()
export class FileBrowserStore extends ComponentStore<FileBrowserState> {

  readonly url$ = this.select(({url}) => url);
  readonly root$ = this.select(({root}) => root);
  readonly path$ = this.select(({path}) => path);
  readonly crumbs$ = this.select(({path}) => {
    const filtered = path.split('/').filter(Boolean);
    return [''].concat(filtered);
  });
  readonly files$ = this.select(({files}) => files);
  readonly dynamicUrl$ = this.select(({url, path}): string | undefined => {
    if (url) {
      return `${url}/${path}`;
    }
    return undefined;
  });

  readonly setUrl = this.updater((state, url: string): FileBrowserState => ({
    ...state,
    url,
  }));

  readonly setRoot = this.updater((state, root: number): FileBrowserState => ({
    ...state,
    root,
  }));

  readonly setPathAndFiles = this.updater((state, folderContent: FolderContent): FileBrowserState => ({
    ...state,
    path: folderContent.path,
    files: folderContent.files
  }));

  readonly renameFile = this.updater((state, renameFile: RenameFile): FileBrowserState => ({
    ...state,
    path: state.path,
    files: state.files.map(file => file.name === renameFile.oldName ? {...file, name: renameFile.newName} : file)
  }));

  readonly markNotEmpty = this.updater((state, destFolder: string): FileBrowserState => ({
    ...state,
    path: state.path,
    files: state.files.map(file => file.name === destFolder ? {...file, isEmpty: false} : file)
  }));

  readonly removeFromList = this.updater((state, removedFileName: string): FileBrowserState => ({
    ...state,
    path: state.path,
    files: state.files.filter(file => file.name !== removedFileName)
  }));

  readonly addFolderToList = this.updater((state, name: string): FileBrowserState => ({
    ...state,
    path: state.path,
    files: [...state.files, {name, isFolder: true, isEmpty: true }]
  }));

  readonly addFileToList = this.updater((state, name: string): FileBrowserState => ({
    ...state,
    path: state.path,
    files: [...state.files, {name, isFolder: false, isEmpty: true }]
  }));

  constructor() {
    super(initialState);
  }

}
