import { environment } from '../../../environments/environment';
import { Hit } from '../model/map.model';
import {
  AddressesLayerAttrs,
  BordersLayerAttrs,
  BuildingsLayerAttrs, CasesLayerAttrs, DossiersLayerAttrs,
  FriacLayerAttrs,
  GenericAttributs,
  ParcelNumbersLayerAttrs,
} from '../model/map-extent.model';
import { translate } from '@ngneat/transloco';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import { Configuration } from '../../authentication/model/configuration.model';

export const LAYER_NAME = {
  buildings: 'BUILDING',
  cases: 'CASES',
  dossiers: 'DOSSIERS',
  addresses: 'ADRESSES',
  borders: 'LIMITES',
  parcelsNumbers: 'PARCELS_NUMBERS',
  parcelsBorders: 'PARCELS_BORDERS',
  friac: 'FRIAC',
} as const;
export type LayerName = typeof LAYER_NAME[keyof typeof LAYER_NAME];

export const getLayer = (configuration: Configuration, layerName: LayerName): FeatureLayer => {
  let outFields = [] as string[];
  let options;

  switch (layerName) {
    case LAYER_NAME.buildings: {
      outFields = [
        BuildingsLayerAttrs.BuildingId,
        BuildingsLayerAttrs.EGID,
        BuildingsLayerAttrs.Affectations,
        BuildingsLayerAttrs.GKLAS,
        BuildingsLayerAttrs.FidBat,
      ];
      options = {
        url: configuration.portalUrl + configuration.buildingsPath,
        minScale: 25000,
      };
      break;
    }
    case LAYER_NAME.cases: {
      outFields = [
        CasesLayerAttrs.NoAffaireFriac,
        CasesLayerAttrs.UrlSharepoint,
      ];
      const sharePointLinkLabel = translate('building.map.sharePointLink');
      const numCaseLabel = translate('building.map.numCase');
      const labelClass = {
        symbol: {
          type: 'text',
          color: [43, 43, 43, 255],
          haloColor: 'white',
          haloSize: 1,
          font: {
            family: 'Arial',
            size: 9,
            weight: 'bold'
          }
        },
        labelPlacement: 'above-center',
        labelExpressionInfo: { expression: `$feature.${CasesLayerAttrs.StatutAffaire}` },
      };
      options = {
        url: configuration.portalUrl + configuration.casesPath,
        definitionExpression: `${CasesLayerAttrs.TypeAffaire} = '0'`,
        minScale: 3000,
        labelingInfo: [labelClass],
        popupTemplate: {
          title: translate('building.map.caseFriac'),
          content: [
            {
              type: 'text',
              text:
                '<a href="{' +
                CasesLayerAttrs.UrlSharepoint +
                '}" style="color:red">' +
                '<span class="esri-icon-link-external" ></span> ' +
                sharePointLinkLabel +
                '</a>',
            },
            {
              type: 'fields',
              fieldInfos: {
                fieldName: `${CasesLayerAttrs.NoAffaireFriac}`,
                label: `${numCaseLabel}`,
              }
            },
          ],
        },
      };
      break;
    }
    case LAYER_NAME.dossiers: {
      outFields = [DossiersLayerAttrs.ID];
      options = {
        url: configuration.portalUrl + configuration.dossiersPath,
        minScale: 25000,
      };
      break;
    }
    case LAYER_NAME.friac: {
      outFields = [GenericAttributs.All];
      const localizedLinkLabel = translate('building.map.friacLink');

      options = {
        url: configuration.portalUrl + configuration.friacPath,
        popupEnabled: true,
        minScale: 3000,
        popupTemplate: {
          title: translate('building.map.buildingPermit'),
          content: [
            {
              type: 'text',
              text:
                '<a href="{' +
                FriacLayerAttrs.LienFriac +
                '}" style="color:red">' +
                '<span class="esri-icon-link-external" ></span> ' +
                localizedLinkLabel +
                '</a>',
            },
            {
              type: 'fields',
              fieldInfos: Object.values(FriacLayerAttrs)
                .filter(attrs => !attrs.includes(FriacLayerAttrs.LienFriac))
                .map(attr => ({ fieldName: attr })),
            },
          ],
        },
      };
      break;
    }
    case LAYER_NAME.addresses: {

      outFields = [AddressesLayerAttrs.Adresse];
      options = {
        url: environment.openDataBaseUrl + environment.adressesPath,
        minScale: 1500,
      };
      break;
    }
    case LAYER_NAME.borders: {
      outFields = [BordersLayerAttrs.FOSNR];
      options = {
        url: environment.openDataBaseUrl + environment.limitesPath,
        minScale: 25000,
      };
      break;
    }
    case LAYER_NAME.parcelsNumbers: {
      outFields = [ParcelNumbersLayerAttrs.Label];
      options = {
        url: environment.openDataBaseUrl + environment.parcelsNumbersPath,
        labelingInfo: [
          {
            symbol: {
              type: 'text',
            },
            labelExpressionInfo: {
              expression: '$feature.' + ParcelNumbersLayerAttrs.Label,
            },
          },
        ],
        minScale: 3000,
      };
      break;
    }
    case LAYER_NAME.parcelsBorders: {
      outFields = [GenericAttributs.ObjectID];
      options = {
        url: environment.openDataBaseUrl + environment.parcelsBordersPath,
        minScale: 3000,
      };
      break;
    }
  }
  return new FeatureLayer({
    outFields,
    id: layerName,
    ...options,
  });
};

export const findLayer = (hits: Array<Hit>, layer: LayerName): Hit | undefined => hits.find(hit => hit.graphic.layer?.id === layer);

export const findAllGraphics = (hits: Array<Hit>, layer: LayerName): Graphic[] =>
  hits.filter(hit => hit.graphic.layer.id === layer).map(hit => hit.graphic);

export const buildDefinitionExpression = (contextValues: string[]): string =>
  `${BuildingsLayerAttrs.LayerContext1} IN (${contextValues.join(',')})` ;
