import { AlertModule } from '../alert/alert.module';
import { NgModule } from '@angular/core';
import { BuildingPageComponent } from './building-page/building-page.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HistoryComponent } from './history/history.component';
import { TypologyComponent } from './typology/typology.component';
import { AppRoutingModule } from '../app-routing.module';
import { BuildingListComponent } from './building-list/building-list.component';
import { ExternalLinksComponent } from './external-links/external-links.component';
import { StringPipeModule } from '../shared/string.module';
import { ArrayPipeModule } from '../shared/array.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BuildingsComponent } from './buildings/buildings.component';
import { SelectorComponent } from './selector/selector.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BuildingListGroupComponent } from './building-list-group/building-list-group.component';
import { BuildingListItemComponent } from './building-list-item/building-list-item.component';
import { TypologyAffectationsComponent } from './typology-affectations/typology-affectations.component';
import { BuildingListButtonsComponent } from './building-list-buttons/building-list-buttons.component';
import { DateAdapter } from '@angular/material/core';
import { DialogTagDetailComponent } from './tag/dialog-tag-detail/dialog-tag-detail.component';
import { TimelineComponent } from './timeline/timeline.component';
import { BuildingDocumentsComponent } from './documents/building-documents.component';
import { FilterComponent } from './filter/filter.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { ModalModule } from '../shared/modal/ModalModule';
import { CustomDateAdapter } from '../shared/date-adapter';
import { ControlModule } from '../control/control.module';
import { LinkControlToBuildingComponent } from './link-control-to-building/link-control-to-building.component';
import { MapModule } from '../map/map.module';
import { SharedModule } from '../shared/shared.module';
import { ControlDefectListComponent } from './control-defect-list/control-defect-list.component';
import { ControlDefectItemComponent } from './control-defect-item/control-defect-item.component';
import { TimelineEntryComponent } from './timeline-entry/timeline-entry.component';
import {
  ControlDefectComplianceFormComponent
} from './control-defect-compliance-form/control-defect-compliance-form.component';
import {
  ControlDefectComplianceDetailsComponent
} from './control-defect-compliance-details/control-defect-compliance-details.component';
import { UploadReportDialogComponent } from './building-documents-detail/upload-report-dialog/upload-report-dialog.component';
import {
  TechnicalInstallationSummaryComponent
} from './technical-installations/technical-installation-summary/technical-installation-summary.component';
import {
  AnnouncementFormDialogComponent
} from './technical-installations/announcement-form-dialog/announcement-form-dialog.component';
import { InstallationListComponent } from './technical-installations/installation-list/installation-list.component';
import {
  InstallationDetailComponent
} from './technical-installations/installation-detail/installation-detail.component';
import { UpdateFormDialogComponent } from './technical-installations/update-form-dialog/update-form-dialog.component';
import { DetailedListComponent } from './detailed-list/detailed-list.component';
import { TypologyIndicatorComponent } from './typology-indicator/typology-indicator.component';
import { TypologyEditorComponent } from './typology-editor/typology-editor.component';
import { BatchTypologyEditDialogComponent } from './batch-typology-edit-dialog/batch-typology-edit-dialog.component';
import {
  BatchNextControlDateEditDialogComponent
} from './batch-next-control-date-edit-dialog/batch-next-control-date-edit-dialog.component';
import { BuildingOverviewComponent } from './building-overview/building-overview.component';
import { BatchControlEditDialogComponent } from './batch-control-edit-dialog/batch-control-edit-dialog.component';
import { BuildingInfoComponent } from './building-info/building-info.component';
import { BuildingAddressComponent } from './building-address/building-address.component';
import { DatePipe } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BuildingDocumentsDetailComponent } from './building-documents-detail/building-documents-detail.component';
import { FileBrowserModule } from '../file-browser/file-browser.module';
import { BuildingRemoveComponent } from './building-remove/building-remove.component';
import { BatchAssessmentEditDialogComponent } from './batch-assessment-edit-dialog/batch-assessment-edit-dialog.component';
import { AssessmentModule } from '../assessment/assessment.module';

@NgModule({
  declarations: [
    BuildingPageComponent,
    BuildingsComponent,
    ExternalLinksComponent,
    HistoryComponent,
    BuildingListComponent,
    BuildingListButtonsComponent,
    TypologyComponent,
    SelectorComponent,
    BuildingListGroupComponent,
    BuildingListItemComponent,
    TypologyAffectationsComponent,
    DialogTagDetailComponent,
    TimelineComponent,
    BuildingDocumentsComponent,
    FilterComponent,
    MenuButtonComponent,
    LinkControlToBuildingComponent,
    ControlDefectListComponent,
    ControlDefectItemComponent,
    TimelineEntryComponent,
    ControlDefectComplianceFormComponent,
    ControlDefectComplianceDetailsComponent,
    UploadReportDialogComponent,
    TechnicalInstallationSummaryComponent,
    AnnouncementFormDialogComponent,
    InstallationListComponent,
    InstallationDetailComponent,
    UpdateFormDialogComponent,
    DetailedListComponent,
    TypologyIndicatorComponent,
    TypologyEditorComponent,
    BatchTypologyEditDialogComponent,
    BatchNextControlDateEditDialogComponent,
    BuildingOverviewComponent,
    BatchControlEditDialogComponent,
    BuildingInfoComponent,
    BuildingAddressComponent,
    BuildingDocumentsDetailComponent,
    BuildingRemoveComponent,
    BatchAssessmentEditDialogComponent,
  ],
    imports: [
        AppRoutingModule,
        ArrayPipeModule,
        FlexModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        StringPipeModule,
        InfiniteScrollModule,
        NgScrollbarModule,
        NgxMatSelectSearchModule,
        AlertModule,
        DragDropModule,
        ModalModule,
        ControlModule,
        MapModule,
        SharedModule,
        FileBrowserModule,
        AssessmentModule,
    ],
  exports: [],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
    },
    DatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always'},
    },
  ],
})
export class BuildingModule {
}
