import { Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsLoading, selectUploadProgress } from './store/loading.selector';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'sibat-loading',
  encapsulation: ViewEncapsulation.None,
  template: `
    <mat-progress-bar
      data-testid="progress-bar"
      *ngIf="loading$ | async"
      [mode]="(progress$ | async) ? 'determinate' : 'query'"
      [value]="progress$ | async"
    ></mat-progress-bar>
  `,
  styles: [
    `
      sibat-loading {
        position: sticky;
        z-index: 9999;
        top: 0;
      }
      mat-progress-bar[mode='determinate'] .mat-progress-bar-fill::after {
        background-color: #d33624;
      }
    `,
  ],
})
export class LoadingComponent {
  loading$ = this.store.select(selectIsLoading).pipe(debounceTime(500));
  progress$ = this.store.select(selectUploadProgress);

  constructor(private store: Store) {}
}
