import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthenticationToken } from './store/authentication.selector';
import { first, mergeMap } from 'rxjs/operators';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { language } from '../shared/locale';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private store: Store, private localeService: TranslocoLocaleService) {}

  language() {
    return language(this.localeService.getLocale());
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthenticationToken).pipe(
      first(),
      mergeMap(token => {
        let cloned;
        if (token) {
          cloned = req.clone({
            headers: req.headers.set('Authorization', token).set('Accept-Language', this.language()),
          });
        } else {
          cloned = req.clone({
            headers: req.headers.set('Accept-Language', this.language()),
          });
        }
        return next.handle(cloned);
      })
    );
  }
}
