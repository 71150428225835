import {
  addDefectComplianceSuccess,
  addDefectSuccess,
  approveControlSuccess,
  cancelOngoingControlSuccess,
  cancelRequestExpertApprovalSuccess,
  clearControlDefects,
  clearCurrentControl,
  downloadFullScreenDefectPictureSuccess,
  extractOngoingControl,
  fetchControlSuccess,
  fetchThumbnailsSuccess,
  findNonCompliantDefectSummarySuccess,
  getControlsStatsSuccess,
  linkControlToBuildingSuccess,
  refreshOfflineDefectsInStoreSuccess,
  refreshOngoingControlSuccess,
  refreshOrphanControlsSuccess,
  refreshOrphanControlSuccess,
  removeDefectSuccess,
  requestExpertApprovalSuccess,
  sendDefectSuccess,
  sendOfflineDefectsSuccess,
  startNewControlSuccess,
  startOrphanControlSuccess,
  toggleControlDefectSelection,
  updateControlDetailsSuccess,
  updateDefectsOrderSuccess,
  updateDefectSuccess,
  validateControlSuccess,
} from './control.action';
import { Action, createReducer, on } from '@ngrx/store';
import {
  Control,
  ControlDefects,
  ControlsStats,
  ControlSummary,
  DefectSummary,
  DefectThumbnail,
  mapToControlSummary,
  StoredOfflineEditDefect,
} from '../model/control.model';

export default interface ControlState {
  control?: Control;
  defectThumbnails: DefectThumbnail[];
  fullScreenPicture: string;
  offlineDefects: StoredOfflineEditDefect[];
  orphanControls: ControlSummary[];
  controlDefects?: ControlDefects;
  defectSummary: DefectSummary[];
  defectSummaryCount: number;
  defectSummaryPage: number;
  stats: ControlsStats[];
}

export const initialState: ControlState = {
  control: undefined,
  defectThumbnails: [],
  fullScreenPicture: '',
  offlineDefects: [],
  orphanControls: [],
  defectSummary: [],
  defectSummaryCount: 0,
  defectSummaryPage: 0,
  controlDefects: undefined,
  stats: [],
};

const controlReducer = createReducer(
  initialState,
  on(
    clearCurrentControl,
    (state): ControlState => ({
      ...state,
      control: undefined,
    })
  ),
  on(
    extractOngoingControl,
    (state, action): ControlState => ({
      ...state,
      control: action.control,
    })
  ),
  on(
    refreshOrphanControlSuccess,
    (state, action): ControlState => ({
      ...state,
      control: action.control,
    })
  ),
  on(
    startNewControlSuccess,
    (state, action): ControlState => ({
      ...state,
      control: action.ongoingControl,
    })
  ),
  on(
    linkControlToBuildingSuccess,
    (state, action): ControlState => {
      const updatedOrphans = state.orphanControls.filter(control => control.id !== action.control.id);
      return ({
        ...state,
        control: action.control,
        orphanControls: updatedOrphans
      });
    }
  ),
  on(
    startOrphanControlSuccess,
    (state, action): ControlState => ({
      ...state,
      control: action.control,
    })
  ),
  on(
    refreshOngoingControlSuccess,
    (state, action): ControlState => ({
      ...state,
      control: action.ongoingControl,
    })
  ),
  on(
    cancelOngoingControlSuccess,
    validateControlSuccess,
    (state): ControlState => ({
      ...state,
      control: undefined,
    })
  ),
  on(updateControlDetailsSuccess, (state, action): ControlState => {
    const orphanControls = state.orphanControls.map(control => {
      if (control.id === action.control.id) {
        return mapToControlSummary(action.control);
      } else {
        return control;
      }
    });
    return {
      ...state,
      control: action.control,
      orphanControls,
    };
  }),
  on(
    addDefectSuccess,
    removeDefectSuccess,
    updateDefectSuccess,
    requestExpertApprovalSuccess,
    cancelRequestExpertApprovalSuccess,
    approveControlSuccess,
    sendDefectSuccess,
    sendOfflineDefectsSuccess,
    (state, action): ControlState => ({
      ...state,
      control: action.control,
    })
  ),
  on(
    fetchControlSuccess,
    addDefectComplianceSuccess,
    (state, { control }): ControlState => ({
      ...state,
      controlDefects: {
        controlId: control.id,
        defects: control.defects ?? [],
        selectedDefects: [],
      },
    })
  ),
  on(
    toggleControlDefectSelection,
    (state, { defect }): ControlState => ({
      ...state,
      controlDefects: {
        ...state.controlDefects,
        selectedDefects: state.controlDefects?.selectedDefects.includes(defect)
          ? state.controlDefects?.selectedDefects.filter(selection => defect !== selection)
          : [...(state.controlDefects?.selectedDefects || []), defect],
      } as ControlDefects,
    })
  ),
  on(
    clearControlDefects,
    (state): ControlState => ({
      ...state,
      controlDefects: initialState.controlDefects,
    })
  ),
  on(
    fetchThumbnailsSuccess,
    (state, action): ControlState => ({
      ...state,
      defectThumbnails: action.thumbnails,
    })
  ),
  on(
    downloadFullScreenDefectPictureSuccess,
    (state, action): ControlState => ({
      ...state,
      fullScreenPicture: action.pictureData,
    })
  ),
  on(
    refreshOfflineDefectsInStoreSuccess,
    (state, action): ControlState => ({
      ...state,
      offlineDefects: action.defects,
    })
  ),
  on(
    updateDefectsOrderSuccess,
    (state, action): ControlState => ({
      ...state,
      control: {
        ...state.control,
        defects: action.defects,
      } as Control,
    })
  ),
  on(
    startOrphanControlSuccess,
    (state, action): ControlState => ({
      ...state,
      orphanControls: [...state.orphanControls, mapToControlSummary(action.control)],
    })
  ),
  on(
    refreshOrphanControlsSuccess,
    (state, action): ControlState => ({
      ...state,
      orphanControls: action.orphanControls,
    })
  ),
  on(
    findNonCompliantDefectSummarySuccess,
    (state, action): ControlState => ({
      ...state,
      defectSummary: action.defects,
      defectSummaryPage: action.page,
      defectSummaryCount: action.count,
    })
  ),
  on(
    getControlsStatsSuccess,
    (state, action): ControlState => ({
      ...state,
      stats: action.controlsStats,
    })
  )
);

export const reducer = (state: ControlState | undefined, action: Action): ControlState => controlReducer(state, action);
