import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sibat-chip-base',
  template: `
    <mat-chip (click)="emitSelected()">
      {{ name }}
      <mat-icon (click)="emitClick(); $event.stopPropagation()">{{ icon }}</mat-icon>
    </mat-chip>
  `,
  styles: [
    `
      mat-chip {
        cursor: pointer;
      }

      mat-icon {
        margin-left: 5px;
      }
    `,
  ],
})
export class ChipBaseComponent {
  @Input() icon: string;
  @Input() name: string;
  @Output() selected = new EventEmitter();
  @Output() iconClicked = new EventEmitter();

  emitSelected() {
    this.selected.emit();
  }

  emitClick() {
    this.iconClicked.emit();
  }
}
