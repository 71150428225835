import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from './sign-in/sign-in.component';
import { PasswordModificationComponent } from './password-modification/password-modification.component';
import { PasswordReinitializationComponent } from './password-reinitialization/password-reinitialization.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { SharedModule } from '../shared/shared.module';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';

@NgModule({
  declarations: [
    SignInComponent,
    PasswordModificationComponent,
    PasswordReinitializationComponent,
    NewPasswordComponent,
    SignInPageComponent,
  ],
  imports: [FormsModule, ReactiveFormsModule, SharedModule],
  exports: [SignInComponent, PasswordModificationComponent, PasswordReinitializationComponent, NewPasswordComponent],
})
export class AuthenticationModule {}
